import control from "./apiService";

export default class Actualizar {
  async ListClientData() {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-clientes"
    );
  }

  async DeleteClient(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/desactivar-cliente",
      data
    );
  }
  async infoClientes(uuid) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/info-cliente",
      uuid
    );
  }
}
