<template>
  <div class="row">
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    <div class="col-xxl-7 col-xxxl-6">
      <WhatHappening :dataArray="dataArray" :dataWarehouse="dataWarehouse" />

      <div class="row">
        <div class="col-lg-6 col-xxxl-6 col-md-6">
          <ExpectedEarnings :dataArray="dataArray" />
        </div>
        <div class="col-lg-6 col-xxxl-6 col-md-6">
          <AverageDailySales :dataArray="dataArray" />
        </div>
      </div>
    </div>
    <div class="col-xxl-5 col-xxxl-6">
      <StatsBoxes :dataWarehouse="dataWarehouse" />
    </div>

    <div class="col-xxl-12 col-xxxl-12">
      <WeeklySales :dataAgent="dataAgent" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import createSocketService from "@/services/socketService";

import WhatHappening from "../../components/Dashboard/General/WhatHappening.vue";

import ExpectedEarnings from "../../components/Dashboard/General/ExpectedEarnings.vue";

import AverageDailySales from "../../components/Dashboard/General/AverageDailySales.vue";

import StatsBoxes from "../../components/Dashboard/General/StatsBoxes.vue";

import WeeklySales from "../../components/Dashboard/General/WeeklySales.vue";

export default defineComponent({
  name: "GeneralPage",
  components: {
    WhatHappening,
    ExpectedEarnings,
    AverageDailySales,
    StatsBoxes,
    WeeklySales
  },
  setup() {
    let socketService: any = null;
    const dataArray = ref<any[]>([]);
    const dataWarehouse = ref<any[]>([]);
    const dataAgent = ref<any[]>([]);
    const isLoading = ref(false);

    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'America/Mexico_City',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    const formatter = new Intl.DateTimeFormat('en-CA', options);
    const parts = formatter.formatToParts(new Date());
    const year = parts.find(part => part.type === 'year')?.value;
    const month = parts.find(part => part.type === 'month')?.value;
    const day = parts.find(part => part.type === 'day')?.value;
    const currentDate = ref(`${year}-${month}-${day}`);


    const getData = async () => {
      try {
        const fechadata = {
          "fecha": currentDate.value
        };

        socketService.emit("general-indicators-day", fechadata);
        socketService.on("general-indicators-day", (rspta: any) => {
          try {
            if (!rspta.error) {
              const respuesta = rspta.response[0].resultado;
              dataArray.value = respuesta;
            }
          } catch (error) {
            console.log(error);
          }
        });

      } catch (error) {
        console.log(error);
      }
    }


    const getDataWarehouse = async () => {
      try {
        const fechadata = {
          "fecha": currentDate.value
        };

        socketService.emit("general-indicators-day-warehouse", fechadata);
        socketService.on("general-indicators-day-warehouse", (rspta: any) => {
          try {
            if (!rspta.error) {
              const respuesta = rspta.response;
              dataWarehouse.value = respuesta;
            }
          } catch (error) {
            console.log(error);
          }
        });

      } catch (error) {
        console.log(error);
      }
    }


    const getDataAgent = async () => {
      try {

        const fechadata = {
          "fecha": currentDate.value
        };

        socketService.emit("general-indicators-day-agent", fechadata);
        socketService.on("general-indicators-day-agent", (rspta: any) => {
          try {
            if (!rspta.error) {
              const respuesta = rspta.response;
              dataAgent.value = respuesta;
            }
          } catch (error) {
            console.log(error);
          }
        });

      } catch (error) {
        console.log(error);
      }
    }

    onMounted(async () => {
      try {
        isLoading.value = true;
        socketService = await createSocketService();
        socketService.connect();
        await getData();
        await getDataWarehouse();
        await getDataAgent();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }

    })

    return {
      dataArray,
      dataWarehouse,
      dataAgent,
      isLoading
    }
  }

});
</script>