import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white top-performer-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "list ps-0 mb-0 list-unstyled" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "mb-1 fw-medium text-black-emphasis" }
const _hoisted_8 = { class: "text-muted d-block" }
const _hoisted_9 = { class: "fw-medium lh-1 fs-13 text-muted d-block" }
const _hoisted_10 = { class: "fw-bold text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"mb-20 mb-md-25 d-flex align-items-center justify-content-between\"><h5 class=\"card-title fw-bold mb-0\">Top Performer</h5><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8\"></i> Delete </a></li></ul></div></div>", 1)),
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.performers, (performer) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "d-flex align-items-center justify-content-between",
            key: performer.id
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: require(`../../../../assets/images/${performer.image}`),
                class: "rounded-circle",
                alt: "user"
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h6", _hoisted_7, _toDisplayString(performer.name), 1),
                _createElementVNode("span", _hoisted_8, " Agent ID: " + _toDisplayString(performer.AgentId), 1)
              ])
            ]),
            _createElementVNode("span", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(performer.Average) + " / ", 1),
              _cache[0] || (_cache[0] = _createTextVNode(" 70 "))
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}