<template>
    <div class="row">
        <div class="col-md-12">
            <CalendarioUser/>
        </div>
    </div>
</template>
<script lang="ts">
import CalendarioUser from "@/components/Calendar/CalendarioUser.vue";
import { defineComponent } from "vue";


export default defineComponent({
    name: "CalendarioComp",
    components: {
        CalendarioUser,
    },
});
</script>