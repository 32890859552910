import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/icon/jpg.png'
import _imports_1 from '../../assets/images/icon/png.png'
import _imports_2 from '../../assets/images/icon/pdf.png'
import _imports_3 from '../../assets/images/icon/txt.png'
import _imports_4 from '../../assets/images/icon/doc.png'
import _imports_5 from '../../assets/images/icon/xl4.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_5 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_6 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_7 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_8 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_9 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_10 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_11 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_12 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_13 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_14 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_15 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_16 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_17 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_18 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_19 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_20 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_21 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_22 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_23 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_24 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_25 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }
const _hoisted_26 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_27 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[24] || (_cache[24] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-sm-20 mb-md-25" }, "Documents", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: _imports_0,
              class: "d-block ms-auto me-auto mb-15",
              alt: "jpg"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" sketch-design.jpg ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[3] || (_cache[3] = _createElementVNode("img", {
              src: _imports_1,
              class: "d-block ms-auto me-auto mb-15",
              alt: "png"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" compile.png ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[5] || (_cache[5] = _createElementVNode("img", {
              src: _imports_2,
              class: "d-block ms-auto me-auto mb-15",
              alt: "pdf"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" integrations.pdf ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _cache[7] || (_cache[7] = _createElementVNode("img", {
              src: _imports_3,
              class: "d-block ms-auto me-auto mb-15",
              alt: "txt"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" contact@32.txt ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[9] || (_cache[9] = _createElementVNode("img", {
              src: _imports_4,
              class: "d-block ms-auto me-auto mb-15",
              alt: "doc"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" app-design.doc ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[11] || (_cache[11] = _createElementVNode("img", {
              src: _imports_5,
              class: "d-block ms-auto me-auto mb-15",
              alt: "xl4"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" wireframes.xl4 ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _cache[13] || (_cache[13] = _createElementVNode("img", {
              src: _imports_1,
              class: "d-block ms-auto me-auto mb-15",
              alt: "png"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" image02.png ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _cache[15] || (_cache[15] = _createElementVNode("img", {
              src: _imports_4,
              class: "d-block ms-auto me-auto mb-15",
              alt: "doc"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" bold-sketch.doc ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _cache[17] || (_cache[17] = _createElementVNode("img", {
              src: _imports_0,
              class: "d-block ms-auto me-auto mb-15",
              alt: "jpg"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" sketch-design.jpg ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _cache[19] || (_cache[19] = _createElementVNode("img", {
              src: _imports_1,
              class: "d-block ms-auto me-auto mb-15",
              alt: "png"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode(" compile.png ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _cache[21] || (_cache[21] = _createElementVNode("img", {
              src: _imports_2,
              class: "d-block ms-auto me-auto mb-15",
              alt: "pdf"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode(" integrations.pdf ")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _cache[23] || (_cache[23] = _createElementVNode("img", {
              src: _imports_3,
              class: "d-block ms-auto me-auto mb-15",
              alt: "txt"
            }, null, -1)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode(" contact@32.txt ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"pagination-area d-md-flex justify-content-between align-items-center\"><p class=\"mb-0 text-paragraph\"> Showing <span class=\"fw-bold\">12</span> out of <span class=\"fw-bold\">100</span> results </p><nav class=\"mt-15 mt-md-0\"><ul class=\"pagination mb-0\"><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Previous\"><i class=\"flaticon-chevron-1\"></i></a></li><li class=\"page-item\"><a class=\"page-link active\" href=\"#\">1</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">2</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">3</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Next\"><i class=\"flaticon-chevron\"></i></a></li></ul></nav></div>", 1))
    ])
  ]))
}