<template>
  <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-25">Registrar nuevo usuario</h5>
      <div class="row">
        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Nombre:</span>
            <input type="text" class="form-control shadow-none fs-md-15 text-black" v-model="nombre" />
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Contraseña:</span>
            <input type="password" class="form-control shadow-none fs-md-15 text-black" v-model="clave" />
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Email:</span>
            <input type="email" class="form-control shadow-none fs-md-15 text-black" v-model="email" />
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Teléfono:</span>
            <input type="text" class="form-control shadow-none fs-md-15 text-black" v-model="telefono" />
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Dirección:</span>
            <input type="text" class="form-control shadow-none fs-md-15 text-black" v-model="direccion" />
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">RFC:</span>
            <input type="text" class="form-control shadow-none fs-md-15 text-black" v-model="rfc" />
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <label for="basicFormControlInput1" class="form-label fw-medium text-black">
            Roles
          </label>
          <Button type="button" label="Nuevo" @click="visible = true" class="btn btn-sm btn-primary ms-10"></Button>
          <Dropdown v-model="selectedRoles" :options="roles" optionLabel="rol" optionValue="uuid"
            placeholder="Seleccione el rol" :filter="true" class="w-100" />
        </div>
        <div class="col-md-6 pt-10">
          <label for="basicFormControlInput1" class="form-label fw-medium text-black">
            Almacenes
          </label>
          <MultiSelect v-model="selectedAlmacenes" :options="almacenes" optionLabel="nombre" optionValue="uuid"
            placeholder="Seleccione almacenes" :filter="true" :multiple="true"
            @update:modelValue="updateSelectedAlmacen" class="w-100" />
        </div>
        <div class="col-md-6 pt-10">
          <label for="basicFormControlInput1" class="form-label fw-medium text-black">
            Permisos
          </label>
          <MultiSelect v-model="selectedPermisos" :options="permisos" optionLabel="permiso" optionValue="uuid"
            placeholder="Selecciona el permiso" :filter="true" :multiple="true"
            @update:modelValue="updateSelectedPermisos" class="w-100" />
        </div>
        <div class="col-md-6 pt-10">
          <label for="basicFormControlInput1" class="form-label fw-medium text-black">
            Agentes
          </label>
          <MultiSelect v-model="selectedAgentes" :options="agentes" optionLabel="nombre_agente_cq" optionValue="uuid"
            placeholder="Selecciona el agente" :filter="true" :multiple="true" class="w-100" />
        </div>

        
        <div class="col-sm-12">
          <div class="col-sm-12 col-md-6 pt-4 row justify-content-center">
            <button type="button" @click="EnviarFormulario" class="btn btn-primary btn-md text-center">
              Registrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="visible" modal header="Registra la nueva aseguradora" :style="{ width: '25rem' }">
    <div class="row">
      <label for="username" class="font-semibold w-30">Nombre</label>
      <div class="row">
        <InputText v-model="newRol" id="username" class="pb-10" autocomplete="off" />
        <button @click="saveRol" type="button" class="btn btn-sm btn-primary ">Guardar</button>
      </div>
    </div>
  </Dialog>
</template>
<script>
import { ref, onMounted, defineComponent, watch } from "vue";
import User from "@/services/userService";
import { useToast } from "vue-toastification";
import Dropdown from 'primevue/dropdown';
import mapService from "@/services/mapService";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: "RegistrarUsuario",
  components: { Dropdown, Button, Dialog, InputText },
  setup() {
    const nombre = ref("");
    const newRol = ref("");
    const clave = ref("");
    const email = ref("");
    const telefono = ref("");
    const direccion = ref("");
    const rfc = ref("");
    const almacenes = ref([]);
    const roles = ref([]);
    const permisos = ref([]);
    const agentes = ref([]);
    const selectedAlmacenes = ref([]);
    const selectedRoles = ref([]);
    const selectedPermisos = ref([]);
    const selectedAgentes = ref([]);
    const toast = useToast();
    const isLoading = ref(false);
    const visible = ref(false);

    const getAlmacenes = async () => {
      try {
        const response = await new mapService().ListAlmacenes();
        almacenes.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar los almacenes.");
      }
    };

    const getAgentLi = async () => {
      try {
        const response = await new mapService().listAgents();
        agentes.value = response.datos;
        console.log(agentes.value);
      } catch (error) {
        toast.error("Error al cargar los agentes.");
      }
    };

    const getRoles = async () => {
      try {
        const response = await new mapService().ListRoles();
        roles.value = response.datos;

      } catch (error) {
        toast.error("Error al cargar los roles");
      }
    };

    const getPermisos = async () => {
      try {
        const response = await new mapService().ListPermisos();
        permisos.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar los permisos");
      }
    };

    const saveRol = async () => {
      try {
        const response = await new mapService().saveNewRol(newRol.value);
        if (!response.error) { 
          toast.success("Rol agregado con éxito");
          await getRoles(); 
          newRol.value = ""; 
        } else {
          toast.error("Error al agregar el rol");
        }
      } catch (error) {
        toast.error("Ocurrió un error al intentar guardar el rol");
      }
    }

    const EnviarFormulario = async () => {
      console.log("Ejecutando EnviarFormulario");
      isLoading.value = true;
      try {
        const data = {
          nombre: nombre.value,
          clave: clave.value,
          email: email.value,
          telefono: telefono.value,
          direccion: direccion.value,
          rfc: rfc.value,
          almacenes: selectedAlmacenes.value,
          rols: selectedRoles.value,
          permisos: selectedPermisos.value,
          agentes: selectedAgentes.value
        };     
        
        const response = await new User().registerUser(data);

        if (!response.error) {
          toast.success("Usuario registrado correctamente");
        } else {
          toast.error("Error en el registro del usuario.");
        }
      } catch (error) {
        toast.error("Error al procesar el formulario.");
      } finally {
        nombre.value = "";
        clave.value = "";
        email.value = "";
        telefono.value = "";
        direccion.value = "";
        rfc.value = "";
        selectedAlmacenes.value = [];
        selectedRoles.value = [];
        selectedPermisos.value = [];
        selectedAgentes.value = [];

        isLoading.value = false;
      }
    };


    const updateSelectedRoles = (newRole) => {
      if (newRole === '215bbe5c-be86-4033-b51a-c45420d212c3') {
        selectedAlmacenes.value = almacenes.value.map(almacen => almacen.uuid);
      } else {
        selectedAlmacenes.value = [];
      }

    };


    const updateSelectedAlmacen = (newAlmacenes) => {
      selectedAlmacenes.value = newAlmacenes;
    };

    const updateSelectedPermisos = (newAlmacenes) => {
      selectedPermisos.value = newAlmacenes
    };




    onMounted(async () => {
      await getAlmacenes();
      await getRoles();
      await getPermisos();
      await getAgentLi();
    });

    watch(selectedRoles, (newValue) => {
      updateSelectedRoles(newValue);
    });

    return {
      nombre,
      clave,
      email,
      telefono,
      direccion,
      rfc,
      isLoading,
      almacenes,
      selectedAlmacenes,
      roles,
      selectedRoles,
      permisos,
      agentes,
      selectedPermisos,
      EnviarFormulario,
      updateSelectedAlmacen,
      updateSelectedRoles,
      updateSelectedPermisos,
      visible,
      saveRol,
      newRol,
      selectedAgentes,
    };
  },
});
</script>