<template>
    <section class="vh-100">
        <div class="container-fluid h-custom">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="@/assets/images/icon/LOGO_CMC24.png" class="img-fluid" alt="Sample image" />
                </div>
                <div v-if="error" class="alert alert-danger alert-dismissible fs-md-15 fs-lg-16" role="alert">
                    {{ errorMessage }}
                    <button type="button" class="btn-close shadow-none" @click="error = false"
                        aria-label="Close"></button>
                </div>
                <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <form novalidate>
                        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
                        <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                            <h2 class="mb-15 mb-md-25 fw-bold text-primary btn-init">
                                Ingrese los datos
                            </h2>
                        </div>

                        <!-- Email input -->
                        <div class="form-outline mb-4">
                            <input type="email" id="form3Example3" class="form-control form-control-lg"
                                placeholder="Ingresa tu correo electronico" v-model="email" />
                            <label class="form-label" for="form3Example3">Correo electronico</label>
                        </div>

                        <!-- Password input -->
                        <div class="form-outline mb-3">
                            <div class="input-group">
                                <span class="input-group-text rounded-0 fs-14 fw-bold text-primary pointer"
                                    @click="togglePasswordVisibility">
                                    <i :class="showPassword ? 'flaticon-visibility-off' : 'flaticon-eye'
                                        "></i>
                                </span>
                                <input :type="showPassword ? 'text' : 'password'"
                                    class="form-control shadow-none rounded-0 text-black" placeholder="**************"
                                    v-model="password" />
                            </div>
                            <label class="form-label" for="form3Example4">Password</label>
                        </div>

                        <div class="text-center text-lg-start mt-4 pt-2 transition border-0 btn-init">
                            <button type="button" class="btn btn-primary btn-lg"
                                style="padding-left: 2.5rem; padding-right: 2.5rem" @click="onLogin">
                                Iniciar sesión
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { computed, ref } from "vue";
import Login from "@/services/loginService";
import { useToast } from "vue-toastification";

export default {
    name: "LoginForm",
    setup() {
        const email = ref<string>("");
        const password = ref<string>("");
        const showPassword = ref(false);
        const emptyEmail = ref<boolean>(false);
        const emptyPass = ref<boolean>(false);
        const error = ref(false);
        const errorMessage = ref("");
        const isLoading = ref(false);
        const toast = useToast();
        const login = new Login();

        const isEmailValid = computed(() => {
            return email.value.trim() !== "";
        });

        const isPassValid = computed(() => {
            return password.value.trim() !== "";
        });

        const onLogin = async () => {
            emptyEmail.value = false;
            emptyPass.value = false;

            if (email.value.trim() == "") {
                emptyEmail.value = true;
                return false;
            }

            if (password.value.trim() == "") {
                emptyPass.value = true;
                return false;
            }

            try {
                isLoading.value = true;
                const response = await login.performLogin(
                    password.value,
                    email.value
                );

                if (response === 1) {
                    window.location.href = "/home";
                } else {
                    toast.error(
                        "¡Ups! Parece que hay un problema. Por favor, verifica tus credenciales e inténtalo nuevamente."
                    );
                }

            } catch (error) {
                toast.error(
                    "¡Ups! Hubo un error durante la autenticación. Por favor, inténtalo nuevamente."
                );
            } finally {
                isLoading.value = false;
            }
        };

        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        };

        return {
            email,
            password,
            showPassword,
            onLogin,
            togglePasswordVisibility,
            isEmailValid,
            isPassValid,
            emptyEmail,
            emptyPass,
            error,
            errorMessage,
            isLoading,
        };
    },
};
</script>

<style lang="scss">
.error-message {
    text-align: center;
    font-size: 0.8em;
    color: red;
}

.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.h-custom {
    height: calc(100% - 73px);
}

.btn-init {
    margin-left: 4.5rem;
}

@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }

    .btn-init {
        margin-left: 0rem !important;
    }
}

@media only screen and (max-width: 767px) {
    .card-body h4 {
        margin-top: 10px !important;
    }

    .btn-init {
        margin-left: 0rem !important;
    }
}
</style>