import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white categories-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "list ps-0 mb-0 list-unstyled" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "title ms-15" }
const _hoisted_8 = { class: "d-block text-muted fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Categories", -1)),
        _createVNode(_component_router_link, {
          to: "/courses-grid",
          class: "card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" All Courses ")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (categorie) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "d-flex align-items-center justify-content-between",
            key: categorie.id
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: require(`../../../../assets/images/${categorie.image}`),
                width: "44",
                height: "44",
                class: "rounded-1",
                alt: "course-image"
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "text-decoration-none text-black-emphasis fw-bold fs-md-15 fs-lg-16"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(categorie.title), 1)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("span", _hoisted_8, [
                  _createTextVNode(_toDisplayString(categorie.quantity) + "+ ", 1),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-normal" }, "Courses", -1))
                ])
              ])
            ]),
            _createVNode(_component_router_link, {
              to: "/course-details",
              class: "link-btn d-inline-block transition text-center position-relative text-decoration-none"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "ph ph-arrow-right" }, null, -1)
              ])),
              _: 1
            })
          ]))
        }), 128))
      ])
    ])
  ]))
}