import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white time-duration-box" }
const _hoisted_2 = { class: "card-body position-relative letter-spacing" }
const _hoisted_3 = { class: "chart" }
const _hoisted_4 = { id: "averageCallDurationChart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"icon position-relative lh-1 text-success\"><i class=\"flaticon-clock\"></i></div><div class=\"content mb-0\"><span class=\"d-block fw-bold text-dark-emphasis mb-1\"> Average Call Duration </span><h2 class=\"mb-0 fw-black\">8 min 30s</h2></div>", 2)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_apexchart, {
            type: "area",
            height: "110",
            id: "averageCallDurationChart",
            options: _ctx.averageCallDurationChart,
            series: _ctx.average
          }, null, 8, ["options", "series"])
        ])
      ])
    ])
  ]))
}