import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-6 col-sm-6" }
const _hoisted_3 = { class: "col-xxl-6 col-sm-6" }
const _hoisted_4 = { class: "input-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_PrimeCalendar = _resolveComponent("PrimeCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.selectedAgents,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAgents) = $event)),
        options: _ctx.agents,
        optionLabel: "nombre",
        optionValue: "uuid_usuario",
        placeholder: "Seleccione un agente",
        class: "w-100",
        filter: true
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_PrimeCalendar, {
          modelValue: _ctx.dates,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dates) = $event)),
          selectionMode: "range",
          placeholder: "Inserta un rango de fechas",
          class: "w-100 shadow-none text-black rounded-0 border-0",
          showIcon: true,
          appendTo: "body"
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}