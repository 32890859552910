<template>
    <AsignarRazon />
  
  </template>
  <script lang="ts">
  import { defineComponent } from "vue";
  import AsignarRazon from "@/components/Formulario/AsignarRazon.vue";
  
  export default defineComponent({
    name: "AsignarClientRa",
    components: {
        AsignarRazon,
    },
  });
  </script>