<template>
    <DashboardVehicles />
  
  </template>
  <script lang="ts">
  import { defineComponent } from "vue";
import DashboardVehicles from "../../components/Maps/DashboardVehicles.vue"
  
  export default defineComponent({
    name: "GoogleMapsGener",
    components: {
        DashboardVehicles,
    },
  });
  </script>