import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../../../assets/images/map.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white revenue-country-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-lg-4" }
const _hoisted_5 = { class: "list ps-0 mb-0 list-unstyled" }
const _hoisted_6 = { class: "d-block text-black-emphasis fw-medium fs-md-15 fs-lg-16 mb-1" }
const _hoisted_7 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_8 = { class: "fw-medium text-black-emphasis" }
const _hoisted_9 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"mb-15 md-lg-25 d-sm-flex align-items-center justify-content-between\"><h5 class=\"card-title fw-bold mb-0\">Revenue Target By Country</h5><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8\"></i> Delete </a></li></ul></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.revenues, (revenue) => {
              return (_openBlock(), _createElementBlock("li", {
                key: revenue.id
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(revenue.country), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", {
                    class: _normalizeClass(['fw-medium', revenue.progressColor])
                  }, _toDisplayString(revenue.progress) + "% ", 3),
                  _createElementVNode("span", _hoisted_8, [
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-muted" }, "Target -", -1)),
                    _createTextVNode(" " + _toDisplayString(revenue.target), 1)
                  ])
                ]),
                _createElementVNode("div", {
                  class: "progress",
                  role: "progressbar",
                  "aria-valuenow": revenue.progress,
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(['progress-bar', revenue.bgColor]),
                    style: _normalizeStyle({ width: revenue.progress + '%' })
                  }, null, 6)
                ], 8, _hoisted_9)
              ]))
            }), 128))
          ])
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-lg-8" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "map"
          })
        ], -1))
      ])
    ])
  ]))
}