import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/images/starter.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Starter" }),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"card mb-25 border-0 rounded-0 bg-white text-center starter-card\"><div class=\"card-body letter-spacing\"><img src=\"" + _imports_0 + "\" alt=\"starter-image\"><h1 class=\"fw-bold text-black\"> Welcome To The Adlash Admin Template. Let’s Create Your Own Admin Panel. </h1><button class=\"default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success\" type=\"submit\"> Get Started </button></div></div>", 1))
  ], 64))
}