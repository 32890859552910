import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/logo.svg'
import _imports_1 from '../../../assets/images/white-logo.svg'
import _imports_2 from '../../../assets/images/qr-code.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing invoice-details-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_3 = { class: "d-lg-flex justify-content-between" }
const _hoisted_4 = { class: "text-start" }
const _hoisted_5 = { class: "invoice-info d-lg-flex justify-content-between" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = { class: "ms-10 ms-sm-50 ms-md-100" }
const _hoisted_8 = { class: "table-responsive" }
const _hoisted_9 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_10 = { class: "shadow-none text-paragraph product-title" }
const _hoisted_11 = { class: "shadow-none text-paragraph product-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spam = _resolveComponent("spam")!
  const _component_spand = _resolveComponent("spand")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            src: _imports_0,
            class: "black-logo",
            alt: "logo"
          }, null, -1)),
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_1,
            class: "white-logo",
            alt: "logo"
          }, null, -1)),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "d-block mt-12 mt-md-15 mt-lg-30 fs-md-15 fs-lg-16 fw-medium text-paragraph mb-5 mb-md-8" }, " Company Admin ", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mb-5 text-muted fs-md-15 fs-md-16 lh-base" }, [
            _createTextVNode(" 795 Folsom Ave, Suite 600 San "),
            _createElementVNode("br"),
            _createTextVNode("Francisco, CA 94107, USA ")
          ], -1)),
          _createVNode(_component_spam, { class: "d-block text-muted fs-md-15 fs-md-16" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Reg. number : 245000002519 ")
            ])),
            _: 1
          })
        ]),
        _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"text-lg-center mt-15 mt-md-20 mt-lg-0\"><h2 class=\"text-black fw-black mb-5 mb-sm-10 mb-lg-20 fs-18 fs-md-20 fs-lg-30\"> Invoice </h2><span class=\"d-block fs-md-15 fs-md-16 text-muted\"><span class=\"me-md-5 d-block d-md-inline-block\"> Inv. number : <span class=\"text-paragraph\">#3256120</span></span><span class=\"ms-md-5 d-block d-md-inline-block\"> Date : <span class=\"text-paragraph\">25th March, 2023</span></span></span></div><div class=\"text-lg-end mt-15 mt-md-20 mt-lg-0\"><img src=\"" + _imports_2 + "\" alt=\"qr-code\"></div>", 2))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", null, [
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "d-block text-black fs-md-15 fw-bold mb-12" }, " Invoice To: ", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 fw-medium text-paragraph mb-8" }, " Jane Ronan ", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mb-5 text-muted fs-md-15 fs-md-16 lh-base" }, [
              _createTextVNode(" 795 Folsom Ave, Suite 600 San "),
              _createElementVNode("br"),
              _createTextVNode("Francisco, CA 94107, USA ")
            ], -1)),
            _createVNode(_component_spam, { class: "d-block text-muted fs-md-15 fs-md-16" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Reg. number : 245000002519 ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[11] || (_cache[11] = _createElementVNode("span", { class: "d-block text-black fs-md-15 fw-bold mb-12" }, " Payment: ", -1)),
            _cache[12] || (_cache[12] = _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 fw-medium text-paragraph mb-8" }, " American Bank Ltd. ", -1)),
            _cache[13] || (_cache[13] = _createElementVNode("p", { class: "mb-5 text-muted fs-md-15 fs-md-16 lh-base" }, " IBAN: EST6049283742809 ", -1)),
            _createVNode(_component_spam, { class: "d-block text-muted fs-md-15 fs-md-16" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" Swift Code: SP29015 ")
              ])),
              _: 1
            })
          ])
        ]),
        _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"buttons-list mt-15 mt-md-20 mt-lg-0\"><button class=\"default-btn position-relative transition border-0 fw-medium text-white pt-12 pb-12 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10\" type=\"button\"> Print <i class=\"flaticon-printer position-relative ms-5 fs-12\"></i></button><button class=\"default-outline-btn position-relative transition fw-medium text-primary pt-10 pb-10 ps-25 pe-25 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block bg-gray\" type=\"button\"> Download <i class=\"flaticon-file-1 position-relative ms-5 top-2 fs-15\"></i></button></div>", 1))
      ]),
      _cache[28] || (_cache[28] = _createElementVNode("span", { class: "d-block text-black fs-md-15 fw-bold mb-15 mb-md-20 mb-lg-25" }, " Order Summary ", -1)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("table", _hoisted_9, [
          _cache[27] || (_cache[27] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              }, " #No "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " Item "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " Quantity "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " Unit Price "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 pe-0"
              }, " Total Price ")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _cache[17] || (_cache[17] = _createElementVNode("td", { class: "shadow-none lh-1 fw-semibold text-black ps-0" }, "1.", -1)),
              _createElementVNode("td", _hoisted_10, [
                _cache[16] || (_cache[16] = _createElementVNode("h5", { class: "text-black fw-bold fs-14 fs-md-15 fs-md-16 mb-8 mb-md-10" }, " Sanung Airpods 3s Voltage Multifunction ", -1)),
                _createVNode(_component_spand, { class: "d-block" }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Brand Model: 360059XT, With multifunction Speaker "),
                    _createElementVNode("br", null, null, -1),
                    _createTextVNode("and wifi support. ")
                  ])),
                  _: 1
                })
              ]),
              _cache[18] || (_cache[18] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-paragraph" }, "3", -1)),
              _cache[19] || (_cache[19] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-paragraph" }, "$125.00", -1)),
              _cache[20] || (_cache[20] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-paragraph pe-0" }, " $375.00 ", -1))
            ]),
            _createElementVNode("tr", null, [
              _cache[23] || (_cache[23] = _createElementVNode("td", { class: "shadow-none lh-1 fw-semibold text-black ps-0" }, "2.", -1)),
              _createElementVNode("td", _hoisted_11, [
                _cache[22] || (_cache[22] = _createElementVNode("h5", { class: "text-black fw-bold fs-14 fs-md-15 fs-md-16 mb-8 mb-md-10" }, " Sanung 14” Laptop With HD Display, 8gb Ram, 16gb Rom ", -1)),
                _createVNode(_component_spand, { class: "d-block" }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode(" Brand Model: 360059XT, With multifunction Speaker "),
                    _createElementVNode("br", null, null, -1),
                    _createTextVNode("and wifi support. ")
                  ])),
                  _: 1
                })
              ]),
              _cache[24] || (_cache[24] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-paragraph" }, "2", -1)),
              _cache[25] || (_cache[25] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-paragraph" }, " $1250.00 ", -1)),
              _cache[26] || (_cache[26] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-paragraph pe-0" }, " $2500.00 ", -1))
            ])
          ])
        ])
      ]),
      _cache[29] || (_cache[29] = _createStaticVNode("<ul class=\"order-summary-list ps-0 mb-0 list-unstyled\"><li class=\"d-flex align-items-center justify-content-between\"><span class=\"d-block text-paragraph fw-medium\"> Grand Total </span><span class=\"d-block text-black fs-md-15 fs-lg-16 fw-medium\"> $2875.00 </span></li><li class=\"d-flex align-items-center justify-content-between\"><span class=\"d-block text-paragraph fw-medium\"> Shipping Charge </span><span class=\"d-block text-black fs-md-15 fs-lg-16 fw-medium\"> + $15.00 </span></li><li class=\"d-flex align-items-center justify-content-between\"><span class=\"d-block text-paragraph fw-medium\"> Discount </span><span class=\"d-block text-black fs-md-15 fs-lg-16 fw-medium\"> - $80.00 </span></li><li class=\"d-flex align-items-center justify-content-between\"><span class=\"d-block text-paragraph fw-medium\"> Estimated Tax </span><span class=\"d-block text-black fs-md-15 fs-lg-16 fw-medium\"> + $180.00 </span></li><li class=\"d-flex align-items-center justify-content-between\"><span class=\"d-block text-paragraph fw-medium\"> Total </span><span class=\"d-block text-primary fs-md-15 fs-lg-16 fw-bold\"> $2990.00 </span></li></ul><span class=\"d-block text-black text-center fs-md-15 fs-lg-16 mt-15 mt-md-25 mt-lg-35 mt-xl-50\"> # Thanks For Shopping With <span class=\"text-primary fw-bold\">Adlash</span> # </span>", 2))
    ])
  ]))
}