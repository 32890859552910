<template>
    <DialogComponent v-model:visible="localVisible" :style="{ width: '30rem' }">
        <template #header>
            <h5 class="card-subtitle fw-bold">Comentarios</h5>
        </template>
        <div ref="commentsContainer" style="max-height: 300px; overflow-y: auto;">
            <div v-for="comment in localTask.responseComentarios" :key="comment.uuid" class="comment-box mb-3">
                <strong>{{ comment.nombre }}:</strong>
                <p>{{ comment.comentario }}</p>
            </div>
        </div>

        <div class="mb-3">
            <label for="newComment" class="form-label fw-medium">Nuevo Comentario:</label>
            <textarea id="newComment" class="form-control" v-model="newComment" rows="3"
                placeholder="Escribe tu comentario aquí..."></textarea>
        </div>

        <button class="btn btn-primary btn-sm" @click="addComment">
            Agregar Comentario
        </button>
        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    </DialogComponent>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, nextTick } from "vue";
import createSocketService from "@/services/socketService";
import { useToast } from "vue-toastification";

export default defineComponent({
    props: {
        visible: Boolean,
        task: Object,
    },
    emits: ["close", "comment-add"],
    setup(props, { emit }) {
        const localTask = ref({ ...props.task });
        const localVisible = ref(props.visible);
        const newComment = ref("");
        const toast = useToast();
        const commentsContainer = ref<HTMLElement | null>(null);
        let socketService: any = null;
        const isLoading = ref(false);

        watch(
            () => props.visible,
            (newVal) => {
                console.log("Nuevo valor", newVal);
                localVisible.value = newVal;
                if (newVal) {
                    nextTick(() => {
                        if (commentsContainer.value) {
                            commentsContainer.value.scrollTop = commentsContainer.value.scrollHeight;
                        }
                    });
                }
            }
        );


        //detecto cuando la variable de modal cambie, osea se cierre el modal 
        watch(localVisible, (newValue) => {
            if (!newValue) {
                emit("close");
            }
        });


        const addComment = () => {
            isLoading.value = true;
            if (newComment.value.trim()) {
                const commentData = {
                    uuid_tarea: props.task?.uuid,
                    comentario: newComment.value,
                    archivos: [],
                    nombre: "Tu comentario"
                };

                localTask.value.responseComentarios.push({
                    uuid: "",
                    nombre: commentData.nombre,
                    comentario: commentData.comentario,
                    archivos: commentData.archivos,
                });

                socketService.emit("comment-add", commentData);
                console.log("Comentario agregado:", commentData);

                newComment.value = "";

                nextTick(() => {
                    if (commentsContainer.value) {
                        commentsContainer.value.scrollTop = commentsContainer.value.scrollHeight;
                    }
                });
            }
        };

        const close = () => {
            emit("close");
        };

        onMounted(async () => {
            try {
                socketService = await createSocketService();
                socketService.connect();

                socketService.on('comment-add', (response) => {
                    if (!response.error) {
                        toast.success("Comentario agregado correctamente");
                        socketService.on('save-task-notification', (response) => {
                            if (!response.error) {
                                toast.success("Tarea enviada correctamente");
                            } else {
                                toast.error("Error al enviar la tarea");
                            }
                        });
                    } else {
                        toast.error("Error al enviar la tarea");
                    }
                    isLoading.value = false;
                });
            } catch (error) {
                console.log(error);
            }
        });

        return {
            localVisible,
            localTask,
            newComment,
            addComment,
            close,
            commentsContainer,
            isLoading
        };
    },
});
</script>
