import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white most-lead-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "list ps-0 mb-0 list-unstyled" }
const _hoisted_4 = ["data-bs-title"]
const _hoisted_5 = { class: "row align-items-center" }
const _hoisted_6 = { class: "col-md-4 col-lg-4" }
const _hoisted_7 = { class: "title d-flex align-items-center" }
const _hoisted_8 = { class: "d-block fw-bold text-black-emphasis ms-15 fs-md-15 fs-lg-16" }
const _hoisted_9 = { class: "col-md-6 col-lg-6" }
const _hoisted_10 = { class: "lead-progress me-md-15" }
const _hoisted_11 = ["aria-valuenow"]
const _hoisted_12 = { class: "col-md-2 col-lg-2 text-md-end" }
const _hoisted_13 = { class: "d-block fw-medium text-black-emphasis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"mb-20 d-sm-flex align-items-center justify-content-between letter-spacing\"><h5 class=\"card-title fw-bold mb-0\">Most Lead</h5><div class=\"card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><span class=\"fw-medium text-muted me-8\">Last</span><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option value=\"1\" class=\"fw-medium\" selected>1 Week</option><option value=\"2\" class=\"fw-medium\">2 Week</option><option value=\"3\" class=\"fw-medium\">3 Week</option></select></div></div>", 1)),
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead) => {
          return (_openBlock(), _createElementBlock("li", {
            key: lead.id,
            "data-bs-toggle": "tooltip",
            "data-bs-title": lead.progress + '%'
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass([
                    'icon text-center position-relative rounded-circle',
                    lead.iconColor,
                  ])
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(['position-absolute start-0 end-0 lh-1', lead.icon])
                    }, null, 2)
                  ], 2),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(lead.title), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", {
                    class: "progress",
                    role: "progressbar",
                    "aria-valuenow": lead.progress,
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(['progress-bar', lead.class]),
                      style: _normalizeStyle({ width: lead.progress + '%' })
                    }, null, 6)
                  ], 8, _hoisted_11)
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, _toDisplayString(lead.count), 1)
              ])
            ])
          ], 8, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}