import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleMaps = _resolveComponent("GoogleMaps")!
  const _component_FormularioMaps = _resolveComponent("FormularioMaps")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_GoogleMaps, {
      uuid: _ctx.uuid,
      onMarkerUpdated: _ctx.updateForm
    }, null, 8, ["uuid", "onMarkerUpdated"]),
    _createVNode(_component_FormularioMaps, { formData: _ctx.formData }, null, 8, ["formData"])
  ]))
}