import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-15 p-lg-30" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_5 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_6 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_7 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_8 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_9 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_10 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_11 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_12 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_13 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_14 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_15 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_16 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_17 = { class: "col-12 col-md-12 pt-3" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "row mb-2" }
const _hoisted_23 = { class: "row mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: 'Cargando...'
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[40] || (_cache[40] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-md-25" }, "Registro Vehicular", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[27] || (_cache[27] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Numero:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.numero,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.numero) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[28] || (_cache[28] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Selecciona un modelo:", -1)),
            _createVNode(_component_Button, {
              type: "button",
              label: "Nuevo Marca",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = true)),
              class: "btn btn-sm btn-primary"
            }),
            _createVNode(_component_Button, {
              type: "button",
              label: "Nuevo Modelo",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visibleModelo = true)),
              class: "btn btn-sm btn-secondary"
            }),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedModel,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedModel) = $event)),
              options: _ctx.modelos,
              optionLabel: "modelo",
              optionValue: "uuid",
              placeholder: "Seleccione un modelo",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[29] || (_cache[29] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Descripcion:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.descripcion,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.descripcion) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[30] || (_cache[30] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Serie:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.serie,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.serie) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[31] || (_cache[31] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Placas:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.placas,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.placas) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[32] || (_cache[32] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Color:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.color,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.color) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[33] || (_cache[33] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Tarjeta de circulacion:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.circulacion,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.circulacion) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[34] || (_cache[34] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Estado de Emision:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.emision,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.emision) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[35] || (_cache[35] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Observaciones:", -1)),
            _createVNode(_component_FloatLabel, null, {
              default: _withCtx(() => [
                _createVNode(_component_Textarea, {
                  modelValue: _ctx.observacion,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.observacion) = $event)),
                  rows: "1",
                  cols: "30",
                  class: "w-100"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[36] || (_cache[36] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Selecciona un propietario:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedEmpresa,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedEmpresa) = $event)),
              options: _ctx.empresas,
              optionLabel: "empresa",
              optionValue: "uuid",
              placeholder: "Selecciona un propietario",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[37] || (_cache[37] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Selecciona un ocupante:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedOcupante,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedOcupante) = $event)),
              options: _ctx.empresas,
              optionLabel: "empresa",
              optionValue: "uuid",
              placeholder: "Selecciona un ocupante",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[38] || (_cache[38] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Selecciona un responsable:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedResposable,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.selectedResposable) = $event)),
              options: _ctx.responsables,
              optionLabel: "nombre",
              optionValue: "uuid_usuario",
              placeholder: "Selecciona un responsable",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[39] || (_cache[39] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Selecciona una aseguradora:", -1)),
            _createVNode(_component_Button, {
              type: "button",
              label: "Nueva Aseguradora",
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.visible = true)),
              class: "btn btn-sm btn-primary"
            }),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedAseguradoras,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedAseguradoras) = $event)),
              options: _ctx.aseguradoras,
              optionLabel: "aseguradora",
              optionValue: "uuid",
              placeholder: "Selecciona una aseguradora",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary me-2",
              onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
            }, "Guardar")
          ])
        ])
      ])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.visible) = $event)),
      modal: "",
      header: "Registra la nueva aseguradora",
      style: { width: '25rem' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_18, [
          _cache[41] || (_cache[41] = _createElementVNode("label", {
            for: "username",
            class: "font-semibold w-30"
          }, "Nombre", -1)),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_InputText, {
              modelValue: _ctx.asegura,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.asegura) = $event)),
              v: "",
              class: "pb-10",
              autocomplete: "off"
            }, null, 8, ["modelValue"]),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.saveAse && _ctx.saveAse(...args))),
              class: "btn btn-sm btn-primary"
            }, "Guardar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.visible) = $event)),
      modal: "",
      header: "Registra una nueva marca",
      style: { width: '25rem' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_20, [
          _cache[42] || (_cache[42] = _createElementVNode("label", {
            for: "username",
            class: "font-semibold w-30"
          }, "Nombre", -1)),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_InputText, {
              modelValue: _ctx.marca,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.marca) = $event)),
              v: "",
              class: "pb-10",
              autocomplete: "off"
            }, null, 8, ["modelValue"]),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.saveMarc && _ctx.saveMarc(...args))),
              class: "btn btn-sm btn-primary"
            }, "Guardar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visibleModelo,
      "onUpdate:visible": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.visibleModelo) = $event)),
      modal: "",
      header: "Registra un nuevo modelo",
      style: { width: '25rem' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_22, [
          _cache[43] || (_cache[43] = _createElementVNode("label", {
            for: "marca",
            class: "font-semibold w-30"
          }, "Selecciona una marca", -1)),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.selectedMarca,
            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.selectedMarca) = $event)),
            options: _ctx.marcas,
            optionLabel: "marca",
            optionValue: "uuid",
            placeholder: "Seleccione una marca",
            class: "w-100"
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _cache[44] || (_cache[44] = _createElementVNode("label", {
            for: "modelo",
            class: "font-semibold w-30"
          }, "Nombre del Modelo", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.modelo,
            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.modelo) = $event)),
            class: "w-100",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.saveModelo && _ctx.saveModelo(...args))),
          class: "btn btn-sm btn-primary mt-2"
        }, "Guardar")
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}