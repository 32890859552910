import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white monthly-revenue-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }, [
      _createElementVNode("span", { class: "d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis" }, " REVENUE THIS MONTH "),
      _createElementVNode("h4", { class: "fw-black mb-12 lh-1" }, "$16,520"),
      _createElementVNode("span", { class: "fw-bold text-danger text-badge d-inline-block" }, [
        _createTextVNode(" 1.5% "),
        _createElementVNode("i", { class: "flaticon-down-arrow fs-11 lh-1 position-relative top-1" })
      ]),
      _createElementVNode("div", { class: "mt-15 mt-md-25 mb-8 mb-md-12 d-flex justify-content-between align-items-center" }, [
        _createElementVNode("span", { class: "fw-medium text-secondary" }, [
          _createElementVNode("span", { class: "fw-bold text-black" }, "$9,500"),
          _createTextVNode(" more to reach goal ")
        ]),
        _createElementVNode("span", { class: "text-primary fw-semibold" }, "68%")
      ]),
      _createElementVNode("div", {
        class: "progress",
        role: "progressbar",
        "aria-valuenow": "68",
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }, [
        _createElementVNode("div", {
          class: "progress-bar",
          style: {"width":"68%"}
        })
      ])
    ], -1)
  ])))
}