<template>
  <div class="card mb-25 border-0 rounded-0 bg-white event-topic-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div class="mb-15 mb-sm-20 mb-md-25 mb-lg-30">
        <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
          Topic To Be Covered
        </h5>
      </div>
      <ul class="ps-0 mb-0 list-unstyled">
        <li
          class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
        >
          <i class="flaticon-check-mark-1"></i>
          Complete beginner to expert AI skill
        </li>
        <li
          class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
        >
          <i class="flaticon-check-mark-1"></i>
          Using ChatGPT for coding
        </li>
        <li
          class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
        >
          <i class="flaticon-check-mark-1"></i>
          Real world solution
        </li>
        <li
          class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
        >
          <i class="flaticon-check-mark-1"></i>
          How to optimize your AI
        </li>
        <li
          class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
        >
          <i class="flaticon-check-mark-1"></i>
          How to start building AI
        </li>
        <li
          class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
        >
          <i class="flaticon-check-mark-1"></i>
          How to merge AI with OpenAI Gym
        </li>
        <li
          class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
        >
          <i class="flaticon-check-mark-1"></i>
          How to optimize your AI
        </li>
        <li
          class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
        >
          <i class="flaticon-check-mark-1"></i>
          Real world solution
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopicToBeCovered",
};
</script>