import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-15 p-lg-30" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_5 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_6 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_7 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_8 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_9 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_10 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_11 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_12 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_13 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_14 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_15 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "preview-card position-relative text-center" }
const _hoisted_18 = { class: "preview-content d-flex flex-column align-items-center justify-content-center" }
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  key: 1,
  class: "preview-pdf"
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["onUpdate:modelValue"]
const _hoisted_23 = { class: "col-12 col-md-12 pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: 'Cargando...'
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[21] || (_cache[21] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-md-25" }, "Registro", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[10] || (_cache[10] = _createElementVNode("label", {
              for: "taskTitle",
              class: "form-label fw-medium"
            }, "Fecha:", -1)),
            _createVNode(_component_Calendar, {
              modelValue: _ctx.fecha,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fecha) = $event)),
              class: "w-100",
              showIcon: "",
              showOnFocus: true,
              inputId: "buttondisplay"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[11] || (_cache[11] = _createElementVNode("label", {
              for: "taskPartners",
              class: "form-label fw-medium"
            }, "Agente:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedPermisos.agente,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedPermisos.agente) = $event)),
              options: _ctx.agentList,
              filter: true,
              optionValue: "uuid",
              optionLabel: "agente",
              placeholder: "Seleccione un agente",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[12] || (_cache[12] = _createElementVNode("label", {
              for: "taskPartners",
              class: "form-label fw-medium"
            }, "Folio Factura:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedPermisos.folioFactura,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPermisos.folioFactura) = $event)),
              options: _ctx.invoiceList,
              optionLabel: _ctx.formatOptionLabel,
              filter: true,
              optionValue: "uuid",
              placeholder: "Seleccione una factura",
              class: "w-100"
            }, null, 8, ["modelValue", "options", "optionLabel"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[13] || (_cache[13] = _createElementVNode("label", {
              for: "taskPartners",
              class: "form-label fw-medium"
            }, "Cliente:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control shadow-none",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedPermisos.cliente) = $event)),
              readonly: ""
            }, null, 512), [
              [_vModelText, _ctx.selectedPermisos.cliente]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[14] || (_cache[14] = _createElementVNode("label", {
              for: "taskTitle",
              class: "form-label fw-medium"
            }, "Monto:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "Ingrese monto",
              class: "form-control shadow-none",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.monto) = $event))
            }, null, 512), [
              [_vModelText, _ctx.monto]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[15] || (_cache[15] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Forma de pago:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedPermisos.formaPago,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedPermisos.formaPago) = $event)),
              options: _ctx.methods,
              optionLabel: "nombre",
              optionValue: "uuid",
              filter: true,
              placeholder: "Seleccione una forma de pago",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[16] || (_cache[16] = _createElementVNode("label", {
              for: "taskTitle",
              class: "form-label fw-medium"
            }, "Estatus del sistema:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "Ingrese status",
              class: "form-control shadow-none",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedPermisos.statusSistema) = $event))
            }, null, 512), [
              [_vModelText, _ctx.selectedPermisos.statusSistema]
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[17] || (_cache[17] = _createElementVNode("label", {
              for: "taskTitle",
              class: "form-label fw-medium"
            }, "Folio REP:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "Ingrese folio",
              class: "form-control shadow-none",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedPermisos.folioRep) = $event))
            }, null, 512), [
              [_vModelText, _ctx.selectedPermisos.folioRep]
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[18] || (_cache[18] = _createElementVNode("label", {
              for: "taskPartners",
              class: "form-label fw-medium"
            }, "Observaciones:", -1)),
            _withDirectives(_createElementVNode("textarea", {
              id: "newComment",
              class: "form-control",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedPermisos.observaciones) = $event)),
              rows: "2",
              placeholder: "Escribe alguna observación aquí..."
            }, null, 512), [
              [_vModelText, _ctx.selectedPermisos.observaciones]
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[19] || (_cache[19] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Archivos/ imágenes* : ", -1)),
              _createVNode(_component_FileUpload, {
                ref: "fileUploadRef",
                mode: "basic",
                name: "documento[]",
                multiple: true,
                accept: "image/*, application/pdf",
                maxFileSize: 1000000,
                auto: false,
                chooseLabel: "Explorar",
                style: {"width":"100%"},
                onSelect: _ctx.manageSelectedFiles
              }, null, 8, ["onSelect"])
            ]),
            (_ctx.filesPreview.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _cache[20] || (_cache[20] = _createElementVNode("h5", null, "Previsualización:", -1)),
                  _createElementVNode("div", _hoisted_16, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filesPreview, (archivo, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "col-4 mb-3"
                      }, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            (archivo.tipo === 'imagen')
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: archivo.url,
                                  alt: "Imagen subida",
                                  class: "preview-image"
                                }, null, 8, _hoisted_19))
                              : (_openBlock(), _createElementBlock("div", _hoisted_20, "📄 PDF: " + _toDisplayString(archivo.nombre), 1)),
                            _createElementVNode("button", {
                              onClick: ($event: any) => (_ctx.deleteFile(index)),
                              class: "delete-button"
                            }, " × ", 8, _hoisted_21),
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": ($event: any) => ((archivo.observacion) = $event),
                              type: "text",
                              placeholder: "Observación",
                              class: "mt-2 form-control"
                            }, null, 8, _hoisted_22), [
                              [_vModelText, archivo.observacion]
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary me-2",
              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
            }, "Guardar")
          ])
        ])
      ])
    ])
  ], 64))
}