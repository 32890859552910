<template>
  <div class="row">
    <div class="col-xxxl-8">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing project-card"
      >
        <div class="card-body p-20 p-md-25 p-lg-30">
          <div
            class="mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center"
          >
            <div class="title d-flex align-items-center">
              <img
                src="../../../assets/images/projects/project-mini1.jpg"
                class="rounded-1"
                width="80"
                alt="project"
              />
              <div class="ms-15">
                <span class="badge text-outline-primary">In Process</span>
                <h5
                  class="mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mt-8 mt-md-10 text-black"
                >
                  Adlash CRM Dashboard Design
                </h5>
              </div>
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                  >
                    <i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                  >
                    <i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                  >
                    <i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <span
            class="d-block fs-md-15 fs-md-16 text-black fw-medium mb-8 mb-md-10"
          >
            Project Details:
          </span>
          <p class="text-muted lh-base fs-md-15 fs-lg-16">
            This project combiles all the data and backend information to the
            frontend and also design should be appealing. To an English person,
            it will seem like simplified English, as a skeptical Cambridge
            friend of mine told me what Occidental is. The European languages
            are members of the same family. Their separate existence is a myth.
            For science, music, sport, etc.
          </p>
          <ul class="features-list ps-0 list-unstyled mb-15">
            <li class="position-relative fs-md-15 fs-lg-16 text-black">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium.
            </li>
            <li class="position-relative fs-md-15 fs-lg-16 text-black">
              Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
              toterum pitael accustision.
            </li>
          </ul>
          <p class="text-muted lh-base fs-md-15 fs-lg-16">
            New 2023 Speedmaster Moonwatch Professional Co-Axial Master
            Chronometer 42mm Mens. Lorem ipsum dolor sit amet, consectetur
            adipising, sed do eiusmod tempor.
          </p>
          <div class="mb-5 d-flex justify-content-between align-items-center">
            <span class="fs-md-15 text-secondary">Project Progress:</span>
            <span class="text-primary fw-semibold">38%</span>
          </div>
          <div
            class="progress"
            role="progressbar"
            aria-valuenow="38"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar" style="width: 38%"></div>
          </div>
          <div class="mt-15 d-sm-flex justify-content-between">
            <span class="text-muted fs-md-15 d-block">
              <i
                class="flaticon-calendar lh-1 me-5 position-relative top-1"
              ></i>
              Project Deadline:
              <span class="fw-semibold text-paragraph fs-md-14">
                5th Mar, 2023
              </span>
            </span>
            <span class="text-muted fs-md-15 d-block mt-5 mt-sm-0">
              <i
                class="flaticon-document lh-1 me-1 position-relative top-2"
              ></i>
              <span class="text-black">12</span>/35
            </span>
          </div>
        </div>
        <div class="card-body pb-10 ps-10 pe-10 pt-0">
          <div class="info bg-gray">
            <div class="row">
              <div class="col-sm-6 col-lg-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-info rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-bar-chart-1"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                  >
                    Total Task:
                  </span>
                  <span class="fw-medium text-paragraph">46</span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-success rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-bar-chart-1"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                  >
                    Completed Task:
                  </span>
                  <span class="fw-medium text-paragraph">14</span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-warning rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-bar-chart-1"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                  >
                    Total Budget:
                  </span>
                  <span class="fw-medium text-paragraph">$ 20,000</span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-danger rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-bar-chart-1"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                  >
                    Duration:
                  </span>
                  <span class="fw-medium text-paragraph">120.5 Hrs</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="card mb-25 border-0 rounded-0 bg-white to-do-list-box">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <div
                class="mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between"
              >
                <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
                  To Do List
                </h5>
                <a
                  href="#"
                  class="card-link-btn text-decoration-none text-primary fw-medium position-relative d-inline-block mt-10 mt-sm-0"
                >
                  Add A New Task
                </a>
              </div>
              <ul class="to-do-list ps-0 list-unstyled mb-0">
                <li
                  class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between"
                >
                  <div
                    class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium"
                  >
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="task1"
                    />
                    <label class="form-check-label" for="task1">
                      Design a Facebook Ad
                    </label>
                  </div>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle position-relative top-2 lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-view lh-1 me-8"></i> View</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-pen lh-1 me-8"></i> Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-delete lh-1 me-8"></i> Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between"
                >
                  <div
                    class="form-check mb-0 form-check-info mb-0 fs-md-15 fs-lg-16 text-black fw-medium"
                  >
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="task2"
                    />
                    <label class="form-check-label" for="task2">
                      Analyze Dashboard Data
                    </label>
                  </div>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle position-relative top-2 lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-view lh-1 me-8"></i> View</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-pen lh-1 me-8"></i> Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-delete lh-1 me-8"></i> Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between"
                >
                  <div
                    class="form-check mb-0 form-check-success mb-0 fs-md-15 fs-lg-16 text-black fw-medium"
                  >
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="task3"
                    />
                    <label class="form-check-label" for="task3">
                      Taking Interview
                    </label>
                  </div>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle position-relative top-2 lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-view lh-1 me-8"></i> View</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-pen lh-1 me-8"></i> Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-delete lh-1 me-8"></i> Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between"
                >
                  <div
                    class="form-check mb-0 form-check-danger mb-0 fs-md-15 fs-lg-16 text-black fw-medium"
                  >
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="task4"
                    />
                    <label class="form-check-label" for="task4">
                      Stockholder Meeting
                    </label>
                  </div>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle position-relative top-2 lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-view lh-1 me-8"></i> View</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-pen lh-1 me-8"></i> Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-delete lh-1 me-8"></i> Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between"
                >
                  <div
                    class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium"
                  >
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="task5"
                    />
                    <label class="form-check-label" for="task5">
                      Design a Instagram Ad
                    </label>
                  </div>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle position-relative top-2 lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-view lh-1 me-8"></i> View</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-pen lh-1 me-8"></i> Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-delete lh-1 me-8"></i> Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between"
                >
                  <div
                    class="form-check mb-0 form-check-success mb-0 fs-md-15 fs-lg-16 text-black fw-medium"
                  >
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="task6"
                    />
                    <label class="form-check-label" for="task6">
                      Analyze Adlash Data
                    </label>
                  </div>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle position-relative top-2 lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-view lh-1 me-8"></i> View</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-pen lh-1 me-8"></i> Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i class="flaticon-delete lh-1 me-8"></i> Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card mb-25 border-0 rounded-0 bg-white attachments-card">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <div
                class="mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between"
              >
                <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
                  Attachments
                </h5>
                <a
                  href="#"
                  class="card-link-btn text-decoration-none text-primary fw-medium position-relative d-inline-block mt-10 mt-sm-0"
                  >Upload New File</a
                >
              </div>
              <ul class="list ps-0 list-unstyled mb-0">
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div
                      class="icon text-center position-relative text-primary rounded-circle"
                    >
                      <i class="flaticon-zip-file"></i>
                    </div>
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Facebook Ad.zip</span
                      >
                      <span class="d-block text-muted fs-12"
                        >Size: 3.25 MB</span
                      >
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="bg-transparent p-0 border-0 fs-18 lh-1 text-paragraph"
                  >
                    <i class="flaticon-download"></i>
                  </button>
                </li>
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div
                      class="icon text-center position-relative text-primary rounded-circle"
                    >
                      <i class="flaticon-zip-file"></i>
                    </div>
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Dashboard Inspiration.zip</span
                      >
                      <span class="d-block text-muted fs-12"
                        >Size: 1.87 MB</span
                      >
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="bg-transparent p-0 border-0 fs-18 lh-1 text-paragraph"
                  >
                    <i class="flaticon-download"></i>
                  </button>
                </li>
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div
                      class="icon text-center position-relative text-primary rounded-circle"
                    >
                      <i class="flaticon-zip-file"></i>
                    </div>
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Adlash Logo.zip</span
                      >
                      <span class="d-block text-muted fs-12"
                        >Size: 4.43 MB</span
                      >
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="bg-transparent p-0 border-0 fs-18 lh-1 text-paragraph"
                  >
                    <i class="flaticon-download"></i>
                  </button>
                </li>
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div
                      class="icon text-center position-relative text-primary rounded-circle"
                    >
                      <i class="flaticon-zip-file"></i>
                    </div>
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Adlash All Asset.zip</span
                      >
                      <span class="d-block text-muted fs-12"
                        >Size: 3.00 MB</span
                      >
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="bg-transparent p-0 border-0 fs-18 lh-1 text-paragraph"
                  >
                    <i class="flaticon-download"></i>
                  </button>
                </li>
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div
                      class="icon text-center position-relative text-primary rounded-circle"
                    >
                      <i class="flaticon-zip-file"></i>
                    </div>
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Images.zip</span
                      >
                      <span class="d-block text-muted fs-12"
                        >Size: 2.11 MB</span
                      >
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="bg-transparent p-0 border-0 fs-18 lh-1 text-paragraph"
                  >
                    <i class="flaticon-download"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing project-comments-card"
      >
        <div class="card-body p-0">
          <div
            class="title pt-15 pt-md-20 pb-15 pb-md-20 ps-15 ps-sm-20 ps-md-25 ps-lg-30 pe-15 pe-sm-20 pe-md-25 pe-lg-30"
          >
            <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
              Comments (13)
            </h5>
          </div>
          <div class="p-15 p-sm-20 p-md-25 p-lg-30">
            <div class="list mb-20 mb-md-25 mb-lg-30">
              <div class="item position-relative">
                <div class="d-md-flex">
                  <div class="image">
                    <img
                      src="../../../assets/images/user/user7.jpg"
                      width="55"
                      height="55"
                      class="rounded-circle"
                      alt="user"
                    />
                  </div>
                  <div class="ms-md-15 mt-12 mt-md-0">
                    <div
                      class="mb-5 d-flex align-items-center justify-content-between"
                    >
                      <div>
                        <span
                          class="d-block text-black fw-medium fs-md-15 fs-lg-16 lh-1"
                        >
                          Brad Traversy
                          <span class="text-muted fw-normal fs-13 ms-5"
                            >12th Mar, 10:20 AM</span
                          >
                        </span>
                      </div>
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="flaticon-dots"></i>
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="javascript:void(0);"
                              ><i
                                class="flaticon-view lh-1 me-8 position-relative top-1"
                              ></i>
                              View</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="javascript:void(0);"
                              ><i
                                class="flaticon-pen lh-1 me-8 position-relative top-1"
                              ></i>
                              Edit</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="javascript:void(0);"
                              ><i
                                class="flaticon-delete lh-1 me-8 position-relative top-1"
                              ></i>
                              Delete</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
                      Hey Victor! Could you please give me the transaction
                      number that we provide yesterday? We need this for the
                      invoice. After that I will give you the full invoice with
                      payment.
                    </p>
                    <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
                      I need this badly. It will be better if you send this
                      asap.
                    </p>
                    <div class="buttons-list mt-15">
                      <a
                        href="#"
                        target="_blank"
                        class="d-inline-block text-decoration-none text-black position-relative transition"
                        data-bs-toggle="tooltip"
                        data-bs-title="Click to download"
                      >
                        <i class="flaticon-gallery-1"></i> Dashboard.jpg
                      </a>
                      <a
                        href="#"
                        target="_blank"
                        class="d-inline-block text-decoration-none text-black position-relative transition"
                        data-bs-toggle="tooltip"
                        data-bs-title="Click to download"
                      >
                        <i class="flaticon-gallery-1"></i> Structure.zip
                      </a>
                    </div>
                    <button
                      class="default-btn position-relative transition border-0 fw-medium text-white pt-5 pb-5 ps-15 pe-15 rounded-1 d-inline-block mt-15"
                      type="button"
                    >
                      Reply
                    </button>
                  </div>
                </div>
                <span
                  class="more-conversation fs-13 text-muted fw-semibold d-inline-block bg-white"
                >
                  Today
                </span>
              </div>
              <div class="item position-relative">
                <div class="d-md-flex">
                  <div class="image">
                    <img
                      src="../../../assets/images/user/user5.jpg"
                      width="55"
                      height="55"
                      class="rounded-circle"
                      alt="user"
                    />
                  </div>
                  <div class="ms-md-15 mt-12 mt-md-0">
                    <div
                      class="mb-5 d-flex align-items-center justify-content-between"
                    >
                      <div>
                        <span
                          class="d-block text-black fw-medium fs-md-15 fs-lg-16 lh-1"
                        >
                          Jennifer Loren
                          <span class="text-muted fw-normal fs-13 ms-5"
                            >12th Mar, 10:20 AM</span
                          >
                        </span>
                      </div>
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="flaticon-dots"></i>
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="javascript:void(0);"
                              ><i
                                class="flaticon-view lh-1 me-8 position-relative top-1"
                              ></i>
                              View</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="javascript:void(0);"
                              ><i
                                class="flaticon-pen lh-1 me-8 position-relative top-1"
                              ></i>
                              Edit</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="javascript:void(0);"
                              ><i
                                class="flaticon-delete lh-1 me-8 position-relative top-1"
                              ></i>
                              Delete</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
                      Sure! I’m updating the project asap. Thanks.
                    </p>
                    <button
                      class="default-btn position-relative transition border-0 fw-medium text-white pt-5 pb-5 ps-15 pe-15 rounded-1 d-inline-block"
                      type="button"
                    >
                      Reply
                    </button>
                  </div>
                </div>
                <div class="item position-relative">
                  <div class="d-md-flex">
                    <div class="image">
                      <img
                        src="../../../assets/images/user/user7.jpg"
                        width="55"
                        height="55"
                        class="rounded-circle"
                        alt="user"
                      />
                    </div>
                    <div class="ms-md-15 mt-12 mt-md-0">
                      <div
                        class="mb-5 d-flex align-items-center justify-content-between"
                      >
                        <div>
                          <span
                            class="d-block text-black fw-medium fs-md-15 fs-lg-16 lh-1"
                          >
                            Brad Traversy
                            <span class="text-muted fw-normal fs-13 ms-5"
                              >12th Mar, 10:20 AM</span
                            >
                          </span>
                        </div>
                        <div class="dropdown">
                          <button
                            class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="flaticon-dots"></i>
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item d-flex align-items-center"
                                href="javascript:void(0);"
                                ><i
                                  class="flaticon-view lh-1 me-8 position-relative top-1"
                                ></i>
                                View</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item d-flex align-items-center"
                                href="javascript:void(0);"
                                ><i
                                  class="flaticon-pen lh-1 me-8 position-relative top-1"
                                ></i>
                                Edit</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item d-flex align-items-center"
                                href="javascript:void(0);"
                                ><i
                                  class="flaticon-delete lh-1 me-8 position-relative top-1"
                                ></i>
                                Delete</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
                        Hey Victor! Could you please give me the transaction
                        number that we provide yesterday? We need this for the
                        invoice. After that I will give you the full invoice
                        with payment.
                      </p>
                      <button
                        class="default-btn position-relative transition border-0 fw-medium text-white pt-5 pb-5 ps-15 pe-15 rounded-1 d-inline-block"
                        type="button"
                      >
                        Reply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form>
              <div class="write-your-comment position-relative">
                <div class="write-comment position-relative">
                  <input
                    type="text"
                    class="input-comment d-block w-100 text-black fs-14"
                    placeholder="Type your message here"
                  />
                  <button
                    type="button"
                    class="bg-transparent p-0 border-0 transition"
                  >
                    <i class="ph ph-smiley"></i>
                  </button>
                </div>
                <div class="buttons-list">
                  <button
                    type="button"
                    class="border-0 d-inline-block position-relative text-center transition"
                  >
                    <i class="flaticon-gallery-1"></i>
                  </button>
                  <button
                    type="button"
                    class="border-0 d-inline-block position-relative text-center transition"
                  >
                    <i class="flaticon-link-1"></i>
                  </button>
                  <button
                    type="button"
                    class="border-0 d-inline-block position-relative text-center transition active"
                  >
                    <i class="flaticon-airplane"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxxl-4">
      <div class="row">
        <div class="col-xxl-6 col-xxxl-12 order-2 order-xxxl-2">
          <div class="card mb-25 border-0 rounded-0 bg-white team-members-card">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <div class="mb-15 mb-sm-20 mb-md-25 mb-lg-30">
                <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
                  Team Members
                </h5>
              </div>
              <ul class="ps-0 mb-0 list-unstyled">
                <li
                  class="d-sm-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <img
                      src="../../../assets/images/user/user7.jpg"
                      width="50"
                      height="50"
                      class="rounded-circle"
                      alt="user"
                    />
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Victor James
                        <span class="fs-12 fw-bold text-info ms-5"
                          >Team Lead</span
                        ></span
                      >
                      <span class="d-block text-muted"
                        >Joined 16th March, 2023</span
                      >
                    </div>
                  </div>
                  <span class="mt-12 mt-sm-0 badge text-outline-primary"
                    >Project Manager</span
                  >
                </li>
                <li
                  class="d-sm-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <img
                      src="../../../assets/images/user/user1.jpg"
                      width="50"
                      height="50"
                      class="rounded-circle"
                      alt="user"
                    />
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Jonathon Ronan</span
                      >
                      <span class="d-block text-muted"
                        >Joined 15th March, 2023</span
                      >
                    </div>
                  </div>
                  <span class="mt-12 mt-sm-0 badge text-outline-primary"
                    >Project Co-ordinator</span
                  >
                </li>
                <li
                  class="d-sm-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <img
                      src="../../../assets/images/user/user8.jpg"
                      width="50"
                      height="50"
                      class="rounded-circle"
                      alt="user"
                    />
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Skyler White</span
                      >
                      <span class="d-block text-muted"
                        >Joined 14th March, 2023</span
                      >
                    </div>
                  </div>
                  <span class="mt-12 mt-sm-0 badge text-outline-primary"
                    >Software Engineer</span
                  >
                </li>
                <li
                  class="d-sm-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <img
                      src="../../../assets/images/user/user2.jpg"
                      width="50"
                      height="50"
                      class="rounded-circle"
                      alt="user"
                    />
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Angela Carter</span
                      >
                      <span class="d-block text-muted"
                        >Joined 13th March, 2023</span
                      >
                    </div>
                  </div>
                  <span class="mt-12 mt-sm-0 badge text-outline-primary"
                    >UI/UX Designer</span
                  >
                </li>
                <li
                  class="d-sm-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <img
                      src="../../../assets/images/user/user5.jpg"
                      width="50"
                      height="50"
                      class="rounded-circle"
                      alt="user"
                    />
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Jane Ronan</span
                      >
                      <span class="d-block text-muted"
                        >Joined 12th March, 2023</span
                      >
                    </div>
                  </div>
                  <span class="mt-12 mt-sm-0 badge text-outline-primary"
                    >Web Developer</span
                  >
                </li>
                <li
                  class="d-sm-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <img
                      src="../../../assets/images/user/user3.jpg"
                      width="50"
                      height="50"
                      class="rounded-circle"
                      alt="user"
                    />
                    <div class="ms-12">
                      <span
                        class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-1"
                        >Walter White</span
                      >
                      <span class="d-block text-muted"
                        >Joined 11th March, 2023</span
                      >
                    </div>
                  </div>
                  <span class="mt-12 mt-sm-0 badge text-outline-primary"
                    >Web Designer</span
                  >
                </li>
              </ul>
              <button
                type="button"
                class="show-more-btn d-block w-100 fw-medium text-center fs-md-15 fs-lg-16 ps-15 pe-15 pt-12 pb-12 pt-md-15 pb-md-15 transition mt-15 mt-md-20 fs-lg-25"
              >
                Add New Member
                <i
                  class="flaticon-plus ms-5 fs-13 lh-1 position-relative top-1"
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xxxl-12 order-1 order-xxxl-2">
          <div
            class="card mb-25 border-0 rounded-0 bg-white recent-activity-box"
          >
            <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
              <div
                class="mb-15 mb-md-20 mb-xxxl-25 d-flex align-items-center justify-content-between"
              >
                <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
                  Recent Activity
                </h5>
                <div class="dropdown">
                  <button
                    class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        View</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Edit</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <ul class="list ps-0 mb-0 list-unstyled">
                <li class="position-relative fw-medium text-dark-emphasis">
                  <div
                    class="icon position-absolute start-0 rounded-circle text-center"
                  >
                    <i class="ph-duotone ph-envelope-simple"></i>
                  </div>
                  <span class="text-black fw-bold">J. Ronan</span> sent an Email
                  <span class="d-block fs-13 mt-1">35 mins ago</span>
                  <a
                    href="#"
                    class="link-btn text-decoration-none position-absolute end-0"
                    ><i class="flaticon-chevron"></i
                  ></a>
                </li>
                <li class="position-relative fw-medium text-dark-emphasis">
                  <div
                    class="icon position-absolute start-0 rounded-circle text-center"
                  >
                    <i class="ph-duotone ph-first-aid-kit"></i>
                  </div>
                  <span class="text-black fw-bold">Victoria</span> archived a
                  <span class="text-black fw-bold">Board for Project</span> in
                  team
                  <span class="d-block fs-13 mt-1">1 hr ago</span>
                  <a
                    href="#"
                    class="link-btn text-decoration-none position-absolute end-0"
                    ><i class="flaticon-chevron"></i
                  ></a>
                </li>
                <li class="position-relative fw-medium text-dark-emphasis">
                  <div
                    class="icon position-absolute start-0 rounded-circle text-center"
                  >
                    <i class="ph-duotone ph-check-circle"></i>
                  </div>
                  <span class="text-black fw-bold">Walter White</span> completed
                  the project
                  <span class="text-black fw-bold">“The Dashboard”</span>
                  <span class="d-block fs-13 mt-1">2 hrs ago</span>
                  <a
                    href="#"
                    class="link-btn text-decoration-none position-absolute end-0"
                    ><i class="flaticon-chevron"></i
                  ></a>
                </li>
                <li class="position-relative fw-medium text-dark-emphasis">
                  <div
                    class="icon position-absolute start-0 rounded-circle text-center"
                  >
                    <i class="ph-duotone ph-shield-plus"></i>
                  </div>
                  <span class="text-black fw-bold">Jennifer</span> added
                  <span class="text-black fw-bold">Micheal</span> in the project
                  <span class="text-black fw-bold">“The Adlash Design”</span>
                  <span class="d-block fs-13 mt-1">3 hrs ago</span>
                  <a
                    href="#"
                    class="link-btn text-decoration-none position-absolute end-0"
                    ><i class="flaticon-chevron"></i
                  ></a>
                </li>
                <li class="position-relative fw-medium text-dark-emphasis">
                  <div
                    class="icon position-absolute start-0 rounded-circle text-center"
                  >
                    <i class="ph-duotone ph-envelope-simple"></i>
                  </div>
                  <span class="text-black fw-bold">S. Smith</span> sent an Email
                  <span class="d-block fs-13 mt-1">1 day ago</span>
                  <a
                    href="#"
                    class="link-btn text-decoration-none position-absolute end-0"
                    ><i class="flaticon-chevron"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="card mb-25 border-0 rounded-0 bg-white working-schedule-box"
          >
            <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
              <div
                class="mb-15 d-sm-flex align-items-center justify-content-between"
              >
                <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
                  Working Schedule
                </h5>
                <div
                  class="card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5"
                >
                  <span class="fw-medium text-muted me-8">Show</span>
                  <select
                    class="form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium"
                  >
                    <option value="1" class="fw-medium" selected>All</option>
                    <option value="2" class="fw-medium">Sat</option>
                    <option value="3" class="fw-medium">Sun</option>
                    <option value="4" class="fw-medium">Mon</option>
                    <option value="5" class="fw-medium">Tue</option>
                    <option value="6" class="fw-medium">Wed</option>
                    <option value="7" class="fw-medium">Thu</option>
                    <option value="8" class="fw-medium">Fri</option>
                  </select>
                </div>
              </div>
              <ul
                class="schedule-date-list ps-0 mb-0 list-unstyled d-flex flex-wrap text-center"
              >
                <li class="col text-black fw-medium">
                  <span class="d-block fw-bold text-dark-emphasis">Sat</span>
                  04
                </li>
                <li class="col text-black fw-medium">
                  <span class="d-block fw-bold text-dark-emphasis">Sun</span>
                  05
                </li>
                <li class="col text-black fw-medium">
                  <span class="d-block fw-bold text-dark-emphasis">Mon</span>
                  06
                </li>
                <li class="col text-black fw-medium active">
                  <span class="d-block fw-bold text-dark-emphasis">Tue</span>
                  07
                </li>
                <li class="col text-black fw-medium">
                  <span class="d-block fw-bold text-dark-emphasis">Wed</span>
                  08
                </li>
                <li class="col text-black fw-medium">
                  <span class="d-block fw-bold text-dark-emphasis">Thu</span>
                  09
                </li>
                <li class="col text-black fw-medium">
                  <span class="d-block fw-bold text-dark-emphasis">Fri</span>
                  10
                </li>
              </ul>
              <div
                class="mt-15 mt-md-20 mb-12 d-flex align-items-center justify-content-between"
              >
                <h6 class="card-title fw-bold mb-0">Todays Schedule</h6>
                <a
                  href="javascript:void(0);"
                  class="card-link-btn text-decoration-none text-primary fw-medium position-relative"
                  >Add A Schedule</a
                >
              </div>
              <ul class="info-list ps-0 mb-0 list-unstyled">
                <li class="d-inline-block">
                  <div class="d-flex align-items-center">
                    <span
                      class="d-block w-10 h-10 bg-primary rounded-circle me-8"
                    ></span>
                    <span class="fw-bold">
                      2
                      <span class="text-muted fw-semibold">Meetings</span>
                    </span>
                  </div>
                </li>
                <li class="d-inline-block">
                  <div class="d-flex align-items-center">
                    <span
                      class="d-block w-10 h-10 bg-success rounded-circle me-8"
                    ></span>
                    <span class="fw-bold">
                      2
                      <span class="text-muted fw-semibold">Discussion</span>
                    </span>
                  </div>
                </li>
                <li class="d-inline-block">
                  <div class="d-flex align-items-center">
                    <span
                      class="d-block w-10 h-10 bg-info rounded-circle me-8"
                    ></span>
                    <span class="fw-bold">
                      1
                      <span class="text-muted fw-semibold">Reviews</span>
                    </span>
                  </div>
                </li>
              </ul>
              <div class="schedule-list mt-15 mt-md-20">
                <div
                  class="list-item pt-15 pb-15 pt-md-20 pb-md-20 ps-20 pe-15 position-relative d-sm-flex justify-content-between align-items-center bg-f2f1f9"
                >
                  <div class="content">
                    <div class="d-flex align-items-center mb-5">
                      <h6 class="fw-medium mb-0">
                        10:00 - 11:00
                        <span class="text-muted fs-12">AM</span>
                      </h6>
                      <div
                        class="ms-10 icon rounded-circle bg-primary text-center text-white position-relative"
                      >
                        <i class="flaticon-bell-2"></i>
                      </div>
                    </div>
                    <span class="d-block text-black fw-semibold fs-md-15 mb-1">
                      Adlash Project Estimation Meeting
                    </span>
                    <span class="d-block text-muted fs-12 fw-medium">
                      Lead by
                      <span class="text-primary">John Harry</span>
                    </span>
                  </div>
                  <router-link
                    to="/project-details"
                    class="card-btn bg-white d-inline-block fw-medium text-decoration-none transition ps-15 pe-15 pt-7 pb-7 border-0 mt-12 mt-sm-0"
                  >
                    View Details
                  </router-link>
                </div>
                <div
                  class="list-item pt-15 pb-15 pt-md-20 pb-md-20 ps-20 pe-15 position-relative d-sm-flex justify-content-between align-items-center bg-f3f7f9"
                >
                  <div class="content">
                    <div class="d-flex align-items-center mb-5">
                      <h6 class="fw-medium mb-0">
                        11:00 - 11:30
                        <span class="text-muted fs-12">AM</span>
                      </h6>
                      <div
                        class="ms-10 icon rounded-circle bg-white text-center text-info-light position-relative"
                      >
                        <i class="flaticon-bell-2"></i>
                      </div>
                    </div>
                    <span class="d-block text-black fw-semibold fs-md-15 mb-1">
                      Dashboard HTML Design Review
                    </span>
                    <span class="d-block text-muted fs-12 fw-medium">
                      Lead by
                      <span class="text-info-light">Jonathon Andy</span>
                    </span>
                  </div>
                  <router-link
                    to="/project-details"
                    class="card-btn bg-white d-inline-block fw-medium text-decoration-none transition ps-15 pe-15 pt-7 pb-7 border-0 mt-12 mt-sm-0"
                  >
                    View Details
                  </router-link>
                </div>
                <div
                  class="list-item pt-15 pb-15 pt-md-20 pb-md-20 ps-20 pe-15 position-relative d-sm-flex justify-content-between align-items-center bg-ecf3f2"
                >
                  <div class="content">
                    <div class="d-flex align-items-center mb-5">
                      <h6 class="fw-medium mb-0">
                        12:00 - 1:30
                        <span class="text-muted fs-12">PM</span>
                      </h6>
                      <div
                        class="ms-10 icon rounded-circle bg-white text-center text-success position-relative"
                      >
                        <i class="flaticon-bell-2"></i>
                      </div>
                    </div>
                    <span class="d-block text-black fw-semibold fs-md-15 mb-1">
                      Dashboard UI/UX Design Review
                    </span>
                    <span class="d-block text-muted fs-12 fw-medium">
                      Lead by
                      <span class="text-success">Jonathon Andy</span>
                    </span>
                  </div>
                  <router-link
                    to="/project-details"
                    class="card-btn bg-white d-inline-block fw-medium text-decoration-none transition ps-15 pe-15 pt-7 pb-7 border-0 mt-12 mt-sm-0"
                  >
                    View Details
                  </router-link>
                </div>
                <div
                  class="list-item pt-15 pb-15 pt-md-20 pb-md-20 ps-20 pe-15 position-relative d-sm-flex justify-content-between align-items-center bg-f2f1f9"
                >
                  <div class="content">
                    <div class="d-flex align-items-center mb-5">
                      <h6 class="fw-medium mb-0">
                        10:00 - 11:00
                        <span class="text-muted fs-12">AM</span>
                      </h6>
                      <div
                        class="ms-10 icon rounded-circle bg-white text-center text-primary position-relative"
                      >
                        <i class="flaticon-bell-2"></i>
                      </div>
                    </div>
                    <span class="d-block text-black fw-semibold fs-md-15 mb-1">
                      Adlash Project Estimation Meeting
                    </span>
                    <span class="d-block text-muted fs-12 fw-medium">
                      Lead by
                      <span class="text-primary">John Harry</span>
                    </span>
                  </div>
                  <router-link
                    to="/project-details"
                    class="card-btn bg-white d-inline-block fw-medium text-decoration-none transition ps-15 pe-15 pt-7 pb-7 border-0 mt-12 mt-sm-0"
                  >
                    View Details
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectDetails",
};
</script>