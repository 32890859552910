import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "progress mb-3" }
const _hoisted_8 = { class: "list-group" }
const _hoisted_9 = { class: "d-flex align-items-start" }
const _hoisted_10 = ["onUpdate:modelValue", "onChange"]
const _hoisted_11 = ["onUpdate:modelValue", "onInput"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "d-flex justify-content-between mt-2" }
const _hoisted_16 = { class: "flex-grow-1 me-1" }
const _hoisted_17 = ["onUpdate:modelValue", "onInput"]
const _hoisted_18 = { class: "flex-grow-1 ms-1" }
const _hoisted_19 = ["onUpdate:modelValue", "onInput"]
const _hoisted_20 = { class: "d-flex justify-content-between mt-2" }
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { class: "mb-3" }
const _hoisted_23 = { class: "mb-3" }
const _hoisted_24 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_25 = { class: "list-group" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = ["onClick"]
const _hoisted_28 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_29 = { class: "list-group" }
const _hoisted_30 = ["src", "onClick"]
const _hoisted_31 = { class: "mb-3" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = ["value"]
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { class: "mb-3" }
const _hoisted_36 = { class: "col-md-12 pt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_ModalComment = _resolveComponent("ModalComment")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TagModal = _resolveComponent("TagModal")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!
  const _component_VueLoading = _resolveComponent("VueLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DialogComponent, {
      visible: _ctx.localVisible,
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localVisible) = $event)),
      style: { width: '35rem' }
    }, {
      header: _withCtx(() => _cache[15] || (_cache[15] = [
        _createElementVNode("div", { class: "col-md-6 pt-20" }, [
          _createElementVNode("h5", { class: "card-subtitle fw-bold" }, "Detalles de la Tarea")
        ], -1)
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[16] || (_cache[16] = _createElementVNode("label", {
              for: "overviewEmail",
              class: "form-label fw-medium"
            }, "Título de la tarea:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control shadow-none text-black fs-md-15 fs-lg-16",
              id: "overviewEmail",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newEvent.title) = $event))
            }, null, 512), [
              [_vModelText, _ctx.newEvent.title]
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _cache[17] || (_cache[17] = _createElementVNode("label", {
              for: "eventDescription",
              class: "form-label fw-medium"
            }, "Descripción de la tarea:", -1)),
            _withDirectives(_createElementVNode("textarea", {
              class: "form-control",
              id: "eventDescription",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newEvent.description) = $event)),
              rows: "3"
            }, null, 512), [
              [_vModelText, _ctx.newEvent.description]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[18] || (_cache[18] = _createElementVNode("label", {
              for: "eventStartTime",
              class: "form-label fw-medium"
            }, "Fecha y Hora de inicio:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "datetime-local",
              class: "form-control",
              id: "eventStartTime",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newEvent.startTime) = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.newEvent.startTime]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[19] || (_cache[19] = _createElementVNode("label", {
              for: "eventEndTime",
              class: "form-label fw-medium"
            }, "Fecha y Hora de finalización:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "datetime-local",
              class: "form-control",
              id: "eventEndTime",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newEvent.endTime) = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.newEvent.endTime]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label fw-medium" }, "Progreso de actividades:", -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: "progress-bar",
                role: "progressbar",
                style: _normalizeStyle({ width: `${_ctx.progress}%` })
              }, _toDisplayString(_ctx.progress.toFixed(0)) + "% ", 5)
            ]),
            _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label fw-medium" }, "Checklist de actividades:", -1)),
            _createElementVNode("ul", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newActivity.checklist, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "list-group-item"
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      "onUpdate:modelValue": ($event: any) => ((item.check) = $event),
                      onChange: ($event: any) => (_ctx.markAsModified(item))
                    }, null, 40, _hoisted_10), [
                      [_vModelCheckbox, item.check]
                    ]),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control ms-2 flex-grow-1",
                      "onUpdate:modelValue": ($event: any) => ((item.nombre) = $event),
                      onInput: ($event: any) => (_ctx.markAsModified(item)),
                      placeholder: "Escribe una tarea...",
                      rows: "2"
                    }, null, 40, _hoisted_11), [
                      [_vModelText, item.nombre]
                    ]),
                    _createElementVNode("button", {
                      class: "btn btn-danger btn-sm ms-2",
                      onClick: ($event: any) => (_ctx.removeChecklistItem(index))
                    }, " Eliminar ", 8, _hoisted_12),
                    (item.isNew)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-success btn-sm",
                          onClick: ($event: any) => (_ctx.emitAddActivity(item))
                        }, " Añadir ", 8, _hoisted_13))
                      : (item.isModified)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: "btn btn-success btn-sm",
                            onClick: ($event: any) => (_ctx.emitEditActivity(item))
                          }, " Actualizar ", 8, _hoisted_14))
                        : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _cache[20] || (_cache[20] = _createElementVNode("label", {
                        for: "startDate",
                        class: "form-label"
                      }, "Fecha inicio:", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "datetime-local",
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((item.fecha_inicio) = $event),
                        onInput: ($event: any) => (_ctx.markAsModified(item))
                      }, null, 40, _hoisted_17), [
                        [_vModelText, item.fecha_inicio]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _cache[21] || (_cache[21] = _createElementVNode("label", {
                        for: "EndDate",
                        class: "form-label"
                      }, "Fecha fin:", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "datetime-local",
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((item.fecha_fin) = $event),
                        onInput: ($event: any) => (_ctx.markAsModified(item))
                      }, null, 40, _hoisted_19), [
                        [_vModelText, item.fecha_fin]
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-sm",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.addChecklistItem && _ctx.addChecklistItem(...args)))
              }, " Agregar ")
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _cache[24] || (_cache[24] = _createElementVNode("label", {
              for: "overviewEmail",
              class: "form-label fw-medium"
            }, "Usuarios:", -1)),
            _createVNode(_component_MultiSelect, {
              modelValue: _ctx.selectedPartner,
              "onUpdate:modelValue": [
                _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedPartner) = $event)),
                _ctx.updateSelectedPartners
              ],
              options: _ctx.partners,
              optionLabel: "nombre",
              optionValue: "uuid",
              placeholder: "Selecciona un usuario",
              filter: true,
              multiple: true,
              class: "w-100"
            }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _cache[25] || (_cache[25] = _createElementVNode("label", {
              for: "overviewEmail",
              class: "form-label fw-medium"
            }, "Tags:", -1)),
            _createElementVNode("button", {
              class: "btn btn-success btn-sm px-2 py-1 mx-2",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.openTagModal && _ctx.openTagModal(...args)))
            }, " crear tag "),
            _createVNode(_component_MultiSelect, {
              modelValue: _ctx.selectedTag,
              "onUpdate:modelValue": [
                _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedTag) = $event)),
                _ctx.updateSelectedTag
              ],
              options: _ctx.tags,
              optionLabel: "tag",
              optionValue: "uuid",
              placeholder: "Seleccione tags",
              filter: true,
              multiple: true,
              class: "w-100"
            }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _cache[26] || (_cache[26] = _createElementVNode("label", {
              for: "eventFiles",
              class: "form-label fw-medium"
            }, "Adjuntar archivos:", -1)),
            _createElementVNode("input", {
              type: "file",
              class: "form-control",
              id: "eventFiles",
              multiple: "",
              onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args)))
            }, null, 32)
          ]),
          (_ctx.uploadedFiles.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _cache[27] || (_cache[27] = _createElementVNode("label", { class: "form-label" }, "Archivos cargados", -1)),
                _createElementVNode("ul", _hoisted_25, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadedFiles, (file, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "list-group-item d-flex justify-content-between align-items-center"
                    }, [
                      _createTextVNode(_toDisplayString(file.contentType) + " ", 1),
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-success btn-sm me-2",
                        onClick: ($event: any) => (_ctx.addFileTask(file))
                      }, " Agregar ", 8, _hoisted_26),
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-danger btn-sm",
                        onClick: ($event: any) => (_ctx.removeFile(index))
                      }, " Eliminar ", 8, _hoisted_27)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.task.archivos && _ctx.task.archivos.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _cache[28] || (_cache[28] = _createElementVNode("label", { class: "form-label" }, "Archivos adjuntos existentes", -1)),
                _createElementVNode("ul", _hoisted_29, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.task.archivos, (file, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "list-group-item d-flex justify-content-between align-items-center"
                    }, [
                      _createElementVNode("img", {
                        src: _ctx.getFileIcon(file.nombre),
                        alt: "icono",
                        class: "me-2",
                        style: {"width":"20px","cursor":"pointer"},
                        onClick: ($event: any) => (_ctx.openFile(file.path, file.nombre))
                      }, null, 8, _hoisted_30),
                      _createTextVNode(" " + _toDisplayString(file.nombre), 1)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_31, [
            _cache[29] || (_cache[29] = _createElementVNode("label", { class: "form-label fw-medium" }, "Comentarios:", -1)),
            (_ctx.task.responseComentarios && _ctx.task.responseComentarios.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                  _createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    value: _ctx.task.responseComentarios[_ctx.task.responseComentarios.length - 1]
            .comentario
            ,
                    readonly: ""
                  }, null, 8, _hoisted_33),
                  (_ctx.task.responseComentarios.length > 0)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn btn-secondary btn-sm",
                        onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.openCommentsModal && _ctx.openCommentsModal(...args)))
                      }, " Ver más "))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_34, [
                  _createElementVNode("button", {
                    class: "btn btn-primary mt-2",
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.openCommentsModal && _ctx.openCommentsModal(...args)))
                  }, " Agregar Comentario ")
                ]))
          ]),
          (_ctx.showCommentsModal)
            ? (_openBlock(), _createBlock(_component_ModalComment, {
                key: 2,
                visible: _ctx.showCommentsModal,
                task: _ctx.task,
                onClose: _ctx.closeCommentsModal
              }, null, 8, ["visible", "task", "onClose"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_35, [
            _cache[30] || (_cache[30] = _createElementVNode("label", {
              for: "overviewEmail",
              class: "form-label fw-medium"
            }, "Prioridad:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedPriority,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedPriority) = $event)),
              options: _ctx.priorities,
              optionLabel: "prioridad",
              optionValue: "uuid",
              filter: true,
              placeholder: "Seleccione una prioridad",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-primary text-center",
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.guardarTask && _ctx.guardarTask(...args)))
            }, " Guardar Tarea "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-secondary text-center ms-2",
              onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            }, " Cerrar ")
          ])
        ]),
        _createVNode(_component_TagModal, {
          visible: _ctx.isTagModalVisible,
          onClose: _ctx.closeTagModal
        }, null, 8, ["visible", "onClose"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: "Cargando..."
    }, null, 8, ["active"])
  ], 64))
}