import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-5 col-xl-4" }
const _hoisted_3 = { class: "col-lg-7 col-xl-8" }
const _hoisted_4 = { class: "leads-details-tabs" }
const _hoisted_5 = {
  class: "tab-content",
  id: "myTabContent"
}
const _hoisted_6 = {
  class: "tab-pane fade show active",
  id: "tasks-tab-pane",
  role: "tabpanel",
  tabindex: "0"
}
const _hoisted_7 = { class: "card mb-25 border-0 rounded-0 bg-white to-do-list-box letter-spacing" }
const _hoisted_8 = { class: "card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }
const _hoisted_9 = { class: "d-flex align-items-center mt-10 mt-md-0" }
const _hoisted_10 = { class: "card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }
const _hoisted_11 = {
  key: 0,
  class: "to-do-list ps-0 list-unstyled mb-0"
}
const _hoisted_12 = { class: "form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium" }
const _hoisted_13 = {
  class: "form-check-label",
  for: "task1"
}
const _hoisted_14 = { class: "fs-12 text-muted fw-normal ms-5" }
const _hoisted_15 = { class: "action-buttons mt-10 mt-sm-0 d-flex align-items-center" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 1,
  class: "text-center mt-4"
}
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "mb-3" }
const _hoisted_20 = { class: "mb-3" }
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { class: "mb-3" }
const _hoisted_23 = { class: "mb-3" }
const _hoisted_24 = { class: "progress mb-3" }
const _hoisted_25 = { class: "list-group" }
const _hoisted_26 = { class: "d-flex align-items-start" }
const _hoisted_27 = ["onUpdate:modelValue"]
const _hoisted_28 = ["onUpdate:modelValue"]
const _hoisted_29 = { class: "d-flex justify-content-between mt-2" }
const _hoisted_30 = { class: "flex-grow-1 me-1" }
const _hoisted_31 = ["onUpdate:modelValue"]
const _hoisted_32 = { class: "flex-grow-1 ms-1" }
const _hoisted_33 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeadsInformation = _resolveComponent("LeadsInformation")!
  const _component_PrimePaginator = _resolveComponent("PrimePaginator")!
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LeadsInformation, { uuid: _ctx.uuid }, null, 8, ["uuid"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[14] || (_cache[14] = _createElementVNode("ul", {
            class: "nav nav-tabs border-0 mb-25",
            id: "myTab",
            role: "tablist"
          }, [
            _createElementVNode("li", {
              class: "nav-item",
              role: "presentation"
            }, [
              _createElementVNode("button", {
                class: "nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100 active",
                id: "tasks-tab",
                "data-bs-toggle": "tab",
                "data-bs-target": "#tasks-tab-pane",
                type: "button",
                role: "tab",
                "aria-controls": "tasks-tab-pane",
                "aria-selected": "true"
              }, " Tareas ")
            ]),
            _createElementVNode("li", {
              class: "nav-item",
              role: "presentation"
            }, [
              _createElementVNode("button", {
                class: "nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100",
                id: "deals-tab",
                "data-bs-toggle": "tab",
                "data-bs-target": "#deals-tab-pane",
                type: "button",
                role: "tab",
                "aria-controls": "deals-tab-pane",
                "aria-selected": "false"
              }, " Eventos ")
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[9] || (_cache[9] = _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Listado", -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("form", {
                      class: "search-box position-relative me-15",
                      onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                    }, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control shadow-none text-black rounded-0 border-0",
                        placeholder: "Buscar",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.searchQuery]
                      ]),
                      _cache[8] || (_cache[8] = _createElementVNode("button", {
                        type: "submit",
                        class: "bg-transparent text-primary transition p-0 border-0"
                      }, [
                        _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
                      ], -1))
                    ], 32)
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.filteredPaginatedTasks.length > 0)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPaginatedTasks, (item) => {
                          return (_openBlock(), _createElementBlock("li", {
                            class: "to-do-list-item d-sm-flex align-items-center justify-content-between",
                            key: item.uuid_tarea
                          }, [
                            _createElementVNode("div", _hoisted_12, [
                              _cache[10] || (_cache[10] = _createElementVNode("input", {
                                class: "form-check-input shadow-none",
                                type: "checkbox",
                                id: "task1"
                              }, null, -1)),
                              _createElementVNode("label", _hoisted_13, _toDisplayString(item.titulo), 1),
                              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formatFecha(item.fecha_inicio)), 1),
                              _createElementVNode("span", {
                                class: "ms-20 badge",
                                style: _normalizeStyle({ backgroundColor: item.color })
                              }, _toDisplayString(item.prioridad), 5)
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("button", {
                                onClick: ($event: any) => (_ctx.showModal(item.uuid_tarea)),
                                class: "bg-transparent p-0 border-0 text-black transition lh-1"
                              }, _cache[11] || (_cache[11] = [
                                _createElementVNode("i", { class: "flaticon-plus" }, null, -1)
                              ]), 8, _hoisted_16)
                            ])
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[12] || (_cache[12] = [
                        _createElementVNode("div", { class: "card p-3" }, [
                          _createElementVNode("p", null, "No hay datos disponibles")
                        ], -1)
                      ]))),
                  (_ctx.filteredTasks.length > _ctx.rowsPerPage)
                    ? (_openBlock(), _createBlock(_component_PrimePaginator, {
                        key: 2,
                        rows: _ctx.rowsPerPage,
                        totalRecords: _ctx.filteredTasks.length,
                        page: _ctx.currentPage,
                        onPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentPage = $event.page))
                      }, null, 8, ["rows", "totalRecords", "page"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("div", {
              class: "tab-pane fade",
              id: "deals-tab-pane",
              role: "tabpanel",
              tabindex: "0"
            }, [
              _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }, [
                _createElementVNode("div", { class: "card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                  _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Eventos"),
                  _createElementVNode("div", { class: "d-flex align-items-center mt-10 mt-md-0" }, [
                    _createElementVNode("form", { class: "search-box position-relative me-15" }, [
                      _createElementVNode("input", {
                        type: "text",
                        class: "form-control shadow-none text-black rounded-0 border-0",
                        placeholder: "Buscar"
                      }),
                      _createElementVNode("button", {
                        type: "submit",
                        class: "bg-transparent text-primary transition p-0 border-0"
                      }, [
                        _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                  _createElementVNode("div", { class: "table-responsive" })
                ])
              ])
            ], -1))
          ])
        ])
      ]),
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: "Cargando..."
      }, null, 8, ["active"])
    ]),
    _createVNode(_component_DialogComponent, {
      visible: _ctx.localVisible,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localVisible) = $event)),
      style: { width: '35rem' }
    }, {
      header: _withCtx(() => _cache[15] || (_cache[15] = [
        _createElementVNode("div", { class: "col-md-6 pt-20" }, [
          _createElementVNode("h5", { class: "card-subtitle fw-bold" }, "Detalles de la Tarea")
        ], -1)
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _cache[16] || (_cache[16] = _createElementVNode("label", {
              for: "overviewEmail",
              class: "form-label fw-medium"
            }, "Título de la tarea:", -1)),
            _withDirectives(_createElementVNode("textarea", {
              class: "form-control",
              id: "eventDescription",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newEvent.title) = $event)),
              rows: "3",
              cols: "20",
              readonly: ""
            }, null, 512), [
              [_vModelText, _ctx.newEvent.title]
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _cache[17] || (_cache[17] = _createElementVNode("label", {
              for: "eventDescription",
              class: "form-label fw-medium"
            }, "Descripción de la tarea:", -1)),
            _withDirectives(_createElementVNode("textarea", {
              class: "form-control",
              id: "eventDescription",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newEvent.description) = $event)),
              rows: "3",
              readonly: ""
            }, null, 512), [
              [_vModelText, _ctx.newEvent.description]
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _cache[18] || (_cache[18] = _createElementVNode("label", {
              for: "eventStartTime",
              class: "form-label fw-medium"
            }, "Fecha y Hora de inicio:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "datetime-local",
              class: "form-control",
              id: "eventStartTime",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newEvent.startTime) = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.newEvent.startTime]
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _cache[19] || (_cache[19] = _createElementVNode("label", {
              for: "eventEndTime",
              class: "form-label fw-medium"
            }, "Fecha y Hora de finalización:", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "datetime-local",
              class: "form-control",
              id: "eventEndTime",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newEvent.endTime) = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.newEvent.endTime]
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label fw-medium" }, "Progreso de actividades:", -1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", {
                class: "progress-bar",
                role: "progressbar",
                style: _normalizeStyle({ width: `${_ctx.progress}%` })
              }, _toDisplayString(_ctx.progress.toFixed(0)) + "% ", 5)
            ]),
            _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label fw-medium" }, "Checklist de actividades:", -1)),
            _createElementVNode("ul", _hoisted_25, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newActivity.checklist, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "list-group-item"
                }, [
                  _createElementVNode("div", _hoisted_26, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      "onUpdate:modelValue": ($event: any) => ((item.check) = $event)
                    }, null, 8, _hoisted_27), [
                      [_vModelCheckbox, item.check]
                    ]),
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control ms-2 flex-grow-1",
                      "onUpdate:modelValue": ($event: any) => ((item.nombre) = $event),
                      placeholder: "Escribe una tarea...",
                      rows: "4",
                      readonly: ""
                    }, null, 8, _hoisted_28), [
                      [_vModelText, item.nombre]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _cache[20] || (_cache[20] = _createElementVNode("label", {
                        for: "startDate",
                        class: "form-label"
                      }, "Fecha inicio:", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "datetime-local",
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((item.fecha_inicio) = $event),
                        readonly: ""
                      }, null, 8, _hoisted_31), [
                        [_vModelText, item.fecha_inicio]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _cache[21] || (_cache[21] = _createElementVNode("label", {
                        for: "EndDate",
                        class: "form-label"
                      }, "Fecha fin:", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "datetime-local",
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((item.fecha_fin) = $event),
                        readonly: ""
                      }, null, 8, _hoisted_33), [
                        [_vModelText, item.fecha_fin]
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}