import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_3 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "search mb-3" }
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "d-flex justify-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: "Cargando..."
      }, null, 8, ["active"]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-sm-flex align-items-center" }, [
        _createElementVNode("h5", { class: "text-center" }, "Listado de vehículos")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputText, {
          modelValue: _ctx.searchTerm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
          placeholder: "Buscar por número o color",
          class: "w-100"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_DataTable, {
          value: _ctx.filteredVehicles,
          showGridlines: "",
          tableStyle: "min-width: 50rem",
          paginator: "",
          rows: 10,
          rowsPerPageOptions: [5, 10, 20, 50]
        }, {
          footer: _withCtx(() => [
            _createTextVNode(" Total " + _toDisplayString(_ctx.filteredVehicles ? _ctx.filteredVehicles.length : 0) + " Vehículos. ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              class: "column-table",
              header: "Opciones",
              style: {"width":"20%"}
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    icon: "pi pi-pencil",
                    class: "p-button-sm p-button-warning me-1",
                    onClick: ($event: any) => (_ctx.editVehicle(slotProps.data.uuid))
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "numero",
              header: "Número",
              style: {"width":"30%"}
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "placas",
              header: "Placas",
              style: {"width":"30%"}
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "color",
              header: "Color",
              style: {"width":"20%"}
            })
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ])
  ]))
}