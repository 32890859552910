import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_5 = { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "shadow-none lh-1 fw-medium text-primary" }
const _hoisted_10 = { class: "shadow-none lh-1 fw-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between\"><h5 class=\"card-title fw-bold mb-0\">Recent Leads</h5><div class=\"card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><span class=\"fw-medium text-muted me-8\">Last</span><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option value=\"1\" class=\"fw-medium\" selected>1 Week</option><option value=\"2\" class=\"fw-medium\">2 Week</option><option value=\"3\" class=\"fw-medium\">3 Week</option></select></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              }, " Name "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " EMAIL "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " STATUS "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: lead.id
              }, [
                _createElementVNode("th", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "form-check mb-0" }, [
                      _createElementVNode("input", {
                        class: "form-check-input shadow-none",
                        type: "checkbox"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("img", {
                        src: require(`../../../../assets/images/${lead.image}`),
                        class: "rounded-circle me-8",
                        width: "24",
                        height: "24",
                        alt: "user"
                      }, null, 8, _hoisted_8),
                      _createTextVNode(" " + _toDisplayString(lead.name), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_9, _toDisplayString(lead.email), 1),
                _createElementVNode("td", _hoisted_10, [
                  _createElementVNode("span", {
                    class: _normalizeClass(['badge', lead.class])
                  }, _toDisplayString(lead.status), 3)
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                  _createElementVNode("div", { class: "dropdown" }, [
                    _createElementVNode("button", {
                      class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                      type: "button",
                      "data-bs-toggle": "dropdown",
                      "aria-expanded": "false"
                    }, [
                      _createElementVNode("i", { class: "flaticon-dots" })
                    ]),
                    _createElementVNode("ul", { class: "dropdown-menu" }, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" View ")
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" Edit ")
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" Delete ")
                        ])
                      ])
                    ])
                  ])
                ], -1))
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}