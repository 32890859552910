<template>
    <DialogComponent v-model:visible="localVisible" :style="{ width: '35rem' }">
        <template #header>
            <div class="pt-20">
                <h5 class="card-subtitle fw-bold">Capacitación</h5>
            </div>
        </template>
        <div class="row">
            <div class="mb-3 col-6">
                <label for="fechaInicio" class="form-label fw-medium">Fecha Inicio:</label>
                <input type="text" class="form-control" id="fechaInicio" :value="formattedFecha" readonly />
            </div>

            <div class="mb-3 col-6">
                <label for="horaInicio" class="form-label fw-medium">Hora Inicio:</label>
                <input type="text" class="form-control" id="horaInicio" :value="formattedHora" readonly />
            </div>

            <div class="mb-3">
                <label for="EndDate" class="form-label fw-medium">Fecha fin:</label>
                <input type="datetime-local" class="form-control" id="EndDate"
                    v-model="capacitacionData.fecha_fin" />
            </div>


            <div class="mb-3">
                <label for="razonSocial" class="form-label fw-medium">Cliente:</label>
                <input type="text" class="form-control" id="razonSocial" v-model="capacitacionData.cliente" readonly />
            </div>

            <div class="mb-3">
                <label for="localidad" class="form-label fw-medium">Localidad:</label>
                <input type="text" class="form-control" id="localidad" v-model="capacitacionData.localidad" readonly />
            </div>

            <div class="mb-3">
                <label for="giro" class="form-label fw-medium">Giro:</label>
                <input type="text" class="form-control" id="giro" v-model="capacitacionData.giro" />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Actividad:</label>
                <input type="text" class="form-control" id="cotizado" v-model="capacitacionData.actividad" readonly />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Segmento:</label>
                <input type="text" class="form-control" id="cotizado" v-model="capacitacionData.segmento" readonly />
            </div>

            <div class="mb-3">
                <label for="observacion" class="form-label fw-medium">Observación:</label>
                <textarea class="form-control" id="observacion" v-model="capacitacionData.observacion" rows="3"
                    readonly></textarea>
            </div>

            <div class="col-md-12 pt-20 text-end">
                <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">
                    Cerrar
                </button>
                <button type="button" class="btn btn-sm btn-primary text-center ms-2" @click="sendEdit">
                    Guardar Cambios
                </button>
            </div>
        </div>

        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    </DialogComponent>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed } from "vue";
import { CapacitacionData } from "@/interfaces/interfaces";
import { useToast } from "vue-toastification";
import createSocketService from "@/services/socketService";


export default defineComponent({
    name: "ModalContentEditCapacitacion",
    props: {
        visible: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        capacitacion: {
            type: Object as PropType<CapacitacionData>,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const localVisible = ref(props.visible);
        const capacitacionData = ref({ ...props.capacitacion });
        const editInProgress = ref(false);
        const isLoading = ref(false);
        const toast = useToast();
        let socketService: any = null;

        const closeModal = () => {
            localVisible.value = false;
            emit("close");
        };

        const formatToBackendDate = (datetime: string): string => {
            const date = new Date(datetime);
            if (isNaN(date.getTime())) return ""; 
            const isoString = date.toISOString(); 
            return isoString.replace("T", " ").slice(0, -1); 
        };

        const formatToDatetimeLocal = (isoString: string): string => {
            if (!isoString) return ""; 
            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0"); 
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        };



        const sendEdit = async () => {
            if (editInProgress.value) return;
            editInProgress.value = true;

            const payloadVenta = {
                uuid_ruteo: capacitacionData.value.uuid_ruteo,
                proyeccion: {
                    venta: capacitacionData.value.venta,
                },
                giro: capacitacionData.value.giro,
                souvenirPromocion: capacitacionData.value.souvenir_promocion,
                uuid_tipo_actividad: capacitacionData.value.uuid_tipo_actividad,
                fecha_fin: capacitacionData.value.fecha_fin
                    ? formatToBackendDate(capacitacionData.value.fecha_fin)
                    : null,
            };
            console.log(payloadVenta);

            try {
                socketService = await createSocketService();
                socketService.connect();
                isLoading.value = true;
                socketService.emit("edit-activity", payloadVenta);

                socketService.on("edit-activity", (response: any) => {
                    if (editInProgress.value) {
                        if (!response.error) {
                            toast.success("Tarea editada correctamente.");
                            closeModal();
                        } else {
                            toast.error("Error al editar la tarea.");
                        }

                        editInProgress.value = false;
                        isLoading.value = false;
                    }
                });
            } catch (error) {
                toast.error("Error al procesar la edición.");
                console.error(error);
                editInProgress.value = false;
                isLoading.value = false;
            }
        };

        watch(
            () => props.visible,
            (newVisible) => {
                localVisible.value = newVisible;
            },
            { immediate: true }
        );

        watch(
            () => props.capacitacion,
            (newData) => {
                capacitacionData.value = { ...newData };
                capacitacionData.value.fecha_fin = newData.fecha_fin
                    ? formatToDatetimeLocal(newData.fecha_fin)
                    : "";
            },
            { immediate: true }
        );

        const formattedFecha = computed(() => {
            return capacitacionData.value.fecha_inicio.split("T")[0];
        });

        const formattedHora = computed(() => {
            return capacitacionData.value.fecha_inicio.split("T")[1].slice(0, 5);
        });

        return {
            localVisible,
            capacitacionData,
            closeModal,
            formattedFecha,
            formattedHora,
            sendEdit,
            isLoading
        };
    },
});
</script>
