import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white add-product-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "col-md-12" }
const _hoisted_8 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_9 = { class: "col-md-12" }
const _hoisted_10 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_11 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!
  const _component_ImageUpload = _resolveComponent("ImageUpload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"col-md-6\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\">Product Name</label><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. Sensung Smart Watch\"></div></div><div class=\"col-md-6\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\"> Sub Text </label><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. Sensung Smart Watch\"></div></div>", 2)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[0] || (_cache[0] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Product Description ", -1)),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_QuillEditor, {
                  theme: "snow",
                  placeholder: "Write your product description",
                  toolbar: "full"
                })
              ])
            ])
          ]),
          _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"col-md-12\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\"> Select Category </label><select class=\"form-select shadow-none fw-semibold rounded-0\"><option selected>Electronics</option><option value=\"1\">Computer</option><option value=\"2\">Mobile</option><option value=\"3\">Sports</option><option value=\"4\">Home</option><option value=\"5\">Technology</option></select></div></div><div class=\"col-md-6\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\">Price</label><div class=\"input-group\"><span class=\"input-group-text rounded-0 fs-14 fw-bold text-primary\"> $ </span><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. 120.00\"></div></div></div><div class=\"col-md-6\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\"> Discount </label><div class=\"input-group\"><span class=\"input-group-text rounded-0 fs-14 fw-bold text-primary\"> % </span><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. 15\"></div></div></div><div class=\"col-md-6\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\">Stock</label><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. 50\"></div></div><div class=\"col-md-6\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\">Orders</label><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. 30\"></div></div>", 5)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[1] || (_cache[1] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Product Image ", -1)),
              _createVNode(_component_ImageUpload)
            ])
          ]),
          _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"col-md-6\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\"> Meta Title </label><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. Sensung Smart Watch\"></div></div><div class=\"col-md-6\"><div class=\"form-group mb-15 mb-sm-20 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\"> Meta Keyword </label><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. Sensung Smart Watch\"></div></div>", 2)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[2] || (_cache[2] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Meta Description ", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_QuillEditor, {
                  theme: "snow",
                  placeholder: "Write your meta description",
                  toolbar: "full"
                })
              ])
            ])
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-md-12" }, [
            _createElementVNode("button", {
              class: "default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16",
              type: "submit"
            }, " Create Product ")
          ], -1))
        ])
      ])
    ])
  ]))
}