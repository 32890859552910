import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white active-tasks-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "active-tasks-list ps-0 list-unstyled mb-0" }
const _hoisted_4 = { class: "d-block fs-md-15 fs-lg-16 text-black fw-medium" }
const _hoisted_5 = { class: "task-info mt-8 mt-sm-0 d-flex justify-content-between align-items-center pe-15" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "fw-medium d-block text-white ms-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between\"><h5 class=\"card-title fw-bold mb-0\">Active Tasks</h5><div class=\"card-buttons\"><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 active\"> Day </a><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 ms-5\"> Week </a><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 ms-5\"> Month </a><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 ms-5\"> Year 2023 </a></div></div>", 1)),
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "active-task-list-item d-sm-flex align-items-center",
            key: task.id
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(task.category), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass([
                'users-list d-flex align-items-center pe-15 pe-md-40',
                task.bgClass,
              ])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(task.usersList, (user) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "rounded-circle text-center",
                    key: user.id
                  }, [
                    _createElementVNode("img", {
                      src: require(`../../../../assets/images/${user.image}`),
                      class: "rounded-circle",
                      alt: "user"
                    }, null, 8, _hoisted_6)
                  ]))
                }), 128)),
                _createElementVNode("span", _hoisted_7, _toDisplayString(task.title), 1)
              ], 2),
              _createElementVNode("span", {
                class: _normalizeClass(['fw-bold', task.class])
              }, _toDisplayString(task.percent) + "% ", 3)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}