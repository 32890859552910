import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = {
  id: "clientPaymentStatusChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between" }, [
        _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Client Payment Status")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_apexchart, {
          type: "polarArea",
          height: "430",
          options: _ctx.clientPaymentStatusChart,
          series: _ctx.client
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}