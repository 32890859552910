import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/user/user3.jpg'
import _imports_1 from '../../../assets/images/user/user1.jpg'
import _imports_2 from '../../../assets/images/user/user5.jpg'
import _imports_3 from '../../../assets/images/user/user6.jpg'
import _imports_4 from '../../../assets/images/user/user7.jpg'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_5 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_6 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_7 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_8 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_9 = { class: "shadow-none lh-1 fw-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[26] || (_cache[26] = _createStaticVNode("<div class=\"mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between\"><h6 class=\"card-title fw-bold mb-0\">Unsolved Tickets</h6><div class=\"d-flex align-items-center mt-12 mt-md-0\"><form class=\"me-15\"><div class=\"input-group\"><input type=\"text\" class=\"form-control shadow-none fw-medium ps-12 pt-8 pb-8 pe-12 letter-spacing\" placeholder=\"Search\"><button class=\"default-btn position-relative transition border-0 text-white ps-12 pe-12 rounded-1\" type=\"button\"><i class=\"flaticon-search-interface-symbol position-relative\"></i></button></div></form><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8\"></i> View</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8\"></i> Edit</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8\"></i> Delete</a></li></ul></div></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[25] || (_cache[25] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              }, " CLIENT "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " SUBJECT "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " STATUS "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " PRIORITY "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              }, " AGENT ")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _cache[1] || (_cache[1] = _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                _createElementVNode("div", { class: "d-flex align-items-center" }, [
                  _createElementVNode("div", { class: "form-check mb-0" }, [
                    _createElementVNode("input", {
                      class: "form-check-input shadow-none",
                      type: "checkbox"
                    })
                  ]),
                  _createElementVNode("div", { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      class: "rounded-circle me-8",
                      width: "24",
                      height: "24",
                      alt: "user"
                    }),
                    _createTextVNode(" J. Ronan ")
                  ])
                ])
              ], -1)),
              _createElementVNode("td", _hoisted_5, [
                _createVNode(_component_router_link, {
                  to: "/ticket-preview",
                  class: "text-decoration-none"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Adlash Dashboard Design ")
                  ])),
                  _: 1
                })
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                _createElementVNode("span", { class: "badge text-outline-success" }, "Recent")
              ], -1)),
              _cache[3] || (_cache[3] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-danger text-uppercase" }, " URGENT ", -1)),
              _cache[4] || (_cache[4] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                _createElementVNode("select", { class: "form-select shadow-none rounded-0 fs-md-15 fs-lg-16 fw-medium" }, [
                  _createElementVNode("option", { selected: "" }, "J. Linowiski"),
                  _createElementVNode("option", { value: "1" }, "Z. White"),
                  _createElementVNode("option", { value: "2" }, "C. Benden"),
                  _createElementVNode("option", { value: "3" }, "A. Smith")
                ])
              ], -1))
            ]),
            _createElementVNode("tr", null, [
              _cache[6] || (_cache[6] = _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                _createElementVNode("div", { class: "d-flex align-items-center" }, [
                  _createElementVNode("div", { class: "form-check mb-0" }, [
                    _createElementVNode("input", {
                      class: "form-check-input shadow-none",
                      type: "checkbox"
                    })
                  ]),
                  _createElementVNode("div", { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }, [
                    _createElementVNode("img", {
                      src: _imports_1,
                      class: "rounded-circle me-8",
                      width: "24",
                      height: "24",
                      alt: "user"
                    }),
                    _createTextVNode(" V. James ")
                  ])
                ])
              ], -1)),
              _createElementVNode("td", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: "/ticket-preview",
                  class: "text-decoration-none"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Higher Calculus App Design ")
                  ])),
                  _: 1
                })
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                _createElementVNode("span", { class: "badge text-outline-danger" }, "Overdue")
              ], -1)),
              _cache[8] || (_cache[8] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-primary text-uppercase" }, " HIGH ", -1)),
              _cache[9] || (_cache[9] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                _createElementVNode("select", { class: "form-select shadow-none rounded-0 fs-md-15 fs-lg-16 fw-medium" }, [
                  _createElementVNode("option", null, "J. Linowiski"),
                  _createElementVNode("option", {
                    value: "1",
                    selected: ""
                  }, "Z. White"),
                  _createElementVNode("option", { value: "2" }, "C. Benden"),
                  _createElementVNode("option", { value: "3" }, "A. Smith")
                ])
              ], -1))
            ]),
            _createElementVNode("tr", null, [
              _cache[11] || (_cache[11] = _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                _createElementVNode("div", { class: "d-flex align-items-center" }, [
                  _createElementVNode("div", { class: "form-check mb-0" }, [
                    _createElementVNode("input", {
                      class: "form-check-input shadow-none",
                      type: "checkbox"
                    })
                  ]),
                  _createElementVNode("div", { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }, [
                    _createElementVNode("img", {
                      src: _imports_2,
                      class: "rounded-circle me-8",
                      width: "24",
                      height: "24",
                      alt: "user"
                    }),
                    _createTextVNode(" A. Carter ")
                  ])
                ])
              ], -1)),
              _createElementVNode("td", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: "/ticket-preview",
                  class: "text-decoration-none"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Admin Issue ")
                  ])),
                  _: 1
                })
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                _createElementVNode("span", { class: "badge text-outline-info" }, "Remaining")
              ], -1)),
              _cache[13] || (_cache[13] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-info-light text-uppercase" }, " MEDIUM ", -1)),
              _cache[14] || (_cache[14] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                _createElementVNode("select", { class: "form-select shadow-none rounded-0 fs-md-15 fs-lg-16 fw-medium" }, [
                  _createElementVNode("option", null, "J. Linowiski"),
                  _createElementVNode("option", { value: "1" }, "Z. White"),
                  _createElementVNode("option", {
                    value: "2",
                    selected: ""
                  }, "C. Benden"),
                  _createElementVNode("option", { value: "3" }, "A. Smith")
                ])
              ], -1))
            ]),
            _createElementVNode("tr", null, [
              _cache[16] || (_cache[16] = _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                _createElementVNode("div", { class: "d-flex align-items-center" }, [
                  _createElementVNode("div", { class: "form-check mb-0" }, [
                    _createElementVNode("input", {
                      class: "form-check-input shadow-none",
                      type: "checkbox"
                    })
                  ]),
                  _createElementVNode("div", { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }, [
                    _createElementVNode("img", {
                      src: _imports_3,
                      class: "rounded-circle me-8",
                      width: "24",
                      height: "24",
                      alt: "user"
                    }),
                    _createTextVNode(" B. White ")
                  ])
                ])
              ], -1)),
              _createElementVNode("td", _hoisted_8, [
                _createVNode(_component_router_link, {
                  to: "/ticket-preview",
                  class: "text-decoration-none"
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Email Submission Issue ")
                  ])),
                  _: 1
                })
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                _createElementVNode("span", { class: "badge text-outline-primary" }, "Responded")
              ], -1)),
              _cache[18] || (_cache[18] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-warning text-uppercase" }, " LOW ", -1)),
              _cache[19] || (_cache[19] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                _createElementVNode("select", { class: "form-select shadow-none rounded-0 fs-md-15 fs-lg-16 fw-medium" }, [
                  _createElementVNode("option", null, "J. Linowiski"),
                  _createElementVNode("option", { value: "1" }, "Z. White"),
                  _createElementVNode("option", { value: "2" }, "C. Benden"),
                  _createElementVNode("option", {
                    value: "3",
                    selected: ""
                  }, "A. Smith")
                ])
              ], -1))
            ]),
            _createElementVNode("tr", null, [
              _cache[21] || (_cache[21] = _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                _createElementVNode("div", { class: "d-flex align-items-center" }, [
                  _createElementVNode("div", { class: "form-check mb-0" }, [
                    _createElementVNode("input", {
                      class: "form-check-input shadow-none",
                      type: "checkbox"
                    })
                  ]),
                  _createElementVNode("div", { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }, [
                    _createElementVNode("img", {
                      src: _imports_4,
                      class: "rounded-circle me-8",
                      width: "24",
                      height: "24",
                      alt: "user"
                    }),
                    _createTextVNode(" S. Smith ")
                  ])
                ])
              ], -1)),
              _createElementVNode("td", _hoisted_9, [
                _createVNode(_component_router_link, {
                  to: "/ticket-preview",
                  class: "text-decoration-none"
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode(" Adlash Design Issue ")
                  ])),
                  _: 1
                })
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                _createElementVNode("span", { class: "badge text-outline-muted" }, "Closed")
              ], -1)),
              _cache[23] || (_cache[23] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-danger text-uppercase" }, " URGENT ", -1)),
              _cache[24] || (_cache[24] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                _createElementVNode("select", { class: "form-select shadow-none rounded-0 fs-md-15 fs-lg-16 fw-medium" }, [
                  _createElementVNode("option", { selected: "" }, "J. Linowiski"),
                  _createElementVNode("option", { value: "1" }, "Z. White"),
                  _createElementVNode("option", { value: "2" }, "C. Benden"),
                  _createElementVNode("option", { value: "3" }, "A. Smith")
                ])
              ], -1))
            ])
          ])
        ])
      ])
    ])
  ]))
}