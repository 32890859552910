import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/icon/call.png'
import _imports_1 from '../../../assets/images/icon/calendar.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 call-stats-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"card-body letter-spacing position-relative\"><div class=\"icon text-center rounded-circle position-absolute\"><img src=\"" + _imports_0 + "\" class=\"position-absolute start-0 end-0 ms-auto me-auto\" alt=\"icon\"></div><span class=\"d-block text-paragraph fw-medium\">OUTGOING CALL</span><h3 class=\"fw-black text-primary\"> 10 <span class=\"text-black\">Leads Today</span></h3><div class=\"d-flex align-items-center\"><span class=\"fw-bold text-success text-badge d-inline-block\"><i class=\"flaticon-up-arrow fs-11 lh-1 position-relative top-1\"></i> 5.5% </span><span class=\"text-paragraph d-inline-block ms-8\">Than yesterday</span></div></div><hr><div class=\"card-body letter-spacing position-relative\"><div class=\"icon text-center rounded-circle position-absolute\"><img src=\"" + _imports_1 + "\" class=\"position-absolute start-0 end-0 ms-auto me-auto\" alt=\"icon\"></div><span class=\"d-block text-paragraph fw-medium\">SUPPORT CALL</span><h3 class=\"fw-black text-primary\"> 23 <span class=\"text-black\">Leads This Week</span></h3><div class=\"d-flex align-items-center\"><span class=\"fw-bold text-danger text-badge d-inline-block\"><i class=\"flaticon-down-arrow fs-11 lh-1 position-relative top-1\"></i> 3.3% </span><span class=\"text-paragraph d-inline-block ms-8\">Than last week</span></div></div>", 3)
  ])))
}