import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white performance-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = {
  id: "performanceChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"mb-15 d-sm-flex align-items-center justify-content-between\"><h5 class=\"card-title fw-bold mb-0\">Performance</h5><div class=\"card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><span class=\"fw-medium text-muted me-8\">Last</span><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option value=\"1\" class=\"fw-medium\" selected>1 Week</option><option value=\"2\" class=\"fw-medium\">2 Week</option><option value=\"3\" class=\"fw-medium\">3 Week</option></select></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_apexchart, {
          type: "donut",
          height: "270",
          options: _ctx.performanceChart,
          series: _ctx.performance
        }, null, 8, ["options", "series"])
      ]),
      _cache[1] || (_cache[1] = _createStaticVNode("<ul class=\"list ps-0 mb-0 list-unstyled\"><li class=\"d-inline-block text-primary position-relative\"><h5 class=\"mb-7 text-primary fw-black lh-1\"> 2.5 <span class=\"text-muted fs-14 fw-semibold\">hrs</span></h5><span class=\"d-block text-black-emphasis fs-13 fw-medium lh-1\"> Lesson Discuss </span></li><li class=\"d-inline-block text-info position-relative\"><h5 class=\"mb-7 text-info fw-black lh-1\"> 1.3 <span class=\"text-muted fs-14 fw-semibold\">hrs</span></h5><span class=\"d-block text-black-emphasis fs-13 fw-medium lh-1\"> Taking Class </span></li><li class=\"d-inline-block text-success position-relative\"><h5 class=\"mb-7 text-success fw-black lh-1\"> 45 <span class=\"text-muted fs-14 fw-semibold\">mins</span></h5><span class=\"d-block text-black-emphasis fs-13 fw-medium lh-1\"> Work In Progress </span></li></ul>", 1))
    ])
  ]))
}