<template>
    <TaskList />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import TaskList from "../../components/Projects/Task/TaskList.vue";
  
  export default defineComponent({
    name: "TeamsPage",
    components: {
      TaskList,
    },
  });
  </script>