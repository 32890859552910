import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TodaysSchedule = _resolveComponent("TodaysSchedule")!
  const _component_ModalContentEdit = _resolveComponent("ModalContentEdit")!
  const _component_ModalContentEditEvent = _resolveComponent("ModalContentEditEvent")!
  const _component_ModalContentEditRuteo = _resolveComponent("ModalContentEditRuteo")!
  const _component_ModalContentEditVenta = _resolveComponent("ModalContentEditVenta")!
  const _component_ModalContentEditCobranza = _resolveComponent("ModalContentEditCobranza")!
  const _component_ModalContentEditVentCobra = _resolveComponent("ModalContentEditVentCobra")!
  const _component_ModalContentEditProspecccion = _resolveComponent("ModalContentEditProspecccion")!
  const _component_ModalContentEditVisitaSeguimiento = _resolveComponent("ModalContentEditVisitaSeguimiento")!
  const _component_ModalContentEditVisitaTecnica = _resolveComponent("ModalContentEditVisitaTecnica")!
  const _component_ModalContentEditVisitaReactivacion = _resolveComponent("ModalContentEditVisitaReactivacion")!
  const _component_ModalContentEditCapacitacion = _resolveComponent("ModalContentEditCapacitacion")!
  const _component_ModalContentEditEntregaProducto = _resolveComponent("ModalContentEditEntregaProducto")!
  const _component_ModalContentEditInspeccion = _resolveComponent("ModalContentEditInspeccion")!
  const _component_VueLoading = _resolveComponent("VueLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "col-md-9" }, [
      _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white calendar-card" }, [
        _createElementVNode("div", { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }, [
          _createElementVNode("div", { id: "calendar" })
        ])
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TodaysSchedule)
    ]),
    _createVNode(_component_ModalContentEdit, {
      visible: _ctx.modalVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalVisible) = $event)),
      task: _ctx.taskList,
      onClose: _ctx.closeModal
    }, null, 8, ["visible", "task", "onClose"]),
    _createVNode(_component_ModalContentEditEvent, {
      visible: _ctx.modalEventVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalEventVisible) = $event)),
      event: _ctx.eventData,
      onClose: _ctx.closeEventModal
    }, null, 8, ["visible", "event", "onClose"]),
    _createVNode(_component_ModalContentEditRuteo, {
      visible: _ctx.modalRuteoVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalRuteoVisible) = $event)),
      ruteo: _ctx.ruteoData,
      onClose: _ctx.closeRuteoModal
    }, null, 8, ["visible", "ruteo", "onClose"]),
    _createVNode(_component_ModalContentEditVenta, {
      visible: _ctx.modalVentaVisible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalVentaVisible) = $event)),
      venta: _ctx.ventaData,
      onClose: _ctx.closeVentaModal
    }, null, 8, ["visible", "venta", "onClose"]),
    _createVNode(_component_ModalContentEditCobranza, {
      visible: _ctx.modalCobranzaVisible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalCobranzaVisible) = $event)),
      cobranza: _ctx.cobranzaData,
      onClose: _ctx.closeCobranzaModal
    }, null, 8, ["visible", "cobranza", "onClose"]),
    _createVNode(_component_ModalContentEditVentCobra, {
      visible: _ctx.modalVentCobraVisible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalVentCobraVisible) = $event)),
      ventcobra: _ctx.ventacobraData,
      onClose: _ctx.closeVentCobraModal
    }, null, 8, ["visible", "ventcobra", "onClose"]),
    _createVNode(_component_ModalContentEditProspecccion, {
      visible: _ctx.modalProspeccionVisible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalProspeccionVisible) = $event)),
      prospeccion: _ctx.prospeccionData,
      onClose: _ctx.closeProspeccionModal
    }, null, 8, ["visible", "prospeccion", "onClose"]),
    _createVNode(_component_ModalContentEditVisitaSeguimiento, {
      visible: _ctx.modalVisitaSeguimientoVisible,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modalVisitaSeguimientoVisible) = $event)),
      visitaSeguimiento: _ctx.visitaSeguimientoData,
      onClose: _ctx.closeVisitaSeguimientoModal
    }, null, 8, ["visible", "visitaSeguimiento", "onClose"]),
    _createVNode(_component_ModalContentEditVisitaTecnica, {
      visible: _ctx.modalVisitaTecnicaVisible,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modalVisitaTecnicaVisible) = $event)),
      visitaTecnica: _ctx.visitaTecnicaData,
      onClose: _ctx.closeVisitaTecnicaModal
    }, null, 8, ["visible", "visitaTecnica", "onClose"]),
    _createVNode(_component_ModalContentEditVisitaReactivacion, {
      visible: _ctx.modalVisitaReactivacionVisible,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modalVisitaReactivacionVisible) = $event)),
      visitaReactivacion: _ctx.visitaReactivacionData,
      onClose: _ctx.closeVisitaReactivacionModal
    }, null, 8, ["visible", "visitaReactivacion", "onClose"]),
    _createVNode(_component_ModalContentEditCapacitacion, {
      visible: _ctx.modalCapacitacionVisible,
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalCapacitacionVisible) = $event)),
      capacitacion: _ctx.capacitacionData,
      onClose: _ctx.closeCapacitacionModal
    }, null, 8, ["visible", "capacitacion", "onClose"]),
    _createVNode(_component_ModalContentEditEntregaProducto, {
      visible: _ctx.modalEntregaProductoVisible,
      "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modalEntregaProductoVisible) = $event)),
      entregaProducto: _ctx.entregaProductoData,
      onClose: _ctx.closeEntregaProductoModal
    }, null, 8, ["visible", "entregaProducto", "onClose"]),
    _createVNode(_component_ModalContentEditInspeccion, {
      visible: _ctx.modalInspeccionVisible,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modalInspeccionVisible) = $event)),
      inspeccion: _ctx.inspeccionData,
      onClose: _ctx.closeInspeccionModal
    }, null, 8, ["visible", "inspeccion", "onClose"]),
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: "Cargando..."
    }, null, 8, ["active"])
  ]))
}