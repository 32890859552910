<template>
  <div
    class="dashboard-container"
    :class="{ 'sidebar-hidden': !isSidebarVisible }"
  >
    <aside v-if="isSidebarVisible" class="sidebar">
      <h2>Filtro</h2>
      <button
        class="toggle-sidebar-btn"
        @click="isSidebarVisible = !isSidebarVisible"
      >
        Ocultar
      </button>

      <div class="tabs">
        <button
          class="tab-button"
          :class="{ active: selectedTab === 'all' }"
          @click="setTab('all')"
        >
          Todos
        </button>
        <button
          class="tab-button"
          :class="{ active: selectedTab === 'car' }"
          @click="setTab('car')"
        >
          Carros
        </button>
        <button
          class="tab-button"
          :class="{ active: selectedTab === 'truck' }"
          @click="setTab('truck')"
        >
          Camiones
        </button>
        <button
          class="tab-button"
          :class="{ active: selectedTab === 'byRepartidor' }"
          @click="setTab('byRepartidor')"
        >
          Repartidores
        </button>
        <button
          class="tab-button"
          :class="{ active: selectedTab === 'byBodega' }"
          @click="setTab('byBodega')"
        >
          Bodega
        </button>
      </div>

      <ul
        class="vehicle-list"
        v-if="selectedTab !== 'byRepartidor' && selectedTab !== 'byBodega'"
      >
        <li
          v-for="vehicle in filteredVehicles"
          :key="vehicle.id"
          @click="focusOnVehicle(vehicle)"
          class="vehicle-item"
        >
          <strong>ID:</strong> {{ vehicle.id }} - <strong>Tipo:</strong>
          {{ vehicle.type }}
          <br />
          <span>Estado: {{ vehicle.status }}</span>
          <br />
          <span
            >Posición: {{ vehicle.position.lat.toFixed(5) }},
            {{ vehicle.position.lng.toFixed(5) }}</span
          >
        </li>
      </ul>
      <ul class="vehicle-list" v-if="selectedTab === 'byRepartidor'">
        <li
          v-for="repartidor in repartidores"
          :key="repartidor.id"
          @click="filterByRepartidor(repartidor)"
          class="vehicle-item"
        >
          <strong>Repartidor:</strong> {{ repartidor.name }} -
          <strong>Vehículo:</strong> {{ repartidor.vehicle.model }}
        </li>
      </ul>
      <ul class="vehicle-list" v-if="selectedTab === 'byBodega'">
        <li
          v-for="bodega in bodegas"
          :key="bodega.id"
          @click="filterByBodega(bodega)"
          class="vehicle-item"
        >
          <strong>Bodega:</strong> {{ bodega.name }}
          <br />
          <span>Vehículos: {{ bodega.vehicles.length }}</span>
        </li>
      </ul>
    </aside>
    <button
      v-if="!isSidebarVisible"
      class="show-sidebar-btn"
      @click="isSidebarVisible = true"
    >
      Mostrar Filtro
    </button>

    <!-- Contenedor del Mapa -->
    <div class="map-container">
      <div id="map" ref="mapRef"></div>

      <!-- Botones flotantes personalizados -->
      <div class="map-buttons">
        <button class="icon-btn" @click="zoomIn">
          <img
            src="../../assets/plus-small_4338829.png"
            title="Zoom"
            alt="Zoom In"
          />
        </button>
        <button class="icon-btn" @click="zoomOut">
          <img
            src="../../assets/minus-small_4338830.png"
            title="Zoom"
            alt="Zoom Out"
          />
        </button>
        <button class="icon-btn" @click="resetView">
          <img
            src="../../assets/icons8-cita-recurrente-50.png"
            title="Restablecer"
            alt="Restablecer Vista"
          />
        </button>
        <button class="icon-btn" @click="toggleMapType">
          <img
            title="Cambiar vista"
            src="../../assets/movilidad.png"
            alt="Cambiar Vista"
          />
        </button>
        <button class="icon-btn" @click="toggleTraffic">
          <img
            src="../../assets/embotellamiento.png"
            title="Mostrar tráfico"
            alt="Mostrar/Ocultar Tráfico"
          />
        </button>
        <button class="icon-btn" @click="centerOnUserLocation">
          <img
            src="../../assets/mapa.png"
            title="Mi ubicación"
            alt="Mi Ubicación"
          />
        </button>
        <button class="icon-btn" @click="toggleFullscreen">
          <img
            src="../../assets/pantalla-completa.png"
            title="Pantalla completa"
            alt="Pantalla Completa"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from "vue";

export default {
  setup() {
    const map = ref(null);
    const directionsService = ref(null);
    const directionsRenderer = ref(null);
    const mapCenter = { lat: 19.4326, lng: -99.1332 };
    const selectedTab = ref("all");
    const isSidebarVisible = ref(true);

    const vehicles = reactive([
      {
        id: 1,
        type: "car",
        position: { lat: 17.986, lng: -92.93 },
        model: "Nissan Versa",
        status: "Encendido",
        timeOn: "2 horas 30 minutos",
        icon: "http://maps.google.com/mapfiles/kml/shapes/cabs.png",
      },
      {
        id: 2,
        type: "truck",
        position: { lat: 17.988, lng: -92.942 },
        model: "Toyota Hilux",
        status: "Apagado",
        timeOn: "1 hora 10 minutos",
        icon: "http://maps.google.com/mapfiles/kml/shapes/truck.png",
      },
      {
        id: 3,
        type: "car",
        position: { lat: 17.98, lng: -92.92 },
        model: "Honda Civic",
        status: "Encendido",
        timeOn: "3 horas",
        icon: "http://maps.google.com/mapfiles/kml/shapes/cabs.png",
      },
      {
        id: 4,
        type: "truck",
        position: { lat: 17.985, lng: -92.935 },
        model: "Ford Ranger",
        status: "Apagado",
        timeOn: "45 minutos",
        icon: "http://maps.google.com/mapfiles/kml/shapes/truck.png",
      },
    ]);

    const repartidores = reactive([
      {
        id: 1,
        name: "Carlos Gómez",
        vehicle: vehicles[0],
      },
      { id: 2, name: "Juan Pérez", vehicle: vehicles[2] },
    ]);

    const bodegas = reactive([
      { id: 1, name: "Bodega Central", vehicles: [vehicles[0], vehicles[1]] },
      { id: 2, name: "Bodega Norte", vehicles: [vehicles[2], vehicles[3]] },
    ]);

    const filteredVehicles = computed(() => {
      if (selectedTab.value === "all") return vehicles;
      if (selectedTab.value === "car")
        return vehicles.filter((v) => v.type === "car");
      if (selectedTab.value === "truck")
        return vehicles.filter((v) => v.type === "truck");
      return [];
    });

    const filterByRepartidor = (repartidor) => {
      hideAllMarkers();
      showMarkers([repartidor.vehicle]);
      focusOnVehicle(repartidor.vehicle);
    };

    const filterByBodega = (bodega) => {
      hideAllMarkers();
      showMarkers(bodega.vehicles);
      focusOnVehicle(bodega.vehicles[0]);
    };

    const hideAllMarkers = () => {
      vehicles.forEach((vehicle) => {
        if (vehicle.marker) {
          vehicle.marker.setMap(null);
        }
      });
    };

    const showMarkers = (vehiclesToShow) => {
      vehiclesToShow.forEach((vehicle) => {
        if (vehicle.marker) {
          vehicle.marker.setMap(map.value);
        }
      });
    };

    onMounted(() => {
      map.value = new google.maps.Map(document.getElementById("map"), {
        center: mapCenter,
        zoom: 13,
        mapTypeId: "hybrid",
        mapId: "f8d56006232c1d87",
        disableDefaultUI: true,
      });

      directionsService.value = new google.maps.DirectionsService();
      directionsRenderer.value = new google.maps.DirectionsRenderer({
        map: map.value,
        suppressMarkers: true,
      });

      vehicles.forEach((vehicle) => {
        vehicle.marker = new google.maps.Marker({
          position: vehicle.position,
          map: map.value,
          icon: vehicle.icon, // Usamos el icono del vehículo
          title: `Vehículo ${vehicle.id}`,
        });
      });
    });

    const focusOnVehicle = (vehicle) => {
      map.value.setCenter(vehicle.position);
      map.value.setZoom(15);
    };

    const setTab = (tab) => {
      if (tab === "all") {
        showMarkers(vehicles);
      } else {
        hideAllMarkers();
      }
      selectedTab.value = tab;
    };

    const zoomIn = () => map.value.setZoom(map.value.getZoom() + 1);
    const zoomOut = () => map.value.setZoom(map.value.getZoom() - 1);

    const resetView = () => {
      map.value.setCenter(mapCenter);
      map.value.setZoom(13);
    };

    const toggleMapType = () => {
      const currentType = map.value.getMapTypeId();
      map.value.setMapTypeId(currentType === "hybrid" ? "roadmap" : "hybrid");
    };

    const toggleTraffic = () => {
      if (!map.value.trafficLayer) {
        map.value.trafficLayer = new google.maps.TrafficLayer();
      }
      const isTrafficVisible = map.value.trafficLayer.getMap();
      map.value.trafficLayer.setMap(isTrafficVisible ? null : map.value);
    };

    const centerOnUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.value.setCenter(pos);
          map.value.setZoom(15);
        });
      }
    };

    const toggleFullscreen = () => {
      isSidebarVisible.value = !isSidebarVisible.value;
      const elem = document.documentElement;
      if (!document.fullscreenElement) {
        elem.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    };

    return {
      map,
      selectedTab,
      isSidebarVisible,
      vehicles,
      repartidores,
      bodegas,
      filteredVehicles,
      focusOnVehicle,
      filterByRepartidor,
      filterByBodega,
      setTab,
      zoomIn,
      zoomOut,
      resetView,
      toggleMapType,
      toggleTraffic,
      centerOnUserLocation,
      toggleFullscreen,
    };
  },
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f4f4f4;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .dashboard-container {
    flex-direction: row;
  }
}

.sidebar {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .sidebar {
    width: 300px;
  }
}

.sidebar h2 {
  margin-bottom: 20px;
  color: #007bff;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  flex: 1;
  padding: 10px;
  background-color: #e9ecef;
  border: none;
  border-radius: 5px;
}

.tab-button.active {
  background-color: #007bff;
  color: #fff;
}

.vehicle-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vehicle-item {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: aliceblue;
}

.map-container {
  flex-grow: 1;
  position: relative;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}

.map-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icon-btn {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-btn img {
  width: 24px;
  height: 24px;
}

.icon-btn:hover {
  background-color: rgba(255, 255, 255, 1);
}

.toggle-sidebar-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.show-sidebar-btn {
  position: absolute;
  margin-top: 3%;
  margin-left: 3%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}
</style>
