import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_5 = { class: "shadow-none lh-1 fw-bold ps-0" }
const _hoisted_6 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_7 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "shadow-none lh-1 fw-medium text-body-tertiary" }
const _hoisted_11 = { class: "shadow-none lh-1 fw-medium text-body-tertiary" }
const _hoisted_12 = { class: "shadow-none lh-1 fw-medium pe-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between\"><h6 class=\"card-title fw-bold mb-0\">Products Orders</h6><div class=\"card-select mt-10 mt-md-0 mb-10 mb-md-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><span class=\"fw-medium text-muted me-8\">Status</span><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option selected class=\"fw-medium\">Show All</option><option value=\"1\" class=\"fw-medium\">In Stock</option></select></div><form><div class=\"input-group\"><input type=\"text\" class=\"form-control shadow-none fw-medium ps-12 pt-8 pb-8 pe-12 letter-spacing\" placeholder=\"Search\"><button class=\"default-btn position-relative transition border-0 text-white ps-12 pe-12 rounded-1\" type=\"button\"><i class=\"flaticon-search-interface-symbol position-relative\"></i></button></div></form></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              }, " ORDER ID "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " CREATED "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " CUSTOMER "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " TOTAL "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " PROFIT "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 pe-0"
              }, " STATUS ")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: order.id
              }, [
                _createElementVNode("th", _hoisted_5, [
                  _createVNode(_component_router_link, {
                    to: "/product-details",
                    class: "text-decoration-none text-black-emphasis"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(order.orderID), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("td", _hoisted_6, [
                  _createTextVNode(_toDisplayString(order.createdTime) + " ", 1),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-body-tertiary" }, " ago", -1))
                ]),
                _createElementVNode("td", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("img", {
                      src: require(`../../../../assets/images/${order.image}`),
                      class: "rounded-circle me-8",
                      width: "24",
                      height: "24",
                      alt: "user"
                    }, null, 8, _hoisted_9),
                    _createTextVNode(" " + _toDisplayString(order.name), 1)
                  ])
                ]),
                _createElementVNode("td", _hoisted_10, " $" + _toDisplayString(order.totalPrice), 1),
                _createElementVNode("td", _hoisted_11, " $" + _toDisplayString(order.profitPrice), 1),
                _createElementVNode("td", _hoisted_12, [
                  _createElementVNode("span", {
                    class: _normalizeClass(['badge', order.class])
                  }, _toDisplayString(order.status), 3)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}