import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box" }
const _hoisted_3 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "title ms-15" }
const _hoisted_6 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_7 = { class: "fw-black mb-8 lh-1" }
const _hoisted_8 = { class: "fw-medium text-dark-emphasis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-xl-3 col-sm-6",
        key: stat.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass([
                'icon position-relative rounded-circle text-center',
                stat.class,
              ])
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(stat.icon)
                }, null, 2)
              ], 2),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(stat.title), 1),
                _createElementVNode("h4", _hoisted_7, _toDisplayString(stat.sign) + _toDisplayString(stat.currentValue), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(stat.sign) + _toDisplayString(stat.previousValue), 1),
                _createElementVNode("span", {
                  class: _normalizeClass(['fw-bold text-success ms-8', stat.classTwo])
                }, [
                  _createTextVNode(_toDisplayString(stat.percent) + "% ", 1),
                  _createElementVNode("i", {
                    class: _normalizeClass([
                    stat.iconUpDown,
                    'fs-12 lh-1 position-relative top-1',
                  ])
                  }, null, 2)
                ], 2)
              ])
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}