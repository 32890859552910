<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-4">
    <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
      <h5 class="mb-4 text-center">Buscador Análogo</h5>

      <!-- <div class="row align-items-center">
        <div class="col-md-6">
          <label for="tipoProducto">Seleccione una clasificación:</label>
          <multiselect v-model="selectedTipo" :options="clasificaciones" :searchable="true" :close-on-select="true"
            placeholder="Seleccione una clasificación" track-by="uuid" label="nombre" class="w-100">
          </multiselect>
        </div>

        <div class="col-md-6 dise">
          <label for="producto">Buscar Producto:</label>
          <div class="autocomplete-container">
            <AutoComplete v-model="selectedProducto" :suggestions="filteredProductos" field="nombre_producto"
              @complete="searchProductos" placeholder="Buscar por nombre de producto">
            </AutoComplete>

            <button v-if="selectedProducto" @click="clearProducto" class="clear-btn">
              X
            </button>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-6 pt-10">
          <div class="input-group w-100">
            <label for="basicFormControlInput1" class="form-label fw-medium text-black">
              Clasificación
            </label>
            <multiselect v-model="selectedTipo" :options="clasificaciones" :searchable="true" :close-on-select="true"
              placeholder="Seleccione una clasificación" track-by="uuid" label="nombre" class="w-100">
            </multiselect>
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group w-100">
            <label for="basicFormControlInput1" class="form-label fw-medium text-black">
              Buscar Producto
            </label>
            <AutoComplete v-model="selectedProducto" :suggestions="filteredProductos" field="nombre_producto"
              @complete="searchProductos" placeholder="Buscar por nombre de producto" class="w-100">
            </AutoComplete>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-3">
        <button @click="onProductoSelect" class="btn btn-primary">
          Buscar
        </button>
      </div>

      <div v-if="equivalenteProductos.length > 0" class="mt-4">
        <h3 class="text-center">Productos Equivalentes</h3>
        <table class="table table-bordered">
          <thead class="thead-dark">
            <tr>
              <th>Nombre del Producto</th>
              <th>Marca</th>
              <th>Clasificación</th>
              <th>Características</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="producto in equivalenteProductos" :key="producto.uuid_producto">
              <td>{{ producto.nombre_producto }}</td>
              <td>{{ producto.nombre_marca }}</td>
              <td>{{ producto.nombre_clasificacion }}</td>
              <td>
                <ul>
                  <li v-for="caracteristica in producto.caracteristicas" :key="caracteristica.uuid_caracteristica">
                    {{ caracteristica.nombre_caracteristica }}:
                    {{ caracteristica.valor }}
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import Multiselect from "vue-multiselect";
import AutoComplete from "primevue/autocomplete";
import Producto from "@/services/productService";
import { useToast } from "vue-toastification";
import { ProductoEquivalente, Clasificacion } from "@/interfaces/interfaces";

export default defineComponent({
  components: {
    Multiselect,
    AutoComplete,
  },
  setup() {
    const selectedTipo = ref<Clasificacion | null>(null);
    const clasificaciones = ref<Clasificacion[]>([]);
    const resultadoBusqueda = ref<ProductoEquivalente[]>([]);
    const selectedProducto = ref<ProductoEquivalente | null>(null);
    const filteredProductos = ref<ProductoEquivalente[]>([]);
    const equivalenteProductos = ref<ProductoEquivalente[]>([]);
    const toast = useToast();

    const getClasificaciones = async () => {
      try {
        const response = await new Producto().ListClasificacion();
        clasificaciones.value = response.datos;
      } catch (error) {
        toast.error("Error al traer las clasificaciones");
      }
    };

    const searchProduct = async () => {
      if (selectedTipo.value) {
        const data = {
          uuid_clasificacion: selectedTipo.value.uuid,
        };
        try {
          const response = await new Producto().SearchProducts(data);
          resultadoBusqueda.value = response.datos;
          console.log(JSON.stringify(resultadoBusqueda.value, null, 2));
        } catch (error) {
          toast.error("Error al buscar los productos");
        }
      } else {
        toast.warning("Por favor seleccione una clasificación.");
      }
    };

    const searchProductos = (event: { query: string }) => {
      const query = event.query.toLowerCase();
      filteredProductos.value = resultadoBusqueda.value.filter((producto) =>
        producto.nombre_producto.toLowerCase().includes(query)
      );
    };

    const clearProducto = () => {
      selectedProducto.value = null;
      equivalenteProductos.value = []; // Limpiar la tabla
    };

    const onProductoSelect = async () => {
      if (!selectedTipo.value) {
        toast.warning("Por favor seleccione una clasificación.");
        return;
      }

      if (!selectedProducto.value) {
        toast.warning("Por favor seleccione un producto.");
        return;
      }

      const productoSeleccionado = resultadoBusqueda.value.find(
        (producto) =>
          producto.nombre_producto === selectedProducto.value?.nombre_producto
      );

      if (productoSeleccionado) {
        const uuidProducto = productoSeleccionado.uuid_producto;
        const dato = { uuid: uuidProducto };

        try {
          const response = await new Producto().ListEquivalente(dato);
          equivalenteProductos.value = response.datos;
          console.log(JSON.stringify(response, null, 2));
        } catch (error) {
          console.log("Error al obtener equivalentes:", error);
        }
      } else {
        toast.error("Producto no encontrado.");
      }
    };

    onMounted(() => {
      getClasificaciones();
    });

    watch(selectedTipo, (newVal) => {
      if (newVal) {
        searchProduct();
      }
    });

    return {
      selectedTipo,
      clasificaciones,
      selectedProducto,
      filteredProductos,
      equivalenteProductos,
      searchProductos,
      clearProducto,
      onProductoSelect,
    };
  },
});
</script>

<style scoped>
.autocomplete-container {
  display: flex;
  align-items: center;
}

.clear-btn {
  background: transparent;
  border: none;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.clear-btn:hover {
  color: red;
}

::v-deep .p-autocomplete .p-inputtext {
  width: 100%;
}

.table {
  margin-top: 20px;
  font-size: 0.9rem;
}

thead.thead-dark th {
  background-color: #343a40;
  color: white;
}

thead th {
  text-align: center;
}

tbody td {
  text-align: center;
  vertical-align: middle;
}

ul {
  list-style-type: none;
  padding-left: 0;
}
</style>
