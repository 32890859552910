import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/admin.jpg'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-5 col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeadsInformation = _resolveComponent("LeadsInformation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LeadsInformation)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-lg-7 col-xl-8" }, [
      _createElementVNode("div", { class: "leads-details-tabs" }, [
        _createElementVNode("ul", {
          class: "nav nav-tabs border-0 mb-25",
          id: "myTab",
          role: "tablist"
        }, [
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("button", {
              class: "nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100 active",
              id: "tasks-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#tasks-tab-pane",
              type: "button",
              role: "tab",
              "aria-controls": "tasks-tab-pane",
              "aria-selected": "true"
            }, " Tasks ")
          ]),
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("button", {
              class: "nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100",
              id: "deals-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#deals-tab-pane",
              type: "button",
              role: "tab",
              "aria-controls": "deals-tab-pane",
              "aria-selected": "false"
            }, " Deals ")
          ]),
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("button", {
              class: "nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100",
              id: "emails-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#emails-tab-pane",
              type: "button",
              role: "tab",
              "aria-controls": "emails-tab-pane",
              "aria-selected": "false"
            }, " Emails ")
          ]),
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("button", {
              class: "nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100",
              id: "attachments-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#attachments-tab-pane",
              type: "button",
              role: "tab",
              "aria-controls": "attachments-tab-pane",
              "aria-selected": "false"
            }, " Attachments ")
          ])
        ]),
        _createElementVNode("div", {
          class: "tab-content",
          id: "myTabContent"
        }, [
          _createElementVNode("div", {
            class: "tab-pane fade show active",
            id: "tasks-tab-pane",
            role: "tabpanel",
            tabindex: "0"
          }, [
            _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white to-do-list-box letter-spacing" }, [
              _createElementVNode("div", { class: "card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Tasks"),
                _createElementVNode("div", { class: "d-flex align-items-center mt-10 mt-md-0" }, [
                  _createElementVNode("form", { class: "search-box position-relative me-15" }, [
                    _createElementVNode("input", {
                      type: "text",
                      class: "form-control shadow-none text-black rounded-0 border-0",
                      placeholder: "Search task"
                    }),
                    _createElementVNode("button", {
                      type: "submit",
                      class: "bg-transparent text-primary transition p-0 border-0"
                    }, [
                      _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
                    ])
                  ]),
                  _createElementVNode("button", {
                    class: "dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block",
                    type: "button"
                  }, [
                    _createElementVNode("i", { class: "flaticon-dots" })
                  ])
                ])
              ]),
              _createElementVNode("div", { class: "card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("ul", { class: "to-do-list ps-0 list-unstyled mb-0" }, [
                  _createElementVNode("li", { class: "to-do-list-item d-sm-flex align-items-center justify-content-between" }, [
                    _createElementVNode("div", { class: "form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium" }, [
                      _createElementVNode("input", {
                        class: "form-check-input shadow-none",
                        type: "checkbox",
                        id: "task1"
                      }),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: "task1"
                      }, " Design a Facebook Ad "),
                      _createElementVNode("span", { class: "fs-12 text-muted fw-normal ms-5" }, " 19 Mar, 2023 ")
                    ]),
                    _createElementVNode("div", { class: "action-buttons mt-10 mt-sm-0 d-flex align-items-center" }, [
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-pen" })
                      ]),
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1 ms-10 ms-md-20" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-trash" })
                      ])
                    ])
                  ]),
                  _createElementVNode("li", { class: "to-do-list-item d-sm-flex align-items-center justify-content-between" }, [
                    _createElementVNode("div", { class: "form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium" }, [
                      _createElementVNode("input", {
                        class: "form-check-input shadow-none",
                        type: "checkbox",
                        id: "task2"
                      }),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: "task2"
                      }, " Analyze Dashboard Data "),
                      _createElementVNode("span", { class: "fs-12 text-muted fw-normal ms-5" }, "18 Mar, 2023")
                    ]),
                    _createElementVNode("div", { class: "action-buttons mt-10 mt-sm-0 d-flex align-items-center" }, [
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-pen" })
                      ]),
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1 ms-10 ms-md-20" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-trash" })
                      ])
                    ])
                  ]),
                  _createElementVNode("li", { class: "to-do-list-item d-sm-flex align-items-center justify-content-between" }, [
                    _createElementVNode("div", { class: "form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium" }, [
                      _createElementVNode("input", {
                        class: "form-check-input shadow-none",
                        type: "checkbox",
                        id: "task3"
                      }),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: "task3"
                      }, " Market And Consumer Insights "),
                      _createElementVNode("span", { class: "fs-12 text-muted fw-normal ms-5" }, "17 Mar, 2023")
                    ]),
                    _createElementVNode("div", { class: "action-buttons mt-10 mt-sm-0 d-flex align-items-center" }, [
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-pen" })
                      ]),
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1 ms-10 ms-md-20" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-trash" })
                      ])
                    ])
                  ]),
                  _createElementVNode("li", { class: "to-do-list-item d-sm-flex align-items-center justify-content-between" }, [
                    _createElementVNode("div", { class: "form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium" }, [
                      _createElementVNode("input", {
                        class: "form-check-input shadow-none",
                        type: "checkbox",
                        id: "task4"
                      }),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: "task4"
                      }, " Review Dashboard Design "),
                      _createElementVNode("span", { class: "fs-12 text-muted fw-normal ms-5" }, "16 Mar, 2023")
                    ]),
                    _createElementVNode("div", { class: "action-buttons mt-10 mt-sm-0 d-flex align-items-center" }, [
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-pen" })
                      ]),
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1 ms-10 ms-md-20" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-trash" })
                      ])
                    ])
                  ]),
                  _createElementVNode("li", { class: "to-do-list-item d-sm-flex align-items-center justify-content-between" }, [
                    _createElementVNode("div", { class: "form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium" }, [
                      _createElementVNode("input", {
                        class: "form-check-input shadow-none",
                        type: "checkbox",
                        id: "task5"
                      }),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: "task5"
                      }, " Stockholder Meeting "),
                      _createElementVNode("span", { class: "fs-12 text-muted fw-normal ms-5" }, "15 Mar, 2023")
                    ]),
                    _createElementVNode("div", { class: "action-buttons mt-10 mt-sm-0 d-flex align-items-center" }, [
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-pen" })
                      ]),
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1 ms-10 ms-md-20" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-trash" })
                      ])
                    ])
                  ]),
                  _createElementVNode("li", { class: "to-do-list-item d-sm-flex align-items-center justify-content-between" }, [
                    _createElementVNode("div", { class: "form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium" }, [
                      _createElementVNode("input", {
                        class: "form-check-input shadow-none",
                        type: "checkbox",
                        id: "task6"
                      }),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: "task6"
                      }, " Taking Interview "),
                      _createElementVNode("span", { class: "fs-12 text-muted fw-normal ms-5" }, "14 Mar, 2023")
                    ]),
                    _createElementVNode("div", { class: "action-buttons mt-10 mt-sm-0 d-flex align-items-center" }, [
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-pen" })
                      ]),
                      _createElementVNode("button", { class: "bg-transparent p-0 border-0 text-black transition lh-1 ms-10 ms-md-20" }, [
                        _createElementVNode("i", { class: "ph-duotone ph-trash" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("a", {
                  href: "#",
                  class: "card-link-btn text-decoration-none text-primary fw-medium position-relative d-inline-block mt-10 mt-sm-25",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#createNewTaskModal"
                }, "Add A New Task")
              ])
            ]),
            _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }, [
              _createElementVNode("div", { class: "card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Deals"),
                _createElementVNode("div", { class: "d-flex align-items-center mt-10 mt-md-0" }, [
                  _createElementVNode("form", { class: "search-box position-relative me-15" }, [
                    _createElementVNode("input", {
                      type: "text",
                      class: "form-control shadow-none text-black rounded-0 border-0",
                      placeholder: "Search task"
                    }),
                    _createElementVNode("button", {
                      type: "submit",
                      class: "bg-transparent text-primary transition p-0 border-0"
                    }, [
                      _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
                    ])
                  ]),
                  _createElementVNode("button", {
                    class: "dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block mt-10 mt-sm-0",
                    type: "button"
                  }, [
                    _createElementVNode("i", { class: "flaticon-dots" })
                  ])
                ])
              ]),
              _createElementVNode("div", { class: "card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("div", { class: "table-responsive" }, [
                  _createElementVNode("table", { class: "table text-nowrap align-middle mb-0" }, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                        }, " Deal Name "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Amout "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Status "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Probability "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Closing Date "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                        })
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" J. Ronan ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 6,400,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-success" }, "Won Deal")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " ronanjohnny@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" J. Pinkman ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 100,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-info" }, "New Deal")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " pinky321@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 12 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" A. Carter ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 1,840,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-danger" }, "Cancelled")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " ronanjohnny@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" V. Loper ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 6,400,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-success" }, "Won Deal")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " lopervic@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" W. White ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 100,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-info" }, "New Deal")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " whitespace@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 10 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" T. Skyler ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 400,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-danger" }, "Cancelled")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " ronanjohnny@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center" }, [
                  _createElementVNode("a", {
                    href: "#",
                    class: "card-link-btn text-decoration-none text-primary fw-medium position-relative d-inline-block",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#createNewDealModal"
                  }, "Add A New Deal"),
                  _createElementVNode("div", { class: "pagination-area d-md-flex align-items-center mt-10 mt-md-0" }, [
                    _createElementVNode("p", { class: "mb-0 text-paragraph" }, [
                      _createTextVNode(" Showing "),
                      _createElementVNode("span", { class: "fw-bold" }, "11"),
                      _createTextVNode(" out of "),
                      _createElementVNode("span", { class: "fw-bold" }, "134"),
                      _createTextVNode(" results ")
                    ]),
                    _createElementVNode("nav", { class: "mt-12 mt-md-0 ms-md-20" }, [
                      _createElementVNode("ul", { class: "pagination mb-0" }, [
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#",
                            "aria-label": "Previous"
                          }, [
                            _createElementVNode("i", { class: "flaticon-chevron-1" })
                          ])
                        ]),
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link active",
                            href: "#"
                          }, "1")
                        ]),
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#"
                          }, "2")
                        ]),
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#"
                          }, "3")
                        ]),
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#",
                            "aria-label": "Next"
                          }, [
                            _createElementVNode("i", { class: "flaticon-chevron" })
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }, [
              _createElementVNode("div", { class: "card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Emails"),
                _createElementVNode("div", { class: "d-flex align-items-center mt-10 mt-md-0" }, [
                  _createElementVNode("form", { class: "search-box position-relative me-15" }, [
                    _createElementVNode("input", {
                      type: "text",
                      class: "form-control shadow-none text-black rounded-0 border-0",
                      placeholder: "Search email"
                    }),
                    _createElementVNode("button", {
                      type: "submit",
                      class: "bg-transparent text-primary transition p-0 border-0"
                    }, [
                      _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
                    ])
                  ]),
                  _createElementVNode("button", {
                    class: "dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block mt-10 mt-sm-0",
                    type: "button"
                  }, [
                    _createElementVNode("i", { class: "flaticon-dots" })
                  ])
                ])
              ]),
              _createElementVNode("div", { class: "card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("div", { class: "table-responsive" }, [
                  _createElementVNode("table", { class: "table text-nowrap align-middle mb-0" }, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                        }, " Subject "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Sent By "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Status "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Date "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                        })
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Design a Facebook Ad "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " J. Ronan "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-warning" }, "Bounce")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Analyze Dashboard Data "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " W. White "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-info" }, "Draft")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 12 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Review Dashboard Design "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " V. Loper "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-danger" }, "Spam")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 11 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Taking Interview "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " A. Carter "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-success" }, "Sent")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 10 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Stockholder Meeting "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " J. Pinkman "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-primary" }, "Delivered")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 09 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white attachments-box" }, [
              _createElementVNode("div", { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }, [
                _createElementVNode("div", { class: "mb-15 mb-md-20 mb-lg-25 d-sm-flex align-items-center justify-content-between" }, [
                  _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Attachments")
                ]),
                _createElementVNode("ul", { class: "list ps-0 mb-0 list-unstyled" }, [
                  _createElementVNode("li", null, [
                    _createElementVNode("span", { class: "fw-medium d-block fs-md-15 fs-lg-16" }, [
                      _createElementVNode("i", { class: "flaticon-image lh-1 position-relative text-primary top-2 me-5" }),
                      _createTextVNode(" Adlash_1.png ")
                    ]),
                    _createElementVNode("div", { class: "d-flex align-items-center text-muted mt-5" }, [
                      _createElementVNode("span", { class: "d-block" }, "555kb"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "J. Ronan"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "13 Apr, 2023")
                    ]),
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "admin",
                      width: "100",
                      class: "mt-10"
                    })
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("span", { class: "fw-medium d-block fs-md-15 fs-lg-16" }, [
                      _createElementVNode("i", { class: "flaticon-image lh-1 position-relative text-primary top-2 me-5" }),
                      _createTextVNode(" Adlash_2.png ")
                    ]),
                    _createElementVNode("div", { class: "d-flex align-items-center text-muted mt-5" }, [
                      _createElementVNode("span", { class: "d-block" }, "1MB"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "J. Pinkman"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "12 Apr, 2023")
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("span", { class: "fw-medium d-block fs-md-15 fs-lg-16" }, [
                      _createElementVNode("i", { class: "flaticon-image lh-1 position-relative text-primary top-2 me-5" }),
                      _createTextVNode(" Adlash_3.png ")
                    ]),
                    _createElementVNode("div", { class: "d-flex align-items-center text-muted mt-5" }, [
                      _createElementVNode("span", { class: "d-block" }, "472kb"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "A. Carter"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "11 Apr, 2023")
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("span", { class: "fw-medium d-block fs-md-15 fs-lg-16" }, [
                      _createElementVNode("i", { class: "flaticon-document lh-1 position-relative text-primary top-2 me-5" }),
                      _createTextVNode(" Adlash_4.txt ")
                    ]),
                    _createElementVNode("div", { class: "d-flex align-items-center text-muted mt-5" }, [
                      _createElementVNode("span", { class: "d-block" }, "433kb"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "V. Loper"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "10 Apr, 2023")
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "tab-pane fade",
            id: "deals-tab-pane",
            role: "tabpanel",
            tabindex: "0"
          }, [
            _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }, [
              _createElementVNode("div", { class: "card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Deals"),
                _createElementVNode("div", { class: "d-flex align-items-center mt-10 mt-md-0" }, [
                  _createElementVNode("form", { class: "search-box position-relative me-15" }, [
                    _createElementVNode("input", {
                      type: "text",
                      class: "form-control shadow-none text-black rounded-0 border-0",
                      placeholder: "Search task"
                    }),
                    _createElementVNode("button", {
                      type: "submit",
                      class: "bg-transparent text-primary transition p-0 border-0"
                    }, [
                      _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
                    ])
                  ]),
                  _createElementVNode("button", {
                    class: "dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block mt-10 mt-sm-0",
                    type: "button"
                  }, [
                    _createElementVNode("i", { class: "flaticon-dots" })
                  ])
                ])
              ]),
              _createElementVNode("div", { class: "card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("div", { class: "table-responsive" }, [
                  _createElementVNode("table", { class: "table text-nowrap align-middle mb-0" }, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                        }, " Deal Name "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Amout "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Status "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Probability "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Closing Date "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                        })
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" J. Ronan ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 6,400,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-success" }, "Won Deal")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " ronanjohnny@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" J. Pinkman ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 100,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-info" }, "New Deal")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " pinky321@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 12 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" A. Carter ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 1,840,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-danger" }, "Cancelled")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " ronanjohnny@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" V. Loper ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 6,400,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-success" }, "Won Deal")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " lopervic@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" W. White ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 100,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-info" }, "New Deal")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " whitespace@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 10 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }, [
                          _createElementVNode("div", { class: "form-check d-flex align-items-center mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none me-8 mt-0",
                              type: "checkbox"
                            }),
                            _createTextVNode(" T. Skyler ")
                          ])
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " $ 400,000.00 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-danger" }, "Cancelled")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " ronanjohnny@gmail.com "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center" }, [
                  _createElementVNode("a", {
                    href: "#",
                    class: "card-link-btn text-decoration-none text-primary fw-medium position-relative d-inline-block",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#createNewDealModal"
                  }, "Add A New Deal"),
                  _createElementVNode("div", { class: "pagination-area d-md-flex align-items-center mt-10 mt-md-0" }, [
                    _createElementVNode("p", { class: "mb-0 text-paragraph" }, [
                      _createTextVNode(" Showing "),
                      _createElementVNode("span", { class: "fw-bold" }, "11"),
                      _createTextVNode(" out of "),
                      _createElementVNode("span", { class: "fw-bold" }, "134"),
                      _createTextVNode(" results ")
                    ]),
                    _createElementVNode("nav", { class: "mt-12 mt-md-0 ms-md-20" }, [
                      _createElementVNode("ul", { class: "pagination mb-0" }, [
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#",
                            "aria-label": "Previous"
                          }, [
                            _createElementVNode("i", { class: "flaticon-chevron-1" })
                          ])
                        ]),
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link active",
                            href: "#"
                          }, "1")
                        ]),
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#"
                          }, "2")
                        ]),
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#"
                          }, "3")
                        ]),
                        _createElementVNode("li", { class: "page-item" }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#",
                            "aria-label": "Next"
                          }, [
                            _createElementVNode("i", { class: "flaticon-chevron" })
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "tab-pane fade",
            id: "emails-tab-pane",
            role: "tabpanel",
            tabindex: "0"
          }, [
            _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }, [
              _createElementVNode("div", { class: "card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Emails"),
                _createElementVNode("div", { class: "d-flex align-items-center mt-10 mt-md-0" }, [
                  _createElementVNode("form", { class: "search-box position-relative me-15" }, [
                    _createElementVNode("input", {
                      type: "text",
                      class: "form-control shadow-none text-black rounded-0 border-0",
                      placeholder: "Search email"
                    }),
                    _createElementVNode("button", {
                      type: "submit",
                      class: "bg-transparent text-primary transition p-0 border-0"
                    }, [
                      _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
                    ])
                  ]),
                  _createElementVNode("button", {
                    class: "dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block mt-10 mt-sm-0",
                    type: "button"
                  }, [
                    _createElementVNode("i", { class: "flaticon-dots" })
                  ])
                ])
              ]),
              _createElementVNode("div", { class: "card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }, [
                _createElementVNode("div", { class: "table-responsive" }, [
                  _createElementVNode("table", { class: "table text-nowrap align-middle mb-0" }, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                        }, " Subject "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Sent By "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Status "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                        }, " Date "),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                        })
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Design a Facebook Ad "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " J. Ronan "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-warning" }, "Bounce")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 13 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Analyze Dashboard Data "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " W. White "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-info" }, "Draft")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 12 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Review Dashboard Design "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " V. Loper "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-danger" }, "Spam")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 11 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Taking Interview "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " A. Carter "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-success" }, "Sent")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 10 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "shadow-none lh-1 fw-medium text-black-emphasis title fs-md-15 fs-lg-16 ps-0" }, [
                          _createTextVNode(" Stockholder Meeting "),
                          _createElementVNode("span", { class: "d-block fs-13 mt-10 text-muted fw-normal" }, "ronanjohnny@gmail.com")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " J. Pinkman "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium" }, [
                          _createElementVNode("span", { class: "badge text-outline-primary" }, "Delivered")
                        ]),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-black-emphasis" }, " 09 Apr, 2023 "),
                        _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                          _createElementVNode("div", { class: "dropdown" }, [
                            _createElementVNode("button", {
                              class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("i", { class: "flaticon-dots" })
                            ]),
                            _createElementVNode("ul", { class: "dropdown-menu" }, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" View")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Edit")
                                ])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  class: "dropdown-item d-flex align-items-center",
                                  href: "javascript:void(0);"
                                }, [
                                  _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                                  _createTextVNode(" Delete")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "tab-pane fade",
            id: "attachments-tab-pane",
            role: "tabpanel",
            tabindex: "0"
          }, [
            _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white attachments-box" }, [
              _createElementVNode("div", { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }, [
                _createElementVNode("div", { class: "mb-15 mb-md-20 mb-lg-25 d-sm-flex align-items-center justify-content-between" }, [
                  _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Attachments")
                ]),
                _createElementVNode("ul", { class: "list ps-0 mb-0 list-unstyled" }, [
                  _createElementVNode("li", null, [
                    _createElementVNode("span", { class: "fw-medium d-block fs-md-15 fs-lg-16" }, [
                      _createElementVNode("i", { class: "flaticon-image lh-1 position-relative text-primary top-2 me-5" }),
                      _createTextVNode(" Adlash_1.png ")
                    ]),
                    _createElementVNode("div", { class: "d-flex align-items-center text-muted mt-5" }, [
                      _createElementVNode("span", { class: "d-block" }, "555kb"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "J. Ronan"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "13 Apr, 2023")
                    ]),
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "admin",
                      width: "100",
                      class: "mt-10"
                    })
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("span", { class: "fw-medium d-block fs-md-15 fs-lg-16" }, [
                      _createElementVNode("i", { class: "flaticon-image lh-1 position-relative text-primary top-2 me-5" }),
                      _createTextVNode(" Adlash_2.png ")
                    ]),
                    _createElementVNode("div", { class: "d-flex align-items-center text-muted mt-5" }, [
                      _createElementVNode("span", { class: "d-block" }, "1MB"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "J. Pinkman"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "12 Apr, 2023")
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("span", { class: "fw-medium d-block fs-md-15 fs-lg-16" }, [
                      _createElementVNode("i", { class: "flaticon-image lh-1 position-relative text-primary top-2 me-5" }),
                      _createTextVNode(" Adlash_3.png ")
                    ]),
                    _createElementVNode("div", { class: "d-flex align-items-center text-muted mt-5" }, [
                      _createElementVNode("span", { class: "d-block" }, "472kb"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "A. Carter"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "11 Apr, 2023")
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("span", { class: "fw-medium d-block fs-md-15 fs-lg-16" }, [
                      _createElementVNode("i", { class: "flaticon-document lh-1 position-relative text-primary top-2 me-5" }),
                      _createTextVNode(" Adlash_4.txt ")
                    ]),
                    _createElementVNode("div", { class: "d-flex align-items-center text-muted mt-5" }, [
                      _createElementVNode("span", { class: "d-block" }, "433kb"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "V. Loper"),
                      _createElementVNode("span", { class: "w-6 h-6 rounded-circle bg-muted ms-8 me-8" }),
                      _createElementVNode("span", { class: "d-block" }, "10 Apr, 2023")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ], -1))
  ]))
}