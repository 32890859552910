import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("select", {
      class: "form-select shadow-none text-black fs-md-15",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedState) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.filterLocations && _ctx.filterLocations(...args)))
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("option", { value: "" }, "Selecciona una región", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states, (state) => {
        return (_openBlock(), _createElementBlock("option", {
          key: state,
          value: state
        }, _toDisplayString(state), 9, _hoisted_1))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.selectedState]
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", {
      class: "pac-card",
      id: "pac-card"
    }, [
      _createElementVNode("div", { id: "search-container" }, [
        _createElementVNode("input", {
          id: "pac-input",
          type: "text",
          placeholder: "Buscar ubicación",
          style: {"width":"100%","padding":"5px"}
        })
      ])
    ], -1)),
    _cache[4] || (_cache[4] = _createElementVNode("div", {
      id: "map",
      style: {"width":"100%","height":"500px"}
    }, null, -1)),
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: 'Cargando...'
    }, null, 8, ["active"])
  ]))
}