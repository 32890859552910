import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/icon/folder.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_5 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_6 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_7 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_8 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_9 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_10 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_11 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_12 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_13 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_14 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_15 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_16 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_17 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_18 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_19 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_20 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_21 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_22 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_23 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }
const _hoisted_24 = { class: "col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2" }
const _hoisted_25 = { class: "bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[34] || (_cache[34] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-sm-20 mb-md-25" }, "Assets", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Projects ")
              ])),
              _: 1
            }),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 387 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 4.5 GB ")
            ], -1))
          ])
        ]),
        _cache[33] || (_cache[33] = _createStaticVNode("<div class=\"col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2\"><div class=\"bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 p-15 p-md-20\"><div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\"><a href=\"#\" class=\"d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium\"> Documents </a><div class=\"mt-30 d-flex align-items-center justify-content-between\"><span class=\"d-block fs-md-15 fs-lg-16 text-black-emphasis\"> 1572 Files </span><span class=\"d-block fs-md-15 fs-lg-16 text-black-emphasis\"> 7.3 GB </span></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Media ")
              ])),
              _: 1
            }),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 12341 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 2.8 GB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Applications ")
              ])),
              _: 1
            }),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 2487 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 5.0 GB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" ET Template ")
              ])),
              _: 1
            }),
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 321 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 2.2 GB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" React Template ")
              ])),
              _: 1
            }),
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 543 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 3.3 GB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" Material UI ")
              ])),
              _: 1
            }),
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 123 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 1.1 GB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode(" WP Themes ")
              ])),
              _: 1
            }),
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 54 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 500 MB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _cache[22] || (_cache[22] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode(" Personal Photos ")
              ])),
              _: 1
            }),
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 311 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 2.5 GB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createTextVNode(" Mobile Apps ")
              ])),
              _: 1
            }),
            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 387 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 4.5 GB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _cache[28] || (_cache[28] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[27] || (_cache[27] = [
                _createTextVNode(" Important Files ")
              ])),
              _: 1
            }),
            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 333 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 3.1 GB ")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _cache[31] || (_cache[31] = _createStaticVNode("<div class=\"mb-30 d-flex align-items-center justify-content-between\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><img src=\"" + _imports_0 + "\" class=\"d-block ms-auto me-auto mb-15\" alt=\"folder\">", 2)),
            _createVNode(_component_router_link, {
              to: "/file-manager",
              class: "d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            }, {
              default: _withCtx(() => _cache[30] || (_cache[30] = [
                _createTextVNode(" Angular Template ")
              ])),
              _: 1
            }),
            _cache[32] || (_cache[32] = _createElementVNode("div", { class: "mt-30 d-flex align-items-center justify-content-between" }, [
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 453 Files "),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 text-black-emphasis" }, " 2.2 GB ")
            ], -1))
          ])
        ])
      ]),
      _cache[35] || (_cache[35] = _createStaticVNode("<div class=\"pagination-area d-md-flex justify-content-between align-items-center\"><p class=\"mb-0 text-paragraph\"> Showing <span class=\"fw-bold\">12</span> out of <span class=\"fw-bold\">100</span> results </p><nav class=\"mt-15 mt-md-0\"><ul class=\"pagination mb-0\"><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Previous\"><i class=\"flaticon-chevron-1\"></i></a></li><li class=\"page-item\"><a class=\"page-link active\" href=\"#\">1</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">2</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">3</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Next\"><i class=\"flaticon-chevron\"></i></a></li></ul></nav></div>", 1))
    ])
  ]))
}