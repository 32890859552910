<template>
    <div class="row">
        <div class="col-md-12">
            <RegistrarUsuario/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import RegistrarUsuario from "@/components/crud/RegistrarUsuario.vue";

export default defineComponent({
    name: "RegistarUsuario",
    components: {
        RegistrarUsuario,
    },
});
</script>