<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <span class="fw-semibold me-5 text-black">Use example:</span>
          <code>
            &lt;<span class="red">i </span>
            <span class="green">class</span>=<span class="yellow"
              >&quot;flaticon-chevron&quot;</span
            >&gt;&lt;/<span class="red">i</span>&gt;
          </code>
          <span class="ms-5 me-5 text-muted fw-semibold">or</span>
          <code>
            &lt;<span class="red">span </span> <span class="green">class</span>=
            <span class="yellow">&quot;flaticon-chevron&quot;</span>
            &gt;&lt;/
            <span class="red">span</span>&gt;
          </code>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-chevron fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-chevron
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-chevron-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-chevron-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-right-arrow fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-right-arrow
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-left-arrow fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-left-arrow
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-chevron-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-chevron-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-chevron-3 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-chevron-3
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-right-arrow-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-right-arrow-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-left-arrow-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-left-arrow-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-telegram fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-telegram
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-like fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-like
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-like-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-like-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-dislike fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-dislike
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-dislike-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-dislike-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-eye fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-eye
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-view fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-view
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-eye-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-eye-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-visibility-off fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-visibility-off
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-price-tag fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-price-tag
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-price-tag-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-price-tag-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-heart fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-heart
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-heart-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-heart-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-star fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-star
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-star-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-star-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-star-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-star-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-shopping-cart fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-shopping-cart
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-shopping-cart-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-shopping-cart-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bag fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bag
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-shopping-cart-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-shopping-cart-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-user fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-user
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-lock fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-lock
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-unlock fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-unlock
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-play-button-arrowhead fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-play-button-arrowhead
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-play fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-play
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-search-interface-symbol fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-search-interface-symbol
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-search fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-search
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-plus fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-plus
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-minus fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-minus
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-close fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-close
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-menu fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-menu
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-menu-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-menu-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-menu-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-menu-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bookmark fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bookmark
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bookmark-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bookmark-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-folder fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-folder
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-folder-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-folder-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-diamond fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-diamond
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-diamond-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-diamond-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-check fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-check
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-check-mark fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-check-mark
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-draw-check-mark fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-draw-check-mark
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-check-mark-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-check-mark-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-customer-service fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-customer-service
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-technical-support fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-technical-support
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-support fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-support
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-people fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-people
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-users-group fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-users-group
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-black-circle fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-black-circle
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-3d-cube fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-3d-cube
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-cube-3d fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-cube-3d
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-straight-quotes fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-straight-quotes
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-right-quote fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-right-quote
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-quotes fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-quotes
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-quote fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-quote
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bell fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bell
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bell-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bell-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-secure-shield fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-secure-shield
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-shield fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-shield
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-tap fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-tap
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-tap-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-tap-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-fingerprint-scan fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-fingerprint-scan
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-fingerprint fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-fingerprint
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-brain fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-brain
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-brainstorm fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-brainstorm
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-credit-card fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-credit-card
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-credit-cards fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-credit-cards
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-award fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-award
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-trophy fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-trophy
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-award-symbol fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-award-symbol
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-award-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-award-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-download fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-download
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-file fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-file
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-download-circular-button fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-download-circular-button
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-fast-delivery fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-fast-delivery
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-gift-box-with-a-bow fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-gift-box-with-a-bow
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-giftbox fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-giftbox
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-layer fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-layer
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-layer-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-layer-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-information fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-information
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-info fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-info
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-worldwide fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-worldwide
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-world fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-world
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-envelope fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-envelope
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-email fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-email
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-email-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-email-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-phone-call fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-phone-call
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-telephone fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-telephone
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-maps-and-flags fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-maps-and-flags
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-maps-and-flags-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-maps-and-flags-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pin fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pin
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-marker fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-marker
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-thumbtacks fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-thumbtacks
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-thumbtacks-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-thumbtacks-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-placeholder fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-placeholder
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-home fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-home
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-home-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-home-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-video-camera fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-video-camera
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-video-camera-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-video-camera-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-camera fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-camera
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-photo-camera fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-photo-camera
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-refresh fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-refresh
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-edit fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-edit
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pen fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pen
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-share fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-share
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-share-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-share-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-printer fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-printer
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-calendar fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-calendar
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-calendar-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-calendar-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-diskette fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-diskette
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-gallery fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-gallery
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-musical-note fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-musical-note
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-taget fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-taget
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-mail fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-mail
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-airplane fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-airplane
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-chat fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-chat
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-up-right-arrow fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-up-right-arrow
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-upper-left-arrow fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-upper-left-arrow
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-flash fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-flash
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-flash-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-flash-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-date fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-date
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-comment fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-comment
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-time fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-time
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-user-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-user-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-hot-sale fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-hot-sale
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-more fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-more
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-dots fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-dots
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-power fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-power
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-browser fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-browser
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-price-tag-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-price-tag-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-communication fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-communication
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-warning fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-warning
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-cubes fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-cubes
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-form fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-form
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-table fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-table
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-shapes fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-shapes
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-map fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-map
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-gps fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-gps
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-settings fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-settings
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-layer-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-layer-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-headphones fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-headphones
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-project fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-project
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-party fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-party
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-share-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-share-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-widget fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-widget
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-moon fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-moon
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bell-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bell-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-notification fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-notification
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-menu-3 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-menu-3
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-option fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-option
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-more-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-more-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-email-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-email-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-email-3 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-email-3
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-logout fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-logout
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-book fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-book
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-next fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-next
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-atm fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-atm
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-image fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-image
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-link fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-link
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-send fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-send
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-file-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-file-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-printing fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-printing
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-close-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-close-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-mail-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-mail-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-telephone-call fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-telephone-call
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-web fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-web
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-industry fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-industry
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-employee fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-employee
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-money fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-money
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-date-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-date-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-search-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-search-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-mail-inbox-app fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-mail-inbox-app
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-status fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-status
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-snooze fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-snooze
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-draft fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-draft
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-mail-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-mail-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-delete fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-delete
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-spam fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-spam
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-star-3 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-star-3
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pin-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pin-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-heart-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-heart-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-document fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-document
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-wallet fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-wallet
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-checkout fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-checkout
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-lock-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-lock-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-check-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-check-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-play-button fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-play-button
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-video fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-video
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-volume fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-volume
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-hourglass fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-hourglass
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-video-lesson fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-video-lesson
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-student-with-graduation-cap fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-student-with-graduation-cap
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-merge fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-merge
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-cancel fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-cancel
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-ban fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-ban
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-zip-file fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-zip-file
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-list fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-list
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-cloud-computing fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-cloud-computing
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-dribbble fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-dribbble
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-behance fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-behance
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-add-photo fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-add-photo
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-setting fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-setting
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-settings-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-settings-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-sun fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-sun
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-track fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-track
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-chart fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-chart
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-avatar fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-avatar
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-user-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-user-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-shield-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-shield-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-extension fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-extension
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-ticket fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-ticket
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-graduation fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-graduation
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-magistrate fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-magistrate
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-trolley-cart fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-trolley-cart
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-arroba fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-arroba
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-rocket fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-rocket
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-chat-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-chat-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-calendar-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-calendar-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-dashboard fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-dashboard
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-up-arrow fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-up-arrow
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-down-arrow fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-down-arrow
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-sterile-box fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-sterile-box
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-card fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-card
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-sugar-cubes fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-sugar-cubes
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-express-delivery fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-express-delivery
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-compare fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-compare
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-idea fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-idea
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-user-3 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-user-3
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-clock fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-clock
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-copy fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-copy
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-gallery-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-gallery-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-link-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-link-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-parcel fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-parcel
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pie-chart fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pie-chart
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pie-chart-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pie-chart-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bar-chart fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bar-chart
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bar-chart-1 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bar-chart-1
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-bar-chart-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-bar-chart-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-analytics fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-analytics
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pie-chart-2 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pie-chart-2
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pie-chart-3 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pie-chart-3
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pie-chart-4 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pie-chart-4
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pie-chart-5 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pie-chart-5
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-stats fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-stats
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-pie-chart-6 fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-pie-chart-6
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="flaticon flaticon-translate fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            .flaticon-translate
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlaticonsContent",
};
</script>