<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <ul class="nav nav-tabs mb-15 mb-md-20" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="basicFiguresPreview-tab"
                data-bs-toggle="tab"
                data-bs-target="#basicFiguresPreview-tab-pane"
                type="button"
                role="tab"
                aria-controls="basicFiguresPreview-tab-pane"
                aria-selected="true"
              >
                Preview
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="basicFiguresCode-tab"
                data-bs-toggle="tab"
                data-bs-target="#basicFiguresCode-tab-pane"
                type="button"
                role="tab"
                aria-controls="basicFiguresCode-tab-pane"
                aria-selected="false"
              >
                Code
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="basicFiguresPreview-tab-pane"
              role="tabpanel"
              tabindex="0"
            >
              <figure class="figure mb-0">
                <img
                  src="../../../assets/images/gallery/gallery1.jpg"
                  class="figure-img img-fluid rounded"
                  alt="gallery-image"
                />
                <figcaption class="figure-caption fs-md-15">
                  A caption for the above image.
                </figcaption>
              </figure>
            </div>
            <div
              class="tab-pane fade position-relative"
              id="basicFiguresCode-tab-pane"
              role="tabpanel"
              tabindex="0"
            >
              <!-- <button
                class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
                data-clipboard-target="#basicFiguresCode"
              >
                Copy
              </button> -->
              <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="basicFiguresCode">
<div>&lt;figure class="figure"&gt;</div>
    <div>&lt;img src="../../../assets/images/gallery/gallery1.jpg" class="figure-img img-fluid rounded" alt="gallery-image"&gt;</div>
    <div>&lt;figcaption class="figure-caption fs-md-15"&gt;A caption for the above image.&lt;/figcaption&gt;</div>
<div>&lt;/figure&gt;</div>
</code>
</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <ul class="nav nav-tabs mb-15 mb-md-20" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="basicFigures2Preview-tab"
                data-bs-toggle="tab"
                data-bs-target="#basicFigures2Preview-tab-pane"
                type="button"
                role="tab"
                aria-controls="basicFigures2Preview-tab-pane"
                aria-selected="true"
              >
                Preview
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="basicFigures2Code-tab"
                data-bs-toggle="tab"
                data-bs-target="#basicFigures2Code-tab-pane"
                type="button"
                role="tab"
                aria-controls="basicFigures2Code-tab-pane"
                aria-selected="false"
              >
                Code
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="basicFigures2Preview-tab-pane"
              role="tabpanel"
              tabindex="0"
            >
              <figure class="figure mb-0">
                <img
                  src="../../../assets/images/gallery/gallery2.jpg"
                  class="figure-img img-fluid rounded"
                  alt="gallery-image"
                />
                <figcaption class="figure-caption text-center fs-md-15">
                  A caption for the above image.
                </figcaption>
              </figure>
            </div>
            <div
              class="tab-pane fade position-relative"
              id="basicFigures2Code-tab-pane"
              role="tabpanel"
              tabindex="0"
            >
              <!-- <button
                class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
                data-clipboard-target="#basicFigures2Code"
              >
                Copy
              </button> -->
              <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="basicFigures2Code">
<div>&lt;figure class="figure"&gt;</div>
    <div>&lt;img src="../../../assets/images/gallery/gallery2.jpg" class="figure-img img-fluid rounded" alt="gallery-image"&gt;</div>
    <div>&lt;figcaption class="figure-caption text-center fs-md-15"&gt;A caption for the above image.&lt;/figcaption&gt;</div>
<div>&lt;/figure&gt;</div>
</code>
</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <ul class="nav nav-tabs mb-15 mb-md-20" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="basicFigures3Preview-tab"
                data-bs-toggle="tab"
                data-bs-target="#basicFigures3Preview-tab-pane"
                type="button"
                role="tab"
                aria-controls="basicFigures3Preview-tab-pane"
                aria-selected="true"
              >
                Preview
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="basicFigures3Code-tab"
                data-bs-toggle="tab"
                data-bs-target="#basicFigures3Code-tab-pane"
                type="button"
                role="tab"
                aria-controls="basicFigures3Code-tab-pane"
                aria-selected="false"
              >
                Code
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="basicFigures3Preview-tab-pane"
              role="tabpanel"
              tabindex="0"
            >
              <figure class="figure mb-0">
                <img
                  src="../../../assets/images/gallery/gallery3.jpg"
                  class="figure-img img-fluid rounded"
                  alt="gallery-image"
                />
                <figcaption class="figure-caption text-end fs-md-15">
                  A caption for the above image.
                </figcaption>
              </figure>
            </div>
            <div
              class="tab-pane fade position-relative"
              id="basicFigures3Code-tab-pane"
              role="tabpanel"
              tabindex="0"
            >
              <!-- <button
                class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
                data-clipboard-target="#basicFigures3Code"
              >
                Copy
              </button> -->
              <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="basicFigures3Code">
<div>&lt;figure class="figure"&gt;</div>
    <div>&lt;img src="../../../assets/images/gallery/gallery3.jpg" class="figure-img img-fluid rounded" alt="gallery-image"&gt;</div>
    <div>&lt;figcaption class="figure-caption text-end fs-md-15"&gt;A caption for the above image.&lt;/figcaption&gt;</div>
<div>&lt;/figure&gt;</div>
</code>
</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FiguresContent",
};
</script>