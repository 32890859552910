<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15">Margin</h5>
      <div class="position-relative mt-20">
        <!-- <button class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0" data-clipboard-target="#marginCode">
                            Copy
                        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="marginCode">
<div>.m-0 {</div>
    <div>margin: 0!important;</div>
<div>}</div>
<div>.ms-0 {</div>
    <div>margin-left: 0!important;</div>
<div>}</div>
<div>.me-0 {</div>
    <div>margin-right: 0!important;</div>
<div>}</div>
<div>.mt-0 {</div>
    <div>margin-top: 0!important;</div>
<div>}</div>
<div>.mb-0 {</div>
    <div>margin-bottom: 0!important;</div>
<div>}</div>
<div>.m-1 {</div>
    <div>margin: .25rem!important;</div>
<div>}</div>
<div>.ms-1 {</div>
    <div>margin-left: .25rem!important;</div>
<div>}</div>
<div>.me-1 {</div>
    <div>margin-right: .25rem!important;</div>
<div>}</div>
<div>.mt-1 {</div>
    <div>margin-top: .25rem!important;</div>
<div>}</div>
<div>.mb-1 {</div>
    <div>margin-bottom: .25rem!important;</div>
<div>}</div>
<div>.m-2 {</div>
    <div>margin: .5rem!important;</div>
<div>}</div>
<div>.ms-2 {</div>
    <div>margin-left: .5rem!important;</div>
<div>}</div>
<div>.me-2 {</div>
    <div>margin-right: .5rem!important;</div>
<div>}</div>
<div>.mt-2 {</div>
    <div>margin-top: .5rem!important;</div>
<div>}</div>
<div>.mb-2 {</div>
    <div>margin-bottom: .5rem!important;</div>
<div>}</div>
<div>.m-3 {</div>
    <div>margin: 1rem!important;</div>
<div>}</div>
<div>.ms-3 {</div>
    <div>margin-left: 1rem!important;</div>
<div>}</div>
<div>.me-3 {</div>
    <div>margin-right: 1rem!important;</div>
<div>}</div>
<div>.mt-3 {</div>
    <div>margin-top: 1rem!important;</div>
<div>}</div>
<div>.mb-3 {</div>
    <div>margin-bottom: 1rem!important;</div>
<div>}</div>
<div>.m-4 {</div>
    <div>margin: 1.5rem!important;</div>
<div>}</div>
<div>.ms-4 {</div>
    <div>margin-left: 1.5rem!important;</div>
<div>}</div>
<div>.me-4 {</div>
    <div>margin-right: 1.5rem!important;</div>
<div>}</div>
<div>.mt-4 {</div>
    <div>margin-top: 1.5rem!important;</div>
<div>}</div>
<div>.mb-4 {</div>
    <div>margin-bottom: 1.5rem!important;</div>
<div>}</div>
<div>.m-5 {</div>
    <div>margin: 5px!important;</div>
<div>}</div>
<div>.ms-5 {</div>
    <div>margin-left: 5px!important;</div>
<div>}</div>
<div>.me-5 {</div>
    <div>margin-right: 5px!important;</div>
<div>}</div>
<div>.mt-5 {</div>
    <div>margin-top: 5px!important;</div>
<div>}</div>
<div>.mb-5 {</div>
    <div>margin-bottom: 5px!important;</div>
<div>}</div>
<div>.m-6 {</div>
    <div>margin: 6px!important;</div>
<div>}</div>
<div>.ms-6 {</div>
    <div>margin-left: 6px!important;</div>
<div>}</div>
<div>.me-6 {</div>
    <div>margin-right: 6px!important;</div>
<div>}</div>
<div>.mt-6 {</div>
    <div>margin-top: 6px!important;</div>
<div>}</div>
<div>.mb-6 {</div>
    <div>margin-bottom: 6px!important;</div>
<div>}</div>
<div>.m-7 {</div>
    <div>margin: 7px!important;</div>
<div>}</div>
<div>.ms-7 {</div>
    <div>margin-left: 7px!important;</div>
<div>}</div>
<div>.me-7 {</div>
    <div>margin-right: 7px!important;</div>
<div>}</div>
<div>.mt-7 {</div>
    <div>margin-top: 7px!important;</div>
<div>}</div>
<div>.mb-7 {</div>
    <div>margin-bottom: 7px!important;</div>
<div>}</div>
<div>.m-8 {</div>
    <div>margin: 8px!important;</div>
<div>}</div>
<div>.ms-8 {</div>
    <div>margin-left: 8px!important;</div>
<div>}</div>
<div>.me-8 {</div>
    <div>margin-right: 8px!important;</div>
<div>}</div>
<div>.mt-8 {</div>
    <div>margin-top: 8px!important;</div>
<div>}</div>
<div>.mb-8 {</div>
    <div>margin-bottom: 8px!important;</div>
<div>}</div>
<div>.m-10 {</div>
    <div>margin: 10px!important;</div>
<div>}</div>
<div>.ms-10 {</div>
    <div>margin-left: 10px!important;</div>
<div>}</div>
<div>.me-10 {</div>
    <div>margin-right: 10px!important;</div>
<div>}</div>
<div>.mt-10 {</div>
    <div>margin-top: 10px!important;</div>
<div>}</div>
<div>.mb-10 {</div>
    <div>margin-bottom: 10px!important;</div>
<div>}</div>
<div>.m-11 {</div>
    <div>margin: 11px!important;</div>
<div>}</div>
<div>.ms-11 {</div>
    <div>margin-left: 11px!important;</div>
<div>}</div>
<div>.me-11 {</div>
    <div>margin-right: 11px!important;</div>
<div>}</div>
<div>.mt-11 {</div>
    <div>margin-top: 11px!important;</div>
<div>}</div>
<div>.mb-11 {</div>
    <div>margin-bottom: 11px!important;</div>
<div>}</div>
<div>.m-12 {</div>
    <div>margin: 12px!important;</div>
<div>}</div>
<div>.ms-12 {</div>
    <div>margin-left: 12px!important;</div>
<div>}</div>
<div>.me-12 {</div>
    <div>margin-right: 12px!important;</div>
<div>}</div>
<div>.mt-12 {</div>
    <div>margin-top: 12px!important;</div>
<div>}</div>
<div>.mb-12 {</div>
    <div>margin-bottom: 12px!important;</div>
<div>}</div>
<div>.m-15 {</div>
    <div>margin: 15px!important;</div>
<div>}</div>
<div>.ms-15 {</div>
    <div>margin-left: 15px!important;</div>
<div>}</div>
<div>.me-15 {</div>
    <div>margin-right: 15px!important;</div>
<div>}</div>
<div>.mt-15 {</div>
    <div>margin-top: 15px!important;</div>
<div>}</div>
<div>.mb-15 {</div>
    <div>margin-bottom: 15px!important;</div>
<div>}</div>
<div>.m-20 {</div>
    <div>margin: 20px!important;</div>
<div>}</div>
<div>.ms-20 {</div>
    <div>margin-left: 20px!important;</div>
<div>}</div>
<div>.me-20 {</div>
    <div>margin-right: 20px!important;</div>
<div>}</div>
<div>.mt-20 {</div>
    <div>margin-top: 20px!important;</div>
<div>}</div>
<div>.mb-20 {</div>
    <div>margin-bottom: 20px!important;</div>
<div>}</div>
<div>.m-25 {</div>
    <div>margin: 25px!important;</div>
<div>}</div>
<div>.ms-25 {</div>
    <div>margin-left: 25px!important;</div>
<div>}</div>
<div>.me-25 {</div>
    <div>margin-right: 25px!important;</div>
<div>}</div>
<div>.mt-25 {</div>
    <div>margin-top: 25px!important;</div>
<div>}</div>
<div>.mb-25 {</div>
    <div>margin-bottom: 25px!important;</div>
<div>}</div>
<div>.m-30 {</div>
    <div>margin: 30px!important;</div>
<div>}</div>
<div>.ms-30 {</div>
    <div>margin-left: 30px!important;</div>
<div>}</div>
<div>.me-30 {</div>
    <div>margin-right: 30px!important;</div>
<div>}</div>
<div>.mt-30 {</div>
    <div>margin-top: 30px!important;</div>
<div>}</div>
<div>.mb-30 {</div>
    <div>margin-bottom: 30px!important;</div>
<div>}</div>
<div>.m-35 {</div>
    <div>margin: 35px!important;</div>
<div>}</div>
<div>.ms-35 {</div>
    <div>margin-left: 35px!important;</div>
<div>}</div>
<div>.me-35 {</div>
    <div>margin-right: 35px!important;</div>
<div>}</div>
<div>.mt-35 {</div>
    <div>margin-top: 35px!important;</div>
<div>}</div>
<div>.mb-35 {</div>
    <div>margin-bottom: 35px!important;</div>
<div>}</div>
<div>.m-40 {</div>
    <div>margin: 40px!important;</div>
<div>}</div>
<div>.ms-40 {</div>
    <div>margin-left: 40px!important;</div>
<div>}</div>
<div>.me-40 {</div>
    <div>margin-right: 40px!important;</div>
<div>}</div>
<div>.mt-40 {</div>
    <div>margin-top: 40px!important;</div>
<div>}</div>
<div>.mb-40 {</div>
    <div>margin-bottom: 40px!important;</div>
<div>}</div>
<div>.m-45 {</div>
    <div>margin: 45px!important;</div>
<div>}</div>
<div>.ms-45 {</div>
    <div>margin-left: 45px!important;</div>
<div>}</div>
<div>.me-45 {</div>
    <div>margin-right: 45px!important;</div>
<div>}</div>
<div>.mt-45 {</div>
    <div>margin-top: 45px!important;</div>
<div>}</div>
<div>.mb-45 {</div>
    <div>margin-bottom: 45px!important;</div>
<div>}</div>
<div>.m-50 {</div>
    <div>margin: 50px!important;</div>
<div>}</div>
<div>.ms-50 {</div>
    <div>margin-left: 50px!important;</div>
<div>}</div>
<div>.me-50 {</div>
    <div>margin-right: 50px!important;</div>
<div>}</div>
<div>.mt-50 {</div>
    <div>margin-top: 50px!important;</div>
<div>}</div>
<div>.mb-50 {</div>
    <div>margin-bottom: 50px!important;</div>
<div>}</div>
<div>.ms-100 {</div>
    <div>margin-left: 100px!important;</div>
<div>}</div>
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15">Responsive Margin</h5>
      <div class="position-relative mt-20">
        <!-- <button class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0" data-clipboard-target="#responsiveMarginCode">
                            Copy
                        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="responsiveMarginCode">
<div>@media (min-width: 576px) {</div>

    <div>// Margin</div>
    <div>.m-sm-0 {</div>
        <div>margin: 0!important;</div>
    <div>}</div>
    <div>.ms-sm-0 {</div>
        <div>margin-left: 0!important;</div>
    <div>}</div>
    <div>.me-sm-0 {</div>
        <div>margin-right: 0!important;</div>
    <div>}</div>
    <div>.mt-sm-0 {</div>
        <div>margin-top: 0!important;</div>
    <div>}</div>
    <div>.mb-sm-0 {</div>
        <div>margin-bottom: 0!important;</div>
    <div>}</div>
    <div>.m-sm-1 {</div>
        <div>margin: .25rem!important;</div>
    <div>}</div>
    <div>.ms-sm-1 {</div>
        <div>margin-left: .25rem!important;</div>
    <div>}</div>
    <div>.me-sm-1 {</div>
        <div>margin-right: .25rem!important;</div>
    <div>}</div>
    <div>.mt-sm-1 {</div>
        <div>margin-top: .25rem!important;</div>
    <div>}</div>
    <div>.mb-sm-1 {</div>
        <div>margin-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.m-sm-2 {</div>
        <div>margin: .5rem!important;</div>
    <div>}</div>
    <div>.ms-sm-2 {</div>
        <div>margin-left: .5rem!important;</div>
    <div>}</div>
    <div>.me-sm-2 {</div>
        <div>margin-right: .5rem!important;</div>
    <div>}</div>
    <div>.mt-sm-2 {</div>
        <div>margin-top: .5rem!important;</div>
    <div>}</div>
    <div>.mb-sm-2 {</div>
        <div>margin-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.m-sm-3 {</div>
        <div>margin: 1rem!important;</div>
    <div>}</div>
    <div>.ms-sm-3 {</div>
        <div>margin-left: 1rem!important;</div>
    <div>}</div>
    <div>.me-sm-3 {</div>
        <div>margin-right: 1rem!important;</div>
    <div>}</div>
    <div>.mt-sm-3 {</div>
        <div>margin-top: 1rem!important;</div>
    <div>}</div>
    <div>.mb-sm-3 {</div>
        <div>margin-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.m-sm-4 {</div>
        <div>margin: 1.5rem!important;</div>
    <div>}</div>
    <div>.ms-sm-4 {</div>
        <div>margin-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.me-sm-4 {</div>
        <div>margin-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.mt-sm-4 {</div>
        <div>margin-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.mb-sm-4 {</div>
        <div>margin-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.m-sm-5 {</div>
        <div>margin: 5px!important;</div>
    <div>}</div>
    <div>.ms-sm-5 {</div>
        <div>margin-left: 5px!important;</div>
    <div>}</div>
    <div>.me-sm-5 {</div>
        <div>margin-right: 5px!important;</div>
    <div>}</div>
    <div>.mt-sm-5 {</div>
        <div>margin-top: 5px!important;</div>
    <div>}</div>
    <div>.mb-sm-5 {</div>
        <div>margin-bottom: 5px!important;</div>
    <div>}</div>
    <div>.m-sm-8 {</div>
        <div>margin: 8px!important;</div>
    <div>}</div>
    <div>.ms-sm-8 {</div>
        <div>margin-left: 8px!important;</div>
    <div>}</div>
    <div>.me-sm-8 {</div>
        <div>margin-right: 8px!important;</div>
    <div>}</div>
    <div>.mt-sm-8 {</div>
        <div>margin-top: 8px!important;</div>
    <div>}</div>
    <div>.mb-sm-8 {</div>
        <div>margin-bottom: 8px!important;</div>
    <div>}</div>
    <div>.m-sm-10 {</div>
        <div>margin: 10px!important;</div>
    <div>}</div>
    <div>.ms-sm-10 {</div>
        <div>margin-left: 10px!important;</div>
    <div>}</div>
    <div>.me-sm-10 {</div>
        <div>margin-right: 10px!important;</div>
    <div>}</div>
    <div>.mt-sm-10 {</div>
        <div>margin-top: 10px!important;</div>
    <div>}</div>
    <div>.mb-sm-10 {</div>
        <div>margin-bottom: 10px!important;</div>
    <div>}</div>
    <div>.m-sm-11 {</div>
        <div>margin: 11px!important;</div>
    <div>}</div>
    <div>.ms-sm-11 {</div>
        <div>margin-left: 11px!important;</div>
    <div>}</div>
    <div>.me-sm-11 {</div>
        <div>margin-right: 11px!important;</div>
    <div>}</div>
    <div>.mt-sm-11 {</div>
        <div>margin-top: 11px!important;</div>
    <div>}</div>
    <div>.mb-sm-11 {</div>
        <div>margin-bottom: 11px!important;</div>
    <div>}</div>
    <div>.m-sm-12 {</div>
        <div>margin: 12px!important;</div>
    <div>}</div>
    <div>.ms-sm-12 {</div>
        <div>margin-left: 12px!important;</div>
    <div>}</div>
    <div>.me-sm-12 {</div>
        <div>margin-right: 12px!important;</div>
    <div>}</div>
    <div>.mt-sm-12 {</div>
        <div>margin-top: 12px!important;</div>
    <div>}</div>
    <div>.mb-sm-12 {</div>
        <div>margin-bottom: 12px!important;</div>
    <div>}</div>
    <div>.m-sm-15 {</div>
        <div>margin: 15px!important;</div>
    <div>}</div>
    <div>.ms-sm-15 {</div>
        <div>margin-left: 15px!important;</div>
    <div>}</div>
    <div>.me-sm-15 {</div>
        <div>margin-right: 15px!important;</div>
    <div>}</div>
    <div>.mt-sm-15 {</div>
        <div>margin-top: 15px!important;</div>
    <div>}</div>
    <div>.mb-sm-15 {</div>
        <div>margin-bottom: 15px!important;</div>
    <div>}</div>
    <div>.m-sm-20 {</div>
        <div>margin: 20px!important;</div>
    <div>}</div>
    <div>.ms-sm-20 {</div>
        <div>margin-left: 20px!important;</div>
    <div>}</div>
    <div>.me-sm-20 {</div>
        <div>margin-right: 20px!important;</div>
    <div>}</div>
    <div>.mt-sm-20 {</div>
        <div>margin-top: 20px!important;</div>
    <div>}</div>
    <div>.mb-sm-20 {</div>
        <div>margin-bottom: 20px!important;</div>
    <div>}</div>
    <div>.m-sm-25 {</div>
        <div>margin: 25px!important;</div>
    <div>}</div>
    <div>.ms-sm-25 {</div>
        <div>margin-left: 25px!important;</div>
    <div>}</div>
    <div>.me-sm-25 {</div>
        <div>margin-right: 25px!important;</div>
    <div>}</div>
    <div>.mt-sm-25 {</div>
        <div>margin-top: 25px!important;</div>
    <div>}</div>
    <div>.mb-sm-25 {</div>
        <div>margin-bottom: 25px!important;</div>
    <div>}</div>
    <div>.m-sm-30 {</div>
        <div>margin: 30px!important;</div>
    <div>}</div>
    <div>.ms-sm-30 {</div>
        <div>margin-left: 30px!important;</div>
    <div>}</div>
    <div>.me-sm-30 {</div>
        <div>margin-right: 30px!important;</div>
    <div>}</div>
    <div>.mt-sm-30 {</div>
        <div>margin-top: 30px!important;</div>
    <div>}</div>
    <div>.mb-sm-30 {</div>
        <div>margin-bottom: 30px!important;</div>
    <div>}</div>
    <div>.m-sm-35 {</div>
        <div>margin: 35px!important;</div>
    <div>}</div>
    <div>.ms-sm-35 {</div>
        <div>margin-left: 35px!important;</div>
    <div>}</div>
    <div>.me-sm-35 {</div>
        <div>margin-right: 35px!important;</div>
    <div>}</div>
    <div>.mt-sm-35 {</div>
        <div>margin-top: 35px!important;</div>
    <div>}</div>
    <div>.mb-sm-35 {</div>
        <div>margin-bottom: 35px!important;</div>
    <div>}</div>
    <div>.m-sm-40 {</div>
        <div>margin: 40px!important;</div>
    <div>}</div>
    <div>.ms-sm-40 {</div>
        <div>margin-left: 40px!important;</div>
    <div>}</div>
    <div>.me-sm-40 {</div>
        <div>margin-right: 40px!important;</div>
    <div>}</div>
    <div>.mt-sm-40 {</div>
        <div>margin-top: 40px!important;</div>
    <div>}</div>
    <div>.mb-sm-40 {</div>
        <div>margin-bottom: 40px!important;</div>
    <div>}</div>
    <div>.m-sm-45 {</div>
        <div>margin: 45px!important;</div>
    <div>}</div>
    <div>.ms-sm-45 {</div>
        <div>margin-left: 45px!important;</div>
    <div>}</div>
    <div>.me-sm-45 {</div>
        <div>margin-right: 45px!important;</div>
    <div>}</div>
    <div>.mt-sm-45 {</div>
        <div>margin-top: 45px!important;</div>
    <div>}</div>
    <div>.mb-sm-45 {</div>
        <div>margin-bottom: 45px!important;</div>
    <div>}</div>
    <div>.m-sm-50 {</div>
        <div>margin: 50px!important;</div>
    <div>}</div>
    <div>.ms-sm-50 {</div>
        <div>margin-left: 50px!important;</div>
    <div>}</div>
    <div>.me-sm-50 {</div>
        <div>margin-right: 50px!important;</div>
    <div>}</div>
    <div>.mt-sm-50 {</div>
        <div>margin-top: 50px!important;</div>
    <div>}</div>
    <div>.mb-sm-50 {</div>
        <div>margin-bottom: 50px!important;</div>
    <div>}</div>
    <div>.ms-sm-100 {</div>
        <div>margin-left: 100px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 768px) {</div>

    <div>// Margin</div>
    <div>.m-md-0 {</div>
        <div>margin: 0!important;</div>
    <div>}</div>
    <div>.ms-md-0 {</div>
        <div>margin-left: 0!important;</div>
    <div>}</div>
    <div>.me-md-0 {</div>
        <div>margin-right: 0!important;</div>
    <div>}</div>
    <div>.mt-md-0 {</div>
        <div>margin-top: 0!important;</div>
    <div>}</div>
    <div>.mb-md-0 {</div>
        <div>margin-bottom: 0!important;</div>
    <div>}</div>
    <div>.m-md-1 {</div>
        <div>margin: .25rem!important;</div>
    <div>}</div>
    <div>.ms-md-1 {</div>
        <div>margin-left: .25rem!important;</div>
    <div>}</div>
    <div>.me-md-1 {</div>
        <div>margin-right: .25rem!important;</div>
    <div>}</div>
    <div>.mt-md-1 {</div>
        <div>margin-top: .25rem!important;</div>
    <div>}</div>
    <div>.mb-md-1 {</div>
        <div>margin-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.m-md-2 {</div>
        <div>margin: .5rem!important;</div>
    <div>}</div>
    <div>.ms-md-2 {</div>
        <div>margin-left: .5rem!important;</div>
    <div>}</div>
    <div>.me-md-2 {</div>
        <div>margin-right: .5rem!important;</div>
    <div>}</div>
    <div>.mt-md-2 {</div>
        <div>margin-top: .5rem!important;</div>
    <div>}</div>
    <div>.mb-md-2 {</div>
        <div>margin-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.m-md-3 {</div>
        <div>margin: 1rem!important;</div>
    <div>}</div>
    <div>.ms-md-3 {</div>
        <div>margin-left: 1rem!important;</div>
    <div>}</div>
    <div>.me-md-3 {</div>
        <div>margin-right: 1rem!important;</div>
    <div>}</div>
    <div>.mt-md-3 {</div>
        <div>margin-top: 1rem!important;</div>
    <div>}</div>
    <div>.mb-md-3 {</div>
        <div>margin-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.m-md-4 {</div>
        <div>margin: 1.5rem!important;</div>
    <div>}</div>
    <div>.ms-md-4 {</div>
        <div>margin-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.me-md-4 {</div>
        <div>margin-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.mt-md-4 {</div>
        <div>margin-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.mb-md-4 {</div>
        <div>margin-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.m-md-5 {</div>
        <div>margin: 5px!important;</div>
    <div>}</div>
    <div>.ms-md-5 {</div>
        <div>margin-left: 5px!important;</div>
    <div>}</div>
    <div>.me-md-5 {</div>
        <div>margin-right: 5px!important;</div>
    <div>}</div>
    <div>.mt-md-5 {</div>
        <div>margin-top: 5px!important;</div>
    <div>}</div>
    <div>.mb-md-5 {</div>
        <div>margin-bottom: 5px!important;</div>
    <div>}</div>
    <div>.m-md-8 {</div>
        <div>margin: 8px!important;</div>
    <div>}</div>
    <div>.ms-md-8 {</div>
        <div>margin-left: 8px!important;</div>
    <div>}</div>
    <div>.me-md-8 {</div>
        <div>margin-right: 8px!important;</div>
    <div>}</div>
    <div>.mt-md-8 {</div>
        <div>margin-top: 8px!important;</div>
    <div>}</div>
    <div>.mb-md-8 {</div>
        <div>margin-bottom: 8px!important;</div>
    <div>}</div>
    <div>.m-md-10 {</div>
        <div>margin: 10px!important;</div>
    <div>}</div>
    <div>.ms-md-10 {</div>
        <div>margin-left: 10px!important;</div>
    <div>}</div>
    <div>.me-md-10 {</div>
        <div>margin-right: 10px!important;</div>
    <div>}</div>
    <div>.mt-md-10 {</div>
        <div>margin-top: 10px!important;</div>
    <div>}</div>
    <div>.mb-md-10 {</div>
        <div>margin-bottom: 10px!important;</div>
    <div>}</div>
    <div>.m-md-11 {</div>
        <div>margin: 11px!important;</div>
    <div>}</div>
    <div>.ms-md-11 {</div>
        <div>margin-left: 11px!important;</div>
    <div>}</div>
    <div>.me-md-11 {</div>
        <div>margin-right: 11px!important;</div>
    <div>}</div>
    <div>.mt-md-11 {</div>
        <div>margin-top: 11px!important;</div>
    <div>}</div>
    <div>.mb-md-11 {</div>
        <div>margin-bottom: 11px!important;</div>
    <div>}</div>
    <div>.m-md-12 {</div>
        <div>margin: 12px!important;</div>
    <div>}</div>
    <div>.ms-md-12 {</div>
        <div>margin-left: 12px!important;</div>
    <div>}</div>
    <div>.me-md-12 {</div>
        <div>margin-right: 12px!important;</div>
    <div>}</div>
    <div>.mt-md-12 {</div>
        <div>margin-top: 12px!important;</div>
    <div>}</div>
    <div>.mb-md-12 {</div>
        <div>margin-bottom: 12px!important;</div>
    <div>}</div>
    <div>.m-md-15 {</div>
        <div>margin: 15px!important;</div>
    <div>}</div>
    <div>.ms-md-15 {</div>
        <div>margin-left: 15px!important;</div>
    <div>}</div>
    <div>.me-md-15 {</div>
        <div>margin-right: 15px!important;</div>
    <div>}</div>
    <div>.mt-md-15 {</div>
        <div>margin-top: 15px!important;</div>
    <div>}</div>
    <div>.mb-md-15 {</div>
        <div>margin-bottom: 15px!important;</div>
    <div>}</div>
    <div>.m-md-20 {</div>
        <div>margin: 20px!important;</div>
    <div>}</div>
    <div>.ms-md-20 {</div>
        <div>margin-left: 20px!important;</div>
    <div>}</div>
    <div>.me-md-20 {</div>
        <div>margin-right: 20px!important;</div>
    <div>}</div>
    <div>.mt-md-20 {</div>
        <div>margin-top: 20px!important;</div>
    <div>}</div>
    <div>.mb-md-20 {</div>
        <div>margin-bottom: 20px!important;</div>
    <div>}</div>
    <div>.m-md-25 {</div>
        <div>margin: 25px!important;</div>
    <div>}</div>
    <div>.ms-md-25 {</div>
        <div>margin-left: 25px!important;</div>
    <div>}</div>
    <div>.me-md-25 {</div>
        <div>margin-right: 25px!important;</div>
    <div>}</div>
    <div>.mt-md-25 {</div>
        <div>margin-top: 25px!important;</div>
    <div>}</div>
    <div>.mb-md-25 {</div>
        <div>margin-bottom: 25px!important;</div>
    <div>}</div>
    <div>.m-md-30 {</div>
        <div>margin: 30px!important;</div>
    <div>}</div>
    <div>.ms-md-30 {</div>
        <div>margin-left: 30px!important;</div>
    <div>}</div>
    <div>.me-md-30 {</div>
        <div>margin-right: 30px!important;</div>
    <div>}</div>
    <div>.mt-md-30 {</div>
        <div>margin-top: 30px!important;</div>
    <div>}</div>
    <div>.mb-md-30 {</div>
        <div>margin-bottom: 30px!important;</div>
    <div>}</div>
    <div>.m-md-35 {</div>
        <div>margin: 35px!important;</div>
    <div>}</div>
    <div>.ms-md-35 {</div>
        <div>margin-left: 35px!important;</div>
    <div>}</div>
    <div>.me-md-35 {</div>
        <div>margin-right: 35px!important;</div>
    <div>}</div>
    <div>.mt-md-35 {</div>
        <div>margin-top: 35px!important;</div>
    <div>}</div>
    <div>.mb-md-35 {</div>
        <div>margin-bottom: 35px!important;</div>
    <div>}</div>
    <div>.m-md-40 {</div>
        <div>margin: 40px!important;</div>
    <div>}</div>
    <div>.ms-md-40 {</div>
        <div>margin-left: 40px!important;</div>
    <div>}</div>
    <div>.me-md-40 {</div>
        <div>margin-right: 40px!important;</div>
    <div>}</div>
    <div>.mt-md-40 {</div>
        <div>margin-top: 40px!important;</div>
    <div>}</div>
    <div>.mb-md-40 {</div>
        <div>margin-bottom: 40px!important;</div>
    <div>}</div>
    <div>.m-md-45 {</div>
        <div>margin: 45px!important;</div>
    <div>}</div>
    <div>.ms-md-45 {</div>
        <div>margin-left: 45px!important;</div>
    <div>}</div>
    <div>.me-md-45 {</div>
        <div>margin-right: 45px!important;</div>
    <div>}</div>
    <div>.mt-md-45 {</div>
        <div>margin-top: 45px!important;</div>
    <div>}</div>
    <div>.mb-md-45 {</div>
        <div>margin-bottom: 45px!important;</div>
    <div>}</div>
    <div>.m-md-50 {</div>
        <div>margin: 50px!important;</div>
    <div>}</div>
    <div>.ms-md-50 {</div>
        <div>margin-left: 50px!important;</div>
    <div>}</div>
    <div>.me-md-50 {</div>
        <div>margin-right: 50px!important;</div>
    <div>}</div>
    <div>.mt-md-50 {</div>
        <div>margin-top: 50px!important;</div>
    <div>}</div>
    <div>.mb-md-50 {</div>
        <div>margin-bottom: 50px!important;</div>
    <div>}</div>
    <div>.ms-md-100 {</div>
        <div>margin-left: 100px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 992px) {</div>

    <div>// Margin</div>
    <div>.m-lg-0 {</div>
        <div>margin: 0!important;</div>
    <div>}</div>
    <div>.ms-lg-0 {</div>
        <div>margin-left: 0!important;</div>
    <div>}</div>
    <div>.me-lg-0 {</div>
        <div>margin-right: 0!important;</div>
    <div>}</div>
    <div>.mt-lg-0 {</div>
        <div>margin-top: 0!important;</div>
    <div>}</div>
    <div>.mb-lg-0 {</div>
        <div>margin-bottom: 0!important;</div>
    <div>}</div>
    <div>.m-lg-1 {</div>
        <div>margin: .25rem!important;</div>
    <div>}</div>
    <div>.ms-lg-1 {</div>
        <div>margin-left: .25rem!important;</div>
    <div>}</div>
    <div>.me-lg-1 {</div>
        <div>margin-right: .25rem!important;</div>
    <div>}</div>
    <div>.mt-lg-1 {</div>
        <div>margin-top: .25rem!important;</div>
    <div>}</div>
    <div>.mb-lg-1 {</div>
        <div>margin-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.m-lg-2 {</div>
        <div>margin: .5rem!important;</div>
    <div>}</div>
    <div>.ms-lg-2 {</div>
        <div>margin-left: .5rem!important;</div>
    <div>}</div>
    <div>.me-lg-2 {</div>
        <div>margin-right: .5rem!important;</div>
    <div>}</div>
    <div>.mt-lg-2 {</div>
        <div>margin-top: .5rem!important;</div>
    <div>}</div>
    <div>.mb-lg-2 {</div>
        <div>margin-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.m-lg-3 {</div>
        <div>margin: 1rem!important;</div>
    <div>}</div>
    <div>.ms-lg-3 {</div>
        <div>margin-left: 1rem!important;</div>
    <div>}</div>
    <div>.me-lg-3 {</div>
        <div>margin-right: 1rem!important;</div>
    <div>}</div>
    <div>.mt-lg-3 {</div>
        <div>margin-top: 1rem!important;</div>
    <div>}</div>
    <div>.mb-lg-3 {</div>
        <div>margin-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.m-lg-4 {</div>
        <div>margin: 1.5rem!important;</div>
    <div>}</div>
    <div>.ms-lg-4 {</div>
        <div>margin-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.me-lg-4 {</div>
        <div>margin-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.mt-lg-4 {</div>
        <div>margin-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.mb-lg-4 {</div>
        <div>margin-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.m-lg-5 {</div>
        <div>margin: 5px!important;</div>
    <div>}</div>
    <div>.ms-lg-5 {</div>
        <div>margin-left: 5px!important;</div>
    <div>}</div>
    <div>.me-lg-5 {</div>
        <div>margin-right: 5px!important;</div>
    <div>}</div>
    <div>.mt-lg-5 {</div>
        <div>margin-top: 5px!important;</div>
    <div>}</div>
    <div>.mb-lg-5 {</div>
        <div>margin-bottom: 5px!important;</div>
    <div>}</div>
    <div>.m-lg-8 {</div>
        <div>margin: 8px!important;</div>
    <div>}</div>
    <div>.ms-lg-8 {</div>
        <div>margin-left: 8px!important;</div>
    <div>}</div>
    <div>.me-lg-8 {</div>
        <div>margin-right: 8px!important;</div>
    <div>}</div>
    <div>.mt-lg-8 {</div>
        <div>margin-top: 8px!important;</div>
    <div>}</div>
    <div>.mb-lg-8 {</div>
        <div>margin-bottom: 8px!important;</div>
    <div>}</div>
    <div>.m-lg-10 {</div>
        <div>margin: 10px!important;</div>
    <div>}</div>
    <div>.ms-lg-10 {</div>
        <div>margin-left: 10px!important;</div>
    <div>}</div>
    <div>.me-lg-10 {</div>
        <div>margin-right: 10px!important;</div>
    <div>}</div>
    <div>.mt-lg-10 {</div>
        <div>margin-top: 10px!important;</div>
    <div>}</div>
    <div>.mb-lg-10 {</div>
        <div>margin-bottom: 10px!important;</div>
    <div>}</div>
    <div>.m-lg-11 {</div>
        <div>margin: 11px!important;</div>
    <div>}</div>
    <div>.ms-lg-11 {</div>
        <div>margin-left: 11px!important;</div>
    <div>}</div>
    <div>.me-lg-11 {</div>
        <div>margin-right: 11px!important;</div>
    <div>}</div>
    <div>.mt-lg-11 {</div>
        <div>margin-top: 11px!important;</div>
    <div>}</div>
    <div>.mb-lg-11 {</div>
        <div>margin-bottom: 11px!important;</div>
    <div>}</div>
    <div>.m-lg-12 {</div>
        <div>margin: 12px!important;</div>
    <div>}</div>
    <div>.ms-lg-12 {</div>
        <div>margin-left: 12px!important;</div>
    <div>}</div>
    <div>.me-lg-12 {</div>
        <div>margin-right: 12px!important;</div>
    <div>}</div>
    <div>.mt-lg-12 {</div>
        <div>margin-top: 12px!important;</div>
    <div>}</div>
    <div>.mb-lg-12 {</div>
        <div>margin-bottom: 12px!important;</div>
    <div>}</div>
    <div>.m-lg-15 {</div>
        <div>margin: 15px!important;</div>
    <div>}</div>
    <div>.ms-lg-15 {</div>
        <div>margin-left: 15px!important;</div>
    <div>}</div>
    <div>.me-lg-15 {</div>
        <div>margin-right: 15px!important;</div>
    <div>}</div>
    <div>.mt-lg-15 {</div>
        <div>margin-top: 15px!important;</div>
    <div>}</div>
    <div>.mb-lg-15 {</div>
        <div>margin-bottom: 15px!important;</div>
    <div>}</div>
    <div>.m-lg-20 {</div>
        <div>margin: 20px!important;</div>
    <div>}</div>
    <div>.ms-lg-20 {</div>
        <div>margin-left: 20px!important;</div>
    <div>}</div>
    <div>.me-lg-20 {</div>
        <div>margin-right: 20px!important;</div>
    <div>}</div>
    <div>.mt-lg-20 {</div>
        <div>margin-top: 20px!important;</div>
    <div>}</div>
    <div>.mb-lg-20 {</div>
        <div>margin-bottom: 20px!important;</div>
    <div>}</div>
    <div>.m-lg-25 {</div>
        <div>margin: 25px!important;</div>
    <div>}</div>
    <div>.ms-lg-25 {</div>
        <div>margin-left: 25px!important;</div>
    <div>}</div>
    <div>.me-lg-25 {</div>
        <div>margin-right: 25px!important;</div>
    <div>}</div>
    <div>.mt-lg-25 {</div>
        <div>margin-top: 25px!important;</div>
    <div>}</div>
    <div>.mb-lg-25 {</div>
        <div>margin-bottom: 25px!important;</div>
    <div>}</div>
    <div>.m-lg-30 {</div>
        <div>margin: 30px!important;</div>
    <div>}</div>
    <div>.ms-lg-30 {</div>
        <div>margin-left: 30px!important;</div>
    <div>}</div>
    <div>.me-lg-30 {</div>
        <div>margin-right: 30px!important;</div>
    <div>}</div>
    <div>.mt-lg-30 {</div>
        <div>margin-top: 30px!important;</div>
    <div>}</div>
    <div>.mb-lg-30 {</div>
        <div>margin-bottom: 30px!important;</div>
    <div>}</div>
    <div>.m-lg-35 {</div>
        <div>margin: 35px!important;</div>
    <div>}</div>
    <div>.ms-lg-35 {</div>
        <div>margin-left: 35px!important;</div>
    <div>}</div>
    <div>.me-lg-35 {</div>
        <div>margin-right: 35px!important;</div>
    <div>}</div>
    <div>.mt-lg-35 {</div>
        <div>margin-top: 35px!important;</div>
    <div>}</div>
    <div>.mb-lg-35 {</div>
        <div>margin-bottom: 35px!important;</div>
    <div>}</div>
    <div>.m-lg-40 {</div>
        <div>margin: 40px!important;</div>
    <div>}</div>
    <div>.ms-lg-40 {</div>
        <div>margin-left: 40px!important;</div>
    <div>}</div>
    <div>.me-lg-40 {</div>
        <div>margin-right: 40px!important;</div>
    <div>}</div>
    <div>.mt-lg-40 {</div>
        <div>margin-top: 40px!important;</div>
    <div>}</div>
    <div>.mb-lg-40 {</div>
        <div>margin-bottom: 40px!important;</div>
    <div>}</div>
    <div>.m-lg-45 {</div>
        <div>margin: 45px!important;</div>
    <div>}</div>
    <div>.ms-lg-45 {</div>
        <div>margin-left: 45px!important;</div>
    <div>}</div>
    <div>.me-lg-45 {</div>
        <div>margin-right: 45px!important;</div>
    <div>}</div>
    <div>.mt-lg-45 {</div>
        <div>margin-top: 45px!important;</div>
    <div>}</div>
    <div>.mb-lg-45 {</div>
        <div>margin-bottom: 45px!important;</div>
    <div>}</div>
    <div>.m-lg-50 {</div>
        <div>margin: 50px!important;</div>
    <div>}</div>
    <div>.ms-lg-50 {</div>
        <div>margin-left: 50px!important;</div>
    <div>}</div>
    <div>.me-lg-50 {</div>
        <div>margin-right: 50px!important;</div>
    <div>}</div>
    <div>.mt-lg-50 {</div>
        <div>margin-top: 50px!important;</div>
    <div>}</div>
    <div>.mb-lg-50 {</div>
        <div>margin-bottom: 50px!important;</div>
    <div>}</div>
    <div>.ms-lg-100 {</div>
        <div>margin-left: 100px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 1200px) {</div>

    <div>// Margin</div>
    <div>.m-xl-0 {</div>
        <div>margin: 0!important;</div>
    <div>}</div>
    <div>.ms-xl-0 {</div>
        <div>margin-left: 0!important;</div>
    <div>}</div>
    <div>.me-xl-0 {</div>
        <div>margin-right: 0!important;</div>
    <div>}</div>
    <div>.mt-xl-0 {</div>
        <div>margin-top: 0!important;</div>
    <div>}</div>
    <div>.mb-xl-0 {</div>
        <div>margin-bottom: 0!important;</div>
    <div>}</div>
    <div>.m-xl-1 {</div>
        <div>margin: .25rem!important;</div>
    <div>}</div>
    <div>.ms-xl-1 {</div>
        <div>margin-left: .25rem!important;</div>
    <div>}</div>
    <div>.me-xl-1 {</div>
        <div>margin-right: .25rem!important;</div>
    <div>}</div>
    <div>.mt-xl-1 {</div>
        <div>margin-top: .25rem!important;</div>
    <div>}</div>
    <div>.mb-xl-1 {</div>
        <div>margin-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.m-xl-2 {</div>
        <div>margin: .5rem!important;</div>
    <div>}</div>
    <div>.ms-xl-2 {</div>
        <div>margin-left: .5rem!important;</div>
    <div>}</div>
    <div>.me-xl-2 {</div>
        <div>margin-right: .5rem!important;</div>
    <div>}</div>
    <div>.mt-xl-2 {</div>
        <div>margin-top: .5rem!important;</div>
    <div>}</div>
    <div>.mb-xl-2 {</div>
        <div>margin-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.m-xl-3 {</div>
        <div>margin: 1rem!important;</div>
    <div>}</div>
    <div>.ms-xl-3 {</div>
        <div>margin-left: 1rem!important;</div>
    <div>}</div>
    <div>.me-xl-3 {</div>
        <div>margin-right: 1rem!important;</div>
    <div>}</div>
    <div>.mt-xl-3 {</div>
        <div>margin-top: 1rem!important;</div>
    <div>}</div>
    <div>.mb-xl-3 {</div>
        <div>margin-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.m-xl-4 {</div>
        <div>margin: 1.5rem!important;</div>
    <div>}</div>
    <div>.ms-xl-4 {</div>
        <div>margin-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.me-xl-4 {</div>
        <div>margin-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.mt-xl-4 {</div>
        <div>margin-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.mb-xl-4 {</div>
        <div>margin-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.m-xl-5 {</div>
        <div>margin: 5px!important;</div>
    <div>}</div>
    <div>.ms-xl-5 {</div>
        <div>margin-left: 5px!important;</div>
    <div>}</div>
    <div>.me-xl-5 {</div>
        <div>margin-right: 5px!important;</div>
    <div>}</div>
    <div>.mt-xl-5 {</div>
        <div>margin-top: 5px!important;</div>
    <div>}</div>
    <div>.mb-xl-5 {</div>
        <div>margin-bottom: 5px!important;</div>
    <div>}</div>
    <div>.m-xl-8 {</div>
        <div>margin: 8px!important;</div>
    <div>}</div>
    <div>.ms-xl-8 {</div>
        <div>margin-left: 8px!important;</div>
    <div>}</div>
    <div>.me-xl-8 {</div>
        <div>margin-right: 8px!important;</div>
    <div>}</div>
    <div>.mt-xl-8 {</div>
        <div>margin-top: 8px!important;</div>
    <div>}</div>
    <div>.mb-xl-8 {</div>
        <div>margin-bottom: 8px!important;</div>
    <div>}</div>
    <div>.m-xl-10 {</div>
        <div>margin: 10px!important;</div>
    <div>}</div>
    <div>.ms-xl-10 {</div>
        <div>margin-left: 10px!important;</div>
    <div>}</div>
    <div>.me-xl-10 {</div>
        <div>margin-right: 10px!important;</div>
    <div>}</div>
    <div>.mt-xl-10 {</div>
        <div>margin-top: 10px!important;</div>
    <div>}</div>
    <div>.mb-xl-10 {</div>
        <div>margin-bottom: 10px!important;</div>
    <div>}</div>
    <div>.m-xl-11 {</div>
        <div>margin: 11px!important;</div>
    <div>}</div>
    <div>.ms-xl-11 {</div>
        <div>margin-left: 11px!important;</div>
    <div>}</div>
    <div>.me-xl-11 {</div>
        <div>margin-right: 11px!important;</div>
    <div>}</div>
    <div>.mt-xl-11 {</div>
        <div>margin-top: 11px!important;</div>
    <div>}</div>
    <div>.mb-xl-10 {</div>
        <div>margin-bottom: 10px!important;</div>
    <div>}</div>
    <div>.m-xl-12 {</div>
        <div>margin: 12px!important;</div>
    <div>}</div>
    <div>.ms-xl-12 {</div>
        <div>margin-left: 12px!important;</div>
    <div>}</div>
    <div>.me-xl-12 {</div>
        <div>margin-right: 12px!important;</div>
    <div>}</div>
    <div>.mt-xl-12 {</div>
        <div>margin-top: 12px!important;</div>
    <div>}</div>
    <div>.mb-xl-12 {</div>
        <div>margin-bottom: 12px!important;</div>
    <div>}</div>
    <div>.m-xl-15 {</div>
        <div>margin: 15px!important;</div>
    <div>}</div>
    <div>.ms-xl-15 {</div>
        <div>margin-left: 15px!important;</div>
    <div>}</div>
    <div>.me-xl-15 {</div>
        <div>margin-right: 15px!important;</div>
    <div>}</div>
    <div>.mt-xl-15 {</div>
        <div>margin-top: 15px!important;</div>
    <div>}</div>
    <div>.mb-xl-15 {</div>
        <div>margin-bottom: 15px!important;</div>
    <div>}</div>
    <div>.m-xl-20 {</div>
        <div>margin: 20px!important;</div>
    <div>}</div>
    <div>.ms-xl-20 {</div>
        <div>margin-left: 20px!important;</div>
    <div>}</div>
    <div>.me-xl-20 {</div>
        <div>margin-right: 20px!important;</div>
    <div>}</div>
    <div>.mt-xl-20 {</div>
        <div>margin-top: 20px!important;</div>
    <div>}</div>
    <div>.mb-xl-20 {</div>
        <div>margin-bottom: 20px!important;</div>
    <div>}</div>
    <div>.m-xl-25 {</div>
        <div>margin: 25px!important;</div>
    <div>}</div>
    <div>.ms-xl-25 {</div>
        <div>margin-left: 25px!important;</div>
    <div>}</div>
    <div>.me-xl-25 {</div>
        <div>margin-right: 25px!important;</div>
    <div>}</div>
    <div>.mt-xl-25 {</div>
        <div>margin-top: 25px!important;</div>
    <div>}</div>
    <div>.mb-xl-25 {</div>
        <div>margin-bottom: 25px!important;</div>
    <div>}</div>
    <div>.m-xl-30 {</div>
        <div>margin: 30px!important;</div>
    <div>}</div>
    <div>.ms-xl-30 {</div>
        <div>margin-left: 30px!important;</div>
    <div>}</div>
    <div>.me-xl-30 {</div>
        <div>margin-right: 30px!important;</div>
    <div>}</div>
    <div>.mt-xl-30 {</div>
        <div>margin-top: 30px!important;</div>
    <div>}</div>
    <div>.mb-xl-30 {</div>
        <div>margin-bottom: 30px!important;</div>
    <div>}</div>
    <div>.m-xl-35 {</div>
        <div>margin: 35px!important;</div>
    <div>}</div>
    <div>.ms-xl-35 {</div>
        <div>margin-left: 35px!important;</div>
    <div>}</div>
    <div>.me-xl-35 {</div>
        <div>margin-right: 35px!important;</div>
    <div>}</div>
    <div>.mt-xl-35 {</div>
        <div>margin-top: 35px!important;</div>
    <div>}</div>
    <div>.mb-xl-35 {</div>
        <div>margin-bottom: 35px!important;</div>
    <div>}</div>
    <div>.m-xl-40 {</div>
        <div>margin: 40px!important;</div>
    <div>}</div>
    <div>.ms-xl-40 {</div>
        <div>margin-left: 40px!important;</div>
    <div>}</div>
    <div>.me-xl-40 {</div>
        <div>margin-right: 40px!important;</div>
    <div>}</div>
    <div>.mt-xl-40 {</div>
        <div>margin-top: 40px!important;</div>
    <div>}</div>
    <div>.mb-xl-40 {</div>
        <div>margin-bottom: 40px!important;</div>
    <div>}</div>
    <div>.m-xl-45 {</div>
        <div>margin: 45px!important;</div>
    <div>}</div>
    <div>.ms-xl-45 {</div>
        <div>margin-left: 45px!important;</div>
    <div>}</div>
    <div>.me-xl-45 {</div>
        <div>margin-right: 45px!important;</div>
    <div>}</div>
    <div>.mt-xl-45 {</div>
        <div>margin-top: 45px!important;</div>
    <div>}</div>
    <div>.mb-xl-45 {</div>
        <div>margin-bottom: 45px!important;</div>
    <div>}</div>
    <div>.m-xl-50 {</div>
        <div>margin: 50px!important;</div>
    <div>}</div>
    <div>.ms-xl-50 {</div>
        <div>margin-left: 50px!important;</div>
    <div>}</div>
    <div>.me-xl-50 {</div>
        <div>margin-right: 50px!important;</div>
    <div>}</div>
    <div>.mt-xl-50 {</div>
        <div>margin-top: 50px!important;</div>
    <div>}</div>
    <div>.mb-xl-50 {</div>
        <div>margin-bottom: 50px!important;</div>
    <div>}</div>
    <div>.ms-xl-100 {</div>
        <div>margin-left: 100px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 1400px) {</div>

    <div>// Margin</div>
    <div>.m-xxl-0 {</div>
        <div>margin: 0!important;</div>
    <div>}</div>
    <div>.ms-xxl-0 {</div>
        <div>margin-left: 0!important;</div>
    <div>}</div>
    <div>.me-xxl-0 {</div>
        <div>margin-right: 0!important;</div>
    <div>}</div>
    <div>.mt-xxl-0 {</div>
        <div>margin-top: 0!important;</div>
    <div>}</div>
    <div>.mb-xxl-0 {</div>
        <div>margin-bottom: 0!important;</div>
    <div>}</div>
    <div>.m-xxl-1 {</div>
        <div>margin: .25rem!important;</div>
    <div>}</div>
    <div>.ms-xxl-1 {</div>
        <div>margin-left: .25rem!important;</div>
    <div>}</div>
    <div>.me-xxl-1 {</div>
        <div>margin-right: .25rem!important;</div>
    <div>}</div>
    <div>.mt-xxl-1 {</div>
        <div>margin-top: .25rem!important;</div>
    <div>}</div>
    <div>.mb-xxl-1 {</div>
        <div>margin-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.m-xxl-2 {</div>
        <div>margin: .5rem!important;</div>
    <div>}</div>
    <div>.ms-xxl-2 {</div>
        <div>margin-left: .5rem!important;</div>
    <div>}</div>
    <div>.me-xxl-2 {</div>
        <div>margin-right: .5rem!important;</div>
    <div>}</div>
    <div>.mt-xxl-2 {</div>
        <div>margin-top: .5rem!important;</div>
    <div>}</div>
    <div>.mb-xxl-2 {</div>
        <div>margin-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.m-xxl-3 {</div>
        <div>margin: 1rem!important;</div>
    <div>}</div>
    <div>.ms-xxl-3 {</div>
        <div>margin-left: 1rem!important;</div>
    <div>}</div>
    <div>.me-xxl-3 {</div>
        <div>margin-right: 1rem!important;</div>
    <div>}</div>
    <div>.mt-xxl-3 {</div>
        <div>margin-top: 1rem!important;</div>
    <div>}</div>
    <div>.mb-xxl-3 {</div>
        <div>margin-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.m-xxl-4 {</div>
        <div>margin: 1.5rem!important;</div>
    <div>}</div>
    <div>.ms-xxl-4 {</div>
        <div>margin-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.me-xxl-4 {</div>
        <div>margin-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.mt-xxl-4 {</div>
        <div>margin-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.mb-xxl-4 {</div>
        <div>margin-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.m-xxl-5 {</div>
        <div>margin: 5px!important;</div>
    <div>}</div>
    <div>.ms-xxl-5 {</div>
        <div>margin-left: 5px!important;</div>
    <div>}</div>
    <div>.me-xxl-5 {</div>
        <div>margin-right: 5px!important;</div>
    <div>}</div>
    <div>.mt-xxl-5 {</div>
        <div>margin-top: 5px!important;</div>
    <div>}</div>
    <div>.mb-xxl-5 {</div>
        <div>margin-bottom: 5px!important;</div>
    <div>}</div>
    <div>.m-xxl-8 {</div>
        <div>margin: 8px!important;</div>
    <div>}</div>
    <div>.ms-xxl-8 {</div>
        <div>margin-left: 8px!important;</div>
    <div>}</div>
    <div>.me-xxl-8 {</div>
        <div>margin-right: 8px!important;</div>
    <div>}</div>
    <div>.mt-xxl-8 {</div>
        <div>margin-top: 8px!important;</div>
    <div>}</div>
    <div>.mb-xxl-8 {</div>
        <div>margin-bottom: 8px!important;</div>
    <div>}</div>
    <div>.m-xxl-10 {</div>
        <div>margin: 10px!important;</div>
    <div>}</div>
    <div>.ms-xxl-10 {</div>
        <div>margin-left: 10px!important;</div>
    <div>}</div>
    <div>.me-xxl-10 {</div>
        <div>margin-right: 10px!important;</div>
    <div>}</div>
    <div>.mt-xxl-10 {</div>
        <div>margin-top: 10px!important;</div>
    <div>}</div>
    <div>.mb-xxl-10 {</div>
        <div>margin-bottom: 10px!important;</div>
    <div>}</div>
    <div>.m-xxl-11 {</div>
        <div>margin: 11px!important;</div>
    <div>}</div>
    <div>.ms-xxl-11 {</div>
        <div>margin-left: 11px!important;</div>
    <div>}</div>
    <div>.me-xxl-11 {</div>
        <div>margin-right: 11px!important;</div>
    <div>}</div>
    <div>.mt-xxl-11 {</div>
        <div>margin-top: 11px!important;</div>
    <div>}</div>
    <div>.mb-xxl-11 {</div>
        <div>margin-bottom: 11px!important;</div>
    <div>}</div>
    <div>.m-xxl-12 {</div>
        <div>margin: 12px!important;</div>
    <div>}</div>
    <div>.ms-xxl-12 {</div>
        <div>margin-left: 12px!important;</div>
    <div>}</div>
    <div>.me-xxl-12 {</div>
        <div>margin-right: 12px!important;</div>
    <div>}</div>
    <div>.mt-xxl-12 {</div>
        <div>margin-top: 12px!important;</div>
    <div>}</div>
    <div>.mb-xxl-12 {</div>
        <div>margin-bottom: 12px!important;</div>
    <div>}</div>
    <div>.m-xxl-15 {</div>
        <div>margin: 15px!important;</div>
    <div>}</div>
    <div>.ms-xxl-15 {</div>
        <div>margin-left: 15px!important;</div>
    <div>}</div>
    <div>.me-xxl-15 {</div>
        <div>margin-right: 15px!important;</div>
    <div>}</div>
    <div>.mt-xxl-15 {</div>
        <div>margin-top: 15px!important;</div>
    <div>}</div>
    <div>.mb-xxl-15 {</div>
        <div>margin-bottom: 15px!important;</div>
    <div>}</div>
    <div>.m-xxl-20 {</div>
        <div>margin: 20px!important;</div>
    <div>}</div>
    <div>.ms-xxl-20 {</div>
        <div>margin-left: 20px!important;</div>
    <div>}</div>
    <div>.me-xxl-20 {</div>
        <div>margin-right: 20px!important;</div>
    <div>}</div>
    <div>.mt-xxl-20 {</div>
        <div>margin-top: 20px!important;</div>
    <div>}</div>
    <div>.mb-xxl-20 {</div>
        <div>margin-bottom: 20px!important;</div>
    <div>}</div>
    <div>.m-xxl-25 {</div>
        <div>margin: 25px!important;</div>
    <div>}</div>
    <div>.ms-xxl-25 {</div>
        <div>margin-left: 25px!important;</div>
    <div>}</div>
    <div>.me-xxl-25 {</div>
        <div>margin-right: 25px!important;</div>
    <div>}</div>
    <div>.mt-xxl-25 {</div>
        <div>margin-top: 25px!important;</div>
    <div>}</div>
    <div>.mb-xxl-25 {</div>
        <div>margin-bottom: 25px!important;</div>
    <div>}</div>
    <div>.m-xxl-30 {</div>
        <div>margin: 30px!important;</div>
    <div>}</div>
    <div>.ms-xxl-30 {</div>
        <div>margin-left: 30px!important;</div>
    <div>}</div>
    <div>.me-xxl-30 {</div>
        <div>margin-right: 30px!important;</div>
    <div>}</div>
    <div>.mt-xxl-30 {</div>
        <div>margin-top: 30px!important;</div>
    <div>}</div>
    <div>.mb-xxl-30 {</div>
        <div>margin-bottom: 30px!important;</div>
    <div>}</div>
    <div>.m-xxl-35 {</div>
        <div>margin: 35px!important;</div>
    <div>}</div>
    <div>.ms-xxl-35 {</div>
        <div>margin-left: 35px!important;</div>
    <div>}</div>
    <div>.me-xxl-35 {</div>
        <div>margin-right: 35px!important;</div>
    <div>}</div>
    <div>.mt-xxl-35 {</div>
        <div>margin-top: 35px!important;</div>
    <div>}</div>
    <div>.mb-xxl-35 {</div>
        <div>margin-bottom: 35px!important;</div>
    <div>}</div>
    <div>.m-xxl-40 {</div>
        <div>margin: 40px!important;</div>
    <div>}</div>
    <div>.ms-xxl-40 {</div>
        <div>margin-left: 40px!important;</div>
    <div>}</div>
    <div>.me-xxl-40 {</div>
        <div>margin-right: 40px!important;</div>
    <div>}</div>
    <div>.mt-xxl-40 {</div>
        <div>margin-top: 40px!important;</div>
    <div>}</div>
    <div>.mb-xxl-40 {</div>
        <div>margin-bottom: 40px!important;</div>
    <div>}</div>
    <div>.m-xxl-45 {</div>
        <div>margin: 45px!important;</div>
    <div>}</div>
    <div>.ms-xxl-45 {</div>
        <div>margin-left: 45px!important;</div>
    <div>}</div>
    <div>.me-xxl-45 {</div>
        <div>margin-right: 45px!important;</div>
    <div>}</div>
    <div>.mt-xxl-45 {</div>
        <div>margin-top: 45px!important;</div>
    <div>}</div>
    <div>.mb-xxl-45 {</div>
        <div>margin-bottom: 45px!important;</div>
    <div>}</div>
    <div>.m-xxl-50 {</div>
        <div>margin: 50px!important;</div>
    <div>}</div>
    <div>.ms-xxl-50 {</div>
        <div>margin-left: 50px!important;</div>
    <div>}</div>
    <div>.me-xxl-50 {</div>
        <div>margin-right: 50px!important;</div>
    <div>}</div>
    <div>.mt-xxl-50 {</div>
        <div>margin-top: 50px!important;</div>
    <div>}</div>
    <div>.mb-xxl-50 {</div>
        <div>margin-bottom: 50px!important;</div>
    <div>}</div>
    <div>.ms-xxl-100 {</div>
        <div>margin-left: 100px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 1600px) {</div>

    <div>// Margin</div>
    <div>.m-xxxl-0 {</div>
        <div>margin: 0!important;</div>
    <div>}</div>
    <div>.ms-xxxl-0 {</div>
        <div>margin-left: 0!important;</div>
    <div>}</div>
    <div>.me-xxxl-0 {</div>
        <div>margin-right: 0!important;</div>
    <div>}</div>
    <div>.mt-xxxl-0 {</div>
        <div>margin-top: 0!important;</div>
    <div>}</div>
    <div>.mb-xxxl-0 {</div>
        <div>margin-bottom: 0!important;</div>
    <div>}</div>
    <div>.m-xxxl-1 {</div>
        <div>margin: .25rem!important;</div>
    <div>}</div>
    <div>.ms-xxxl-1 {</div>
        <div>margin-left: .25rem!important;</div>
    <div>}</div>
    <div>.me-xxxl-1 {</div>
        <div>margin-right: .25rem!important;</div>
    <div>}</div>
    <div>.mt-xxxl-1 {</div>
        <div>margin-top: .25rem!important;</div>
    <div>}</div>
    <div>.mb-xxxl-1 {</div>
        <div>margin-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.m-xxxl-2 {</div>
        <div>margin: .5rem!important;</div>
    <div>}</div>
    <div>.ms-xxxl-2 {</div>
        <div>margin-left: .5rem!important;</div>
    <div>}</div>
    <div>.me-xxxl-2 {</div>
        <div>margin-right: .5rem!important;</div>
    <div>}</div>
    <div>.mt-xxxl-2 {</div>
        <div>margin-top: .5rem!important;</div>
    <div>}</div>
    <div>.mb-xxxl-2 {</div>
        <div>margin-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.m-xxxl-3 {</div>
        <div>margin: 1rem!important;</div>
    <div>}</div>
    <div>.ms-xxxl-3 {</div>
        <div>margin-left: 1rem!important;</div>
    <div>}</div>
    <div>.me-xxxl-3 {</div>
        <div>margin-right: 1rem!important;</div>
    <div>}</div>
    <div>.mt-xxxl-3 {</div>
        <div>margin-top: 1rem!important;</div>
    <div>}</div>
    <div>.mb-xxxl-3 {</div>
        <div>margin-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.m-xxxl-4 {</div>
        <div>margin: 1.5rem!important;</div>
    <div>}</div>
    <div>.ms-xxxl-4 {</div>
        <div>margin-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.me-xxxl-4 {</div>
        <div>margin-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.mt-xxxl-4 {</div>
        <div>margin-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.mb-xxxl-4 {</div>
        <div>margin-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.m-xxxl-5 {</div>
        <div>margin: 5px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-5 {</div>
        <div>margin-left: 5px!important;</div>
    <div>}</div>
    <div>.me-xxxl-5 {</div>
        <div>margin-right: 5px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-5 {</div>
        <div>margin-top: 5px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-5 {</div>
        <div>margin-bottom: 5px!important;</div>
    <div>}</div>
    <div>.m-xxxl-8 {</div>
        <div>margin: 8px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-8 {</div>
        <div>margin-left: 8px!important;</div>
    <div>}</div>
    <div>.me-xxxl-8 {</div>
        <div>margin-right: 8px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-8 {</div>
        <div>margin-top: 8px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-8 {</div>
        <div>margin-bottom: 8px!important;</div>
    <div>}</div>
    <div>.m-xxxl-10 {</div>
        <div>margin: 10px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-10 {</div>
        <div>margin-left: 10px!important;</div>
    <div>}</div>
    <div>.me-xxxl-10 {</div>
        <div>margin-right: 10px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-10 {</div>
        <div>margin-top: 10px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-10 {</div>
        <div>margin-bottom: 10px!important;</div>
    <div>}</div>
    <div>.m-xxxl-11 {</div>
        <div>margin: 11px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-11 {</div>
        <div>margin-left: 11px!important;</div>
    <div>}</div>
    <div>.me-xxxl-11 {</div>
        <div>margin-right: 11px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-11 {</div>
        <div>margin-top: 11px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-10 {</div>
        <div>margin-bottom: 10px!important;</div>
    <div>}</div>
    <div>.m-xxxl-12 {</div>
        <div>margin: 12px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-12 {</div>
        <div>margin-left: 12px!important;</div>
    <div>}</div>
    <div>.me-xxxl-12 {</div>
        <div>margin-right: 12px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-12 {</div>
        <div>margin-top: 12px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-12 {</div>
        <div>margin-bottom: 12px!important;</div>
    <div>}</div>
    <div>.m-xxxl-15 {</div>
        <div>margin: 15px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-15 {</div>
        <div>margin-left: 15px!important;</div>
    <div>}</div>
    <div>.me-xxxl-15 {</div>
        <div>margin-right: 15px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-15 {</div>
        <div>margin-top: 15px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-15 {</div>
        <div>margin-bottom: 15px!important;</div>
    <div>}</div>
    <div>.m-xxxl-20 {</div>
        <div>margin: 20px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-20 {</div>
        <div>margin-left: 20px!important;</div>
    <div>}</div>
    <div>.me-xxxl-20 {</div>
        <div>margin-right: 20px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-20 {</div>
        <div>margin-top: 20px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-20 {</div>
        <div>margin-bottom: 20px!important;</div>
    <div>}</div>
    <div>.m-xxxl-25 {</div>
        <div>margin: 25px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-25 {</div>
        <div>margin-left: 25px!important;</div>
    <div>}</div>
    <div>.me-xxxl-25 {</div>
        <div>margin-right: 25px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-25 {</div>
        <div>margin-top: 25px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-25 {</div>
        <div>margin-bottom: 25px!important;</div>
    <div>}</div>
    <div>.m-xxxl-30 {</div>
        <div>margin: 30px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-30 {</div>
        <div>margin-left: 30px!important;</div>
    <div>}</div>
    <div>.me-xxxl-30 {</div>
        <div>margin-right: 30px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-30 {</div>
        <div>margin-top: 30px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-30 {</div>
        <div>margin-bottom: 30px!important;</div>
    <div>}</div>
    <div>.m-xxxl-35 {</div>
        <div>margin: 35px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-35 {</div>
        <div>margin-left: 35px!important;</div>
    <div>}</div>
    <div>.me-xxxl-35 {</div>
        <div>margin-right: 35px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-35 {</div>
        <div>margin-top: 35px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-35 {</div>
        <div>margin-bottom: 35px!important;</div>
    <div>}</div>
    <div>.m-xxxl-40 {</div>
        <div>margin: 40px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-40 {</div>
        <div>margin-left: 40px!important;</div>
    <div>}</div>
    <div>.me-xxxl-40 {</div>
        <div>margin-right: 40px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-40 {</div>
        <div>margin-top: 40px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-40 {</div>
        <div>margin-bottom: 40px!important;</div>
    <div>}</div>
    <div>.m-xxxl-45 {</div>
        <div>margin: 45px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-45 {</div>
        <div>margin-left: 45px!important;</div>
    <div>}</div>
    <div>.me-xxxl-45 {</div>
        <div>margin-right: 45px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-45 {</div>
        <div>margin-top: 45px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-45 {</div>
        <div>margin-bottom: 45px!important;</div>
    <div>}</div>
    <div>.m-xxxl-50 {</div>
        <div>margin: 50px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-50 {</div>
        <div>margin-left: 50px!important;</div>
    <div>}</div>
    <div>.me-xxxl-50 {</div>
        <div>margin-right: 50px!important;</div>
    <div>}</div>
    <div>.mt-xxxl-50 {</div>
        <div>margin-top: 50px!important;</div>
    <div>}</div>
    <div>.mb-xxxl-50 {</div>
        <div>margin-bottom: 50px!important;</div>
    <div>}</div>
    <div>.ms-xxxl-100 {</div>
        <div>margin-left: 100px!important;</div>
    <div>}</div>

<div>}</div>
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15">Padding</h5>
      <div class="position-relative mt-20">
        <!-- <button class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0" data-clipboard-target="#paddingCode">
                            Copy
                        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="paddingCode">
<div>// Padding</div>
<div>.p-0 {</div>
    <div>padding: 0!important;</div>
<div>}</div>
<div>.ps-0 {</div>
    <div>padding-left: 0!important;</div>
<div>}</div>
<div>.pe-0 {</div>
    <div>padding-right: 0!important;</div>
<div>}</div>
<div>.pt-0 {</div>
    <div>padding-top: 0!important;</div>
<div>}</div>
<div>.pb-0 {</div>
    <div>padding-bottom: 0!important;</div>
<div>}</div>
<div>.p-1 {</div>
    <div>padding: .25rem!important;</div>
<div>}</div>
<div>.ps-1 {</div>
    <div>padding-left: .25rem!important;</div>
<div>}</div>
<div>.pe-1 {</div>
    <div>padding-right: .25rem!important;</div>
<div>}</div>
<div>.pt-1 {</div>
    <div>padding-top: .25rem!important;</div>
<div>}</div>
<div>.pb-1 {</div>
    <div>padding-bottom: .25rem!important;</div>
<div>}</div>
<div>.p-2 {</div>
    <div>padding: .5rem!important;</div>
<div>}</div>
<div>.ps-2 {</div>
    <div>padding-left: .5rem!important;</div>
<div>}</div>
<div>.pe-2 {</div>
    <div>padding-right: .5rem!important;</div>
<div>}</div>
<div>.pt-2 {</div>
    <div>padding-top: .5rem!important;</div>
<div>}</div>
<div>.pb-2 {</div>
    <div>padding-bottom: .5rem!important;</div>
<div>}</div>
<div>.p-3 {</div>
    <div>padding: 1rem!important;</div>
<div>}</div>
<div>.ps-3 {</div>
    <div>padding-left: 1rem!important;</div>
<div>}</div>
<div>.pe-3 {</div>
    <div>padding-right: 1rem!important;</div>
<div>}</div>
<div>.pt-3 {</div>
    <div>padding-top: 1rem!important;</div>
<div>}</div>
<div>.pb-3 {</div>
    <div>padding-bottom: 1rem!important;</div>
<div>}</div>
<div>.p-4 {</div>
    <div>padding: 1.5rem!important;</div>
<div>}</div>
<div>.ps-4 {</div>
    <div>padding-left: 1.5rem!important;</div>
<div>}</div>
<div>.pe-4 {</div>
    <div>padding-right: 1.5rem!important;</div>
<div>}</div>
<div>.pt-4 {</div>
    <div>padding-top: 1.5rem!important;</div>
<div>}</div>
<div>.pb-4 {</div>
    <div>padding-bottom: 1.5rem!important;</div>
<div>}</div>
<div>.p-5 {</div>
    <div>padding: 5px!important;</div>
<div>}</div>
<div>.ps-5 {</div>
    <div>padding-left: 5px!important;</div>
<div>}</div>
<div>.pe-5 {</div>
    <div>padding-right: 5px!important;</div>
<div>}</div>
<div>.pt-5 {</div>
    <div>padding-top: 5px!important;</div>
<div>}</div>
<div>.pb-5 {</div>
    <div>padding-bottom: 5px!important;</div>
<div>}</div>
<div>.p-6 {</div>
    <div>padding: 6px!important;</div>
<div>}</div>
<div>.ps-6 {</div>
    <div>padding-left: 6px!important;</div>
<div>}</div>
<div>.pe-6 {</div>
    <div>padding-right: 6px!important;</div>
<div>}</div>
<div>.pt-6 {</div>
    <div>padding-top: 6px!important;</div>
<div>}</div>
<div>.pb-6 {</div>
    <div>padding-bottom: 6px!important;</div>
<div>}</div>
<div>.p-7 {</div>
    <div>padding: 7px!important;</div>
<div>}</div>
<div>.ps-7 {</div>
    <div>padding-left: 7px!important;</div>
<div>}</div>
<div>.pe-7 {</div>
    <div>padding-right: 7px!important;</div>
<div>}</div>
<div>.pt-7 {</div>
    <div>padding-top: 7px!important;</div>
<div>}</div>
<div>.pb-7 {</div>
    <div>padding-bottom: 7px!important;</div>
<div>}</div>
<div>.p-8 {</div>
    <div>padding: 8px!important;</div>
<div>}</div>
<div>.ps-8 {</div>
    <div>padding-left: 8px!important;</div>
<div>}</div>
<div>.pe-8 {</div>
    <div>padding-right: 8px!important;</div>
<div>}</div>
<div>.pt-8 {</div>
    <div>padding-top: 8px!important;</div>
<div>}</div>
<div>.pb-8 {</div>
    <div>padding-bottom: 8px!important;</div>
<div>}</div>
<div>.p-10 {</div>
    <div>padding: 10px!important;</div>
<div>}</div>
<div>.ps-10 {</div>
    <div>padding-left: 10px!important;</div>
<div>}</div>
<div>.pe-10 {</div>
    <div>padding-right: 10px!important;</div>
<div>}</div>
<div>.pt-10 {</div>
    <div>padding-top: 10px!important;</div>
<div>}</div>
<div>.pb-10 {</div>
    <div>padding-bottom: 10px!important;</div>
<div>}</div>
<div>.p-11 {</div>
    <div>padding: 11px!important;</div>
<div>}</div>
<div>.ps-11 {</div>
    <div>padding-left: 11px!important;</div>
<div>}</div>
<div>.pe-11 {</div>
    <div>padding-right: 11px!important;</div>
<div>}</div>
<div>.pt-11 {</div>
    <div>padding-top: 11px!important;</div>
<div>}</div>
<div>.pb-11 {</div>
    <div>padding-bottom: 11px!important;</div>
<div>}</div>
<div>.p-12 {</div>
    <div>padding: 12px!important;</div>
<div>}</div>
<div>.ps-12 {</div>
    <div>padding-left: 12px!important;</div>
<div>}</div>
<div>.pe-12 {</div>
    <div>padding-right: 12px!important;</div>
<div>}</div>
<div>.pt-12 {</div>
    <div>padding-top: 12px!important;</div>
<div>}</div>
<div>.pb-12 {</div>
    <div>padding-bottom: 12px!important;</div>
<div>}</div>
<div>.p-15 {</div>
    <div>padding: 15px!important;</div>
<div>}</div>
<div>.ps-15 {</div>
    <div>padding-left: 15px!important;</div>
<div>}</div>
<div>.pe-15 {</div>
    <div>padding-right: 15px!important;</div>
<div>}</div>
<div>.pt-15 {</div>
    <div>padding-top: 15px!important;</div>
<div>}</div>
<div>.pb-15 {</div>
    <div>padding-bottom: 15px!important;</div>
<div>}</div>
<div>.pt-18 {</div>
    <div>padding-top: 18px!important;</div>
<div>}</div>
<div>.pb-18 {</div>
    <div>padding-bottom: 18px!important;</div>
<div>}</div>
<div>.p-20 {</div>
    <div>padding: 20px!important;</div>
<div>}</div>
<div>.ps-20 {</div>
    <div>padding-left: 20px!important;</div>
<div>}</div>
<div>.pe-20 {</div>
    <div>padding-right: 20px!important;</div>
<div>}</div>
<div>.pt-20 {</div>
    <div>padding-top: 20px!important;</div>
<div>}</div>
<div>.pb-20 {</div>
    <div>padding-bottom: 20px!important;</div>
<div>}</div>
<div>.p-25 {</div>
    <div>padding: 25px!important;</div>
<div>}</div>
<div>.ps-25 {</div>
    <div>padding-left: 25px!important;</div>
<div>}</div>
<div>.pe-25 {</div>
    <div>padding-right: 25px!important;</div>
<div>}</div>
<div>.pt-25 {</div>
    <div>padding-top: 25px!important;</div>
<div>}</div>
<div>.pb-25 {</div>
    <div>padding-bottom: 25px!important;</div>
<div>}</div>
<div>.p-30 {</div>
    <div>padding: 30px!important;</div>
<div>}</div>
<div>.ps-30 {</div>
    <div>padding-left: 30px!important;</div>
<div>}</div>
<div>.pe-30 {</div>
    <div>padding-right: 30px!important;</div>
<div>}</div>
<div>.pt-30 {</div>
    <div>padding-top: 30px!important;</div>
<div>}</div>
<div>.pb-30 {</div>
    <div>padding-bottom: 30px!important;</div>
<div>}</div>
<div>.p-35 {</div>
    <div>padding: 35px!important;</div>
<div>}</div>
<div>.ps-35 {</div>
    <div>padding-left: 35px!important;</div>
<div>}</div>
<div>.pe-35 {</div>
    <div>padding-right: 35px!important;</div>
<div>}</div>
<div>.pt-35 {</div>
    <div>padding-top: 35px!important;</div>
<div>}</div>
<div>.pb-35 {</div>
    <div>padding-bottom: 35px!important;</div>
<div>}</div>
<div>.p-40 {</div>
    <div>padding: 40px!important;</div>
<div>}</div>
<div>.ps-40 {</div>
    <div>padding-left: 40px!important;</div>
<div>}</div>
<div>.pe-40 {</div>
    <div>padding-right: 40px!important;</div>
<div>}</div>
<div>.pt-40 {</div>
    <div>padding-top: 40px!important;</div>
<div>}</div>
<div>.pb-40 {</div>
    <div>padding-bottom: 40px!important;</div>
<div>}</div>
<div>.p-45 {</div>
    <div>padding: 45px!important;</div>
<div>}</div>
<div>.ps-45 {</div>
    <div>padding-left: 45px!important;</div>
<div>}</div>
<div>.pe-45 {</div>
    <div>padding-right: 45px!important;</div>
<div>}</div>
<div>.pt-45 {</div>
    <div>padding-top: 45px!important;</div>
<div>}</div>
<div>.pb-45 {</div>
    <div>padding-bottom: 45px!important;</div>
<div>}</div>
<div>.p-50 {</div>
    <div>padding: 50px!important;</div>
<div>}</div>
<div>.ps-50 {</div>
    <div>padding-left: 50px!important;</div>
<div>}</div>
<div>.pe-50 {</div>
    <div>padding-right: 50px!important;</div>
<div>}</div>
<div>.pt-50 {</div>
    <div>padding-top: 50px!important;</div>
<div>}</div>
<div>.pb-50 {</div>
    <div>padding-bottom: 50px!important;</div>
<div>}</div>
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15">Responsive Padding</h5>
      <div class="position-relative mt-20">
        <!-- <button class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0" data-clipboard-target="#responsivePaddingCode">
                            Copy
                        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="responsivePaddingCode">
<div>@media (min-width: 576px) {</div>

    <div>// Padding</div>
    <div>.p-sm-0 {</div>
        <div>padding: 0!important;</div>
    <div>}</div>
    <div>.ps-sm-0 {</div>
        <div>padding-left: 0!important;</div>
    <div>}</div>
    <div>.pe-sm-0 {</div>
        <div>padding-right: 0!important;</div>
    <div>}</div>
    <div>.pt-sm-0 {</div>
        <div>padding-top: 0!important;</div>
    <div>}</div>
    <div>.pb-sm-0 {</div>
        <div>padding-bottom: 0!important;</div>
    <div>}</div>
    <div>.p-sm-1 {</div>
        <div>padding: .25rem!important;</div>
    <div>}</div>
    <div>.ps-sm-1 {</div>
        <div>padding-left: .25rem!important;</div>
    <div>}</div>
    <div>.pe-sm-1 {</div>
        <div>padding-right: .25rem!important;</div>
    <div>}</div>
    <div>.pt-sm-1 {</div>
        <div>padding-top: .25rem!important;</div>
    <div>}</div>
    <div>.pb-sm-1 {</div>
        <div>padding-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.p-sm-2 {</div>
        <div>padding: .5rem!important;</div>
    <div>}</div>
    <div>.ps-sm-2 {</div>
        <div>padding-left: .5rem!important;</div>
    <div>}</div>
    <div>.pe-sm-2 {</div>
        <div>padding-right: .5rem!important;</div>
    <div>}</div>
    <div>.pt-sm-2 {</div>
        <div>padding-top: .5rem!important;</div>
    <div>}</div>
    <div>.pb-sm-2 {</div>
        <div>padding-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.p-sm-3 {</div>
        <div>padding: 1rem!important;</div>
    <div>}</div>
    <div>.ps-sm-3 {</div>
        <div>padding-left: 1rem!important;</div>
    <div>}</div>
    <div>.pe-sm-3 {</div>
        <div>padding-right: 1rem!important;</div>
    <div>}</div>
    <div>.pt-sm-3 {</div>
        <div>padding-top: 1rem!important;</div>
    <div>}</div>
    <div>.pb-sm-3 {</div>
        <div>padding-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.p-sm-4 {</div>
        <div>padding: 1.5rem!important;</div>
    <div>}</div>
    <div>.ps-sm-4 {</div>
        <div>padding-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.pe-sm-4 {</div>
        <div>padding-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.pt-sm-4 {</div>
        <div>padding-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.pb-sm-4 {</div>
        <div>padding-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.p-sm-5 {</div>
        <div>padding: 5px!important;</div>
    <div>}</div>
    <div>.ps-sm-5 {</div>
        <div>padding-left: 5px!important;</div>
    <div>}</div>
    <div>.pe-sm-5 {</div>
        <div>padding-right: 5px!important;</div>
    <div>}</div>
    <div>.pt-sm-5 {</div>
        <div>padding-top: 5px!important;</div>
    <div>}</div>
    <div>.pb-sm-5 {</div>
        <div>padding-bottom: 5px!important;</div>
    <div>}</div>
    <div>.p-sm-8 {</div>
        <div>padding: 8px!important;</div>
    <div>}</div>
    <div>.ps-sm-8 {</div>
        <div>padding-left: 8px!important;</div>
    <div>}</div>
    <div>.pe-sm-8 {</div>
        <div>padding-right: 8px!important;</div>
    <div>}</div>
    <div>.pt-sm-8 {</div>
        <div>padding-top: 8px!important;</div>
    <div>}</div>
    <div>.pb-sm-8 {</div>
        <div>padding-bottom: 8px!important;</div>
    <div>}</div>
    <div>.p-sm-10 {</div>
        <div>padding: 10px!important;</div>
    <div>}</div>
    <div>.ps-sm-10 {</div>
        <div>padding-left: 10px!important;</div>
    <div>}</div>
    <div>.pe-sm-10 {</div>
        <div>padding-right: 10px!important;</div>
    <div>}</div>
    <div>.pt-sm-10 {</div>
        <div>padding-top: 10px!important;</div>
    <div>}</div>
    <div>.pb-sm-10 {</div>
        <div>padding-bottom: 10px!important;</div>
    <div>}</div>
    <div>.p-sm-11 {</div>
        <div>padding: 11px!important;</div>
    <div>}</div>
    <div>.ps-sm-11 {</div>
        <div>padding-left: 11px!important;</div>
    <div>}</div>
    <div>.pe-sm-11 {</div>
        <div>padding-right: 11px!important;</div>
    <div>}</div>
    <div>.pt-sm-11 {</div>
        <div>padding-top: 11px!important;</div>
    <div>}</div>
    <div>.pb-sm-11 {</div>
        <div>padding-bottom: 11px!important;</div>
    <div>}</div>
    <div>.p-sm-12 {</div>
        <div>padding: 12px!important;</div>
    <div>}</div>
    <div>.ps-sm-12 {</div>
        <div>padding-left: 12px!important;</div>
    <div>}</div>
    <div>.pe-sm-12 {</div>
        <div>padding-right: 12px!important;</div>
    <div>}</div>
    <div>.pt-sm-12 {</div>
        <div>padding-top: 12px!important;</div>
    <div>}</div>
    <div>.pb-sm-12 {</div>
        <div>padding-bottom: 12px!important;</div>
    <div>}</div>
    <div>.p-sm-15 {</div>
        <div>padding: 15px!important;</div>
    <div>}</div>
    <div>.ps-sm-15 {</div>
        <div>padding-left: 15px!important;</div>
    <div>}</div>
    <div>.pe-sm-15 {</div>
        <div>padding-right: 15px!important;</div>
    <div>}</div>
    <div>.pt-sm-15 {</div>
        <div>padding-top: 15px!important;</div>
    <div>}</div>
    <div>.pb-sm-15 {</div>
        <div>padding-bottom: 15px!important;</div>
    <div>}</div>
    <div>.pt-sm-18 {</div>
        <div>padding-top: 18px!important;</div>
    <div>}</div>
    <div>.pb-sm-18 {</div>
        <div>padding-bottom: 18px!important;</div>
    <div>}</div>
    <div>.p-sm-20 {</div>
        <div>padding: 20px!important;</div>
    <div>}</div>
    <div>.ps-sm-20 {</div>
        <div>padding-left: 20px!important;</div>
    <div>}</div>
    <div>.pe-sm-20 {</div>
        <div>padding-right: 20px!important;</div>
    <div>}</div>
    <div>.pt-sm-20 {</div>
        <div>padding-top: 20px!important;</div>
    <div>}</div>
    <div>.pb-sm-20 {</div>
        <div>padding-bottom: 20px!important;</div>
    <div>}</div>
    <div>.p-sm-25 {</div>
        <div>padding: 25px!important;</div>
    <div>}</div>
    <div>.ps-sm-25 {</div>
        <div>padding-left: 25px!important;</div>
    <div>}</div>
    <div>.pe-sm-25 {</div>
        <div>padding-right: 25px!important;</div>
    <div>}</div>
    <div>.pt-sm-25 {</div>
        <div>padding-top: 25px!important;</div>
    <div>}</div>
    <div>.pb-sm-25 {</div>
        <div>padding-bottom: 25px!important;</div>
    <div>}</div>
    <div>.p-sm-30 {</div>
        <div>padding: 30px!important;</div>
    <div>}</div>
    <div>.ps-sm-30 {</div>
        <div>padding-left: 30px!important;</div>
    <div>}</div>
    <div>.pe-sm-30 {</div>
        <div>padding-right: 30px!important;</div>
    <div>}</div>
    <div>.pt-sm-30 {</div>
        <div>padding-top: 30px!important;</div>
    <div>}</div>
    <div>.pb-sm-30 {</div>
        <div>padding-bottom: 30px!important;</div>
    <div>}</div>
    <div>.p-sm-35 {</div>
        <div>padding: 35px!important;</div>
    <div>}</div>
    <div>.ps-sm-35 {</div>
        <div>padding-left: 35px!important;</div>
    <div>}</div>
    <div>.pe-sm-35 {</div>
        <div>padding-right: 35px!important;</div>
    <div>}</div>
    <div>.pt-sm-35 {</div>
        <div>padding-top: 35px!important;</div>
    <div>}</div>
    <div>.pb-sm-35 {</div>
        <div>padding-bottom: 35px!important;</div>
    <div>}</div>
    <div>.p-sm-40 {</div>
        <div>padding: 40px!important;</div>
    <div>}</div>
    <div>.ps-sm-40 {</div>
        <div>padding-left: 40px!important;</div>
    <div>}</div>
    <div>.pe-sm-40 {</div>
        <div>padding-right: 40px!important;</div>
    <div>}</div>
    <div>.pt-sm-40 {</div>
        <div>padding-top: 40px!important;</div>
    <div>}</div>
    <div>.pb-sm-40 {</div>
        <div>padding-bottom: 40px!important;</div>
    <div>}</div>
    <div>.p-sm-45 {</div>
        <div>padding: 45px!important;</div>
    <div>}</div>
    <div>.ps-sm-45 {</div>
        <div>padding-left: 45px!important;</div>
    <div>}</div>
    <div>.pe-sm-45 {</div>
        <div>padding-right: 45px!important;</div>
    <div>}</div>
    <div>.pt-sm-45 {</div>
        <div>padding-top: 45px!important;</div>
    <div>}</div>
    <div>.pb-sm-45 {</div>
        <div>padding-bottom: 45px!important;</div>
    <div>}</div>
    <div>.p-sm-50 {</div>
        <div>padding: 50px!important;</div>
    <div>}</div>
    <div>.ps-sm-50 {</div>
        <div>padding-left: 50px!important;</div>
    <div>}</div>
    <div>.pe-sm-50 {</div>
        <div>padding-right: 50px!important;</div>
    <div>}</div>
    <div>.pt-sm-50 {</div>
        <div>padding-top: 50px!important;</div>
    <div>}</div>
    <div>.pb-sm-50 {</div>
        <div>padding-bottom: 50px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 768px) {</div>

    <div>// Padding</div>
    <div>.p-md-0 {</div>
        <div>padding: 0!important;</div>
    <div>}</div>
    <div>.ps-md-0 {</div>
        <div>padding-left: 0!important;</div>
    <div>}</div>
    <div>.pe-md-0 {</div>
        <div>padding-right: 0!important;</div>
    <div>}</div>
    <div>.pt-md-0 {</div>
        <div>padding-top: 0!important;</div>
    <div>}</div>
    <div>.pb-md-0 {</div>
        <div>padding-bottom: 0!important;</div>
    <div>}</div>
    <div>.p-md-1 {</div>
        <div>padding: .25rem!important;</div>
    <div>}</div>
    <div>.ps-md-1 {</div>
        <div>padding-left: .25rem!important;</div>
    <div>}</div>
    <div>.pe-md-1 {</div>
        <div>padding-right: .25rem!important;</div>
    <div>}</div>
    <div>.pt-md-1 {</div>
        <div>padding-top: .25rem!important;</div>
    <div>}</div>
    <div>.pb-md-1 {</div>
        <div>padding-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.p-md-2 {</div>
        <div>padding: .5rem!important;</div>
    <div>}</div>
    <div>.ps-md-2 {</div>
        <div>padding-left: .5rem!important;</div>
    <div>}</div>
    <div>.pe-md-2 {</div>
        <div>padding-right: .5rem!important;</div>
    <div>}</div>
    <div>.pt-md-2 {</div>
        <div>padding-top: .5rem!important;</div>
    <div>}</div>
    <div>.pb-md-2 {</div>
        <div>padding-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.p-md-3 {</div>
        <div>padding: 1rem!important;</div>
    <div>}</div>
    <div>.ps-md-3 {</div>
        <div>padding-left: 1rem!important;</div>
    <div>}</div>
    <div>.pe-md-3 {</div>
        <div>padding-right: 1rem!important;</div>
    <div>}</div>
    <div>.pt-md-3 {</div>
        <div>padding-top: 1rem!important;</div>
    <div>}</div>
    <div>.pb-md-3 {</div>
        <div>padding-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.p-md-4 {</div>
        <div>padding: 1.5rem!important;</div>
    <div>}</div>
    <div>.ps-md-4 {</div>
        <div>padding-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.pe-md-4 {</div>
        <div>padding-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.pt-md-4 {</div>
        <div>padding-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.pb-md-4 {</div>
        <div>padding-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.p-md-5 {</div>
        <div>padding: 5px!important;</div>
    <div>}</div>
    <div>.ps-md-5 {</div>
        <div>padding-left: 5px!important;</div>
    <div>}</div>
    <div>.pe-md-5 {</div>
        <div>padding-right: 5px!important;</div>
    <div>}</div>
    <div>.pt-md-5 {</div>
        <div>padding-top: 5px!important;</div>
    <div>}</div>
    <div>.pb-md-5 {</div>
        <div>padding-bottom: 5px!important;</div>
    <div>}</div>
    <div>.p-md-8 {</div>
        <div>padding: 8px!important;</div>
    <div>}</div>
    <div>.ps-md-8 {</div>
        <div>padding-left: 8px!important;</div>
    <div>}</div>
    <div>.pe-md-8 {</div>
        <div>padding-right: 8px!important;</div>
    <div>}</div>
    <div>.pt-md-8 {</div>
        <div>padding-top: 8px!important;</div>
    <div>}</div>
    <div>.pb-md-8 {</div>
        <div>padding-bottom: 8px!important;</div>
    <div>}</div>
    <div>.p-md-10 {</div>
        <div>padding: 10px!important;</div>
    <div>}</div>
    <div>.ps-md-10 {</div>
        <div>padding-left: 10px!important;</div>
    <div>}</div>
    <div>.pe-md-10 {</div>
        <div>padding-right: 10px!important;</div>
    <div>}</div>
    <div>.pt-md-10 {</div>
        <div>padding-top: 10px!important;</div>
    <div>}</div>
    <div>.pb-md-10 {</div>
        <div>padding-bottom: 10px!important;</div>
    <div>}</div>
    <div>.p-md-11 {</div>
        <div>padding: 11px!important;</div>
    <div>}</div>
    <div>.ps-md-11 {</div>
        <div>padding-left: 11px!important;</div>
    <div>}</div>
    <div>.pe-md-11 {</div>
        <div>padding-right: 11px!important;</div>
    <div>}</div>
    <div>.pt-md-11 {</div>
        <div>padding-top: 11px!important;</div>
    <div>}</div>
    <div>.pb-md-11 {</div>
        <div>padding-bottom: 11px!important;</div>
    <div>}</div>
    <div>.p-md-12 {</div>
        <div>padding: 12px!important;</div>
    <div>}</div>
    <div>.ps-md-12 {</div>
        <div>padding-left: 12px!important;</div>
    <div>}</div>
    <div>.pe-md-12 {</div>
        <div>padding-right: 12px!important;</div>
    <div>}</div>
    <div>.pt-md-12 {</div>
        <div>padding-top: 12px!important;</div>
    <div>}</div>
    <div>.pb-md-12 {</div>
        <div>padding-bottom: 12px!important;</div>
    <div>}</div>
    <div>.p-md-15 {</div>
        <div>padding: 15px!important;</div>
    <div>}</div>
    <div>.ps-md-15 {</div>
        <div>padding-left: 15px!important;</div>
    <div>}</div>
    <div>.pe-md-15 {</div>
        <div>padding-right: 15px!important;</div>
    <div>}</div>
    <div>.pt-md-15 {</div>
        <div>padding-top: 15px!important;</div>
    <div>}</div>
    <div>.pb-md-15 {</div>
        <div>padding-bottom: 15px!important;</div>
    <div>}</div>
    <div>.pt-md-18 {</div>
        <div>padding-top: 18px!important;</div>
    <div>}</div>
    <div>.pb-md-18 {</div>
        <div>padding-bottom: 18px!important;</div>
    <div>}</div>
    <div>.p-md-20 {</div>
        <div>padding: 20px!important;</div>
    <div>}</div>
    <div>.ps-md-20 {</div>
        <div>padding-left: 20px!important;</div>
    <div>}</div>
    <div>.pe-md-20 {</div>
        <div>padding-right: 20px!important;</div>
    <div>}</div>
    <div>.pt-md-20 {</div>
        <div>padding-top: 20px!important;</div>
    <div>}</div>
    <div>.pb-md-20 {</div>
        <div>padding-bottom: 20px!important;</div>
    <div>}</div>
    <div>.p-md-25 {</div>
        <div>padding: 25px!important;</div>
    <div>}</div>
    <div>.ps-md-25 {</div>
        <div>padding-left: 25px!important;</div>
    <div>}</div>
    <div>.pe-md-25 {</div>
        <div>padding-right: 25px!important;</div>
    <div>}</div>
    <div>.pt-md-25 {</div>
        <div>padding-top: 25px!important;</div>
    <div>}</div>
    <div>.pb-md-25 {</div>
        <div>padding-bottom: 25px!important;</div>
    <div>}</div>
    <div>.p-md-30 {</div>
        <div>padding: 30px!important;</div>
    <div>}</div>
    <div>.ps-md-30 {</div>
        <div>padding-left: 30px!important;</div>
    <div>}</div>
    <div>.pe-md-30 {</div>
        <div>padding-right: 30px!important;</div>
    <div>}</div>
    <div>.pt-md-30 {</div>
        <div>padding-top: 30px!important;</div>
    <div>}</div>
    <div>.pb-md-30 {</div>
        <div>padding-bottom: 30px!important;</div>
    <div>}</div>
    <div>.p-md-35 {</div>
        <div>padding: 35px!important;</div>
    <div>}</div>
    <div>.ps-md-35 {</div>
        <div>padding-left: 35px!important;</div>
    <div>}</div>
    <div>.pe-md-35 {</div>
        <div>padding-right: 35px!important;</div>
    <div>}</div>
    <div>.pt-md-35 {</div>
        <div>padding-top: 35px!important;</div>
    <div>}</div>
    <div>.pb-md-35 {</div>
        <div>padding-bottom: 35px!important;</div>
    <div>}</div>
    <div>.p-md-40 {</div>
        <div>padding: 40px!important;</div>
    <div>}</div>
    <div>.ps-md-40 {</div>
        <div>padding-left: 40px!important;</div>
    <div>}</div>
    <div>.pe-md-40 {</div>
        <div>padding-right: 40px!important;</div>
    <div>}</div>
    <div>.pt-md-40 {</div>
        <div>padding-top: 40px!important;</div>
    <div>}</div>
    <div>.pb-md-40 {</div>
        <div>padding-bottom: 40px!important;</div>
    <div>}</div>
    <div>.p-md-45 {</div>
        <div>padding: 45px!important;</div>
    <div>}</div>
    <div>.ps-md-45 {</div>
        <div>padding-left: 45px!important;</div>
    <div>}</div>
    <div>.pe-md-45 {</div>
        <div>padding-right: 45px!important;</div>
    <div>}</div>
    <div>.pt-md-45 {</div>
        <div>padding-top: 45px!important;</div>
    <div>}</div>
    <div>.pb-md-45 {</div>
        <div>padding-bottom: 45px!important;</div>
    <div>}</div>
    <div>.p-md-50 {</div>
        <div>padding: 50px!important;</div>
    <div>}</div>
    <div>.ps-md-50 {</div>
        <div>padding-left: 50px!important;</div>
    <div>}</div>
    <div>.pe-md-50 {</div>
        <div>padding-right: 50px!important;</div>
    <div>}</div>
    <div>.pt-md-50 {</div>
        <div>padding-top: 50px!important;</div>
    <div>}</div>
    <div>.pb-md-50 {</div>
        <div>padding-bottom: 50px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 992px) {</div>

    <div>// Padding</div>
    <div>.p-lg-0 {</div>
        <div>padding: 0!important;</div>
    <div>}</div>
    <div>.ps-lg-0 {</div>
        <div>padding-left: 0!important;</div>
    <div>}</div>
    <div>.pe-lg-0 {</div>
        <div>padding-right: 0!important;</div>
    <div>}</div>
    <div>.pt-lg-0 {</div>
        <div>padding-top: 0!important;</div>
    <div>}</div>
    <div>.pb-lg-0 {</div>
        <div>padding-bottom: 0!important;</div>
    <div>}</div>
    <div>.p-lg-1 {</div>
        <div>padding: .25rem!important;</div>
    <div>}</div>
    <div>.ps-lg-1 {</div>
        <div>padding-left: .25rem!important;</div>
    <div>}</div>
    <div>.pe-lg-1 {</div>
        <div>padding-right: .25rem!important;</div>
    <div>}</div>
    <div>.pt-lg-1 {</div>
        <div>padding-top: .25rem!important;</div>
    <div>}</div>
    <div>.pb-lg-1 {</div>
        <div>padding-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.p-lg-2 {</div>
        <div>padding: .5rem!important;</div>
    <div>}</div>
    <div>.ps-lg-2 {</div>
        <div>padding-left: .5rem!important;</div>
    <div>}</div>
    <div>.pe-lg-2 {</div>
        <div>padding-right: .5rem!important;</div>
    <div>}</div>
    <div>.pt-lg-2 {</div>
        <div>padding-top: .5rem!important;</div>
    <div>}</div>
    <div>.pb-lg-2 {</div>
        <div>padding-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.p-lg-3 {</div>
        <div>padding: 1rem!important;</div>
    <div>}</div>
    <div>.ps-lg-3 {</div>
        <div>padding-left: 1rem!important;</div>
    <div>}</div>
    <div>.pe-lg-3 {</div>
        <div>padding-right: 1rem!important;</div>
    <div>}</div>
    <div>.pt-lg-3 {</div>
        <div>padding-top: 1rem!important;</div>
    <div>}</div>
    <div>.pb-lg-3 {</div>
        <div>padding-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.p-lg-4 {</div>
        <div>padding: 1.5rem!important;</div>
    <div>}</div>
    <div>.ps-lg-4 {</div>
        <div>padding-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.pe-lg-4 {</div>
        <div>padding-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.pt-lg-4 {</div>
        <div>padding-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.pb-lg-4 {</div>
        <div>padding-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.p-lg-5 {</div>
        <div>padding: 5px!important;</div>
    <div>}</div>
    <div>.ps-lg-5 {</div>
        <div>padding-left: 5px!important;</div>
    <div>}</div>
    <div>.pe-lg-5 {</div>
        <div>padding-right: 5px!important;</div>
    <div>}</div>
    <div>.pt-lg-5 {</div>
        <div>padding-top: 5px!important;</div>
    <div>}</div>
    <div>.pb-lg-5 {</div>
        <div>padding-bottom: 5px!important;</div>
    <div>}</div>
    <div>.p-lg-8 {</div>
        <div>padding: 8px!important;</div>
    <div>}</div>
    <div>.ps-lg-8 {</div>
        <div>padding-left: 8px!important;</div>
    <div>}</div>
    <div>.pe-lg-8 {</div>
        <div>padding-right: 8px!important;</div>
    <div>}</div>
    <div>.pt-lg-8 {</div>
        <div>padding-top: 8px!important;</div>
    <div>}</div>
    <div>.pb-lg-8 {</div>
        <div>padding-bottom: 8px!important;</div>
    <div>}</div>
    <div>.p-lg-10 {</div>
        <div>padding: 10px!important;</div>
    <div>}</div>
    <div>.ps-lg-10 {</div>
        <div>padding-left: 10px!important;</div>
    <div>}</div>
    <div>.pe-lg-10 {</div>
        <div>padding-right: 10px!important;</div>
    <div>}</div>
    <div>.pt-lg-10 {</div>
        <div>padding-top: 10px!important;</div>
    <div>}</div>
    <div>.pb-lg-10 {</div>
        <div>padding-bottom: 10px!important;</div>
    <div>}</div>
    <div>.p-lg-11 {</div>
        <div>padding: 11px!important;</div>
    <div>}</div>
    <div>.ps-lg-11 {</div>
        <div>padding-left: 11px!important;</div>
    <div>}</div>
    <div>.pe-lg-11 {</div>
        <div>padding-right: 11px!important;</div>
    <div>}</div>
    <div>.pt-lg-11 {</div>
        <div>padding-top: 11px!important;</div>
    <div>}</div>
    <div>.pb-lg-11 {</div>
        <div>padding-bottom: 11px!important;</div>
    <div>}</div>
    <div>.p-lg-12 {</div>
        <div>padding: 12px!important;</div>
    <div>}</div>
    <div>.ps-lg-12 {</div>
        <div>padding-left: 12px!important;</div>
    <div>}</div>
    <div>.pe-lg-12 {</div>
        <div>padding-right: 12px!important;</div>
    <div>}</div>
    <div>.pt-lg-12 {</div>
        <div>padding-top: 12px!important;</div>
    <div>}</div>
    <div>.pb-lg-12 {</div>
        <div>padding-bottom: 12px!important;</div>
    <div>}</div>
    <div>.p-lg-15 {</div>
        <div>padding: 15px!important;</div>
    <div>}</div>
    <div>.ps-lg-15 {</div>
        <div>padding-left: 15px!important;</div>
    <div>}</div>
    <div>.pe-lg-15 {</div>
        <div>padding-right: 15px!important;</div>
    <div>}</div>
    <div>.pt-lg-15 {</div>
        <div>padding-top: 15px!important;</div>
    <div>}</div>
    <div>.pb-lg-15 {</div>
        <div>padding-bottom: 15px!important;</div>
    <div>}</div>
    <div>.pt-lg-18 {</div>
        <div>padding-top: 18px!important;</div>
    <div>}</div>
    <div>.pb-lg-18 {</div>
        <div>padding-bottom: 18px!important;</div>
    <div>}</div>
    <div>.p-lg-20 {</div>
        <div>padding: 20px!important;</div>
    <div>}</div>
    <div>.ps-lg-20 {</div>
        <div>padding-left: 20px!important;</div>
    <div>}</div>
    <div>.pe-lg-20 {</div>
        <div>padding-right: 20px!important;</div>
    <div>}</div>
    <div>.pt-lg-20 {</div>
        <div>padding-top: 20px!important;</div>
    <div>}</div>
    <div>.pb-lg-20 {</div>
        <div>padding-bottom: 20px!important;</div>
    <div>}</div>
    <div>.p-lg-25 {</div>
        <div>padding: 25px!important;</div>
    <div>}</div>
    <div>.ps-lg-25 {</div>
        <div>padding-left: 25px!important;</div>
    <div>}</div>
    <div>.pe-lg-25 {</div>
        <div>padding-right: 25px!important;</div>
    <div>}</div>
    <div>.pt-lg-25 {</div>
        <div>padding-top: 25px!important;</div>
    <div>}</div>
    <div>.pb-lg-25 {</div>
        <div>padding-bottom: 25px!important;</div>
    <div>}</div>
    <div>.p-lg-30 {</div>
        <div>padding: 30px!important;</div>
    <div>}</div>
    <div>.ps-lg-30 {</div>
        <div>padding-left: 30px!important;</div>
    <div>}</div>
    <div>.pe-lg-30 {</div>
        <div>padding-right: 30px!important;</div>
    <div>}</div>
    <div>.pt-lg-30 {</div>
        <div>padding-top: 30px!important;</div>
    <div>}</div>
    <div>.pb-lg-30 {</div>
        <div>padding-bottom: 30px!important;</div>
    <div>}</div>
    <div>.p-lg-35 {</div>
        <div>padding: 35px!important;</div>
    <div>}</div>
    <div>.ps-lg-35 {</div>
        <div>padding-left: 35px!important;</div>
    <div>}</div>
    <div>.pe-lg-35 {</div>
        <div>padding-right: 35px!important;</div>
    <div>}</div>
    <div>.pt-lg-35 {</div>
        <div>padding-top: 35px!important;</div>
    <div>}</div>
    <div>.pb-lg-35 {</div>
        <div>padding-bottom: 35px!important;</div>
    <div>}</div>
    <div>.p-lg-40 {</div>
        <div>padding: 40px!important;</div>
    <div>}</div>
    <div>.ps-lg-40 {</div>
        <div>padding-left: 40px!important;</div>
    <div>}</div>
    <div>.pe-lg-40 {</div>
        <div>padding-right: 40px!important;</div>
    <div>}</div>
    <div>.pt-lg-40 {</div>
        <div>padding-top: 40px!important;</div>
    <div>}</div>
    <div>.pb-lg-40 {</div>
        <div>padding-bottom: 40px!important;</div>
    <div>}</div>
    <div>.p-lg-45 {</div>
        <div>padding: 45px!important;</div>
    <div>}</div>
    <div>.ps-lg-45 {</div>
        <div>padding-left: 45px!important;</div>
    <div>}</div>
    <div>.pe-lg-45 {</div>
        <div>padding-right: 45px!important;</div>
    <div>}</div>
    <div>.pt-lg-45 {</div>
        <div>padding-top: 45px!important;</div>
    <div>}</div>
    <div>.pb-lg-45 {</div>
        <div>padding-bottom: 45px!important;</div>
    <div>}</div>
    <div>.p-lg-50 {</div>
        <div>padding: 50px!important;</div>
    <div>}</div>
    <div>.ps-lg-50 {</div>
        <div>padding-left: 50px!important;</div>
    <div>}</div>
    <div>.pe-lg-50 {</div>
        <div>padding-right: 50px!important;</div>
    <div>}</div>
    <div>.pt-lg-50 {</div>
        <div>padding-top: 50px!important;</div>
    <div>}</div>
    <div>.pb-lg-50 {</div>
        <div>padding-bottom: 50px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 1200px) {</div>

    <div>// Padding</div>
    <div>.p-xl-0 {</div>
        <div>padding: 0!important;</div>
    <div>}</div>
    <div>.ps-xl-0 {</div>
        <div>padding-left: 0!important;</div>
    <div>}</div>
    <div>.pe-xl-0 {</div>
        <div>padding-right: 0!important;</div>
    <div>}</div>
    <div>.pt-xl-0 {</div>
        <div>padding-top: 0!important;</div>
    <div>}</div>
    <div>.pb-xl-0 {</div>
        <div>padding-bottom: 0!important;</div>
    <div>}</div>
    <div>.p-xl-1 {</div>
        <div>padding: .25rem!important;</div>
    <div>}</div>
    <div>.ps-xl-1 {</div>
        <div>padding-left: .25rem!important;</div>
    <div>}</div>
    <div>.pe-xl-1 {</div>
        <div>padding-right: .25rem!important;</div>
    <div>}</div>
    <div>.pt-xl-1 {</div>
        <div>padding-top: .25rem!important;</div>
    <div>}</div>
    <div>.pb-xl-1 {</div>
        <div>padding-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.p-xl-2 {</div>
        <div>padding: .5rem!important;</div>
    <div>}</div>
    <div>.ps-xl-2 {</div>
        <div>padding-left: .5rem!important;</div>
    <div>}</div>
    <div>.pe-xl-2 {</div>
        <div>padding-right: .5rem!important;</div>
    <div>}</div>
    <div>.pt-xl-2 {</div>
        <div>padding-top: .5rem!important;</div>
    <div>}</div>
    <div>.pb-xl-2 {</div>
        <div>padding-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.p-xl-3 {</div>
        <div>padding: 1rem!important;</div>
    <div>}</div>
    <div>.ps-xl-3 {</div>
        <div>padding-left: 1rem!important;</div>
    <div>}</div>
    <div>.pe-xl-3 {</div>
        <div>padding-right: 1rem!important;</div>
    <div>}</div>
    <div>.pt-xl-3 {</div>
        <div>padding-top: 1rem!important;</div>
    <div>}</div>
    <div>.pb-xl-3 {</div>
        <div>padding-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.p-xl-4 {</div>
        <div>padding: 1.5rem!important;</div>
    <div>}</div>
    <div>.ps-xl-4 {</div>
        <div>padding-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.pe-xl-4 {</div>
        <div>padding-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.pt-xl-4 {</div>
        <div>padding-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.pb-xl-4 {</div>
        <div>padding-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.p-xl-5 {</div>
        <div>padding: 5px!important;</div>
    <div>}</div>
    <div>.ps-xl-5 {</div>
        <div>padding-left: 5px!important;</div>
    <div>}</div>
    <div>.pe-xl-5 {</div>
        <div>padding-right: 5px!important;</div>
    <div>}</div>
    <div>.pt-xl-5 {</div>
        <div>padding-top: 5px!important;</div>
    <div>}</div>
    <div>.pb-xl-5 {</div>
        <div>padding-bottom: 5px!important;</div>
    <div>}</div>
    <div>.p-xl-8 {</div>
        <div>padding: 8px!important;</div>
    <div>}</div>
    <div>.ps-xl-8 {</div>
        <div>padding-left: 8px!important;</div>
    <div>}</div>
    <div>.pe-xl-8 {</div>
        <div>padding-right: 8px!important;</div>
    <div>}</div>
    <div>.pt-xl-8 {</div>
        <div>padding-top: 8px!important;</div>
    <div>}</div>
    <div>.pb-xl-8 {</div>
        <div>padding-bottom: 8px!important;</div>
    <div>}</div>
    <div>.p-xl-10 {</div>
        <div>padding: 10px!important;</div>
    <div>}</div>
    <div>.ps-xl-10 {</div>
        <div>padding-left: 10px!important;</div>
    <div>}</div>
    <div>.pe-xl-10 {</div>
        <div>padding-right: 10px!important;</div>
    <div>}</div>
    <div>.pt-xl-10 {</div>
        <div>padding-top: 10px!important;</div>
    <div>}</div>
    <div>.pb-xl-10 {</div>
        <div>padding-bottom: 10px!important;</div>
    <div>}</div>
    <div>.p-xl-11 {</div>
        <div>padding: 11px!important;</div>
    <div>}</div>
    <div>.ps-xl-11 {</div>
        <div>padding-left: 11px!important;</div>
    <div>}</div>
    <div>.pe-xl-11 {</div>
        <div>padding-right: 11px!important;</div>
    <div>}</div>
    <div>.pt-xl-11 {</div>
        <div>padding-top: 11px!important;</div>
    <div>}</div>
    <div>.pb-xl-11 {</div>
        <div>padding-bottom: 11px!important;</div>
    <div>}</div>
    <div>.p-xl-12 {</div>
        <div>padding: 12px!important;</div>
    <div>}</div>
    <div>.ps-xl-12 {</div>
        <div>padding-left: 12px!important;</div>
    <div>}</div>
    <div>.pe-xl-12 {</div>
        <div>padding-right: 12px!important;</div>
    <div>}</div>
    <div>.pt-xl-12 {</div>
        <div>padding-top: 12px!important;</div>
    <div>}</div>
    <div>.pb-xl-12 {</div>
        <div>padding-bottom: 12px!important;</div>
    <div>}</div>
    <div>.p-xl-15 {</div>
        <div>padding: 15px!important;</div>
    <div>}</div>
    <div>.ps-xl-15 {</div>
        <div>padding-left: 15px!important;</div>
    <div>}</div>
    <div>.pe-xl-15 {</div>
        <div>padding-right: 15px!important;</div>
    <div>}</div>
    <div>.pt-xl-15 {</div>
        <div>padding-top: 15px!important;</div>
    <div>}</div>
    <div>.pb-xl-15 {</div>
        <div>padding-bottom: 15px!important;</div>
    <div>}</div>
    <div>.pt-xl-18 {</div>
        <div>padding-top: 18px!important;</div>
    <div>}</div>
    <div>.pb-xl-18 {</div>
        <div>padding-bottom: 18px!important;</div>
    <div>}</div>
    <div>.p-xl-20 {</div>
        <div>padding: 20px!important;</div>
    <div>}</div>
    <div>.ps-xl-20 {</div>
        <div>padding-left: 20px!important;</div>
    <div>}</div>
    <div>.pe-xl-20 {</div>
        <div>padding-right: 20px!important;</div>
    <div>}</div>
    <div>.pt-xl-20 {</div>
        <div>padding-top: 20px!important;</div>
    <div>}</div>
    <div>.pb-xl-20 {</div>
        <div>padding-bottom: 20px!important;</div>
    <div>}</div>
    <div>.p-xl-25 {</div>
        <div>padding: 25px!important;</div>
    <div>}</div>
    <div>.ps-xl-25 {</div>
        <div>padding-left: 25px!important;</div>
    <div>}</div>
    <div>.pe-xl-25 {</div>
        <div>padding-right: 25px!important;</div>
    <div>}</div>
    <div>.pt-xl-25 {</div>
        <div>padding-top: 25px!important;</div>
    <div>}</div>
    <div>.pb-xl-25 {</div>
        <div>padding-bottom: 25px!important;</div>
    <div>}</div>
    <div>.p-xl-30 {</div>
        <div>padding: 30px!important;</div>
    <div>}</div>
    <div>.ps-xl-30 {</div>
        <div>padding-left: 30px!important;</div>
    <div>}</div>
    <div>.pe-xl-30 {</div>
        <div>padding-right: 30px!important;</div>
    <div>}</div>
    <div>.pt-xl-30 {</div>
        <div>padding-top: 30px!important;</div>
    <div>}</div>
    <div>.pb-xl-30 {</div>
        <div>padding-bottom: 30px!important;</div>
    <div>}</div>
    <div>.p-xl-35 {</div>
        <div>padding: 35px!important;</div>
    <div>}</div>
    <div>.ps-xl-35 {</div>
        <div>padding-left: 35px!important;</div>
    <div>}</div>
    <div>.pe-xl-35 {</div>
        <div>padding-right: 35px!important;</div>
    <div>}</div>
    <div>.pt-xl-35 {</div>
        <div>padding-top: 35px!important;</div>
    <div>}</div>
    <div>.pb-xl-35 {</div>
        <div>padding-bottom: 35px!important;</div>
    <div>}</div>
    <div>.p-xl-40 {</div>
        <div>padding: 40px!important;</div>
    <div>}</div>
    <div>.ps-xl-40 {</div>
        <div>padding-left: 40px!important;</div>
    <div>}</div>
    <div>.pe-xl-40 {</div>
        <div>padding-right: 40px!important;</div>
    <div>}</div>
    <div>.pt-xl-40 {</div>
        <div>padding-top: 40px!important;</div>
    <div>}</div>
    <div>.pb-xl-40 {</div>
        <div>padding-bottom: 40px!important;</div>
    <div>}</div>
    <div>.p-xl-45 {</div>
        <div>padding: 45px!important;</div>
    <div>}</div>
    <div>.ps-xl-45 {</div>
        <div>padding-left: 45px!important;</div>
    <div>}</div>
    <div>.pe-xl-45 {</div>
        <div>padding-right: 45px!important;</div>
    <div>}</div>
    <div>.pt-xl-45 {</div>
        <div>padding-top: 45px!important;</div>
    <div>}</div>
    <div>.pb-xl-45 {</div>
        <div>padding-bottom: 45px!important;</div>
    <div>}</div>
    <div>.p-xl-50 {</div>
        <div>padding: 50px!important;</div>
    <div>}</div>
    <div>.ps-xl-50 {</div>
        <div>padding-left: 50px!important;</div>
    <div>}</div>
    <div>.pe-xl-50 {</div>
        <div>padding-right: 50px!important;</div>
    <div>}</div>
    <div>.pt-xl-50 {</div>
        <div>padding-top: 50px!important;</div>
    <div>}</div>
    <div>.pb-xl-50 {</div>
        <div>padding-bottom: 50px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 1400px) {</div>

    <div>// Padding</div>
    <div>.p-xxl-0 {</div>
        <div>padding: 0!important;</div>
    <div>}</div>
    <div>.ps-xxl-0 {</div>
        <div>padding-left: 0!important;</div>
    <div>}</div>
    <div>.pe-xxl-0 {</div>
        <div>padding-right: 0!important;</div>
    <div>}</div>
    <div>.pt-xxl-0 {</div>
        <div>padding-top: 0!important;</div>
    <div>}</div>
    <div>.pb-xxl-0 {</div>
        <div>padding-bottom: 0!important;</div>
    <div>}</div>
    <div>.p-xxl-1 {</div>
        <div>padding: .25rem!important;</div>
    <div>}</div>
    <div>.ps-xxl-1 {</div>
        <div>padding-left: .25rem!important;</div>
    <div>}</div>
    <div>.pe-xxl-1 {</div>
        <div>padding-right: .25rem!important;</div>
    <div>}</div>
    <div>.pt-xxl-1 {</div>
        <div>padding-top: .25rem!important;</div>
    <div>}</div>
    <div>.pb-xxl-1 {</div>
        <div>padding-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.p-xxl-2 {</div>
        <div>padding: .5rem!important;</div>
    <div>}</div>
    <div>.ps-xxl-2 {</div>
        <div>padding-left: .5rem!important;</div>
    <div>}</div>
    <div>.pe-xxl-2 {</div>
        <div>padding-right: .5rem!important;</div>
    <div>}</div>
    <div>.pt-xxl-2 {</div>
        <div>padding-top: .5rem!important;</div>
    <div>}</div>
    <div>.pb-xxl-2 {</div>
        <div>padding-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.p-xxl-3 {</div>
        <div>padding: 1rem!important;</div>
    <div>}</div>
    <div>.ps-xxl-3 {</div>
        <div>padding-left: 1rem!important;</div>
    <div>}</div>
    <div>.pe-xxl-3 {</div>
        <div>padding-right: 1rem!important;</div>
    <div>}</div>
    <div>.pt-xxl-3 {</div>
        <div>padding-top: 1rem!important;</div>
    <div>}</div>
    <div>.pb-xxl-3 {</div>
        <div>padding-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.p-xxl-4 {</div>
        <div>padding: 1.5rem!important;</div>
    <div>}</div>
    <div>.ps-xxl-4 {</div>
        <div>padding-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.pe-xxl-4 {</div>
        <div>padding-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.pt-xxl-4 {</div>
        <div>padding-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.pb-xxl-4 {</div>
        <div>padding-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.p-xxl-5 {</div>
        <div>padding: 5px!important;</div>
    <div>}</div>
    <div>.ps-xxl-5 {</div>
        <div>padding-left: 5px!important;</div>
    <div>}</div>
    <div>.pe-xxl-5 {</div>
        <div>padding-right: 5px!important;</div>
    <div>}</div>
    <div>.pt-xxl-5 {</div>
        <div>padding-top: 5px!important;</div>
    <div>}</div>
    <div>.pb-xxl-5 {</div>
        <div>padding-bottom: 5px!important;</div>
    <div>}</div>
    <div>.p-xxl-8 {</div>
        <div>padding: 8px!important;</div>
    <div>}</div>
    <div>.ps-xxl-8 {</div>
        <div>padding-left: 8px!important;</div>
    <div>}</div>
    <div>.pe-xxl-8 {</div>
        <div>padding-right: 8px!important;</div>
    <div>}</div>
    <div>.pt-xxl-8 {</div>
        <div>padding-top: 8px!important;</div>
    <div>}</div>
    <div>.pb-xxl-8 {</div>
        <div>padding-bottom: 8px!important;</div>
    <div>}</div>
    <div>.p-xxl-10 {</div>
        <div>padding: 10px!important;</div>
    <div>}</div>
    <div>.ps-xxl-10 {</div>
        <div>padding-left: 10px!important;</div>
    <div>}</div>
    <div>.pe-xxl-10 {</div>
        <div>padding-right: 10px!important;</div>
    <div>}</div>
    <div>.pt-xxl-10 {</div>
        <div>padding-top: 10px!important;</div>
    <div>}</div>
    <div>.pb-xxl-10 {</div>
        <div>padding-bottom: 10px!important;</div>
    <div>}</div>
    <div>.p-xxl-11 {</div>
        <div>padding: 11px!important;</div>
    <div>}</div>
    <div>.ps-xxl-11 {</div>
        <div>padding-left: 11px!important;</div>
    <div>}</div>
    <div>.pe-xxl-11 {</div>
        <div>padding-right: 11px!important;</div>
    <div>}</div>
    <div>.pt-xxl-11 {</div>
        <div>padding-top: 11px!important;</div>
    <div>}</div>
    <div>.pb-xxl-11 {</div>
        <div>padding-bottom: 11px!important;</div>
    <div>}</div>
    <div>.p-xxl-12 {</div>
        <div>padding: 12px!important;</div>
    <div>}</div>
    <div>.ps-xxl-12 {</div>
        <div>padding-left: 12px!important;</div>
    <div>}</div>
    <div>.pe-xxl-12 {</div>
        <div>padding-right: 12px!important;</div>
    <div>}</div>
    <div>.pt-xxl-12 {</div>
        <div>padding-top: 12px!important;</div>
    <div>}</div>
    <div>.pb-xxl-12 {</div>
        <div>padding-bottom: 12px!important;</div>
    <div>}</div>
    <div>.p-xxl-15 {</div>
        <div>padding: 15px!important;</div>
    <div>}</div>
    <div>.ps-xxl-15 {</div>
        <div>padding-left: 15px!important;</div>
    <div>}</div>
    <div>.pe-xxl-15 {</div>
        <div>padding-right: 15px!important;</div>
    <div>}</div>
    <div>.pt-xxl-15 {</div>
        <div>padding-top: 15px!important;</div>
    <div>}</div>
    <div>.pb-xxl-15 {</div>
        <div>padding-bottom: 15px!important;</div>
    <div>}</div>
    <div>.pt-xxl-18 {</div>
        <div>padding-top: 18px!important;</div>
    <div>}</div>
    <div>.pb-xxl-18 {</div>
        <div>padding-bottom: 18px!important;</div>
    <div>}</div>
    <div>.p-xxl-20 {</div>
        <div>padding: 20px!important;</div>
    <div>}</div>
    <div>.ps-xxl-20 {</div>
        <div>padding-left: 20px!important;</div>
    <div>}</div>
    <div>.pe-xxl-20 {</div>
        <div>padding-right: 20px!important;</div>
    <div>}</div>
    <div>.pt-xxl-20 {</div>
        <div>padding-top: 20px!important;</div>
    <div>}</div>
    <div>.pb-xxl-20 {</div>
        <div>padding-bottom: 20px!important;</div>
    <div>}</div>
    <div>.p-xxl-25 {</div>
        <div>padding: 25px!important;</div>
    <div>}</div>
    <div>.ps-xxl-25 {</div>
        <div>padding-left: 25px!important;</div>
    <div>}</div>
    <div>.pe-xxl-25 {</div>
        <div>padding-right: 25px!important;</div>
    <div>}</div>
    <div>.pt-xxl-25 {</div>
        <div>padding-top: 25px!important;</div>
    <div>}</div>
    <div>.pb-xxl-25 {</div>
        <div>padding-bottom: 25px!important;</div>
    <div>}</div>
    <div>.p-xxl-30 {</div>
        <div>padding: 30px!important;</div>
    <div>}</div>
    <div>.ps-xxl-30 {</div>
        <div>padding-left: 30px!important;</div>
    <div>}</div>
    <div>.pe-xxl-30 {</div>
        <div>padding-right: 30px!important;</div>
    <div>}</div>
    <div>.pt-xxl-30 {</div>
        <div>padding-top: 30px!important;</div>
    <div>}</div>
    <div>.pb-xxl-30 {</div>
        <div>padding-bottom: 30px!important;</div>
    <div>}</div>
    <div>.p-xxl-35 {</div>
        <div>padding: 35px!important;</div>
    <div>}</div>
    <div>.ps-xxl-35 {</div>
        <div>padding-left: 35px!important;</div>
    <div>}</div>
    <div>.pe-xxl-35 {</div>
        <div>padding-right: 35px!important;</div>
    <div>}</div>
    <div>.pt-xxl-35 {</div>
        <div>padding-top: 35px!important;</div>
    <div>}</div>
    <div>.pb-xxl-35 {</div>
        <div>padding-bottom: 35px!important;</div>
    <div>}</div>
    <div>.p-xxl-40 {</div>
        <div>padding: 40px!important;</div>
    <div>}</div>
    <div>.ps-xxl-40 {</div>
        <div>padding-left: 40px!important;</div>
    <div>}</div>
    <div>.pe-xxl-40 {</div>
        <div>padding-right: 40px!important;</div>
    <div>}</div>
    <div>.pt-xxl-40 {</div>
        <div>padding-top: 40px!important;</div>
    <div>}</div>
    <div>.pb-xxl-40 {</div>
        <div>padding-bottom: 40px!important;</div>
    <div>}</div>
    <div>.p-xxl-45 {</div>
        <div>padding: 45px!important;</div>
    <div>}</div>
    <div>.ps-xxl-45 {</div>
        <div>padding-left: 45px!important;</div>
    <div>}</div>
    <div>.pe-xxl-45 {</div>
        <div>padding-right: 45px!important;</div>
    <div>}</div>
    <div>.pt-xxl-45 {</div>
        <div>padding-top: 45px!important;</div>
    <div>}</div>
    <div>.pb-xxl-45 {</div>
        <div>padding-bottom: 45px!important;</div>
    <div>}</div>
    <div>.p-xxl-50 {</div>
        <div>padding: 50px!important;</div>
    <div>}</div>
    <div>.ps-xxl-50 {</div>
        <div>padding-left: 50px!important;</div>
    <div>}</div>
    <div>.pe-xxl-50 {</div>
        <div>padding-right: 50px!important;</div>
    <div>}</div>
    <div>.pt-xxl-50 {</div>
        <div>padding-top: 50px!important;</div>
    <div>}</div>
    <div>.pb-xxl-50 {</div>
        <div>padding-bottom: 50px!important;</div>
    <div>}</div>

<div>}</div>

<div>@media (min-width: 1600px) {</div>

    <div>// Padding</div>
    <div>.p-xxxl-0 {</div>
        <div>padding: 0!important;</div>
    <div>}</div>
    <div>.ps-xxxl-0 {</div>
        <div>padding-left: 0!important;</div>
    <div>}</div>
    <div>.pe-xxxl-0 {</div>
        <div>padding-right: 0!important;</div>
    <div>}</div>
    <div>.pt-xxxl-0 {</div>
        <div>padding-top: 0!important;</div>
    <div>}</div>
    <div>.pb-xxxl-0 {</div>
        <div>padding-bottom: 0!important;</div>
    <div>}</div>
    <div>.p-xxxl-1 {</div>
        <div>padding: .25rem!important;</div>
    <div>}</div>
    <div>.ps-xxxl-1 {</div>
        <div>padding-left: .25rem!important;</div>
    <div>}</div>
    <div>.pe-xxxl-1 {</div>
        <div>padding-right: .25rem!important;</div>
    <div>}</div>
    <div>.pt-xxxl-1 {</div>
        <div>padding-top: .25rem!important;</div>
    <div>}</div>
    <div>.pb-xxxl-1 {</div>
        <div>padding-bottom: .25rem!important;</div>
    <div>}</div>
    <div>.p-xxxl-2 {</div>
        <div>padding: .5rem!important;</div>
    <div>}</div>
    <div>.ps-xxxl-2 {</div>
        <div>padding-left: .5rem!important;</div>
    <div>}</div>
    <div>.pe-xxxl-2 {</div>
        <div>padding-right: .5rem!important;</div>
    <div>}</div>
    <div>.pt-xxxl-2 {</div>
        <div>padding-top: .5rem!important;</div>
    <div>}</div>
    <div>.pb-xxxl-2 {</div>
        <div>padding-bottom: .5rem!important;</div>
    <div>}</div>
    <div>.p-xxxl-3 {</div>
        <div>padding: 1rem!important;</div>
    <div>}</div>
    <div>.ps-xxxl-3 {</div>
        <div>padding-left: 1rem!important;</div>
    <div>}</div>
    <div>.pe-xxxl-3 {</div>
        <div>padding-right: 1rem!important;</div>
    <div>}</div>
    <div>.pt-xxxl-3 {</div>
        <div>padding-top: 1rem!important;</div>
    <div>}</div>
    <div>.pb-xxxl-3 {</div>
        <div>padding-bottom: 1rem!important;</div>
    <div>}</div>
    <div>.p-xxxl-4 {</div>
        <div>padding: 1.5rem!important;</div>
    <div>}</div>
    <div>.ps-xxxl-4 {</div>
        <div>padding-left: 1.5rem!important;</div>
    <div>}</div>
    <div>.pe-xxxl-4 {</div>
        <div>padding-right: 1.5rem!important;</div>
    <div>}</div>
    <div>.pt-xxxl-4 {</div>
        <div>padding-top: 1.5rem!important;</div>
    <div>}</div>
    <div>.pb-xxxl-4 {</div>
        <div>padding-bottom: 1.5rem!important;</div>
    <div>}</div>
    <div>.p-xxxl-5 {</div>
        <div>padding: 5px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-5 {</div>
        <div>padding-left: 5px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-5 {</div>
        <div>padding-right: 5px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-5 {</div>
        <div>padding-top: 5px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-5 {</div>
        <div>padding-bottom: 5px!important;</div>
    <div>}</div>
    <div>.p-xxxl-8 {</div>
        <div>padding: 8px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-8 {</div>
        <div>padding-left: 8px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-8 {</div>
        <div>padding-right: 8px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-8 {</div>
        <div>padding-top: 8px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-8 {</div>
        <div>padding-bottom: 8px!important;</div>
    <div>}</div>
    <div>.p-xxxl-10 {</div>
        <div>padding: 10px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-10 {</div>
        <div>padding-left: 10px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-10 {</div>
        <div>padding-right: 10px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-10 {</div>
        <div>padding-top: 10px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-10 {</div>
        <div>padding-bottom: 10px!important;</div>
    <div>}</div>
    <div>.p-xxxl-11 {</div>
        <div>padding: 11px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-11 {</div>
        <div>padding-left: 11px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-11 {</div>
        <div>padding-right: 11px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-11 {</div>
        <div>padding-top: 11px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-11 {</div>
        <div>padding-bottom: 11px!important;</div>
    <div>}</div>
    <div>.p-xxxl-12 {</div>
        <div>padding: 12px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-12 {</div>
        <div>padding-left: 12px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-12 {</div>
        <div>padding-right: 12px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-12 {</div>
        <div>padding-top: 12px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-12 {</div>
        <div>padding-bottom: 12px!important;</div>
    <div>}</div>
    <div>.p-xxxl-15 {</div>
        <div>padding: 15px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-15 {</div>
        <div>padding-left: 15px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-15 {</div>
        <div>padding-right: 15px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-15 {</div>
        <div>padding-top: 15px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-15 {</div>
        <div>padding-bottom: 15px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-18 {</div>
        <div>padding-top: 18px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-18 {</div>
        <div>padding-bottom: 18px!important;</div>
    <div>}</div>
    <div>.p-xxxl-20 {</div>
        <div>padding: 20px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-20 {</div>
        <div>padding-left: 20px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-20 {</div>
        <div>padding-right: 20px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-20 {</div>
        <div>padding-top: 20px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-20 {</div>
        <div>padding-bottom: 20px!important;</div>
    <div>}</div>
    <div>.p-xxxl-25 {</div>
        <div>padding: 25px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-25 {</div>
        <div>padding-left: 25px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-25 {</div>
        <div>padding-right: 25px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-25 {</div>
        <div>padding-top: 25px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-25 {</div>
        <div>padding-bottom: 25px!important;</div>
    <div>}</div>
    <div>.p-xxxl-30 {</div>
        <div>padding: 30px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-30 {</div>
        <div>padding-left: 30px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-30 {</div>
        <div>padding-right: 30px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-30 {</div>
        <div>padding-top: 30px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-30 {</div>
        <div>padding-bottom: 30px!important;</div>
    <div>}</div>
    <div>.p-xxxl-35 {</div>
        <div>padding: 35px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-35 {</div>
        <div>padding-left: 35px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-35 {</div>
        <div>padding-right: 35px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-35 {</div>
        <div>padding-top: 35px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-35 {</div>
        <div>padding-bottom: 35px!important;</div>
    <div>}</div>
    <div>.p-xxxl-40 {</div>
        <div>padding: 40px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-40 {</div>
        <div>padding-left: 40px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-40 {</div>
        <div>padding-right: 40px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-40 {</div>
        <div>padding-top: 40px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-40 {</div>
        <div>padding-bottom: 40px!important;</div>
    <div>}</div>
    <div>.p-xxxl-45 {</div>
        <div>padding: 45px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-45 {</div>
        <div>padding-left: 45px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-45 {</div>
        <div>padding-right: 45px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-45 {</div>
        <div>padding-top: 45px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-45 {</div>
        <div>padding-bottom: 45px!important;</div>
    <div>}</div>
    <div>.p-xxxl-50 {</div>
        <div>padding: 50px!important;</div>
    <div>}</div>
    <div>.ps-xxxl-50 {</div>
        <div>padding-left: 50px!important;</div>
    <div>}</div>
    <div>.pe-xxxl-50 {</div>
        <div>padding-right: 50px!important;</div>
    <div>}</div>
    <div>.pt-xxxl-50 {</div>
        <div>padding-top: 50px!important;</div>
    <div>}</div>
    <div>.pb-xxxl-50 {</div>
        <div>padding-bottom: 50px!important;</div>
    <div>}</div>

<div>}</div>
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <a
        href="https://getbootstrap.com/docs/5.3/utilities/spacing/"
        target="_blank"
        class="default-btn text-decoration-none transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success d-inline-block"
      >
        More About Spacing
      </a>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15">
        Responsive Breakpoint for This Template
      </h5>
      <div class="position-relative mt-20">
        <!-- <button class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0" data-clipboard-target="#responsiveBreakpointCode">
                            Copy
                        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="responsiveBreakpointCode">
<div>@media only screen and (max-width : 767px) {}</div>

<div>@media only screen and (min-width : 576px) and (max-width : 767px) {}</div>

<div>@media only screen and (min-width : 768px) and (max-width : 991px) {}</div>

<div>@media only screen and (min-width : 992px) and (max-width : 1199px) {}</div>

<div>@media only screen and (min-width: 1200px) and (max-width: 1399px) {}</div>

<div>@media only screen and (min-width: 1600px) {}</div>
</code>
</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpacingContent",
};
</script>