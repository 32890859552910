<template>
    <div class="row">
        <div class="col-xxl-6 col-sm-6">
            <Dropdown v-model="selectedAgents" :options="agents" optionLabel="nombre" optionValue="uuid_usuario"
                placeholder="Seleccione un agente" class="w-100" :filter="true" />
        </div>
        <div class="col-xxl-6 col-sm-6">
            <div class="input-group">
                <PrimeCalendar v-model="dates" selectionMode="range" placeholder="Inserta un rango de fechas"
                    class="w-100 shadow-none text-black rounded-0 border-0" :showIcon="true" appendTo="body" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { agentesItem } from "@/interfaces/interfaces";
import Control from "@/services/agentsService";
import Dropdown from "primevue/dropdown";

export default defineComponent({
    name: "AgentFilters",
    components: {
        Dropdown
    },
    setup(props, { emit }) {
        const agents = ref<agentesItem[]>([]);
        const selectedAgents = ref<agentesItem[]>([]);
        const uuidAgent = ref<agentesItem[]>([]);


        const getCurrentMonthRange = () => {
            const now = new Date();
            const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
            const lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            return [firstDay, lastDay];
        };


        const dates = ref(getCurrentMonthRange());


        const getAgentList = async () => {
            try {
                const response = await new Control().listGeneralAgents();
                if (!response.error) {
                    agents.value = response.datos;

                    console.log(agents.value);
                }
            } catch (error) {
                console.log(error);
            }
        }

        watch(
            () => selectedAgents.value,
            (newValue) => {
                uuidAgent.value = newValue;
                emitFilterChange(); 
            },
            { deep: true }
        );

        watch(
            () => dates.value,
            (newValue) => {
                emitFilterChange();
            }
        );

        // Función para emitir el evento con los datos necesarios
        const emitFilterChange = () => {
            emit("filterChange", {
                selectedAgents: uuidAgent.value,
                dateRange: dates.value
            });
        };


        onMounted(async () => {
            await getAgentList();
        })

        return {
            getAgentList,
            agents,
            selectedAgents,
            getCurrentMonthRange,
            dates
        }
    }
});
</script>