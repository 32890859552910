import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white daily-sales-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis" }, " AVERAGE DAILY SALES ", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "fw-black mb-12 lh-1" }, "$5,302", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-bold text-success text-badge d-inline-block" }, [
        _createTextVNode(" 3.9% "),
        _createElementVNode("i", { class: "flaticon-up-arrow fs-11 lh-1 position-relative top-1" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_apexchart, {
          type: "bar",
          height: "150",
          width: "220",
          id: "averageDailySalesChart",
          options: _ctx.averageDailySalesChart,
          series: _ctx.sales
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}