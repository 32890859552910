<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-md-20 mb-xl-25">
            Card Placeholder
          </h5>
          <div class="row">
            <div class="col-sm-6">
              <div class="card" aria-hidden="true">
                <img
                  src="../../../assets/images/gallery/gallery1.jpg"
                  class="card-img-top"
                  alt="card-image"
                />
                <div class="card-body">
                  <h5 class="card-title fw-bold">Card title</h5>
                  <p
                    class="card-text fs-md-15 fs-lg-16 text-paragraph lh-base mb-15"
                  >
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <a href="#" class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mt-25 mt-sm-0">
              <div class="card" aria-hidden="true">
                <img
                  src="../../../assets/images/gallery/gallery1.jpg"
                  class="card-img-top"
                  alt="card-image"
                />
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <a class="btn btn-primary disabled placeholder col-6"></a>
                </div>
              </div>
            </div>
          </div>
          <div class="position-relative mt-25">
            <!-- <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#cardPlaceholderCode"
            >
              Copy
            </button> -->
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="cardPlaceholderCode">
<div>&lt;div class="card"&gt;</div>
    <div>&lt;img src="..." class="card-img-top" alt="..."&gt;</div>
    <div>&lt;div class="card-body"&gt;</div>
        <div>&lt;h5 class="card-title"&gt;Card title&lt;/h5&gt;</div>
        <div>&lt;p class="card-text"&gt;Some quick example text to build on the card title and make up the bulk of the card's content.&lt;/p&gt;</div>
        <div>&lt;a href="#" class="btn btn-primary"&gt;Go somewhere&lt;/a&gt;</div>
    <div>&lt;/div&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="card" aria-hidden="true"&gt;</div>
    <div>&lt;img src="..." class="card-img-top" alt="..."&gt;</div>
    <div>&lt;div class="card-body"&gt;</div>
        <div>&lt;h5 class="card-title placeholder-glow"&gt;</div>
            <div>&lt;span class="placeholder col-6"&gt;&lt;/span&gt;</div>
        <div>&lt;/h5&gt;</div>
        <div>&lt;p class="card-text placeholder-glow"&gt;</div>
            <div>&lt;span class="placeholder col-7"&gt;&lt;/span&gt;</div>
            <div>&lt;span class="placeholder col-4"&gt;&lt;/span&gt;</div>
            <div>&lt;span class="placeholder col-4"&gt;&lt;/span&gt;</div>
            <div>&lt;span class="placeholder col-6"&gt;&lt;/span&gt;</div>
            <div>&lt;span class="placeholder col-8"&gt;&lt;/span&gt;</div>
        <div>&lt;/p&gt;</div>
        <div>&lt;a class="btn btn-primary disabled placeholder col-6"&gt;&lt;/a&gt;</div>
    <div>&lt;/div&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-md-20">Color Placeholder</h5>
          <span class="placeholder col-12"></span>
          <span class="placeholder col-12 bg-primary"></span>
          <span class="placeholder col-12 bg-secondary"></span>
          <span class="placeholder col-12 bg-success"></span>
          <span class="placeholder col-12 bg-danger"></span>
          <span class="placeholder col-12 bg-warning"></span>
          <span class="placeholder col-12 bg-info"></span>
          <span class="placeholder col-12 bg-light"></span>
          <span class="placeholder col-12 bg-dark"></span>
          <div class="position-relative mt-25">
            <!-- <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#colorPlaceholderCode"
            >
              Copy
            </button> -->
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="colorPlaceholderCode">
<div>&lt;span class="placeholder col-12"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 bg-primary"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 bg-secondary"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 bg-success"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 bg-danger"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 bg-warning"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 bg-info"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 bg-light"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 bg-dark"&gt;&lt;/span&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-md-20">Sizing Placeholder</h5>
          <span class="placeholder col-12 placeholder-lg"></span>
          <span class="placeholder col-12"></span>
          <span class="placeholder col-12 placeholder-sm"></span>
          <span class="placeholder col-12 placeholder-xs"></span>
          <div class="position-relative mt-25">
            <!-- <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#sizingPlaceholderCode"
            >
              Copy
            </button> -->
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="sizingPlaceholderCode">
<div>&lt;span class="placeholder col-12 placeholder-lg"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 placeholder-sm"&gt;&lt;/span&gt;</div>
<div>&lt;span class="placeholder col-12 placeholder-xs"&gt;&lt;/span&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-md-20">
            Animation Placeholder
          </h5>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
          <p class="placeholder-wave mb-0">
            <span class="placeholder col-12"></span>
          </p>
          <div class="position-relative mt-25">
            <!-- <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#animationPlaceholderCode"
            >
              Copy
            </button> -->
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="animationPlaceholderCode">
<div>&lt;p class="placeholder-glow"&gt;</div>
    <div>&lt;span class="placeholder col-12"&gt;&lt;/span&gt;</div>
<div>&lt;/p&gt;</div>

<div>&lt;p class="placeholder-wave"&gt;</div>
    <div>&lt;span class="placeholder col-12"&gt;&lt;/span&gt;</div>
<div>&lt;/p&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlaceholdersContent",
};
</script>