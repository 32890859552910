<template>
  <div class="row">
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white pricing-card">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div
            class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
          >
            <div class="price">
              <div class="text-black fw-black lh-1 mb-5">
                $ 00
                <span class="text-muted fw-normal fs-14">/ Month</span>
              </div>
              <span class="d-block text-muted">
                A simple start for everyone
              </span>
            </div>
            <span
              class="d-inline-block bg-primary text-white fs-13 fw-medium ps-12 pe-12 pt-1 pb-1 rounded-1"
            >
              Free
            </span>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This event combines all the data and back end information to the
            frontend. Organized by EnvyTheme.
          </p>
          <ul class="ps-0 mb-0 list-unstyled mt-15 mt-sm-20 mt-md-25">
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Complete beginner to expert AI skill
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Using ChatGPT for coding
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Real world solution
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              How to optimize your AI
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-close"></i>
              How to start building AI
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-close"></i>
              How to merge AI with OpenAI Gym
            </li>
          </ul>
          <a
            href="javascript:void(0);"
            class="link-btn ps-15 pe-15 pt-12 pb-12 ps-md-20 pe-md-20 pt-md-15 pb-md-15 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary mt-15 mt-sm-20 mt-md-25 mt-lg-30 fs-md-15 fs-lg-16"
          >
            Get Started Now
            <i class="ph ph-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white pricing-card">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div
            class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
          >
            <div class="price">
              <div class="text-black fw-black lh-1 mb-5">
                $ 19.99
                <span class="text-muted fw-normal fs-14">/ Month</span>
              </div>
              <span class="d-block text-muted">
                A simple start for everyone
              </span>
            </div>
            <span
              class="d-inline-block bg-success text-white fs-13 fw-medium ps-12 pe-12 pt-1 pb-1 rounded-1"
            >
              Basic
            </span>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This event combines all the data and back end information to the
            frontend. Organized by EnvyTheme.
          </p>
          <ul class="ps-0 mb-0 list-unstyled mt-15 mt-sm-20 mt-md-25">
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Complete beginner to expert AI skill
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Using ChatGPT for coding
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Real world solution
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              How to optimize your AI
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              How to start building AI
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-close"></i>
              How to merge AI with OpenAI Gym
            </li>
          </ul>
          <a
            href="javascript:void(0);"
            class="link-btn ps-15 pe-15 pt-12 pb-12 ps-md-20 pe-md-20 pt-md-15 pb-md-15 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary mt-15 mt-sm-20 mt-md-25 mt-lg-30 fs-md-15 fs-lg-16"
          >
            Get Started Now
            <i class="ph ph-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white pricing-card">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div
            class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
          >
            <div class="price">
              <div class="text-black fw-black lh-1 mb-5">
                $ 59.99
                <span class="text-muted fw-normal fs-14">/ Month</span>
              </div>
              <span class="d-block text-muted">
                A simple start for everyone
              </span>
            </div>
            <span
              class="d-inline-block bg-info text-white fs-13 fw-medium ps-12 pe-12 pt-1 pb-1 rounded-1"
            >
              Enterprise
            </span>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This event combines all the data and back end information to the
            frontend. Organized by EnvyTheme.
          </p>
          <ul class="ps-0 mb-0 list-unstyled mt-15 mt-sm-20 mt-md-25">
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Complete beginner to expert AI skill
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Using ChatGPT for coding
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Real world solution
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              How to optimize your AI
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              How to start building AI
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-close"></i>
              How to merge AI with OpenAI Gym
            </li>
          </ul>
          <a
            href="javascript:void(0);"
            class="link-btn ps-15 pe-15 pt-12 pb-12 ps-md-20 pe-md-20 pt-md-15 pb-md-15 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary mt-15 mt-sm-20 mt-md-25 mt-lg-30 fs-md-15 fs-lg-16"
          >
            Get Started Now
            <i class="ph ph-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white pricing-card">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div
            class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
          >
            <div class="price">
              <div class="text-black fw-black lh-1 mb-5">
                $ 99.99
                <span class="text-muted fw-normal fs-14">/ Month</span>
              </div>
              <span class="d-block text-muted">
                A simple start for everyone
              </span>
            </div>
            <span
              class="d-inline-block bg-danger text-white fs-13 fw-medium ps-12 pe-12 pt-1 pb-1 rounded-1"
            >
              Business
            </span>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This event combines all the data and back end information to the
            frontend. Organized by EnvyTheme.
          </p>
          <ul class="ps-0 mb-0 list-unstyled mt-15 mt-sm-20 mt-md-25">
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Complete beginner to expert AI skill
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Using ChatGPT for coding
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              Real world solution
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              How to optimize your AI
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              How to start building AI
            </li>
            <li
              class="text-paragraph fs-md-15 fs-lg-16 fw-medium position-relative"
            >
              <i class="flaticon-check-mark-1"></i>
              How to merge AI with OpenAI Gym
            </li>
          </ul>
          <a
            href="javascript:void(0);"
            class="link-btn ps-15 pe-15 pt-12 pb-12 ps-md-20 pe-md-20 pt-md-15 pb-md-15 d-flex align-items-center justify-content-between w-100 text-decoration-none fw-medium text-primary mt-15 mt-sm-20 mt-md-25 mt-lg-30 fs-md-15 fs-lg-16"
          >
            Get Started Now
            <i class="ph ph-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingContent",
};
</script>