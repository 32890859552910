<template>
    <div class="row">
      <div class="col-md-12">
        <ActualizarVehicle :uuid="uuid" />
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from "vue";
  import ActualizarVehicle from "@/components/Vehiculos/ActualizarVehicle.vue";
  import { useRoute } from 'vue-router';
  
  export default defineComponent({
    name: "ActualizarVehicleComponent",
    components: {
      ActualizarVehicle,
    },
    setup() {
      const route = useRoute();
      const uuid = ref<string | undefined>(undefined);
  
      if (route.params.uuid) {
        uuid.value = Array.isArray(route.params.uuid) ? route.params.uuid[0] : route.params.uuid;
      }
  
      return {
        uuid,
      };
  
    },
  });
  </script>