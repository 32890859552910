import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white website-visitors" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = {
  id: "websiteVisitorsChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"mb-lg-15 d-sm-flex align-items-center justify-content-between\"><h6 class=\"card-title fw-bold mb-0\">Website Visitors</h6><div class=\"card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><span class=\"fw-medium text-muted me-8\">Last</span><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option value=\"1\" class=\"fw-medium\">1 Month</option><option value=\"2\" class=\"fw-medium\">2 Months</option><option value=\"3\" class=\"fw-medium\">3 Months</option><option value=\"4\" class=\"fw-medium\">4 Months</option><option value=\"5\" class=\"fw-medium\">5 Months</option><option value=\"6\" class=\"fw-medium\">6 Months</option><option value=\"7\" class=\"fw-medium\">7 Months</option><option value=\"8\" class=\"fw-medium\" selected>8 Months</option><option value=\"9\" class=\"fw-medium\">9 Months</option></select></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_apexchart, {
          type: "bar",
          height: "385",
          options: _ctx.websiteVisitorsChart,
          series: _ctx.website
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}