import control from "./apiService";

export default class Agent {
    async listGeneralAgents() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-usuarios-agentes",
        );
    }


    async listData(uuid, init, finit) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-facturas-agentes",
            {
                uuidUser: [uuid],
                finit: init,
                fend: finit
            }
        );
    }

    async sendFile64(data) {
        return await new control().fetchData(
            "/puerta-enlace/85%20YV_01/cmc24/api/YC_dk038/yetox/v1/cargar-ruteo",
            data
        );
    }
}
