import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-12" }
const _hoisted_3 = { class: "col-lg-6" }
const _hoisted_4 = { class: "col-lg-6" }
const _hoisted_5 = { class: "card mb-25 border-0 rounded-0 bg-white sales-pos-location-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_WebsiteVisitors = _resolveComponent("WebsiteVisitors")!
  const _component_ProjectDistribution = _resolveComponent("ProjectDistribution")!
  const _component_UnresolvedTickets = _resolveComponent("UnresolvedTickets")!
  const _component_PerformanceChart = _resolveComponent("PerformanceChart")!
  const _component_ClientPaymentStatus = _resolveComponent("ClientPaymentStatus")!
  const _component_SalesByPOSLocation = _resolveComponent("SalesByPOSLocation")!
  const _component_TaskOverview = _resolveComponent("TaskOverview")!
  const _component_NumberOfTickets = _resolveComponent("NumberOfTickets")!
  const _component_CustomerSatisfaction = _resolveComponent("CustomerSatisfaction")!
  const _component_CampaignsChart = _resolveComponent("CampaignsChart")!
  const _component_AverageEnrollmentRate = _resolveComponent("AverageEnrollmentRate")!
  const _component_WeeklySales = _resolveComponent("WeeklySales")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Charts" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_WebsiteVisitors),
        _createVNode(_component_ProjectDistribution),
        _createVNode(_component_UnresolvedTickets),
        _createVNode(_component_PerformanceChart),
        _createVNode(_component_ClientPaymentStatus)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SalesByPOSLocation),
        _createVNode(_component_TaskOverview),
        _createVNode(_component_NumberOfTickets),
        _createVNode(_component_CustomerSatisfaction),
        _createVNode(_component_CampaignsChart),
        _createVNode(_component_AverageEnrollmentRate)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_WeeklySales)
        ])
      ])
    ])
  ], 64))
}