import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white expected-earnings-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "fw-black mb-12 lh-1" }
const _hoisted_4 = { class: "list ps-0 mb-0 list-unstyled mt-15" }
const _hoisted_5 = { class: "text-muted position-relative fw-medium fs-15" }
const _hoisted_6 = { class: "text-black fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "d-block mb-6 fs-15 text-uppercase fw-medium fw-black" }, " CONTADO ", -1)),
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.sum_general_litros || 0) + " L", 1),
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-bold text-success text-badge d-inline-block" }, null, -1)),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          _cache[0] || (_cache[0] = _createTextVNode(" Cantidad de ventas - ")),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.cantidad_facturas || 0), 1)
        ])
      ])
    ])
  ]))
}