<template>
    <DialogComponent v-model:visible="localVisible" :style="{ width: '35rem' }">
        <template #header>
            <div class="pt-20">
                <h5 class="card-subtitle fw-bold">Toma de muestras</h5>
            </div>
        </template>
        <div class="row">
            <div class="mb-3 col-6">
                <label for="fechaInicio" class="form-label fw-medium">Fecha Inicio:</label>
                <input type="text" class="form-control" id="fechaInicio" :value="formattedFecha" readonly />
            </div>

            <div class="mb-3 col-6">
                <label for="horaInicio" class="form-label fw-medium">Hora Inicio:</label>
                <input type="text" class="form-control" id="horaInicio" :value="formattedHora" readonly />
            </div>

            <div class="mb-3">
                <label for="razonSocial" class="form-label fw-medium">Cliente:</label>
                <input type="text" class="form-control" id="razonSocial" v-model="eventoData.cliente" readonly />
            </div>

            <div class="mb-3">
                <label for="localidad" class="form-label fw-medium">Localidad:</label>
                <input type="text" class="form-control" id="localidad" v-model="eventoData.localidad" readonly />
            </div>

            <div class="mb-3">
                <label for="giro" class="form-label fw-medium">Giro:</label>
                <input type="text" class="form-control" id="giro" v-model="eventoData.giro" readonly />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Actividad:</label>
                <input type="text" class="form-control" id="cotizado" v-model="eventoData.actividad" readonly />
            </div>

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Segmento:</label>
                <input type="text" class="form-control" id="cotizado" v-model="eventoData.segmento" readonly />
            </div>

            <div class="mb-3">
                <label for="observacion" class="form-label fw-medium">Observación:</label>
                <textarea class="form-control" id="observacion" v-model="eventoData.observacion" rows="3" readonly></textarea>
            </div>

            <div class="col-md-12 pt-20 text-end">
                <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">
                    Cerrar
                </button>
            </div>
        </div>
    </DialogComponent>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed } from "vue";
import { EventoData } from "@/interfaces/interfaces";
import { useToast } from "vue-toastification";

export default defineComponent({
    name: "ModalContentEditRuteo",
    props: {
        visible: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        event: {
            type: Object as PropType<EventoData>,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const localVisible = ref(props.visible);
        const eventoData = ref({ ...props.event });

        const closeModal = () => {
            localVisible.value = false;
            emit("close");
        };


        watch(
            () => props.visible,
            (newVisible) => {
                localVisible.value = newVisible;
            },
            { immediate: true }
        );

        watch(
            () => props.event,
            (newEventData) => {
                eventoData.value = { ...newEventData };
            },
            { immediate: true }
        );

        const formattedFecha = computed(() => {
            return eventoData.value.fecha_inicio.split("T")[0];
        });

        const formattedHora = computed(() => {
            return eventoData.value.fecha_inicio.split("T")[1].slice(0, 5);
        });

        return {
            localVisible,
            eventoData,
            closeModal,
            formattedFecha,
            formattedHora,
        };
    },
});
</script>
