<template>
    <div class="card mb-15 border-0 rounded-0 bg-white letter-spacing">
        <div
            class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
            <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
            <p class="ms-20">Selecciona un usuario para acceder a su bitácora</p>
        </div>
        <div class="card-body p-15 p-sm-10 p-md-15">
            <div class="search mb-3">
                <InputText v-model="searchTerm" placeholder="Buscar por nombre o email" class="w-100" />
            </div>

            <div class="table-responsive">
                <DataTable :value="filteredUsers" showGridlines tableStyle="min-width: 50rem" paginator :rows="10"
                    :rowsPerPageOptions="[5, 10, 20, 50]">
                    <Column class="column-table" header="Opciones" style="width: 20%">
                        <template #body="slotProps">
                            <div class="d-flex justify-content-start">
                                <Button icon="pi pi-eye" class="p-button-sm p-button-info me-1"
                                    @click="editUser(slotProps.data.uuid)" />
                            </div>
                        </template>
                    </Column>
                    <Column class="column-table" field="nombre" header="Nombre" style="width: 30%"></Column>
                    <Column class="column-table" field="email" header="Email" style="width: 30%"></Column>
                    <Column class="column-table" field="telefono" header="Teléfono" style="width: 20%"></Column>
                    <Column class="column-table" header="Estatus" style="width: 20%">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.status === 1" class="text-success">
                                Activo
                            </span>
                            <div v-else>
                                <span class="text-danger">Inactivo</span>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                        @click="promptActivate(slotProps.data)" />
                                </div>
                            </div>
                        </template>
                    </Column>
                    <template #footer>
                        Total {{ filteredUsers ? filteredUsers.length : 0 }} Usuarios.
                    </template>
                </DataTable>
            </div>
            <DialogComponent v-model:visible="confirmDialogVisible" :modal="true" :closable="false"
                :style="{ width: '30vw' }">
                <p>
                    ¿Estás seguro de que deseas eliminar al usuario
                    <strong>{{ selectedUser?.nombre }}</strong>?
                </p>
                <div class="d-flex justify-content-end">
                    <Button label="No" icon="pi pi-times" class="p-button-text" @click="cancelDelete" />
                    <Button label="Sí" icon="pi pi-check" class="p-button-danger" @click="confirmDelete" />
                </div>
            </DialogComponent>
            <DialogComponent v-model:visible="activateDialogVisible" :modal="true" :closable="false"
                :style="{ width: '30vw' }">
                <p>
                    ¿Estás seguro de que deseas activar al usuario
                    <strong>{{ selectedUser?.nombre }}</strong>?
                </p>
                <div class="d-flex justify-content-end">
                    <Button label="No" icon="pi pi-times" class="p-button-text" @click="cancelActivate" />
                    <Button label="Sí" icon="pi pi-check" class="p-button-success" @click="confirmActivate" />
                </div>
            </DialogComponent>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";
import control from "@/services/userService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import VueLoading from "vue-loading-overlay";
import Button from "primevue/button";
import { UsuariosItem } from "@/interfaces/interfaces";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "ListadoUsuarios",
    components: {
        DataTable,
        Column,
        InputText,
        VueLoading,
        Button,
    },
    setup() {
        const isLoading = ref(true);
        const toast = useToast();
        const usuariosData = ref<UsuariosItem[]>([]);
        const searchTerm = ref("");
        const confirmDialogVisible = ref(false);
        const activateDialogVisible = ref(false);
        const selectedUser = ref<UsuariosItem | null>(null);
        const router = useRouter();

        const getUsuariosData = async () => {
            try {
                isLoading.value = true;
                const response = await new control().ListUsers();
                if (!response.error) {
                    usuariosData.value = response.datos;
                }
            } catch (error) {
                toast.error("Ocurrió un error al obtener la lista de usuarios");
            } finally {
                isLoading.value = false;
            }
        };

        const editUser = (uuid: string) => {
            router.push({ name: "UserDetailPage", params: { uuid } });
        };

        const promptDelete = (user: UsuariosItem) => {
            selectedUser.value = user;
            confirmDialogVisible.value = true;
        };

        const cancelDelete = () => {
            selectedUser.value = null;
            confirmDialogVisible.value = false;
        };

        const confirmDelete = async () => {
            try {
                if (selectedUser.value) {
                    const data = { uuid: selectedUser.value.uuid };
                    const response = await new control().deleteUser(data);
                    if (!response.error) {
                        toast.success("Usuario eliminado correctamente");
                        await getUsuariosData();
                    } else {
                        toast.error("Error al eliminar el usuario");
                    }
                }
            } catch (error) {
                toast.error("Ocurrió un error durante la eliminación");
            } finally {
                cancelDelete();
            }
        };

        const promptActivate = (user: UsuariosItem) => {
            selectedUser.value = user;
            activateDialogVisible.value = true;
        };

        const cancelActivate = () => {
            selectedUser.value = null;
            activateDialogVisible.value = false;
        };

        const confirmActivate = async () => {
            try {
                if (selectedUser.value) {
                    const data = { uuid: selectedUser.value.uuid };
                    const response = await new control().activeUser(data);
                    if (!response.error) {
                        toast.success("Usuario activado correctamente");
                        await getUsuariosData();
                    } else {
                        toast.error("Error al activar el usuario");
                    }
                }
            } catch (error) {
                toast.error("Ocurrió un error durante la activación");
            } finally {
                cancelActivate();
            }
        };

        const filteredUsers = computed(() => {
            if (!searchTerm.value) {
                return usuariosData.value;
            }

            return usuariosData.value.filter((user) => {
                const nombreMatch = user.nombre
                    ? user.nombre.toLowerCase().includes(searchTerm.value.toLowerCase())
                    : false;
                const emailMatch = user.email
                    ? user.email.toLowerCase().includes(searchTerm.value.toLowerCase())
                    : false;

                return nombreMatch || emailMatch;
            });
        });

        onMounted(async () => {
            await getUsuariosData();
        });

        return {
            isLoading,
            searchTerm,
            filteredUsers,
            editUser,
            promptDelete,
            confirmDialogVisible,
            cancelDelete,
            confirmDelete,
            promptActivate,
            activateDialogVisible,
            cancelActivate,
            confirmActivate,
            selectedUser,
        };
    },
});
</script>

<style>
.text-success {
    color: green;
}

.text-danger {
    color: red;
}

.form-switch {
    margin-top: 5px;
}

.dark .search input {
    background-color: #34334a;
    color: #fff;
}

.dark .column-table {
    background-color: #34334a;
    color: #fff;
}
</style>