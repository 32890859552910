import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white sales-pos-location-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = {
  id: "weeklySalesChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"mb-15 mb-sm-0 d-sm-flex align-items-center justify-content-between\"><div class=\"title\"><span class=\"fw-medium text-muted fs-13 d-block mb-5 text-uppercase\"> WEEKLY SALES </span><h4 class=\"card-title fw-black mb-0\">$25,302</h4></div><div class=\"card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><span class=\"fw-medium text-muted me-8\">Select</span><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option value=\"1\" class=\"fw-medium\">This Week</option><option value=\"2\" class=\"fw-medium\" selected>This Month</option><option value=\"3\" class=\"fw-medium\">This Year</option></select></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_apexchart, {
          type: "line",
          height: "375",
          options: _ctx.weeklySalesChart,
          series: _ctx.sales
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}