import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white sales-pos-location-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-15 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = {
  id: "salesByPosLocationChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h6", { class: "card-title fw-bold mb-0" }, "Sales By POS Location", -1)),
        _createVNode(_component_router_link, {
          to: "/",
          class: "card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" View Details ")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createStaticVNode("<ul class=\"list ps-0 mb-5 list-unstyled mt-15\"><li class=\"text-muted position-relative fw-medium\"> Allocated Budget - <span class=\"text-black fw-bold ms-8\">$29,500.00</span><span class=\"fw-bold text-success ms-5\"> 5.5% <i class=\"flaticon-up-arrow fs-12 lh-1 position-relative top-1\"></i></span></li><li class=\"text-muted position-relative fw-medium\"> Actual Spending - <span class=\"text-black fw-bold ms-15\">$19,500.00</span><span class=\"fw-bold text-danger ms-5\"> 1.4% <i class=\"flaticon-down-arrow fs-12 lh-1 position-relative top-1\"></i></span></li></ul>", 1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_apexchart, {
          type: "radar",
          height: "385",
          options: _ctx.salesByPosLocationChart,
          series: _ctx.sales
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}