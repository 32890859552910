<template>
    <div class="row">
        <div class="col-lg-5 col-xl-4">
            <LeadsInformation :uuid="uuid" />
        </div>
        <div class="col-lg-7 col-xl-8">
            <div class="leads-details-tabs">
                <ul class="nav nav-tabs border-0 mb-25" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100 active"
                            id="tasks-tab" data-bs-toggle="tab" data-bs-target="#tasks-tab-pane" type="button"
                            role="tab" aria-controls="tasks-tab-pane" aria-selected="true">
                            Tareas
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100"
                            id="deals-tab" data-bs-toggle="tab" data-bs-target="#deals-tab-pane" type="button"
                            role="tab" aria-controls="deals-tab-pane" aria-selected="false">
                            Eventos
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="tasks-tab-pane" role="tabpanel" tabindex="0">
                        <div class="card mb-25 border-0 rounded-0 bg-white to-do-list-box letter-spacing">
                            <div
                                class="card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                                <h5 class="card-title fw-bold mb-0">Listado</h5>
                                <div class="d-flex align-items-center mt-10 mt-md-0">
                                    <form class="search-box position-relative me-15" @submit.prevent>
                                        <input type="text"
                                            class="form-control shadow-none text-black rounded-0 border-0"
                                            placeholder="Buscar" v-model="searchQuery" />
                                        <button type="submit"
                                            class="bg-transparent text-primary transition p-0 border-0">
                                            <i class="flaticon-search-interface-symbol"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div
                                class="card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                                <ul v-if="filteredPaginatedTasks.length > 0" class="to-do-list ps-0 list-unstyled mb-0">
                                    <li class="to-do-list-item d-sm-flex align-items-center justify-content-between"
                                        v-for="item in filteredPaginatedTasks" :key="item.uuid_tarea">
                                        <div
                                            class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-black fw-medium">
                                            <input class="form-check-input shadow-none" type="checkbox" id="task1" />
                                            <label class="form-check-label" for="task1">
                                                {{ item.titulo }}
                                            </label>
                                            <span class="fs-12 text-muted fw-normal ms-5">
                                                {{ formatFecha(item.fecha_inicio) }}
                                            </span>
                                            <span class="ms-20 badge"
                                                :style="{ backgroundColor: item.color }">
                                                {{ item.prioridad }}
                                            </span>
                                        </div>
                                        <div class="action-buttons mt-10 mt-sm-0 d-flex align-items-center">
                                            <button @click="showModal(item.uuid_tarea)"
                                                class="bg-transparent p-0 border-0 text-black transition lh-1">
                                                <i class="flaticon-plus"></i>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                                <div v-else class="text-center mt-4">
                                    <div class="card p-3">
                                        <p>No hay datos disponibles</p>
                                    </div>
                                </div>
                                <PrimePaginator v-if="filteredTasks.length > rowsPerPage" :rows="rowsPerPage"
                                    :totalRecords="filteredTasks.length" :page="currentPage"
                                    @page="currentPage = $event.page" />
                            </div>

                        </div>
                    </div>
                    <div class="tab-pane fade" id="deals-tab-pane" role="tabpanel" tabindex="0">
                        <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
                            <div
                                class="card-head bg-white d-md-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                                <h5 class="card-title fw-bold mb-0">Eventos</h5>
                                <div class="d-flex align-items-center mt-10 mt-md-0">
                                    <form class="search-box position-relative me-15">
                                        <input type="text"
                                            class="form-control shadow-none text-black rounded-0 border-0"
                                            placeholder="Buscar" />
                                        <button type="submit"
                                            class="bg-transparent text-primary transition p-0 border-0">
                                            <i class="flaticon-search-interface-symbol"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div
                                class="card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                                <div class="table-responsive">
                                    <!-- <table class="table text-nowrap align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col"
                                                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                                                    Deal Name
                                                </th>
                                                <th scope="col"
                                                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                                                    Amout
                                                </th>
                                                <th scope="col"
                                                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                                                    Status
                                                </th>
                                                <th scope="col"
                                                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                                                    Probability
                                                </th>
                                                <th scope="col"
                                                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                                                    Closing Date
                                                </th>
                                                <th scope="col"
                                                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                                                    <div class="form-check d-flex align-items-center mb-0">
                                                        <input class="form-check-input shadow-none me-8 mt-0"
                                                            type="checkbox" />
                                                        J. Ronan
                                                    </div>
                                                </th>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    $ 6,400,000.00
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium">
                                                    <span class="badge text-outline-success">Won Deal</span>
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    ronanjohnny@gmail.com
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    13 Apr, 2023
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                                                    <div class="dropdown">
                                                        <button
                                                            class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                                                            type="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="flaticon-dots"></i>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                                                                    View</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                                                                    Edit</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                                                                    Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                                                    <div class="form-check d-flex align-items-center mb-0">
                                                        <input class="form-check-input shadow-none me-8 mt-0"
                                                            type="checkbox" />
                                                        J. Pinkman
                                                    </div>
                                                </th>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    $ 100,000.00
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium">
                                                    <span class="badge text-outline-info">New Deal</span>
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    pinky321@gmail.com
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    12 Apr, 2023
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                                                    <div class="dropdown">
                                                        <button
                                                            class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                                                            type="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="flaticon-dots"></i>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                                                                    View</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                                                                    Edit</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                                                                    Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                                                    <div class="form-check d-flex align-items-center mb-0">
                                                        <input class="form-check-input shadow-none me-8 mt-0"
                                                            type="checkbox" />
                                                        A. Carter
                                                    </div>
                                                </th>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    $ 1,840,000.00
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium">
                                                    <span class="badge text-outline-danger">Cancelled</span>
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    ronanjohnny@gmail.com
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    13 Apr, 2023
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                                                    <div class="dropdown">
                                                        <button
                                                            class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                                                            type="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="flaticon-dots"></i>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                                                                    View</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                                                                    Edit</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                                                                    Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                                                    <div class="form-check d-flex align-items-center mb-0">
                                                        <input class="form-check-input shadow-none me-8 mt-0"
                                                            type="checkbox" />
                                                        V. Loper
                                                    </div>
                                                </th>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    $ 6,400,000.00
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium">
                                                    <span class="badge text-outline-success">Won Deal</span>
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    lopervic@gmail.com
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    13 Apr, 2023
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                                                    <div class="dropdown">
                                                        <button
                                                            class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                                                            type="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="flaticon-dots"></i>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                                                                    View</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                                                                    Edit</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                                                                    Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                                                    <div class="form-check d-flex align-items-center mb-0">
                                                        <input class="form-check-input shadow-none me-8 mt-0"
                                                            type="checkbox" />
                                                        W. White
                                                    </div>
                                                </th>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    $ 100,000.00
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium">
                                                    <span class="badge text-outline-info">New Deal</span>
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    whitespace@gmail.com
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    10 Apr, 2023
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                                                    <div class="dropdown">
                                                        <button
                                                            class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                                                            type="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="flaticon-dots"></i>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                                                                    View</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                                                                    Edit</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                                                                    Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                                                    <div class="form-check d-flex align-items-center mb-0">
                                                        <input class="form-check-input shadow-none me-8 mt-0"
                                                            type="checkbox" />
                                                        T. Skyler
                                                    </div>
                                                </th>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    $ 400,000.00
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium">
                                                    <span class="badge text-outline-danger">Cancelled</span>
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    ronanjohnny@gmail.com
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                                    13 Apr, 2023
                                                </td>
                                                <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                                                    <div class="dropdown">
                                                        <button
                                                            class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                                                            type="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="flaticon-dots"></i>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                                                                    View</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                                                                    Edit</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item d-flex align-items-center"
                                                                    href="javascript:void(0);"><i
                                                                        class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                                                                    Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    </div>

    <DialogComponent v-model:visible="localVisible" :style="{ width: '35rem' }">
        <template #header>
            <div class="col-md-6 pt-20">
                <h5 class="card-subtitle fw-bold">Detalles de la Tarea</h5>
            </div>
        </template>
        <div class="row">
            <div class="mb-3">
                <label for="overviewEmail" class="form-label fw-medium">Título de la tarea:</label>
                <!-- <input type="text" class="form-control shadow-none text-black fs-md-15 fs-lg-16" id="overviewEmail"
                    v-model="newEvent.title" readonly /> -->

                <textarea class="form-control" id="eventDescription" v-model="newEvent.title" rows="3" cols="20"
                    readonly></textarea>
            </div>

            <div class="mb-3">
                <label for="eventDescription" class="form-label fw-medium">Descripción de la tarea:</label>
                <textarea class="form-control" id="eventDescription" v-model="newEvent.description" rows="3"
                    readonly></textarea>
            </div>

            <div class="mb-3">
                <label for="eventStartTime" class="form-label fw-medium">Fecha y Hora de inicio:</label>
                <input type="datetime-local" class="form-control" id="eventStartTime" v-model="newEvent.startTime"
                    required />
            </div>

            <div class="mb-3">
                <label for="eventEndTime" class="form-label fw-medium">Fecha y Hora de finalización:</label>
                <input type="datetime-local" class="form-control" id="eventEndTime" v-model="newEvent.endTime"
                    required />
            </div>

            <div class="mb-3">
                <label class="form-label fw-medium">Progreso de actividades:</label>
                <div class="progress mb-3">
                    <div class="progress-bar" role="progressbar" :style="{ width: `${progress}%` }">
                        {{ progress.toFixed(0) }}%
                    </div>
                </div>
                <label class="form-label fw-medium">Checklist de actividades:</label>
                <ul class="list-group">
                    <li v-for="(item, index) in newActivity.checklist" :key="index" class="list-group-item">
                        <div class="d-flex align-items-start">
                            <input type="checkbox" v-model="item.check" />
                            <textarea class="form-control ms-2 flex-grow-1" v-model="item.nombre"
                                placeholder="Escribe una tarea..." rows="4" readonly></textarea>
                        </div>

                        <div class="d-flex justify-content-between mt-2">
                            <div class="flex-grow-1 me-1">
                                <label for="startDate" class="form-label">Fecha inicio:</label>
                                <input type="datetime-local" class="form-control" v-model="item.fecha_inicio"
                                    readonly />
                            </div>
                            <div class="flex-grow-1 ms-1">
                                <label for="EndDate" class="form-label">Fecha fin:</label>
                                <input type="datetime-local" class="form-control" v-model="item.fecha_fin" readonly />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </DialogComponent>
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed } from "vue";
import LeadsInformation from "./LeadsInformation.vue";
import moment from 'moment';
import createSocketService from "@/services/socketService";
import { TaskOrEvent, Activity } from "@/interfaces/interfaces"
import PrimePaginator from "primevue/paginator";

export default defineComponent({
    name: "LeadDetails",
    components: {
        LeadsInformation,
        PrimePaginator
    },
    props: {
        uuid: {
            type: String,
            required: false,
        }
    },
    setup(props) {
        let socketService: any = null;
        const tasks = ref<TaskOrEvent[]>([]);
        const events = ref<TaskOrEvent[]>([]);
        const searchQuery = ref('');
        const filteredTasks = ref<TaskOrEvent[]>([]);
        const rowsPerPage = ref(5);
        const currentPage = ref(0);
        const localVisible = ref(false);
        const isLoading = ref(false);
        const newEvent = ref({
            title: "",
            description: "",
            startTime: "",
            endTime: "",
        });
        const newActivity = ref<Activity>({
            title: "",
            description: "",
            startTime: "",
            endTime: "",
            checklist: [],
        });

        const getData = async (uuid) => {

            try {
                isLoading.value = true;
                const formattedStartDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss.SSSSSS');
                const formattedEndDate = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss.SSSSSS');

                const fechadata = {
                    fecha_inicio: formattedStartDate,
                    fecha_fin: formattedEndDate,
                    uuidUsuario: uuid
                };

                socketService = await createSocketService();
                socketService.connect();
                socketService.emit("list-task-user-general", fechadata);
                socketService.on("list-task-user-general", (response: any) => {
                    try {
                        if (!response.error) {
                            response.response.forEach((item) => {
                                if (item.uuid_evento) {
                                    events.value.push(item);
                                } else if (item.uuid_tarea) {
                                    tasks.value.push(item);
                                    filteredTasks.value = tasks.value
                                }
                            });
                        }
                    } catch (error) {
                        console.log(error);

                    }

                });
            } catch (error) {
                console.log(error)
            } finally {
                isLoading.value = false;
            }

        };

        const formatFecha = (fecha) => {
            return moment(fecha).format('YYYY-MM-DD');
        };

        const filterTasks = () => {
            if (searchQuery.value.trim() === '') {
                filteredTasks.value = tasks.value;
            } else {
                filteredTasks.value = tasks.value.filter(task => {
                    return task.titulo && task.titulo.toLowerCase().includes(searchQuery.value.toLowerCase());
                });
            }
        };

        const filteredPaginatedTasks = computed(() => {
            const start = currentPage.value * rowsPerPage.value;
            const end = start + rowsPerPage.value;
            return filteredTasks.value.slice(start, end);
        });

        const showModal = (uuid_tarea) => {
            isLoading.value = true;
            socketService.emit("task-user", { uuid_tarea: uuid_tarea, uuid_evento: uuid_tarea });

            socketService.once("task-user", async (response) => {
                try {
                    if (!response.error) {
                        console.log(response.response)
                        const data = response.response;
                        newEvent.value.title = data.titulo || "";
                        newEvent.value.description = data.descripcion || "";
                        newEvent.value.startTime = moment(data.fecha_inicio).format(
                            "YYYY-MM-DDTHH:mm"
                        );
                        newEvent.value.endTime = moment(data.fecha_fin).format(
                            "YYYY-MM-DDTHH:mm"
                        );

                        newActivity.value.checklist = Array.isArray(data.actividades)
                            ? data.actividades.map((activity) => ({
                                nombre: activity.nombre,
                                check: activity.check === true,
                                fecha_inicio: moment(activity.fecha_inicio).format(
                                    "YYYY-MM-DDTHH:mm"
                                ),
                                fecha_fin: moment(activity.fecha_fin).format(
                                    "YYYY-MM-DDTHH:mm"
                                ),
                                uuid: activity.uuid,
                                isModified: false,
                            }))
                            : [];

                    }
                } catch (error) {
                    console.log("Error al manejar la respuesta:", error);
                } finally {
                    localVisible.value = true;
                    isLoading.value = false;
                }
            });
        };

        const progress = computed(() => {
            const totalItems = newActivity.value.checklist.length;
            const completedItems = newActivity.value.checklist.filter(
                (item) => item.check
            ).length;

            return totalItems > 0 ? (completedItems / totalItems) * 100 : 0;
        });

        watch(searchQuery, filterTasks);

        watch(
            () => props.uuid,
            async (newData) => {
                await getData(newData)
            },
            { immediate: true }
        );

        return {
            tasks,
            events,
            formatFecha,
            filterTasks,
            searchQuery,
            filteredTasks,
            rowsPerPage,
            currentPage,
            filteredPaginatedTasks,
            showModal,
            localVisible,
            newEvent,
            newActivity,
            progress,
            isLoading
        }
    }
});
</script>