<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-sm-20 mb-md-25">Documents</h5>
      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/jpg.png"
              class="d-block ms-auto me-auto mb-15"
              alt="jpg"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              sketch-design.jpg
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/png.png"
              class="d-block ms-auto me-auto mb-15"
              alt="png"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              compile.png
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/pdf.png"
              class="d-block ms-auto me-auto mb-15"
              alt="pdf"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              integrations.pdf
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/txt.png"
              class="d-block ms-auto me-auto mb-15"
              alt="txt"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              contact@32.txt
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/doc.png"
              class="d-block ms-auto me-auto mb-15"
              alt="doc"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              app-design.doc
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/xl4.png"
              class="d-block ms-auto me-auto mb-15"
              alt="xl4"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              wireframes.xl4
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/png.png"
              class="d-block ms-auto me-auto mb-15"
              alt="png"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              image02.png
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/doc.png"
              class="d-block ms-auto me-auto mb-15"
              alt="doc"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              bold-sketch.doc
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/jpg.png"
              class="d-block ms-auto me-auto mb-15"
              alt="jpg"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              sketch-design.jpg
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/png.png"
              class="d-block ms-auto me-auto mb-15"
              alt="png"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              compile.png
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/pdf.png"
              class="d-block ms-auto me-auto mb-15"
              alt="pdf"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              integrations.pdf
            </router-link>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2">
          <div
            class="bg-gray text-center rounded-1 mb-15 mb-sm-20 mb-md-25 pt-35 pb-35 ps-15 pe-15"
          >
            <img
              src="../../assets/images/icon/txt.png"
              class="d-block ms-auto me-auto mb-15"
              alt="txt"
            />
            <router-link
              to="/file-manager"
              class="d-inline-block text-decoration-none text-black fs-md-15 fs-lg-16 fw-medium"
            >
              contact@32.txt
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="pagination-area d-md-flex justify-content-between align-items-center"
      >
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">12</span> out of
          <span class="fw-bold">100</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link active" href="#">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentsFiles",
});
</script>