import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/user/user3.jpg'
import _imports_1 from '../../../assets/images/user/user12.jpg'
import _imports_2 from '../../../assets/images/icon/facebook.png'
import _imports_3 from '../../../assets/images/user/user7.jpg'
import _imports_4 from '../../../assets/images/user/user8.jpg'
import _imports_5 from '../../../assets/images/user/user1.jpg'
import _imports_6 from '../../../assets/images/user/user5.jpg'
import _imports_7 from '../../../assets/images/user/user4.jpg'
import _imports_8 from '../../../assets/images/user/user2.jpg'
import _imports_9 from '../../../assets/images/user/user10.jpg'
import _imports_10 from '../../../assets/images/user/user6.jpg'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-4 col-xxl-3" }
const _hoisted_3 = { class: "card mb-25 border-0 rounded-0 bg-white box-shadow emails-sidebar-box" }
const _hoisted_4 = { class: "card-body p-15 p-sm-20 p-md-25 letter-spacing" }
const _hoisted_5 = { class: "list ps-0 mb-0 list-unstyled" }
const _hoisted_6 = { class: "col-lg-8 col-xxl-9" }
const _hoisted_7 = { class: "card mb-25 border-0 rounded-0 bg-white box-shadow emails-list-box letter-spacing" }
const _hoisted_8 = { class: "card-body p-0" }
const _hoisted_9 = { class: "emails-list ps-0 mb-0 list-unstyled" }
const _hoisted_10 = { class: "email-item position-relative" }
const _hoisted_11 = { class: "email-item position-relative" }
const _hoisted_12 = { class: "email-item bg-color position-relative" }
const _hoisted_13 = { class: "email-item position-relative" }
const _hoisted_14 = { class: "email-item bg-color position-relative" }
const _hoisted_15 = { class: "email-item bg-color position-relative" }
const _hoisted_16 = { class: "email-item position-relative" }
const _hoisted_17 = { class: "email-item bg-color position-relative" }
const _hoisted_18 = { class: "email-item bg-color position-relative" }
const _hoisted_19 = { class: "email-item bg-color position-relative" }
const _hoisted_20 = { class: "email-item bg-color position-relative" }
const _hoisted_21 = { class: "email-item position-relative" }
const _hoisted_22 = { class: "email-item bg-color position-relative" }
const _hoisted_23 = { class: "email-item bg-color position-relative" }
const _hoisted_24 = { class: "compose-email-modal-dialog" }
const _hoisted_25 = { class: "compose-email-modal-header d-flex align-items-center justify-content-between p-15 ps-sm-20 p-md-25" }
const _hoisted_26 = { class: "compose-email-modal-body p-15 ps-sm-20 p-md-25" }
const _hoisted_27 = { class: "form-group mb-15 mb-md-25" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_QuillEditor = _resolveComponent("QuillEditor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("form", { class: "search-box position-relative mb-15" }, [
              _createElementVNode("input", {
                type: "text",
                class: "form-control shadow-none text-black rounded-0 border-0",
                placeholder: "Search email"
              }),
              _createElementVNode("button", {
                type: "submit",
                class: "bg-transparent text-primary transition p-0 border-0"
              }, [
                _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
              ])
            ], -1)),
            _createElementVNode("button", {
              type: "button",
              class: "d-block text-center w-100 bg-primary border-0 box-shadow fw-medium text-white rounded-1 pt-12 pb-12 ps-25 pe-25",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleComposeEmailClass && _ctx.toggleComposeEmailClass(...args)))
            }, " Compose Email "),
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "sub-title text-uppercase mt-20 mt-md-30 mb-15 mb-md-20 fw-medium fs-13 text-muted d-block" }, " MailBox ", -1)),
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: "/emails",
                  class: "d-block text-decoration-none lh-1 transition active"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("div", { class: "title fs-md-15 fs-lg-16 fw-medium text-black" }, [
                      _createElementVNode("i", { class: "flaticon-envelope" }),
                      _createTextVNode(" Inbox ")
                    ], -1),
                    _createElementVNode("span", { class: "rounded-circle dot-badge bg-danger text-white d-inline-block text-center fs-12 fw-semibold" }, " 12 ", -1)
                  ])),
                  _: 1
                })
              ]),
              _cache[3] || (_cache[3] = _createStaticVNode("<li><a href=\"javascript:void(0);\" class=\"d-block text-decoration-none lh-1 transition\"><div class=\"title fs-md-15 fs-lg-16 fw-medium text-black\"><i class=\"flaticon-snooze\"></i> Snoozed </div></a></li><li><a href=\"javascript:void(0);\" class=\"d-block text-decoration-none lh-1 transition\"><div class=\"title fs-md-15 fs-lg-16 fw-medium text-black\"><i class=\"flaticon-draft\"></i> Draft </div><span class=\"rounded-circle dot-badge bg-success text-white d-inline-block text-center fs-12 fw-semibold\"> 2 </span></a></li><li><a href=\"javascript:void(0);\" class=\"d-block text-decoration-none lh-1 transition\"><div class=\"title fs-md-15 fs-lg-16 fw-medium text-black\"><i class=\"flaticon-status\"></i> Sent Email </div></a></li><li><a href=\"javascript:void(0);\" class=\"d-block text-decoration-none lh-1 transition\"><div class=\"title fs-md-15 fs-lg-16 fw-medium text-black\"><i class=\"flaticon-delete\"></i> Trash </div></a></li><li><a href=\"javascript:void(0);\" class=\"d-block text-decoration-none lh-1 transition\"><div class=\"title fs-md-15 fs-lg-16 fw-medium text-black\"><i class=\"flaticon-spam\"></i> Spam </div><span class=\"rounded-circle dot-badge bg-warning text-white d-inline-block text-center fs-12 fw-semibold\"> 4 </span></a></li>", 5))
            ]),
            _cache[6] || (_cache[6] = _createStaticVNode("<span class=\"sub-title text-uppercase mt-20 mt-md-30 mb-15 mb-md-20 fw-medium fs-13 text-muted d-block\"> Filters </span><ul class=\"list ps-0 mb-0 list-unstyled\"><li><a href=\"javascript:void(0);\" class=\"d-block text-decoration-none lh-1 transition\"><div class=\"title fs-md-15 fs-lg-16 fw-medium text-black\"><i class=\"flaticon-star-3\"></i> Starred </div></a></li><li><a href=\"javascript:void(0);\" class=\"d-block text-decoration-none lh-1 transition\"><div class=\"title fs-md-15 fs-lg-16 fw-medium text-black\"><i class=\"flaticon-pin-1\"></i> Important </div></a></li></ul>", 2))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[36] || (_cache[36] = _createStaticVNode("<div class=\"card-head box-shadow bg-white d-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25\"><div class=\"left d-flex align-items-center\"><div class=\"form-check mb-0 me-10\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 lh-1 text-muted position-relative top-2\"><i class=\"flaticon-logout\"></i></button></div><div class=\"right d-flex align-items-center\"><ul class=\"info-list mb-0 list-unstyled position-relative top-3 me-10\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul><button class=\"dot-btn lh-1 position-relative top-1 bg-transparent border-0 shadow-none p-0 transition mt-10 mt-sm-0\" type=\"button\"><i class=\"flaticon-dots\"></i></button></div></div>", 1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-3\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_0 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-bold ms-7 lh-1\"> J. Ronan </span></div></div><span class=\"badge text-outline-success\">New</span></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-xxxl-16 fw-medium text-black\"> Analyse Dashboard Data - <span class=\"fw-normal text-muted\"> How to do the work property </span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">9:30 AM</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[8] || (_cache[8] = _createStaticVNode("<ul class=\"info-list ps-0 mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-3\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_1 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-bold ms-7 lh-1\">D. Angela</span></div></div><span class=\"badge text-outline-success\">New</span></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-xxxl-16 fw-medium text-black\"> Our Visual Design Can Lead - <span class=\"fw-normal text-muted\">How to do the work property in a short time</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">8:08 AM</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[10] || (_cache[10] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_12, [
                _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-3\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_2 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"facebook\"><span class=\"fs-md-15 fs-lg-16 fw-semibold ms-7 lh-1 text-dark-emphasis\">Facebook</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-lg-16 fw-medium text-paragraph\"> Analyse Dashboard Data - <span class=\"fw-normal text-muted\">How to do the work property</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">6:14 AM</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[12] || (_cache[12] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"row\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-warning lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-1\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_3 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-bold ms-7 lh-1\">J. Pinkman, Me (12)</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-xxxl-16 fw-medium text-black\"> Analyse Dashboard Data - <span class=\"fw-normal text-muted\">How to do the work property, giving some file of the project</span></p><div class=\"buttons-list\"><a href=\"#\" target=\"_blank\" class=\"d-inline-block text-decoration-none text-black position-relative transition\"><i class=\"flaticon-gallery-1\"></i> Dashboard.jpg </a><a href=\"#\" target=\"_blank\" class=\"d-inline-block text-decoration-none text-black position-relative transition\"><i class=\"flaticon-gallery-1\"></i> Structure.png </a></div></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">4:13 AM</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[14] || (_cache[14] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-warning lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-1\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_4 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-semibold ms-7 lh-1 text-paragraph\">Jennilia</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-lg-16 fw-medium text-paragraph\"> Hello – Trip home from 🎉 Colombo <span class=\"fw-normal text-muted\">has been arranged, then Jenna will com...</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">19 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[16] || (_cache[16] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-3\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_5 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-semibold ms-7 lh-1 text-paragraph\">Robert Bruce</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-lg-16 fw-medium text-paragraph\"> New Ticket Reply - <span class=\"fw-normal text-muted\">eDemy - Michel Valenzuela</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">18 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[18] || (_cache[18] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_6 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-semibold ms-7 lh-1 text-paragraph\">Jenny Anderson</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-lg-16 fw-medium text-paragraph\"> New Ticket Reply - <span class=\"fw-normal text-muted\">Abev - Manos Pappas</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">17 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[20] || (_cache[20] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[21] || (_cache[21] = _createStaticVNode("<div class=\"row\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_3 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-semibold ms-7 lh-1 text-paragraph\">Victor, Me (03)</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-xxxl-16 fw-medium text-black\"> For your records, <span class=\"fw-normal text-muted\">here is a copy of the information you submitted to us...</span></p><div class=\"buttons-list\"><a href=\"#\" target=\"_blank\" class=\"d-inline-block text-decoration-none text-black position-relative transition\"><i class=\"flaticon-gallery-1\"></i> Task_Management.jpg </a></div></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">16 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[22] || (_cache[22] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[23] || (_cache[23] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-3\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_7 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-semibold ms-7 lh-1 text-paragraph\">Sandy Smith</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-lg-16 fw-medium text-paragraph\"> Weekend on Revibe – <span class=\"fw-normal text-muted\">Today&#39;s Friday and we thought maybe you want so</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">15 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[24] || (_cache[24] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-3\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_8 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-semibold ms-7 lh-1 text-paragraph\">Britteney</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-lg-16 fw-medium text-paragraph\"> Dealing with Technical or Professional | <span class=\"fw-normal text-muted\">Avoiding 3 Common Pitfalls of Affinity Diagramming</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">15 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[26] || (_cache[26] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[27] || (_cache[27] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-3\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_9 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-semibold ms-7 lh-1 text-paragraph\">Robert Bruce</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-lg-16 fw-medium text-paragraph\"> How to take the headache out of Order - <span class=\"fw-normal text-muted\">Hello! As I&#39;ve mentioned before, we have..</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">14 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[28] || (_cache[28] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_21, [
                _cache[29] || (_cache[29] = _createStaticVNode("<div class=\"row\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-warning lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-1\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_10 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-bold ms-7 lh-1\">D. Warner, Me (10)</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-xxxl-16 fw-medium text-black\"> Analyse Dashboard Data - <span class=\"fw-normal text-muted\">How to do the work property, giving some file of the project</span></p><div class=\"buttons-list\"><a href=\"#\" target=\"_blank\" class=\"d-inline-block text-decoration-none text-black position-relative transition\"><i class=\"flaticon-gallery-1\"></i> Dashboard.jpg </a><a href=\"#\" target=\"_blank\" class=\"d-inline-block text-decoration-none text-black position-relative transition\"><i class=\"flaticon-gallery-1\"></i> Structure.png </a></div></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">13 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[30] || (_cache[30] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_22, [
                _cache[31] || (_cache[31] = _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star-3\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_2 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"facebook\"><span class=\"fs-md-15 fs-lg-16 fw-semibold ms-7 lh-1 text-dark-emphasis\">Facebook</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-lg-16 fw-medium text-paragraph\"> Analyse Dashboard Data - <span class=\"fw-normal text-muted\">How to do the work property</span></p></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">12 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[32] || (_cache[32] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ]),
              _createElementVNode("div", _hoisted_23, [
                _cache[33] || (_cache[33] = _createStaticVNode("<div class=\"row\"><div class=\"col-xxl-4\"><div class=\"email-info d-flex align-items-center justify-content-between\"><div class=\"d-flex align-items-center\"><div class=\"form-check mb-0\"><input class=\"form-check-input shadow-none\" type=\"checkbox\"></div><button type=\"button\" class=\"bg-transparent p-0 border-0 text-muted lh-1 ms-2 position-relative top-1 fs-20\"><i class=\"flaticon-star\"></i></button><div class=\"d-flex align-items-center user ms-15\"><img src=\"" + _imports_3 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"fs-md-15 fs-xxxl-16 fw-semibold ms-7 lh-1 text-paragraph\">Victor, Me (03)</span></div></div></div></div><div class=\"col-xxl-7\"><div class=\"email-title\"><p class=\"d-block mb-0 fs-md-15 fs-xxxl-16 fw-medium text-black\"> For your records, <span class=\"fw-normal text-muted\">here is a copy of the information you submitted to us...</span></p><div class=\"buttons-list\"><a href=\"#\" target=\"_blank\" class=\"d-inline-block text-decoration-none text-black position-relative transition\"><i class=\"flaticon-gallery-1\"></i> Task_Management.jpg </a></div></div></div><div class=\"col-xxl-1 text-xxl-end\"><span class=\"d-block text-black fs-13 time\">11 Mar</span></div></div>", 1)),
                _createVNode(_component_router_link, {
                  to: "/read-email",
                  class: "link-btn position-absolute start-0 end-0 top-0 bottom-0 d-block"
                }),
                _cache[34] || (_cache[34] = _createStaticVNode("<ul class=\"info-list mb-0 list-unstyled\"><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-copy\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-delete\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-star-3\"></i></button></li><li class=\"d-inline-block\"><button type=\"button\" class=\"bg-transparent border-0 p-0 text-paragraph lh-1\"><i class=\"flaticon-price-tag-1\"></i></button></li></ul>", 1))
              ])
            ]),
            _cache[35] || (_cache[35] = _createStaticVNode("<div class=\"pagination-area d-md-flex ps-15 ps-sm-20 ps-md-25 pe-15 pe-sm-20 pe-md-25 mt-15 mt-sm-20 mb-15 mb-sm-20 justify-content-between align-items-center\"><p class=\"mb-0 text-paragraph\"> Showing <span class=\"fw-bold\">11</span> out of <span class=\"fw-bold\">134</span> results </p><nav class=\"mt-15 mt-md-0\"><ul class=\"pagination mb-0\"><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Previous\"><i class=\"flaticon-chevron-1\"></i></a></li><li class=\"page-item\"><a class=\"page-link active\" href=\"#\">1</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">2</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">3</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Next\"><i class=\"flaticon-chevron\"></i></a></li></ul></nav></div>", 1))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['compose-email-modal', { active: _ctx.isSidebarActive }]),
      id: "compose-email-modal"
    }, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _cache[38] || (_cache[38] = _createElementVNode("h5", { class: "mb-0 text-black fw-bold" }, "New Message", -1)),
          _createElementVNode("button", {
            class: "close-btn bg-transparent border-0 p-0",
            type: "button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleComposeEmailClass && _ctx.toggleComposeEmailClass(...args)))
          }, _cache[37] || (_cache[37] = [
            _createElementVNode("i", { class: "flaticon-close" }, null, -1)
          ]))
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("form", null, [
            _cache[40] || (_cache[40] = _createStaticVNode("<div class=\"form-group mb-15 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\">To</label><input type=\"email\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. johny12345@gmail.com\"></div><div class=\"form-group mb-15 mb-md-25\"><label class=\"d-block text-black fw-semibold mb-10\">Subject</label><input type=\"text\" class=\"form-control shadow-none rounded-0 text-black\" placeholder=\"e.g. Our visual design can lead you\"></div>", 2)),
            _createElementVNode("div", _hoisted_27, [
              _cache[39] || (_cache[39] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Write Message ", -1)),
              _createVNode(_component_QuillEditor, {
                theme: "snow",
                placeholder: "Write your message here",
                toolbar: "full"
              })
            ]),
            _cache[41] || (_cache[41] = _createElementVNode("button", {
              class: "default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16",
              type: "submit"
            }, " Send Email ", -1))
          ])
        ])
      ])
    ], 2)
  ], 64))
}