<template>
  <GoogleMapsUpdate @locationUpdated="handleLocationUpdate" />
  <FormMapsUpdate :locationData="locationData" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import GoogleMapsUpdate from "../../components/Maps/GoogleMaps/GoogleMapsUpdate.vue";
import FormMapsUpdate from "../../components/Formulario/FormMapsUpdate.vue";

export default defineComponent({
  name: "GoogleMapsUpPage",
  components: {
    GoogleMapsUpdate,
    FormMapsUpdate
  },
  setup() {
    const locationData = ref({});

    const handleLocationUpdate = (data: any) => {
      locationData.value = data;
    };

    return {
      locationData,
      handleLocationUpdate
    };
  }
});
</script>
