<template>
  <div class="row">
    <div class="col-xxl-3 col-sm-6">
      <div class="card mb-25 border-0 rounded-0 bg-white stats-item">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div class="d-flex align-items-center justify-content-between">
            <div
              class="icon position-relative text-primary rounded-1 text-center"
            >
              <i class="flaticon-sterile-box"></i>
            </div>
            <div class="title text-end">
              <span class="d-block fw-bold text-dark-emphasis mb-5">
                Active Projects
              </span>
              <div class="d-flex align-items-center justify-content-end">
                <h3 class="fw-black mb-0 me-10">69</h3>
                <span class="fw-bold text-success text-badge d-inline-block">
                  <i
                    class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"
                  ></i>
                  5.5%
                </span>
              </div>
              <span class="d-block fw-medium text-dark-emphasis mt-5">
                Completed:
                <span class="fw-bold">25</span>
              </span>
            </div>
          </div>
          <div
            class="mt-15 mt-md-25 mb-5 mb-md-8 d-flex justify-content-between align-items-center"
          >
            <span class="fw-medium text-secondary">
              <span class="fw-bold text-black">44</span>
              pending
            </span>
            <span class="text-primary fw-semibold">38%</span>
          </div>
          <div
            class="progress"
            role="progressbar"
            aria-valuenow="38"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar" style="width: 38%"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-sm-6">
      <div class="card mb-25 border-0 rounded-0 bg-white stats-item">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div class="d-flex align-items-center justify-content-between">
            <div
              class="icon position-relative text-success rounded-1 text-center"
            >
              <i class="flaticon-sugar-cubes"></i>
            </div>
            <div class="title text-end">
              <span class="d-block fw-bold text-dark-emphasis mb-5">
                Total Task
              </span>
              <div class="d-flex align-items-center justify-content-end">
                <h3 class="fw-black mb-0 me-10">94</h3>
                <span class="fw-bold text-danger text-badge d-inline-block">
                  <i
                    class="flaticon-down-arrow fs-11 lh-1 position-relative top-1"
                  >
                  </i>
                  3.5%
                </span>
              </div>
              <span class="d-block fw-medium text-dark-emphasis mt-5">
                Completed: <span class="fw-bold">44</span>
              </span>
            </div>
          </div>
          <div
            class="mt-15 mt-md-25 mb-5 mb-md-8 d-flex justify-content-between align-items-center"
          >
            <span class="fw-medium text-secondary">
              <span class="fw-bold text-black">50</span>
              pending
            </span>
            <span class="text-success fw-semibold">42%</span>
          </div>
          <div
            class="progress"
            role="progressbar"
            aria-valuenow="42"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar bg-success" style="width: 42%"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-sm-6">
      <div class="card mb-25 border-0 rounded-0 bg-white stats-item">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div class="d-flex align-items-center justify-content-between">
            <div class="icon position-relative text-info rounded-1 text-center">
              <i class="flaticon-idea"></i>
            </div>
            <div class="title text-end">
              <span class="d-block fw-bold text-dark-emphasis mb-5">
                Productivity
              </span>
              <div class="d-flex align-items-center justify-content-end">
                <h3 class="fw-black mb-0 me-10">90%</h3>
                <span class="fw-bold text-success text-badge d-inline-block">
                  <i
                    class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"
                  >
                  </i>
                  9.3%
                </span>
              </div>
              <span class="d-block fw-medium text-dark-emphasis mt-5">
                Revenue:
                <span class="fw-bold">$2500</span>
              </span>
            </div>
          </div>
          <div
            class="mt-15 mt-md-25 mb-5 mb-md-8 d-flex justify-content-between align-items-center"
          >
            <span class="fw-medium text-secondary">
              <span class="fw-bold text-black">$4504</span>
              more to go
            </span>
            <span class="text-info-light fw-semibold">50%</span>
          </div>
          <div
            class="progress"
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div class="progress-bar bg-info-light" style="width: 50%"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-sm-6">
      <div class="card mb-25 border-0 rounded-0 bg-white stats-item">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div class="d-flex align-items-center justify-content-between">
            <div
              class="icon position-relative text-danger rounded-1 text-center"
            >
              <i class="flaticon-user-3"></i>
            </div>
            <div class="title text-end">
              <span class="d-block fw-bold text-dark-emphasis mb-5">
                Total Members
              </span>
              <div class="d-flex align-items-center justify-content-end">
                <h3 class="fw-black mb-0 me-10">56</h3>
                <span class="fw-bold text-success text-badge d-inline-block">
                  <i
                    class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"
                  >
                  </i>
                  2.5%
                </span>
              </div>
              <span class="d-block fw-medium text-dark-emphasis mt-5">
                Working:
                <span class="fw-bold">54</span>
              </span>
            </div>
          </div>
          <div class="mt-15 mt-md-20 users-list d-flex align-items-center">
            <div class="rounded-circle text-center">
              <img
                src="../../../assets/images/user/user1.jpg"
                class="rounded-circle"
                alt="user"
              />
            </div>
            <div class="rounded-circle text-center">
              <img
                src="../../../assets/images/user/user2.jpg"
                class="rounded-circle"
                alt="user"
              />
            </div>
            <div
              class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-16 fw-bold"
            >
              P
            </div>
            <div class="rounded-circle text-center">
              <img
                src="../../../assets/images/user/user4.jpg"
                class="rounded-circle"
                alt="user"
              />
            </div>
            <div
              class="rounded-circle bg-primary text-center d-flex align-items-center justify-content-center text-white fs-16 fw-bold"
            >
              S
            </div>
            <div class="rounded-circle text-center">
              <img
                src="../../../assets/images/user/user3.jpg"
                class="rounded-circle"
                alt="user"
              />
            </div>
            <div
              class="rounded-circle bg-secondary text-center d-flex align-items-center justify-content-center text-white fs-14 fw-bold"
            >
              +24
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "StatsItem",
});
</script>