<template>
    <div class="row">
      <div class="col-md-12">
        <InspeccionVehicular />
      </div>
    </div>
  </template>

  <script lang="ts">
  import { defineComponent, } from "vue";
 import InspeccionVehicular from "@/components/Vehiculos/InspeccionVehicular.vue";
  
  export default defineComponent({
    name: "ActualizarVehicleComponent",
    components: {
        InspeccionVehicular
    },
  });
  </script>