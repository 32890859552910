<template>
    <div>
        <AgentFilters @filterChange="handleFilterChange" />

    </div>
    <div class="pt-10">
        <InitialIndicators :data="agentData" />
    </div>
    <div class="row">
        <div class="col-xxl-7 col-xxxl-8">
            <TaskOverview />

            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <ProjectDistribution />
                </div>
                <div class="col-lg-6 col-md-12">
                    <RecentActivity />
                </div>
            </div>
        </div>
        <div class="col-xxl-5 col-xxxl-4">
            <WorkingSchedule />
        </div>
    </div>
    <ProjectStats />
    <div class="row">
        <div class="col-lg-5 col-xxl-4">
            <ToDoList />
        </div>
        <div class="col-lg-7 col-xxl-8">
            <ActiveTasks />
        </div>
    </div>

    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import InitialIndicators from "@/components/Dashboard/Agents/InitialIndicators.vue";

import TaskOverview from "../../components/Dashboard/ProjectManagement/TaskOverview.vue";

import ProjectDistribution from "../../components/Dashboard/ProjectManagement/ProjectDistribution.vue";

import RecentActivity from "../../components/Dashboard/ProjectManagement/RecentActivity.vue";

import WorkingSchedule from "../../components/Dashboard/ProjectManagement/WorkingSchedule/index.vue";

import ProjectStats from "../../components/Dashboard/ProjectManagement/ProjectStats/index.vue";

import ToDoList from "../../components/Dashboard/ProjectManagement/ToDoList/index.vue";

import ActiveTasks from "../../components/Dashboard/ProjectManagement/ActiveTasks/index.vue";

import AgentFilters from "@/components/Dashboard/Agents/AgentFilters.vue";
import Agent from "@/services/agentsService";
import { useToast } from "vue-toastification";
import { Invoice } from "@/interfaces/interfaces";

export default defineComponent({
    name: "ProjectManagementPage",
    components: {
        InitialIndicators,
        TaskOverview,
        ProjectDistribution,
        RecentActivity,
        WorkingSchedule,
        ProjectStats,
        ToDoList,
        ActiveTasks,
        AgentFilters
    },
    setup() {
        const uuidAgent = ref<string[]>([]);
        const startDate = ref<string | null>(null);
        const endDate = ref<string | null>(null);
        const isLoading = ref(false);
        const agentData = ref<Invoice[]>([]);
        const toast = useToast();

        const getDataAgents = async () => {
            try {
                isLoading.value = true;
                const rspta = await new Agent().listData(uuidAgent.value, startDate.value, endDate.value);
                if (!rspta.error) {
                    agentData.value = rspta.datos.response;
                    
                    console.log(agentData.value)
                    // console.log(agentData.value);

                    // const totalVentas = agentData.value.reduce((sum, invoice) => {
                    //     return sum + invoice.detalles.reduce((detalleSum, detalle) => detalleSum + detalle.valorUnitario * detalle.cantidad, 0);
                    // }, 0);

                    // console.log(totalVentas)

                }else{
                    console.log("ocurrio un error");
                }
            } catch (error) {
                console.log(error);
                toast.error("Ocurrio un error al listar la información");
            } finally {
                isLoading.value = false;
            }
        }


        const handleFilterChange = async (filterData: { selectedAgents: string[]; dateRange: Date[] }) => {
            const { selectedAgents, dateRange } = filterData;
            uuidAgent.value = selectedAgents;
            startDate.value = dateRange[0] ? dateRange[0].toISOString().split('T')[0] : null;
            endDate.value = dateRange[1] ? dateRange[1].toISOString().split('T')[0] : null;

            await getDataAgents();

        };

        return {
            handleFilterChange,
            isLoading,
            agentData
        }
    }
});
</script>