<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
            <h5 class="card-title fw-bold mb-15 mb-md-25">Actualizar vehiculo</h5>
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Numero:</label>
                    <InputText class="w-100" type="text" v-model="numero" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona un modelo:</label>
                    <Dropdown v-model="selectedModel" :options="modelos" optionLabel="modelo" optionValue="uuid"
                        placeholder="Seleccione un modelo" class="w-100" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Descripcion:</label>
                    <InputText class="w-100" type="text" v-model="descripcion" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Serie:</label>
                    <InputText class="w-100" type="text" v-model="serie" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Placas:</label>
                    <InputText class="w-100" type="text" v-model="placas" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Color:</label>
                    <InputText class="w-100" type="text" v-model="color" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Tarjeta de circulacion:</label>
                    <InputText class="w-100" type="text" v-model="circulacion" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Estado de Emision:</label>
                    <InputText class="w-100" type="text" v-model="emision" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Observaciones:</label>
                    <FloatLabel>
                        <Textarea v-model="observacion" rows="1" cols="30" class="w-100" />
                    </FloatLabel>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona un propietario:</label>
                    <Dropdown v-model="selectedEmpresa" :options="empresas" optionLabel="empresa" optionValue="uuid"
                        placeholder="Selecciona un propietario" class="w-100" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona un ocupante:</label>
                    <Dropdown v-model="selectedOcupante" :options="empresas" optionLabel="empresa" optionValue="uuid"
                        placeholder="Selecciona un ocupante" class="w-100" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona un responsable:</label>
                    <Dropdown v-model="selectedResposable" :options="responsables" optionLabel="nombre" optionValue="uuid_usuario"
                        placeholder="Selecciona un responsable" class="w-100" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Selecciona una aseguradora:</label>
                    <Dropdown v-model="selectedAseguradoras" :options="aseguradoras" optionLabel="aseguradora"
                        optionValue="uuid" placeholder="Selecciona una aseguradora" class="w-100" />
                </div>
                <div class="col-12 col-md-12 pt-3">
                    <button type="button" class="btn btn-md btn-primary me-2" @click="submitForm">Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
</template>
<script lang="ts">
import { ref, onMounted, defineComponent, PropType } from "vue";
import Vehicles from "@/services/VehiclesService";
import { useToast } from "vue-toastification";
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import FloatLabel from 'primevue/floatlabel';
import Textarea from 'primevue/textarea';

export default defineComponent({
    name: "ActualizarVehicle",
    components: { Dropdown, InputText, FloatLabel, Textarea },
    props: {
        uuid: {
            type: String as PropType<string | undefined>,
            required: false,
        },
    },
    setup(props) {
        const toast = useToast();
        const modelos = ref([]);
        const empresas = ref([]);
        const aseguradoras = ref([]);
        const responsables = ref([]);
        const isLoading = ref(false);
        const numero = ref("");
        const descripcion = ref("");
        const serie = ref("");
        const placas = ref("");
        const color = ref("");
        const circulacion = ref("");
        const emision = ref("");
        const observacion = ref("");
        const selectedModel = ref(null);
        const selectedEmpresa = ref(null);
        const selectedOcupante = ref(null);
        const selectedAseguradoras = ref(null);
        const selectedResposable = ref(null);

        const loadOptions = async () => {
            isLoading.value = true;
            try {
                const responseModelos = await new Vehicles().ListVehicle();
                modelos.value = responseModelos.response;
            } catch (error) {
                toast.error("Error al cargar los modelos");
            }

            try {
                const responseEmpresas = await new Vehicles().ListEmpresa();
                empresas.value = responseEmpresas.response;
            } catch (error) {
                toast.error("Error al cargar las empresas");
            }

            try {
                const responseAseguradoras = await new Vehicles().ListAseguradora();
                aseguradoras.value = responseAseguradoras.response;
            } catch (error) {
                toast.error("Error al cargar las aseguradoras");
            }

            try {
                const responseResponsables = await new Vehicles().ListResponsable();
                responsables.value = responseResponsables.response;
            } catch (error) {
                toast.error("Error al cargar los responsables");
            }
            isLoading.value = false;
        };
        const getInfoVehicle = async () => {
            try {

                const response = await new Vehicles().InfoVehicle({ uuid_vehiculo: props.uuid });
                console.log("lo que trae de info", response)
                if (!response.error) {
                    const vehicle = response.response;
                    numero.value = vehicle.numero;
                    descripcion.value = vehicle.descripcion;
                    serie.value = vehicle.serie;
                    placas.value = vehicle.placas;
                    color.value = vehicle.color;
                    circulacion.value = vehicle.tarjeta_circulacion;
                    emision.value = vehicle.emitida;
                    observacion.value = vehicle.observacion;
                    selectedModel.value = vehicle.uuid_modelo;
                    selectedEmpresa.value = vehicle.uuid_empresa_propietaria;
                    selectedOcupante.value = vehicle.uuid_empresa_ocupante;
                    selectedAseguradoras.value = vehicle.uuid_aseguradora;
                    selectedResposable.value = vehicle.uuid_responsable;
                } else {
                    toast.error("Error al cargar los datos del vehículo.");
                }
            } catch (error) {
                console.error(error);
                toast.error("Error en la carga de datos.");
            }        
        };

        const submitForm = async () => {
            isLoading.value = true;
            const data = {
                uuid_vehiculo: props.uuid,
                numero: numero.value,
                uuid_modelo: selectedModel.value,
                descripcion: descripcion.value,
                serie: serie.value,
                placas: placas.value,
                color: color.value,
                tarjeta_circulacion: circulacion.value,
                emitida: emision.value,
                observacion: observacion.value,
                uuid_propietario: selectedEmpresa.value,
                uuid_ocupante: selectedOcupante.value,
                uuid_aseguradora: selectedAseguradoras.value,
                uuid_responsable: selectedResposable.value
            };
            console.log("Payload de actualización:", data);

            try {
                const response = await new Vehicles().Editvehicle(data);
                if (!response.error) {
                    toast.success("Vehículo actualizado correctamente.");
                    isLoading.value = false;
                } else {
                    toast.error("Error en la actualización del vehículo.");
                }
            } catch (error) {
                console.error("imprime el error",error);
                toast.error("Error al guardar los cambios.");
            }
        };

        onMounted(async () => {
            await loadOptions();  
            await getInfoVehicle();
        });

        return {
            numero,
            descripcion,
            serie,
            placas,
            color,
            circulacion,
            emision,
            observacion,
            selectedModel,
            selectedEmpresa,
            selectedOcupante,
            selectedAseguradoras,
            selectedResposable,
            modelos,
            empresas,
            aseguradoras,
            responsables,
            submitForm,
            isLoading
        };
    },
});

</script>