import { ref } from "vue";

export const ruteoData = ref({
    uuid_ruteo: "",
    fecha_inicio: "",
    uuid_cliente: "",
    razon_social: "",
    cliente: "",
    nombre_establecimiento: null,
    localidad: "",
    giro: "",
    uuid_tipo_actividad: "",
    cotizado: "",
    souvenir_promocion: null,
    observacion: null,
    nombre: null,
    uuid_tipo_compra: null,
    tipo_compra: null,
    uuid_proyeccion: null,
    venta: null,
    cobranza: null,
    integrantes: [],
    idactividad_ruteo: 0
});

export const ventaData = ref({
    uuid_ruteo: "",
    fecha_inicio: "",
    uuid_cliente: "",
    razon_social: "",
    cliente: "",
    nombre_establecimiento: null,
    localidad: "",
    giro: "",
    uuid_tipo_actividad: "",
    cotizado: "",
    souvenir_promocion: null,
    observacion: null,
    nombre: null,
    uuid_tipo_compra: null,
    tipo_compra: null,
    uuid_proyeccion: null,
    venta: null,
    cobranza: null,
    integrantes: [],
    idactividad_ruteo: 0
});

export const eventData = ref({
    uuid_ruteo: "",
    fecha_inicio: "",
    uuid_cliente: "",
    razon_social: "",
    cliente: "",
    nombre_establecimiento: null,
    localidad: "",
    giro: "",
    uuid_tipo_actividad: "",
    cotizado: "",
    souvenir_promocion: null,
    observacion: null,
    nombre: null,
    uuid_tipo_compra: null,
    tipo_compra: null,
    uuid_proyeccion: null,
    venta: null,
    cobranza: null,
    integrantes: [],
    idactividad_ruteo: 0
  });

  export const cobranzaData = ref({
    uuid_ruteo: "",
    fecha_inicio: "",
    uuid_cliente: "",
    razon_social: "",
    cliente: "",
    nombre_establecimiento: null,
    localidad: "",
    giro: "",
    uuid_tipo_actividad: "",
    cotizado: "",
    souvenir_promocion: null,
    observacion: null,
    nombre: null,
    uuid_tipo_compra: null,
    tipo_compra: null,
    uuid_proyeccion: null,
    venta: null,
    cobranza: null,
    integrantes: [],
    idactividad_ruteo: 0
  });


  export const ventacobraData = ref({
    uuid_ruteo: "",
    fecha_inicio: "",
    uuid_cliente: "",
    razon_social: "",
    cliente: "",
    nombre_establecimiento: null,
    localidad: "",
    giro: "",
    uuid_tipo_actividad: "",
    cotizado: "",
    souvenir_promocion: null,
    observacion: null,
    nombre: null,
    uuid_tipo_compra: null,
    tipo_compra: null,
    uuid_proyeccion: null,
    venta: null,
    cobranza: null,
    integrantes: [],
    idactividad_ruteo: 0,
  });

  export const prospeccionData = ref({
    uuid_ruteo: "",
    fecha_inicio: "",
    uuid_cliente: "",
    razon_social: "",
    cliente: "",
    nombre_establecimiento: null,
    localidad: "",
    giro: "",
    uuid_tipo_actividad: "",
    cotizado: "",
    souvenir_promocion: null,
    observacion: null,
    nombre: null,
    uuid_tipo_compra: null,
    tipo_compra: null,
    uuid_proyeccion: null,
    venta: null,
    cobranza: null,
    integrantes: [],
    idactividad_ruteo: 0
});

export const visitaSeguimientoData = ref({
  actividad: "",
  uuid_ruteo: "",
  segmento: "",
  seguimiento: "",
  fecha_inicio: "",
  uuid_cliente: "",
  razon_social: "",
  cliente: "",
  nombre_establecimiento: null,
  localidad: "",
  giro: "",
  uuid_tipo_actividad: "",
  cotizado: "",
  souvenir_promocion: null,
  observacion: null,
  nombre: null,
  uuid_tipo_compra: null,
  tipo_compra: null,
  uuid_proyeccion: null,
  venta: null,
  cobranza: null,
  integrantes: [],
  idactividad_ruteo: 0,
});


export const visitaTecnicaData = ref({
  actividad: "",
  uuid_ruteo: "",
  segmento: "",
  seguimiento: "",
  fecha_inicio: "",
  uuid_cliente: "",
  razon_social: "",
  cliente: "",
  nombre_establecimiento: null,
  localidad: "",
  giro: "",
  uuid_tipo_actividad: "",
  cotizado: "",
  souvenir_promocion: null,
  observacion: null,
  nombre: null,
  uuid_tipo_compra: null,
  tipo_compra: null,
  uuid_proyeccion: null,
  venta: null,
  cobranza: null,
  integrantes: [],
  idactividad_ruteo: 0,
});

export const visitaReactivacionData = ref({
  actividad: "",
  uuid_ruteo: "",
  segmento: "",
  seguimiento: "",
  fecha_inicio: "",
  uuid_cliente: "",
  razon_social: "",
  cliente: "",
  nombre_establecimiento: null,
  localidad: "",
  giro: "",
  uuid_tipo_actividad: "",
  cotizado: "",
  souvenir_promocion: null,
  observacion: null,
  nombre: null,
  uuid_tipo_compra: null,
  tipo_compra: null,
  uuid_proyeccion: null,
  venta: null,
  cobranza: null,
  integrantes: [],
  idactividad_ruteo: 0,
});

export const capacitacionData = ref({
  actividad: "",
  uuid_ruteo: "",
  segmento: "",
  seguimiento: "",
  fecha_inicio: "",
  uuid_cliente: "",
  razon_social: "",
  cliente: "",
  nombre_establecimiento: null,
  localidad: "",
  giro: "",
  uuid_tipo_actividad: "",
  cotizado: "",
  souvenir_promocion: null,
  observacion: null,
  nombre: null,
  uuid_tipo_compra: null,
  tipo_compra: null,
  uuid_proyeccion: null,
  venta: null,
  cobranza: null,
  integrantes: [],
  idactividad_ruteo: 0,
});

export const entregaProductoData = ref({
  actividad: "",
  uuid_ruteo: "",
  segmento: "",
  seguimiento: "",
  fecha_inicio: "",
  uuid_cliente: "",
  razon_social: "",
  cliente: "",
  nombre_establecimiento: null,
  localidad: "",
  giro: "",
  uuid_tipo_actividad: "",
  cotizado: "",
  souvenir_promocion: null,
  observacion: null,
  nombre: null,
  uuid_tipo_compra: null,
  tipo_compra: null,
  uuid_proyeccion: null,
  venta: null,
  cobranza: null,
  integrantes: [],
  idactividad_ruteo: 0,
});

export const inspeccionData = ref({
  actividad: "",
  uuid_ruteo: "",
  segmento: "",
  seguimiento: "",
  fecha_inicio: "",
  uuid_cliente: "",
  razon_social: "",
  cliente: "",
  nombre_establecimiento: null,
  localidad: "",
  giro: "",
  uuid_tipo_actividad: "",
  cotizado: "",
  souvenir_promocion: null,
  observacion: null,
  nombre: null,
  uuid_tipo_compra: null,
  tipo_compra: null,
  uuid_proyeccion: null,
  venta: null,
  cobranza: null,
  integrantes: [],
  idactividad_ruteo: 0,
});


