<template>
  <div class="row">
    <div class="col-xxl-8">
      <div class="card mb-25 border-0 rounded-0 bg-white course-details-card">
        <div
          class="card-body p-15 p-sm-20 p-md-25 p-lg-30 lessons-preview-info letter-spacing"
        >
          <h2 class="fw-bold mb-15 mb-md-20">
            Artificial Intelligence A-Z 2023: Build an AI with ChatGPT4
          </h2>
          <div class="image position-relative">
            <img
              src="../../../assets/images/courses/course3.jpg"
              class="mb-15 mb-sm-20 mb-md-25"
              alt="course-details-image"
            />
            <button
              type="button"
              class="video-btn transition border-0 p-0 d-inline-block rounded-circle"
              data-bs-toggle="modal"
              data-bs-target="#videoModal"
            >
              <i class="flaticon-play-button-arrowhead"></i>
            </button>
          </div>
          <div class="mb-12 mb-lg-15">
            <h5 class="card-title fw-bold mb-0 fs-15 fs-md-16 fs-lg-18">
              About The Course
            </h5>
          </div>
          <p class="text-paragraph fs-md-15 fs-lg-16 text-paragraph lh-base">
            Combine the power of Data Science, Machine Learning and Deep
            Learning to create powerful AI for Real-World applications!
          </p>
          <p class="text-paragraph fs-md-15 fs-lg-16 text-paragraph lh-base">
            We’re fully committed to making this the most accessible and
            results-driven AI course on the planet. This requires us to be there
            when you need our help. That’s why we’ve put together a team of
            professional Data Scientists to support you in your journey, meaning
            you’ll get a response from us within 48 hours maximum.
          </p>
          <p class="text-paragraph fs-md-15 fs-lg-16 text-paragraph lh-base">
            Learn key AI concepts and intuition training to get you quickly up
            to speed with all things AI.
          </p>
          <div class="course-info bg-gray mt-20 mt-lg-25">
            <div class="row">
              <div class="col-sm-6 col-lg-3 col-xl-6 col-xxxl-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-info rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-bar-chart-1"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                    >Course Level:</span
                  >
                  <span class="fw-medium text-paragraph">Beginner</span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 col-xl-6 col-xxxl-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-success rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-clock"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                    >Course Duration:</span
                  >
                  <span class="fw-medium text-paragraph">32 Hours</span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 col-xl-6 col-xxxl-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-warning rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-copy"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                    >Total Lessons:</span
                  >
                  <span class="fw-medium text-paragraph">24 Lessons</span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 col-xl-6 col-xxxl-3">
                <div class="info-card position-relative">
                  <div
                    class="icon bg-danger rounded-circle text-center position-absolute start-0 text-white"
                  >
                    <i class="flaticon-people"></i>
                  </div>
                  <span
                    class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                    >Total Students:</span
                  >
                  <span class="fw-medium text-paragraph">204</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-transparent letter-spacing course-details-card"
      >
        <div class="card-body p-0">
          <div class="accordion" id="tableOfContentAccordion">
            <div class="accordion-item border-0">
              <button
                class="accordion-button d-block w-100 text-start text-black fs-14 fs-md-15 fs-lg-16 fw-medium rounded-0 bg-white border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#TableContentCollapseOne"
                aria-expanded="true"
                aria-controls="TableContentCollapseOne"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="number bg-primary text-center text-white rounded-circle fs-13 fw-semibold"
                  >
                    1
                  </div>
                  Welcome to the course!
                </div>
              </button>
              <div
                id="TableContentCollapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#tableOfContentAccordion"
              >
                <div class="accordion-body">
                  <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Welcome Challenge!
                      </a>
                      <span class="text-muted fw-medium ms-12"> 01:22 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Why AI?
                      </a>
                      <span class="text-muted fw-medium ms-12"> 05:10 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Course Structure
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Installing Anaconda
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Use ChatGPT to Build AI More Efficiently
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item border-0">
              <button
                class="accordion-button collapsed d-block w-100 text-start text-black fs-14 fs-md-15 fs-lg-16 fw-medium rounded-0 bg-white border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#TableContentCollapseTwo"
                aria-expanded="true"
                aria-controls="TableContentCollapseTwo"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="number bg-primary text-center text-white rounded-circle fs-13 fw-semibold"
                  >
                    2
                  </div>
                  Python Setup
                </div>
              </button>
              <div
                id="TableContentCollapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#tableOfContentAccordion"
              >
                <div class="accordion-body">
                  <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Welcome Challenge!
                      </a>
                      <span class="text-muted fw-medium ms-12"> 01:22 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Why AI?
                      </a>
                      <span class="text-muted fw-medium ms-12"> 05:10 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Course Structure
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Installing Anaconda
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Use ChatGPT to Build AI More Efficiently
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item border-0">
              <button
                class="accordion-button collapsed d-block w-100 text-start text-black fs-14 fs-md-15 fs-lg-16 fw-medium rounded-0 bg-white border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#TableContentCollapseThree"
                aria-expanded="true"
                aria-controls="TableContentCollapseThree"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="number bg-primary text-center text-white rounded-circle fs-13 fw-semibold"
                  >
                    3
                  </div>
                  Python Objects & Data Structures Basics
                </div>
              </button>
              <div
                id="TableContentCollapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#tableOfContentAccordion"
              >
                <div class="accordion-body">
                  <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Welcome Challenge!
                      </a>
                      <span class="text-muted fw-medium ms-12"> 01:22 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Why AI?
                      </a>
                      <span class="text-muted fw-medium ms-12"> 05:10 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Course Structure
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Installing Anaconda
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Use ChatGPT to Build AI More Efficiently
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item border-0">
              <button
                class="accordion-button collapsed d-block w-100 text-start text-black fs-14 fs-md-15 fs-lg-16 fw-medium rounded-0 bg-white border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#TableContentCollapseFour"
                aria-expanded="true"
                aria-controls="TableContentCollapseFour"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="number bg-primary text-center text-white rounded-circle fs-13 fw-semibold"
                  >
                    4
                  </div>
                  Python Comparison Operators
                </div>
              </button>
              <div
                id="TableContentCollapseFour"
                class="accordion-collapse collapse"
                data-bs-parent="#tableOfContentAccordion"
              >
                <div class="accordion-body">
                  <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Welcome Challenge!
                      </a>
                      <span class="text-muted fw-medium ms-12"> 01:22 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Why AI?
                      </a>
                      <span class="text-muted fw-medium ms-12"> 05:10 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Course Structure
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Installing Anaconda
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Use ChatGPT to Build AI More Efficiently
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item border-0">
              <button
                class="accordion-button collapsed d-block w-100 text-start text-black fs-14 fs-md-15 fs-lg-16 fw-medium rounded-0 bg-white border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#TableContentCollapseFive"
                aria-expanded="true"
                aria-controls="TableContentCollapseFive"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="number bg-primary text-center text-white rounded-circle fs-13 fw-semibold"
                  >
                    5
                  </div>
                  Python Statements
                </div>
              </button>
              <div
                id="TableContentCollapseFive"
                class="accordion-collapse collapse"
                data-bs-parent="#tableOfContentAccordion"
              >
                <div class="accordion-body">
                  <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Welcome Challenge!
                      </a>
                      <span class="text-muted fw-medium ms-12"> 01:22 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Why AI?
                      </a>
                      <span class="text-muted fw-medium ms-12"> 05:10 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Course Structure
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Installing Anaconda
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Use ChatGPT to Build AI More Efficiently
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item border-0">
              <button
                class="accordion-button collapsed d-block w-100 text-start text-black fs-14 fs-md-15 fs-lg-16 fw-medium rounded-0 bg-white border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#TableContentCollapseSix"
                aria-expanded="true"
                aria-controls="TableContentCollapseSix"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="number bg-primary text-center text-white rounded-circle fs-13 fw-semibold"
                  >
                    6
                  </div>
                  Python Objects & Data Structures Basics
                </div>
              </button>
              <div
                id="TableContentCollapseSix"
                class="accordion-collapse collapse"
                data-bs-parent="#tableOfContentAccordion"
              >
                <div class="accordion-body">
                  <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Welcome Challenge!
                      </a>
                      <span class="text-muted fw-medium ms-12"> 01:22 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Why AI?
                      </a>
                      <span class="text-muted fw-medium ms-12"> 05:10 </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Course Structure
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Installing Anaconda
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0);"
                        class="fs-md-15 text-muted fw-medium text-decoration-none position-relative"
                      >
                        <i class="flaticon-play"></i>
                        Use ChatGPT to Build AI More Efficiently
                      </a>
                      <span class="text-muted fw-medium ms-12">
                        <i class="flaticon-lock lh-1 fs-md-15 fs-lg-16"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="show-more-btn d-block w-100 fw-medium text-center mt-15 mt-md-20 ps-15 pe-15 pt-12 pb-12 pt-md-15 pb-md-15 pt-lg-20 pb-lg-20 transition border-0 bg-primary text-white"
          >
            Buy This Course To See All Content
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade videoModal"
    id="videoModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-15 p-sm-20 p-md-25 p-lg-30">
          <iframe
            src="https://www.youtube.com/embed/r7vdoYwiGIc"
            title="How to Create Metal Textures in Photoshop"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <button
          type="button"
          class="btn-close bg-transparent text-white shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="flaticon-close"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LessonsPreview",
};
</script>