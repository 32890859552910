import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white recent-activity-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "list ps-0 mb-0 list-unstyled" }
const _hoisted_4 = { class: "position-relative fw-medium text-dark-emphasis" }
const _hoisted_5 = { class: "position-relative fw-medium text-dark-emphasis" }
const _hoisted_6 = { class: "position-relative fw-medium text-dark-emphasis" }
const _hoisted_7 = { class: "position-relative fw-medium text-dark-emphasis" }
const _hoisted_8 = { class: "position-relative fw-medium text-dark-emphasis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"mb-15 mb-md-20 mb-xxxl-25 d-flex align-items-center justify-content-between\"><h5 class=\"card-title fw-bold mb-0\">Recent Activity</h5><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div>", 1)),
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "icon position-absolute start-0 rounded-circle text-center" }, [
            _createElementVNode("i", { class: "ph-duotone ph-envelope-simple" })
          ], -1)),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-black fw-bold" }, "J. Ronan", -1)),
          _cache[3] || (_cache[3] = _createTextVNode(" sent an Email ")),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "d-block fs-13 mt-1" }, "35 mins ago", -1)),
          _createVNode(_component_router_link, {
            to: "/project-management",
            class: "link-btn text-decoration-none position-absolute end-0"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_5, [
          _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"icon position-absolute start-0 rounded-circle text-center\"><i class=\"ph-duotone ph-first-aid-kit\"></i></div><span class=\"text-black fw-bold\">Victoria</span> archived a <span class=\"text-black fw-bold\">Board for Project</span> in team <span class=\"d-block fs-13 mt-1\">1 hr ago</span>", 6)),
          _createVNode(_component_router_link, {
            to: "/project-management",
            class: "link-btn text-decoration-none position-absolute end-0"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_6, [
          _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"icon position-absolute start-0 rounded-circle text-center\"><i class=\"ph-duotone ph-check-circle\"></i></div><span class=\"text-black fw-bold\">Walter White</span> completed the project <span class=\"text-black fw-bold\">“The Dashboard”</span><span class=\"d-block fs-13 mt-1\">2 hrs ago</span>", 5)),
          _createVNode(_component_router_link, {
            to: "/project-management",
            class: "link-btn text-decoration-none position-absolute end-0"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_7, [
          _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"icon position-absolute start-0 rounded-circle text-center\"><i class=\"ph-duotone ph-shield-plus\"></i></div><span class=\"text-black fw-bold\">Jennifer</span> added <span class=\"text-black fw-bold\">Micheal</span> in the project <span class=\"text-black fw-bold\">“The Adlash Design”</span><span class=\"d-block fs-13 mt-1\">3 hrs ago</span>", 7)),
          _createVNode(_component_router_link, {
            to: "/project-management",
            class: "link-btn text-decoration-none position-absolute end-0"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_8, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "icon position-absolute start-0 rounded-circle text-center" }, [
            _createElementVNode("i", { class: "ph-duotone ph-envelope-simple" })
          ], -1)),
          _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-black fw-bold" }, "S. Smith", -1)),
          _cache[14] || (_cache[14] = _createTextVNode(" sent an Email ")),
          _cache[15] || (_cache[15] = _createElementVNode("span", { class: "d-block fs-13 mt-1" }, "1 day ago", -1)),
          _createVNode(_component_router_link, {
            to: "/project-management",
            class: "link-btn text-decoration-none position-absolute end-0"
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}