<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <span class="fw-semibold me-5 text-black">Use example:</span>
          <code>
            &lt;<span class="red">i </span>
            <span class="green">class</span>=<span class="yellow"
              >&quot;ph ph-address-book&quot;</span
            >&gt;&lt;/<span class="red">i</span>&gt;
          </code>
          <span class="ms-5 me-5 text-muted fw-semibold">or</span>
          <code>
            &lt;<span class="red">span </span>
            <span class="green">class</span>=<span class="yellow"
              >&quot;ph ph-address-book&quot;</span
            >&gt;&lt;/<span class="red">span</span>&gt;
          </code>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="ph ph-address-book fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-address-book&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="ph ph-air-traffic-control fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-air-traffic-control&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-airplane fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-airplane&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-airplay fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-airplay&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-alarm fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-alarm&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-alien fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-alien&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="ph ph-align-bottom fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-align-bottom&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-aperture fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-aperture&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="ph ph-anchor-simple fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-anchor-simple&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="ph ph-android-logo fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-android-logo&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-app-window fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-app-window&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="ph ph-apple-podcasts-logo fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-apple-podcasts-logo&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-archive fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-archive&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-archive-box fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-archive-box&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-armchair fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-armchair&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="ph ph-arrow-circle-down fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-arrow-circle-down&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i class="ph ph-at fs-30 lh-1 position-relative top-2"></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-at&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxxl-2">
      <div
        class="card mb-25 border-0 rounded-0 bg-white text-center letter-spacing"
      >
        <div class="card-body p-0">
          <div class="p-15 p-sm-20 p-md-25">
            <i
              class="ph ph-article-ny-times fs-30 lh-1 position-relative top-2"
            ></i>
          </div>
          <div class="fs-md-15 fs-lg-16 p-10 bg-gray fw-medium">
            <code class="text-black">
              <span class="green">class</span>=<span class="yellow"
                >&quot;ph ph-article-ny-times&quot;</span
              >
            </code>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="text-center mb-25">
        <a
          href="https://phosphoricons.com/"
          class="default-btn d-inline-block text-white text-decoration-none fw-medium pt-10 pb-10 pt-md-12 pb-md-12 ps-25 pe-25 rounded-1 transition"
          target="_blank"
        >
          View All Icons
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhosphoriconsContent",
};
</script>