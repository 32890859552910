<template>
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
            <h5 class="card-title fw-bold mb-15 mb-md-25">Registro</h5>
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTitle" class="form-label fw-medium">Fecha:</label>
                    <Calendar v-model="fecha" class="w-100" showIcon :showOnFocus="true" inputId="buttondisplay" />
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label for="taskPartners" class="form-label fw-medium">Agente:</label>
                    <Dropdown v-model="selectedPermisos.agente" :options="agentList" :filter="true" optionValue="uuid"
                        optionLabel="agente" placeholder="Seleccione un agente" class="w-100" />
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label for="taskPartners" class="form-label fw-medium">Folio Factura:</label>
                    <Dropdown v-model="selectedPermisos.folioFactura" :options="invoiceList"
                        :optionLabel="formatOptionLabel" :filter="true" optionValue="uuid"
                        placeholder="Seleccione una factura" class="w-100" />
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label for="taskPartners" class="form-label fw-medium">Cliente:</label>
                    <input type="text" class="form-control shadow-none" v-model="selectedPermisos.cliente" readonly>
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTitle" class="form-label fw-medium">Monto:</label>
                    <input type="text" placeholder="Ingrese monto" class="form-control shadow-none" v-model="monto" />
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTags" class="form-label fw-medium">Forma de pago:</label>
                    <Dropdown v-model="selectedPermisos.formaPago" :options="methods" optionLabel="nombre"
                        optionValue="uuid" :filter="true" placeholder="Seleccione una forma de pago" class="w-100" />
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTitle" class="form-label fw-medium">Estatus del sistema:</label>
                    <input type="text" placeholder="Ingrese status" class="form-control shadow-none"
                        v-model="selectedPermisos.statusSistema" />
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label for="taskTitle" class="form-label fw-medium">Folio REP:</label>
                    <input type="text" placeholder="Ingrese folio" class="form-control shadow-none"
                        v-model="selectedPermisos.folioRep" />
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label for="taskPartners" class="form-label fw-medium">Observaciones:</label>

                    <textarea id="newComment" class="form-control" v-model="selectedPermisos.observaciones" rows="2"
                        placeholder="Escribe alguna observación aquí..."></textarea>
                </div>


                <div class="col-12 col-md-6 mb-3">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                            Archivos/ imágenes* :
                        </label>
                        <FileUpload ref="fileUploadRef" mode="basic" name="documento[]" :multiple="true"
                            accept="image/*, application/pdf" :maxFileSize="1000000" :auto="false"
                            chooseLabel="Explorar" style="width: 100%" @select="manageSelectedFiles" />
                    </div>
                    <div v-if="filesPreview.length" class="mt-3">
                        <h5>Previsualización:</h5>
                        <div class="row">
                            <div v-for="(archivo, index) in filesPreview" :key="index" class="col-4 mb-3">
                                <div class="preview-card position-relative text-center">
                                    <div
                                        class="preview-content d-flex flex-column align-items-center justify-content-center">
                                        <img v-if="archivo.tipo === 'imagen'" :src="archivo.url" alt="Imagen subida"
                                            class="preview-image" />
                                        <div v-else class="preview-pdf">📄 PDF: {{ archivo.nombre }}</div>
                                        <button @click="deleteFile(index)" class="delete-button">
                                            &times;
                                        </button>
                                        <input v-model="archivo.observacion" type="text" placeholder="Observación"
                                        class="mt-2 form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 pt-3">
                    <button type="button" class="btn btn-md btn-primary me-2" @click="submitForm">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent, watch } from "vue";
import control from "@/services/mapService";
import { useToast } from "vue-toastification";
import Dropdown from 'primevue/dropdown';
import FileUpload from "primevue/fileupload";
import { FilePreview, UploadedFileFact, MethodsItem, invoiceListItem, listAgentItem } from "@/interfaces/interfaces";
import Calendar from "primevue/calendar";
import createSocketService from "@/services/socketService";
import moment from "moment";

export default defineComponent({
    name: "FormCollection",
    components: { Dropdown, FileUpload, Calendar },
    setup() {
        const fecha = ref();
        const monto = ref<string>("");
        const permisos = ref<any[]>([]);
        const methods = ref<MethodsItem[]>([]);
        const invoiceList = ref<invoiceListItem[]>([]);
        const agentList = ref<listAgentItem[]>([]);
        const selectedPermisos = ref({
            folioFactura: null,
            cliente: "",
            observaciones: "",
            agente: null,
            formaPago: null,
            statusSistema: "",
            folioRep: "",
            statusPago: "",
            verificacion: "",
        });
        const isLoading = ref<boolean>(false);
        const files = ref<any[]>([]);
        const toast = useToast();
        const fileUploadRef = ref<any>(null);
        const filesPreview = ref<FilePreview[]>([]);
        const uploadedFiles = ref<UploadedFileFact[]>([]);
        let socketService: any = null;

        const getPermisos = async () => {
            try {
                const response = await new control().ListPermisos();
                if (!response.error) {
                    permisos.value = response.datos;
                }
            } catch (error) {
                toast.error("Error al cargar los permisos");
            }
        };

        const getListAgent = async () => {
            try {

                socketService.emit("list-sales-agent");
                socketService.on("list-sales-agent", (rspta: any) => {
                    console.log(rspta)
                    try {
                        if (!rspta.error) {
                            agentList.value = rspta.response;
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error("Error en obtener los agentes");
                    }
                });

            } catch (error) {
                toast.error("Error en obtener a solicitud");
            }
        }

        const getInvoice = async (uuidUsuario) => {
            try {

                const fechadata = {
                    "uuidUsuario": uuidUsuario
                };

                socketService.emit("list-payment-invoice-user", fechadata);
                socketService.on("list-payment-invoice-user", (rspta: any) => {
                    console.log(rspta)
                    try {
                        if (!rspta.error) {
                            invoiceList.value = rspta.response;
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error("Error en obtener las facturas");
                    }
                });

            } catch (error) {
                toast.error("Error en obtener a solicitud");
            }
        }

        const formatOptionLabel = (option) => {
            return `${option.serie} - ${option.folio}`;
        };


        const getPaymentMethods = async () => {
            try {
                socketService.emit("list-payment-methods");
                socketService.on("list-payment-methods", (rspta: any) => {
                    try {
                        if (!rspta.error) {
                            methods.value = rspta.response;
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error("Error en obtener los métodos de pagos");
                    }
                });

            } catch (error) {
                toast.error("Error en obtener los métodos de pagos");
            }
        }

        const manageSelectedFiles = (event: any) => {
            const archivos = event.files;

            archivos.forEach((archivo: File) => {
                const existingFile = filesPreview.value.find(file => file.nombre === archivo.name);

                if (!existingFile) {
                    if (archivo.type.startsWith('image/')) {
                        const url = URL.createObjectURL(archivo);
                        filesPreview.value.push({ tipo: 'imagen', url, nombre: archivo.name, observacion: '' });
                    } else if (archivo.type === 'application/pdf') {
                        filesPreview.value.push({ tipo: 'pdf', nombre: archivo.name, observacion: '' });
                    }
                }
            });
        };

        const deleteFile = (index: number) => {
            filesPreview.value.splice(index, 1);
        };


        const submitForm = async () => {
            isLoading.value = true;
            try {
                const files = fileUploadRef.value.files;
                if (files && files.length > 0) {
                    const filePromises = Array.from(files).map((file) => {
                        if (!(file instanceof File)) {
                            throw new Error("El archivo no es de tipo File.");
                        }

                        return new Promise<UploadedFileFact>((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);

                            reader.onload = () => {
                                const result = reader.result as string;
                                const observacion = filesPreview.value.find(f => f.nombre === file.name)?.observacion || '';
                                resolve({
                                    base64: result.split(',')[1],
                                    contentType: file.type,
                                    nombreArchivo: observacion || file.name,
                                });
                            };

                            reader.onerror = () => reject(new Error("Error al leer el archivo."));
                        });
                    });

                    uploadedFiles.value = await Promise.all(filePromises);
                }

                const data = {
                    uuidFactura: selectedPermisos.value.folioFactura,
                    uuidFormaPago: selectedPermisos.value.formaPago,
                    fechaPago: moment(fecha.value).format('DD/MM/YYYY'),
                    monto: monto.value,
                    statusSistema: selectedPermisos.value.statusSistema,
                    folioRep: selectedPermisos.value.folioRep,
                    statusPago: "",
                    verificacion: "",
                    observaciones: selectedPermisos.value.observaciones,
                    arrayImagen: uploadedFiles.value,
                };

                socketService.emit("save-payment-invoice", data);
                

                socketService.on("save-payment-invoice", (rspta: any) => {
                    try {
                        if (!rspta.error) {
                            toast.success("Datos guardados exitosamente");
                        }
                        else{
                            toast.error("Error al guardar los datos");
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error("Error al guardar los datos");
                    }finally{
                        isLoading.value = false;
                    }
                });
            } catch (error) {
                console.error(error);
                toast.error("Error al guardar el formulario");
            } finally {
                isLoading.value = false;
            }
        };

        watch(() => selectedPermisos.value.folioFactura, (newVal) => {
            const selectedInvoice = invoiceList.value.find(invoice => invoice.uuid === newVal);
            selectedPermisos.value.cliente = selectedInvoice ? selectedInvoice.razon_social : '';
        });

        watch(() => selectedPermisos.value.agente, (newAgente) => {
            if (newAgente) {
                invoiceList.value = [];
                getInvoice(newAgente);
            } else {
                invoiceList.value = [];
            }
        });

        onMounted(async () => {
            try {
                isLoading.value = true;
                socketService = await createSocketService();
                socketService.connect();

                await getListAgent();
                await getPermisos();
                await getPaymentMethods();
            } catch (error) {
                console.log("ocurrio un error")
            } finally {
                isLoading.value = false;
            }

        });

        return {
            fecha,
            monto,
            permisos,
            selectedPermisos,
            isLoading,
            files,
            submitForm,
            fileUploadRef,
            manageSelectedFiles,
            filesPreview,
            deleteFile,
            methods,
            invoiceList,
            formatOptionLabel,
            agentList
        };
    },
});
</script>
<style scoped>
.preview-card {
    height: 150px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.preview-content {
    height: 100%;
    width: 100%;
    position: relative;
}

.preview-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.preview-pdf {
    font-size: 1em;
    color: #333;
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.8);
    border: none;
    color: white;
    font-size: 1em;
    cursor: pointer;
    border-radius: 50%;
    padding: 2px 6px;
}
</style>