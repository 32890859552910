import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content_app"
}
const _hoisted_2 = {
  key: 1,
  class: "main-content login"
}
const _hoisted_3 = { class: "container d-flex flex-column overflow-hidden transition" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_ctx.isAuthenticated)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isHeaderVisible)
          ? (_openBlock(), _createBlock(_component_MainHeader, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_MainSidebar),
        _createElementVNode("div", {
          class: _normalizeClass([['main-content', { 'no-header': _ctx.isHeaderVisible, 'has-header': !_ctx.isHeaderVisible }], "d-flex flex-column transition overflow-hidden"])
        }, [
          _createVNode(_component_router_view),
          (!_ctx.isMapRoute)
            ? (_openBlock(), _createBlock(_component_MainFooter, { key: 0 }))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_view)
        ])
      ]))
}