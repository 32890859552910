import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-7 col-xxxl-6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-6 col-xxxl-6 col-md-6" }
const _hoisted_5 = { class: "col-lg-6 col-xxxl-6 col-md-6" }
const _hoisted_6 = { class: "col-xxl-5 col-xxxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_ExpectedEarnings = _resolveComponent("ExpectedEarnings")!
  const _component_AverageDailySales = _resolveComponent("AverageDailySales")!
  const _component_StatsBoxes = _resolveComponent("StatsBoxes")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: "Cargando..."
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_WhatHappening, {
        dataArray: _ctx.dataArray,
        dataWarehouse: _ctx.dataWarehouse
      }, null, 8, ["dataArray", "dataWarehouse"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ExpectedEarnings, { dataArray: _ctx.dataArray }, null, 8, ["dataArray"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AverageDailySales, { dataArray: _ctx.dataArray }, null, 8, ["dataArray"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_StatsBoxes, { dataWarehouse: _ctx.dataWarehouse }, null, 8, ["dataWarehouse"])
    ])
  ]))
}