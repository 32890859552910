import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white expected-earnings-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = {
  id: "earningChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<span class=\"d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis\"> EXPECTED EARNINGS </span><h4 class=\"fw-black mb-12 lh-1\">$32,000</h4><span class=\"fw-bold text-success text-badge d-inline-block\"> 5.5% <i class=\"flaticon-up-arrow fs-11 lh-1 position-relative top-1\"></i></span><ul class=\"list ps-0 mb-0 list-unstyled mt-15\"><li class=\"text-muted position-relative fw-medium\"> Groceries - <span class=\"text-black fw-bold\">$9,500</span></li><li class=\"text-muted position-relative fw-medium\"> Electronics - <span class=\"text-black fw-bold\">$11,500</span></li><li class=\"text-muted position-relative fw-medium\"> Others - <span class=\"text-black fw-bold\">$11,000</span></li></ul>", 4)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_apexchart, {
          type: "donut",
          height: "150",
          options: _ctx.earningChart,
          series: _ctx.earnings
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}