<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
        <div class="d-sm-flex align-items-center">
          <h5 class="text-center">Listado de vehículos</h5>
        </div>
      </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="search mb-3">
          <InputText v-model="searchTerm" placeholder="Buscar por número o color" class="w-100" />
        </div>
  
        <div class="table-responsive">
          <DataTable :value="filteredVehicles" showGridlines tableStyle="min-width: 50rem" paginator :rows="10"
            :rowsPerPageOptions="[5, 10, 20, 50]">
            <Column class="column-table" header="Opciones" style="width: 20%">
              <template #body="slotProps">
                <div class="d-flex justify-content-start">
                  <Button icon="pi pi-pencil" class="p-button-sm p-button-warning me-1"
                    @click="editVehicle(slotProps.data.uuid)" />
                </div>
              </template>
            </Column>
            <Column class="column-table" field="numero" header="Número" style="width: 30%"></Column>
            <Column class="column-table" field="placas" header="Placas" style="width: 30%"></Column>
            <Column class="column-table" field="color" header="Color" style="width: 20%"></Column>
            <template #footer>
              Total {{ filteredVehicles ? filteredVehicles.length : 0 }} Vehículos.
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, computed } from "vue";
  import { useToast } from "vue-toastification";
  import Vehicles from "@/services/VehiclesService";
  import DataTable from "primevue/datatable";
  import Column from "primevue/column";
  import InputText from "primevue/inputtext";
  import VueLoading from "vue-loading-overlay";
  import Button from "primevue/button";
  import { useRouter } from "vue-router";
  import {VehicleItem } from "@/interfaces/interfaces";
  
  export default defineComponent({
    name: "ListadoAllVehicles",
    components: {
      DataTable,
      Column,
      InputText,
      VueLoading,
      Button,
    },
    setup() {
      const isLoading = ref(true);
      const toast = useToast();
      const vehiclesData = ref<VehicleItem[]>([]);
      const searchTerm = ref("");
      const router = useRouter();
  
      const getVehiclesData = async () => {
        try {
          isLoading.value = true;
          const response = await new Vehicles().ListAllVehicles();
          if (!response.error) {
            vehiclesData.value = response.response;
          }
        } catch (error) {
          toast.error("Ocurrió un error al obtener la lista de vehículos");
        } finally {
          isLoading.value = false;
        }
      };
  
      const editVehicle = (uuid: string) => {
        router.push({ name: "ActualizarVehicle", params: { uuid } });
      };
  
      const filteredVehicles = computed(() => {
      if (!searchTerm.value) {
        return vehiclesData.value;
      }

      return vehiclesData.value.filter((vehicle) => {
        const numeroMatch = vehicle.numero
          ? vehicle.numero.toLowerCase().includes(searchTerm.value.toLowerCase())
          : false;
        const colorMatch = vehicle.color
          ? vehicle.color.toLowerCase().includes(searchTerm.value.toLowerCase())
          : false;

        return numeroMatch || colorMatch;
      });
    });
  
      onMounted(async () => {
        await getVehiclesData();
      });
  
      return {
        isLoading,
        searchTerm,
        filteredVehicles,
        editVehicle,
      };
    },
  });
  </script>
  
  <style>
  .dark .search input {
    background-color: #34334a;
    color: #fff;
  }
  
  .dark .column-table {
    background-color: #34334a;
    color: #fff;
  }
  </style>
  