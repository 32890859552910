<template>
    <DialogComponent v-model:visible="localVisible" :style="{ width: '35rem' }">
        <template #header>
            <div class="pt-20">
                <h5 class="card-subtitle fw-bold">Visita Técnica</h5>
            </div>
        </template>
        <div class="row">

            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Actividad:</label>
                <input type="text" class="form-control" id="cotizado" v-model="visitaTecnicaData.actividad" readonly />
            </div>

            <div class="mb-3 col-6">
                <label for="fechaInicio" class="form-label fw-medium">Fecha Inicio:</label>
                <input type="text" class="form-control" id="fechaInicio" :value="formattedFecha" readonly />
            </div>

            <div class="mb-3 col-6">
                <label for="horaInicio" class="form-label fw-medium">Hora Inicio:</label>
                <input type="text" class="form-control" id="horaInicio" :value="formattedHora" readonly />
            </div>
            <div class="mb-3">
                <label for="razonSocial" class="form-label fw-medium">Cliente:</label>
                <input type="text" class="form-control" id="razonSocial" v-model="visitaTecnicaData.cliente" readonly />
            </div>

            <div class="mb-3">
                <label for="localidad" class="form-label fw-medium">Localidad:</label>
                <input type="text" class="form-control" id="localidad" v-model="visitaTecnicaData.localidad" readonly />
            </div>

            <div class="mb-3">
                <label for="giro" class="form-label fw-medium">Giro:</label>
                <input type="text" class="form-control" id="giro" v-model="visitaTecnicaData.giro"/>
            </div>



            <div class="mb-3">
                <label for="cotizado" class="form-label fw-medium">Segmento:</label>
                <input type="text" class="form-control" id="cotizado" v-model="visitaTecnicaData.segmento" readonly />
            </div>

            <div class="mb-3">
                <label for="observacion" class="form-label fw-medium">Observación:</label>
                <textarea class="form-control" id="observacion" v-model="visitaTecnicaData.observacion" rows="3"
                    readonly></textarea>
            </div>

            <div class="col-md-12 pt-20 text-end">
                <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">
                    Cerrar
                </button>
                <button type="button" class="btn btn-sm btn-primary text-center ms-2" @click="sendEdit">
                    Guardar Cambios
                </button>

            </div>
        </div>
        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    </DialogComponent>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed } from "vue";
import { VisitaTecnicaData } from "@/interfaces/interfaces";
import { useToast } from "vue-toastification";
import createSocketService from "@/services/socketService";

export default defineComponent({
    name: "ModalContentEditVisitaTecnica",
    props: {
        visible: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        visitaTecnica: {
            type: Object as PropType<VisitaTecnicaData>,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const localVisible = ref(props.visible);
        const visitaTecnicaData = ref({ ...props.visitaTecnica });
        const editInProgress = ref(false);
        const isLoading = ref(false);
        let socketService: any = null;
        const toast = useToast();

        const sendEdit = async () => {
            if (editInProgress.value) return;
            editInProgress.value = true;

            const payloadVenta = {
                uuid_ruteo: visitaTecnicaData.value.uuid_ruteo,
                proyeccion: {
                    venta: visitaTecnicaData.value.venta,
                },
                giro: visitaTecnicaData.value.giro,
                souvenirPromocion: visitaTecnicaData.value.souvenir_promocion,
                uuid_tipo_actividad: visitaTecnicaData.value.uuid_tipo_actividad,
                fecha_fin: "",
            };
            console.log(payloadVenta);

            try {
                socketService = await createSocketService();
                socketService.connect();
                isLoading.value = true;
                socketService.emit("edit-activity", payloadVenta);

                socketService.on("edit-activity", (response: any) => {
                    if (editInProgress.value) {
                        if (!response.error) {
                            toast.success("Tarea editada correctamente.");
                            closeModal();
                        } else {
                            toast.error("Error al editar la tarea.");
                        }

                        editInProgress.value = false;
                        isLoading.value = false;
                    }
                });
            } catch (error) {
                toast.error("Error al procesar la edición.");
                console.error(error);
                editInProgress.value = false;
                isLoading.value = false;
            }
        };



        const closeModal = () => {
            localVisible.value = false;
            emit("close");
        };

        watch(
            () => props.visible,
            (newVisible) => {
                localVisible.value = newVisible;
            },
            { immediate: true }
        );

        watch(
            () => props.visitaTecnica,
            (newData) => {
                visitaTecnicaData.value = { ...newData };
            },
            { immediate: true }
        );

        const formattedFecha = computed(() => {
            return visitaTecnicaData.value.fecha_inicio.split("T")[0];
        });

        const formattedHora = computed(() => {
            return visitaTecnicaData.value.fecha_inicio.split("T")[1].slice(0, 5);
        });

        return {
            localVisible,
            visitaTecnicaData,
            closeModal,
            formattedFecha,
            formattedHora,
            sendEdit,
            isLoading
        };
    },
});
</script>
