<template>
  <div
    class="card mb-25 border-0 rounded-0 bg-white text-center maintenance-card"
  >
    <div class="card-body letter-spacing">
      <img
        src="../../../assets/images/maintenance.png"
        alt="maintenance-image"
      />
      <h1 class="fw-bold text-black">
        We are currently performing maintenance
      </h1>
      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base ms-auto me-auto">
        We’re making the system more awesome. We’ll be back shortly.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaintenanceContent",
};
</script>