import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/social-timeline.jpg'
import _imports_1 from '../../../assets/images/user/user1.jpg'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-7 col-xxxl-8" }
const _hoisted_3 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing cover-image-card" }
const _hoisted_4 = { class: "card-body p-10" }
const _hoisted_5 = { class: "settings-tabs mt-10 ps-0 mb-0 list-unstyled" }
const _hoisted_6 = { class: "col-xxl-5 col-xxxl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SideBar = _resolveComponent("SideBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_0,
            alt: "social-timeline-image"
          }, null, -1)),
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/social-timeline",
                class: "fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Timeline ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/social-about",
                class: "fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" About ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/social-activity",
                class: "fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Activity ")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"card mb-25 border-0 rounded-0 bg-white letter-spacing contact-card\"><div class=\"card-body p-20 p-md-25 p-lg-30\"><div class=\"mb-15 mb-md-20 mb-lg-25 d-flex justify-content-between\"><div class=\"position-relative rounded-circle\"><img src=\"" + _imports_1 + "\" class=\"rounded-circle\" width=\"95\" height=\"95\" alt=\"user\"></div><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div></div><h5 class=\"fs-15 fs-md-18 fw-bold text-black mb-0\">Anderson Harry</h5><ul class=\"info-list ps-0 mb-0 list-unstyled\"><li class=\"text-paragraph fs-md-15 fs-lg-16 position-relative\"><span class=\"fw-semibold text-muted fs-12 fs-md-13 fs-lg-14\"> PHONE: </span> +1 234 4567 5678 </li><li class=\"text-paragraph fs-md-15 fs-lg-16 position-relative\"><span class=\"fw-semibold text-muted fs-12 fs-md-13 fs-lg-14\"> EMAIL: </span> andersonharry@gmail.com </li><li class=\"text-paragraph fs-md-15 fs-lg-16 position-relative\"><span class=\"fw-semibold text-muted fs-12 fs-md-13 fs-lg-14\"> ADDRESS: </span> 795 Folsom Ave, Suite 600 San Francisco, CA 94107 United States </li></ul></div></div>", 1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_SideBar)
    ])
  ]))
}