<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-lg-20 mb-xl-25">
            Link Colors
          </h5>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-primary">Primary link</a>
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-secondary">Secondary link</a>
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-success">Success link</a>
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-danger">Danger link</a>
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-warning">Warning link</a>
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-info">Info link</a>
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-light">Light link</a>
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-dark">Dark link</a>
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a href="#" class="link-body-emphasis">Emphasis link</a>
          </p>
          <div class="position-relative mt-25">
            <!-- <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#linkColorsCode"
            >
              Copy
            </button> -->
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="linkColorsCode">
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-primary"&gt;Primary link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-secondary"&gt;Secondary link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-success"&gt;Success link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-danger"&gt;Danger link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-warning"&gt;Warning link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-info"&gt;Info link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-light"&gt;Light link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-dark"&gt;Dark link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-body-emphasis"&gt;Emphasis link&lt;/a&gt;&lt;/p&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-lg-20 mb-xl-25">
            Link Utilities
          </h5>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Primary link</a
            >
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Secondary link</a
            >
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Success link</a
            >
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Danger link</a
            >
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-warning link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Warning link</a
            >
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Info link</a
            >
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Light link</a
            >
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Dark link</a
            >
          </p>
          <p class="fs-md-15 fs-lg-16">
            <a
              href="#"
              class="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover"
              >Emphasis link</a
            >
          </p>
          <div class="position-relative mt-25">
            <!-- <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#linkUtilitiesCode"
            >
              Copy
            </button> -->
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="linkUtilitiesCode">
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"&gt;Primary link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"&gt;Secondary link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"&gt;Success link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"&gt;Danger link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-warning link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"&gt;Warning link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"&gt;Info link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"&gt;Light link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"&gt;Dark link&lt;/a&gt;&lt;/p&gt;</div>
<div>&lt;p class="fs-md-15 fs-lg-16"&gt;&lt;a href="#" class="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover"&gt;Emphasis link&lt;/a&gt;&lt;/p&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColoredLinks",
};
</script>