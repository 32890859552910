<template>
    <UserDetail :uuid="uuid" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import UserDetail from "@/components/User/UserDetail.vue";
import { useRoute } from 'vue-router';
export default defineComponent({
    name: "UserDetailPage",
    components: {
        UserDetail,
    },
    setup() {
        const route = useRoute();
        const uuid = ref<string | undefined>(undefined);

        if (route.params.uuid) {
            uuid.value = Array.isArray(route.params.uuid) ? route.params.uuid[0] : route.params.uuid;
        }

        return {
            uuid,
        };

    },
});
</script>