import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_3 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "search mb-3" }
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "d-flex justify-content-start" }
const _hoisted_7 = {
  key: 0,
  class: "text-success"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "form-check form-switch" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "d-flex justify-content-end" }
const _hoisted_12 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: "Cargando..."
      }, null, 8, ["active"]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "d-sm-flex align-items-center" }, [
        _createElementVNode("h5", { class: "text-center" }, "Listado de usuarios")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputText, {
          modelValue: _ctx.searchTerm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
          placeholder: "Buscar por nombre o email",
          class: "w-100"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_DataTable, {
          value: _ctx.filteredUsers,
          showGridlines: "",
          tableStyle: "min-width: 50rem",
          paginator: "",
          rows: 10,
          rowsPerPageOptions: [5, 10, 20, 50]
        }, {
          footer: _withCtx(() => [
            _createTextVNode(" Total " + _toDisplayString(_ctx.filteredUsers ? _ctx.filteredUsers.length : 0) + " Usuarios. ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              class: "column-table",
              header: "Opciones",
              style: {"width":"20%"}
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    icon: "pi pi-pencil",
                    class: "p-button-sm p-button-warning me-1",
                    onClick: ($event: any) => (_ctx.editUser(slotProps.data.uuid))
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_Button, {
                    icon: "pi pi-trash",
                    class: "p-button-sm p-button-danger me-1",
                    onClick: ($event: any) => (_ctx.promptDelete(slotProps.data))
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "nombre",
              header: "Nombre",
              style: {"width":"30%"}
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "email",
              header: "Email",
              style: {"width":"30%"}
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "telefono",
              header: "Teléfono",
              style: {"width":"20%"}
            }),
            _createVNode(_component_Column, {
              sortable: "",
              class: "column-table",
              field: "rol",
              header: "Rol",
              style: {"width":"20%"}
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              field: "rfc",
              header: "RFC",
              style: {"width":"20%"}
            }),
            _createVNode(_component_Column, {
              class: "column-table",
              header: "Estatus",
              style: {"width":"20%"}
            }, {
              body: _withCtx((slotProps) => [
                (slotProps.data.status === 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Activo "))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-danger" }, "Inactivo", -1)),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("input", {
                          class: "form-check-input",
                          type: "checkbox",
                          role: "switch",
                          onClick: ($event: any) => (_ctx.promptActivate(slotProps.data))
                        }, null, 8, _hoisted_10)
                      ])
                    ]))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _createVNode(_component_DialogComponent, {
        visible: _ctx.confirmDialogVisible,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmDialogVisible) = $event)),
        modal: true,
        closable: false,
        style: { width: '30vw' }
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, [
            _cache[5] || (_cache[5] = _createTextVNode(" ¿Estás seguro de que deseas eliminar al usuario ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.selectedUser?.nombre), 1),
            _cache[6] || (_cache[6] = _createTextVNode("? "))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_Button, {
              label: "No",
              icon: "pi pi-times",
              class: "p-button-text",
              onClick: _ctx.cancelDelete
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              label: "Sí",
              icon: "pi pi-check",
              class: "p-button-danger",
              onClick: _ctx.confirmDelete
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_DialogComponent, {
        visible: _ctx.activateDialogVisible,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activateDialogVisible) = $event)),
        modal: true,
        closable: false,
        style: { width: '30vw' }
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, [
            _cache[7] || (_cache[7] = _createTextVNode(" ¿Estás seguro de que deseas activar al usuario ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.selectedUser?.nombre), 1),
            _cache[8] || (_cache[8] = _createTextVNode("? "))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Button, {
              label: "No",
              icon: "pi pi-times",
              class: "p-button-text",
              onClick: _ctx.cancelActivate
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              label: "Sí",
              icon: "pi pi-check",
              class: "p-button-success",
              onClick: _ctx.confirmActivate
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}