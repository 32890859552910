import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/user/user1.jpg'
import _imports_1 from '../../../assets/images/user/user2.jpg'
import _imports_2 from '../../../assets/images/user/user4.jpg'
import _imports_3 from '../../../assets/images/user/user3.jpg'


const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "col-xxl-3 col-sm-6" }, [
      _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white stats-item" }, [
        _createElementVNode("div", { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }, [
          _createElementVNode("div", { class: "d-flex align-items-center justify-content-between" }, [
            _createElementVNode("div", { class: "icon position-relative text-primary rounded-1 text-center" }, [
              _createElementVNode("i", { class: "flaticon-sterile-box" })
            ]),
            _createElementVNode("div", { class: "title text-end" }, [
              _createElementVNode("span", { class: "d-block fw-bold text-dark-emphasis mb-5" }, " Total de ventas "),
              _createElementVNode("div", { class: "d-flex align-items-center justify-content-end" }, [
                _createElementVNode("h3", { class: "fw-black mb-0 me-10" }, "69"),
                _createElementVNode("span", { class: "fw-bold text-success text-badge d-inline-block" }, [
                  _createElementVNode("i", { class: "flaticon-up-arrow fs-11 lh-1 position-relative top-1" }),
                  _createTextVNode(" 5.5% ")
                ])
              ]),
              _createElementVNode("span", { class: "d-block fw-medium text-dark-emphasis mt-5" }, [
                _createTextVNode(" Completed: "),
                _createElementVNode("span", { class: "fw-bold" }, "25")
              ])
            ])
          ]),
          _createElementVNode("div", { class: "mt-15 mt-md-25 mb-5 mb-md-8 d-flex justify-content-between align-items-center" }, [
            _createElementVNode("span", { class: "fw-medium text-secondary" }, [
              _createElementVNode("span", { class: "fw-bold text-black" }, "44"),
              _createTextVNode(" pending ")
            ]),
            _createElementVNode("span", { class: "text-primary fw-semibold" }, "38%")
          ]),
          _createElementVNode("div", {
            class: "progress",
            role: "progressbar",
            "aria-valuenow": "38",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }, [
            _createElementVNode("div", {
              class: "progress-bar",
              style: {"width":"38%"}
            })
          ])
        ])
      ])
    ], -1),
    _createElementVNode("div", { class: "col-xxl-3 col-sm-6" }, [
      _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white stats-item" }, [
        _createElementVNode("div", { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }, [
          _createElementVNode("div", { class: "d-flex align-items-center justify-content-between" }, [
            _createElementVNode("div", { class: "icon position-relative text-success rounded-1 text-center" }, [
              _createElementVNode("i", { class: "flaticon-sugar-cubes" })
            ]),
            _createElementVNode("div", { class: "title text-end" }, [
              _createElementVNode("span", { class: "d-block fw-bold text-dark-emphasis mb-5" }, " Cantidad de ventas "),
              _createElementVNode("div", { class: "d-flex align-items-center justify-content-end" }, [
                _createElementVNode("h3", { class: "fw-black mb-0 me-10" }, "94"),
                _createElementVNode("span", { class: "fw-bold text-danger text-badge d-inline-block" }, [
                  _createElementVNode("i", { class: "flaticon-down-arrow fs-11 lh-1 position-relative top-1" }),
                  _createTextVNode(" 3.5% ")
                ])
              ]),
              _createElementVNode("span", { class: "d-block fw-medium text-dark-emphasis mt-5" }, [
                _createTextVNode(" Completed: "),
                _createElementVNode("span", { class: "fw-bold" }, "44")
              ])
            ])
          ]),
          _createElementVNode("div", { class: "mt-15 mt-md-25 mb-5 mb-md-8 d-flex justify-content-between align-items-center" }, [
            _createElementVNode("span", { class: "fw-medium text-secondary" }, [
              _createElementVNode("span", { class: "fw-bold text-black" }, "50"),
              _createTextVNode(" pending ")
            ]),
            _createElementVNode("span", { class: "text-success fw-semibold" }, "42%")
          ]),
          _createElementVNode("div", {
            class: "progress",
            role: "progressbar",
            "aria-valuenow": "42",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }, [
            _createElementVNode("div", {
              class: "progress-bar bg-success",
              style: {"width":"42%"}
            })
          ])
        ])
      ])
    ], -1),
    _createElementVNode("div", { class: "col-xxl-3 col-sm-6" }, [
      _createElementVNode("div", { class: "card mb-25 border-0 rounded-0 bg-white stats-item" }, [
        _createElementVNode("div", { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }, [
          _createElementVNode("div", { class: "d-flex align-items-center justify-content-between" }, [
            _createElementVNode("div", { class: "icon position-relative text-info rounded-1 text-center" }, [
              _createElementVNode("i", { class: "flaticon-idea" })
            ]),
            _createElementVNode("div", { class: "title text-end" }, [
              _createElementVNode("span", { class: "d-block fw-bold text-dark-emphasis mb-5" }, " Litros vendidos "),
              _createElementVNode("div", { class: "d-flex align-items-center justify-content-end" }, [
                _createElementVNode("h3", { class: "fw-black mb-0 me-10" }, "90%"),
                _createElementVNode("span", { class: "fw-bold text-success text-badge d-inline-block" }, [
                  _createElementVNode("i", { class: "flaticon-up-arrow fs-11 lh-1 position-relative top-1" }),
                  _createTextVNode(" 9.3% ")
                ])
              ]),
              _createElementVNode("span", { class: "d-block fw-medium text-dark-emphasis mt-5" }, [
                _createTextVNode(" Revenue: "),
                _createElementVNode("span", { class: "fw-bold" }, "$2500")
              ])
            ])
          ]),
          _createElementVNode("div", { class: "mt-15 mt-md-25 mb-5 mb-md-8 d-flex justify-content-between align-items-center" }, [
            _createElementVNode("span", { class: "fw-medium text-secondary" }, [
              _createElementVNode("span", { class: "fw-bold text-black" }, "$4504"),
              _createTextVNode(" more to go ")
            ]),
            _createElementVNode("span", { class: "text-info-light fw-semibold" }, "50%")
          ]),
          _createElementVNode("div", {
            class: "progress",
            role: "progressbar",
            "aria-valuenow": "50",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }, [
            _createElementVNode("div", {
              class: "progress-bar bg-info-light",
              style: {"width":"50%"}
            })
          ])
        ])
      ])
    ], -1),
    _createStaticVNode("<div class=\"col-xxl-3 col-sm-6\"><div class=\"card mb-25 border-0 rounded-0 bg-white stats-item\"><div class=\"card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing\"><div class=\"d-flex align-items-center justify-content-between\"><div class=\"icon position-relative text-danger rounded-1 text-center\"><i class=\"flaticon-user-3\"></i></div><div class=\"title text-end\"><span class=\"d-block fw-bold text-dark-emphasis mb-5\"> Venta promedio </span><div class=\"d-flex align-items-center justify-content-end\"><h3 class=\"fw-black mb-0 me-10\">56</h3><span class=\"fw-bold text-success text-badge d-inline-block\"><i class=\"flaticon-up-arrow fs-11 lh-1 position-relative top-1\"></i> 2.5% </span></div><span class=\"d-block fw-medium text-dark-emphasis mt-5\"> Working: <span class=\"fw-bold\">54</span></span></div></div><div class=\"mt-15 mt-md-20 users-list d-flex align-items-center\"><div class=\"rounded-circle text-center\"><img src=\"" + _imports_0 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle text-center\"><img src=\"" + _imports_1 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-16 fw-bold\"> P </div><div class=\"rounded-circle text-center\"><img src=\"" + _imports_2 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle bg-primary text-center d-flex align-items-center justify-content-center text-white fs-16 fw-bold\"> S </div><div class=\"rounded-circle text-center\"><img src=\"" + _imports_3 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle bg-secondary text-center d-flex align-items-center justify-content-center text-white fs-14 fw-bold\"> +24 </div></div></div></div></div>", 1)
  ])))
}