
import { InspectionRow } from "@/interfaces/interfaces";
import { ref } from "vue";

export const inspectionRows = ref<InspectionRow[]>([
  { label: "Cinturón de Seguridad", modelValue: "5c8b4d0b-0b22-47f8-af57-51cd05fcfa24", cumpleValue: "" },
  { label: "Radio", modelValue: "d6d2c270-bbb4-43bb-9ffa-9c739ad6a527", cumpleValue: "" },
  { label: "Retrovisor", modelValue: "21d4dac8-ee4f-4f64-bb24-1a3b6921c999", cumpleValue: "" },
  { label: "Claxon/Bocina", modelValue: "040dc04d-d3d8-4a67-8a82-adde0325fd14", cumpleValue: "" },
  { label: "Botiquín de Primeros Aux.", modelValue: "20698158-bfea-4586-808f-53ed7f8664ab", cumpleValue: "" },
  { label: "Viseras Cubre Sol", modelValue: "7e743ccc-f509-449e-a914-83df3b6a8ec1", cumpleValue: "" },
  { label: "Freno de Mano", modelValue: "192e365b-f926-45f5-a71c-6416bce8e38d", cumpleValue: "" },
  { label: "Monitor de Velocidad", modelValue: "79113ed8-2e60-4446-a9f7-4654600e2557", cumpleValue: "" },
  { label: "Indicadores en el Tablero", modelValue: "31023a73-8f30-4b6a-8ab7-c3cc48dee794", cumpleValue: "" },
  { label: "Limpiadores de Parabrisas", modelValue: "a0387083-ce6e-4102-9544-1fbb56be8089", cumpleValue: "" },
  { label: "Control de A/C", modelValue: "da688f3d-31f9-4479-8759-2a37c0cc3dc6", cumpleValue: "" },
]);

export const exteriorInspectionRows = ref<InspectionRow[]>([
  { label: "Faros y Luces Delantero", modelValue: "7d88956f-fc49-422d-84d9-85b081938e00", cumpleValue: "" },
  { label: "Luces Intermitentes", modelValue: "21db1ae7-e572-4135-af7c-15c682921ae7", cumpleValue: "" },
  { label: "Freno", modelValue: "0d783d72-0830-444a-8487-20439d0ec366", cumpleValue: "" },
  { label: "Reversa", modelValue: "b6464511-d418-4825-956e-81ebbe8ab95a", cumpleValue: "" },
  { label: "Faros de Niebla", modelValue: "6d95b916-7f27-4df8-82b9-85bd52e1727e", cumpleValue: "" },
  { label: "Tapón de Combustible", modelValue: "945d8df3-c524-4af2-bb8c-ab44bae8d81b", cumpleValue: "" },
  { label: "Manijas", modelValue: "dc696944-653b-4487-8244-d3091df388db", cumpleValue: "" },
  { label: "Llave", modelValue: "f35f56b2-1026-4a40-badd-f59597e021e0", cumpleValue: "" },
  { label: "Parabrisas", modelValue: "07746bb0-cbaa-4152-8fe0-9592394a4c49", cumpleValue: "" },
  { label: "Medallón", modelValue: "e892cdd0-50f0-4f1e-98b6-e7e758d9ce40", cumpleValue: "" },
  { label: "Vidrios de Puertas", modelValue: "3f1bfb19-311f-49c6-af79-386ba1ce6951", cumpleValue: "" },
  { label: "Espejo Lateral Derecho", modelValue: "1b7c5d10-e910-4888-9ed2-3c898cf9cf70", cumpleValue: "" },
  { label: "Espejo Lateral Izquierdo", modelValue: "54d2573f-58a5-409a-8c76-80f7e2792d6a", cumpleValue: "" },
]);

export const motorInspectionRows = ref<InspectionRow[]>([
  { label: "Aceite/motor", modelValue: "29805e10-4984-4262-9a80-ce0ef22df042", cumpleValue: "" },
  { label: "Tapón Para Aceite/motor", modelValue: "fd0e0054-92b0-4c04-b2fd-8de2a3e3fd5a", cumpleValue: "" },
  { label: "Bayoneta de nivel de aceite", modelValue: "fb477d32-6cce-4c8d-b873-422d6feca53e", cumpleValue: "" },
  { label: "Líquido Refrigerante", modelValue: "7ac8dcf2-8b67-4cf8-ac93-8f340bc340a7", cumpleValue: "" },
  { label: "Tapón Para Refrigerante", modelValue: "12c7e237-61da-4264-bb15-b90759b6244b", cumpleValue: "" },
  { label: "Batería", modelValue: "4caed812-4d63-473a-adbf-707355afb609", cumpleValue: "" },
  { label: "Líquido Limpiavidrios", modelValue: "b83369d7-b64f-4e7d-8e9f-4eaf82122f8f", cumpleValue: "" },
  { label: "Tapón Líq. Limpiavidrios", modelValue: "8c503a00-7a2a-4d72-a1b3-497d022283f7", cumpleValue: "" },
  { label: "Líquido de freno", modelValue: "3f1d9082-a085-4f3d-b3dd-af66cca5fa62", cumpleValue: "" },
  { label: "Tapón de Líq. De freno", modelValue: "0828582b-e05f-45ca-a0de-16aa9f0bea3a", cumpleValue: "" },
  { label: "Transmisión Automática", modelValue: "990def62-42f5-4f35-9715-5c3995bdc817", cumpleValue: "" },
  { label: "Transmisión Estándar", modelValue: "e21894b8-75b2-41cb-be91-edf9b30adbc1", cumpleValue: "" },
]);

export const toolsInspectionRows = ref<InspectionRow[]>([
  { label: "Gato Hidráulico-Mecánico", modelValue: "6c639f3a-6c22-459a-91f4-3f92c95fc50c", cumpleValue: "" },
  { label: "Llave para birlos", modelValue: "96ef3e90-4dfd-467f-ae1c-4e4fda5d6262", cumpleValue: "" },
  { label: "Triángulos Reflectores", modelValue: "bede08b1-3701-4fcd-b726-a8ff68c9b7b5", cumpleValue: "" },
  { label: "Cables Pasa Corriente", modelValue: "647280e5-ab72-4b0f-a425-11b211270b37", cumpleValue: "" },
  { label: "Extintor", modelValue: "28497813-94fa-4fd2-8272-8dba77308dfe", cumpleValue: "" },
  { label: "Kit de Herramientas", modelValue: "e6bfb668-bf44-4eb4-b8e0-509eb104412e", cumpleValue: "" },
  { label: "Llanta de Refacción", modelValue: "a70d4670-0448-4469-a860-b39b2058a3cb", cumpleValue: "" },
]);

export const tiresInspectionRows = ref<InspectionRow[]>([
  { label: "Delantera Derecha", modelValue: "7118f830-59c3-42e9-8c9a-fda450292151", cumpleValue: "" },
  { label: "Delantera Izquierda", modelValue: "c193141e-6bf1-400b-9243-28d4e8e4b987", cumpleValue: "" },
  { label: "Trasera Derecha", modelValue: "5f72b71c-b636-47eb-8a00-0e9393fd1b9d", cumpleValue: "" },
  { label: "Trasera Izquierda", modelValue: "c52c6496-7ecb-4774-995f-e3fcdfb1a9e4", cumpleValue: "" },
]);

export const bodyInspectionRows = ref<InspectionRow[]>([
  { label: "Costado Derecho", modelValue: "9fdacbbf-7985-494d-ab95-ae23ebbc4758", cumpleValue: "" },
  { label: "Costado Izquierdo", modelValue: "b769d0bf-7001-4f11-9a22-763cbf1e83eb", cumpleValue: "" },
  { label: "Cofre", modelValue: "1da0ebee-bcba-4f94-bc91-c57efcc164a5", cumpleValue: "" },
  { label: "Trasero", modelValue: "c8c31d2f-7562-4d2d-88ce-925b9209ebec", cumpleValue: "" },
  { label: "Asientos Delanteros", modelValue: "15f5cf32-2aee-4580-b3eb-962774287432", cumpleValue: "" },
  { label: "Asientos Traseros", modelValue: "f3480a1f-7cc3-49be-a45f-76939b01dc6e", cumpleValue: "" },
  { label: "Tablero, Volante", modelValue: "3e25e15e-0b8b-4153-bc8d-a6e1ff7d3dd6", cumpleValue: "" },
  { label: "Piso de la Unidad/Tapetes", modelValue: "6becf39a-2c25-43a3-a7eb-d80d3e8320bf", cumpleValue: "" },
]);
