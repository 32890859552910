import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white p-20 p-md-25 p-lg-30" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-md-3" }
const _hoisted_5 = { class: "input-group" }
const _hoisted_6 = { class: "col-md-3" }
const _hoisted_7 = { class: "input-group" }
const _hoisted_8 = { class: "col-md-3" }
const _hoisted_9 = { class: "input-group" }
const _hoisted_10 = { class: "col-md-3" }
const _hoisted_11 = { class: "input-group" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "card mb-25 border-0 rounded-0 letter-spacing project-card h-100" }
const _hoisted_14 = { class: "card-body p-20 p-md-25 p-lg-30 d-flex flex-column justify-content-between" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md-8" }
const _hoisted_17 = { class: "mb-0 fs-14 fs-md-16 fs-lg-20 fw-bold mt-8 mt-md-10" }
const _hoisted_18 = { class: "col-md-4 text-end" }
const _hoisted_19 = { class: "info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled" }
const _hoisted_20 = {
  key: 0,
  class: "text-secondary fs-md-15 fs-md-16 fw-semibold"
}
const _hoisted_21 = { class: "text-secondary fs-md-15 fs-md-16 fw-semibold" }
const _hoisted_22 = { class: "text-secondary fs-md-15 fs-md-16 fw-semibold" }
const _hoisted_23 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_24 = { class: "d-flex justify-content-between align-items-center mt-auto" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "text-secondary fs-md-15 fs-md-16 fw-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_CommentsDialog = _resolveComponent("CommentsDialog")!
  const _component_PrimeCalendar = _resolveComponent("PrimeCalendar")!
  const _component_PrimeDropdown = _resolveComponent("PrimeDropdown")!
  const _component_PrimePaginator = _resolveComponent("PrimePaginator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: 'Cargando...'
    }, null, 8, ["active"]),
    _createVNode(_component_CommentsDialog, {
      visible: _ctx.isDialogVisible,
      comments: _ctx.selectedComments,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = $event))
    }, null, 8, ["visible", "comments"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_PrimeCalendar, {
                modelValue: _ctx.dates,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dates) = $event)),
                selectionMode: "range",
                placeholder: "Inserta un rango de fechas",
                class: "w-100 shadow-none text-black rounded-0 border-0",
                showIcon: true,
                appendTo: "body",
                dateFormat: "dd/mm/yy"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_PrimeDropdown, {
                modelValue: _ctx.selectedPriority,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPriority) = $event)),
                options: _ctx.priority,
                optionLabel: "name",
                placeholder: "Selecciona una prioridad",
                checkmark: "",
                highlightOnSelect: false,
                showClear: true,
                class: "w-100"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_PrimeDropdown, {
                modelValue: _ctx.selectedType,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedType) = $event)),
                options: _ctx.typeOptions,
                optionLabel: "name",
                placeholder: "Tarea o Evento",
                checkmark: "",
                highlightOnSelect: false,
                showClear: true,
                class: "w-100"
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "input-group-text" }, [
                _createElementVNode("i", { class: "pi pi-search" })
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchTerm) = $event)),
                placeholder: "Buscar por tarea o evento"
              }, null, 512), [
                [_vModelText, _ctx.searchTerm]
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPaginatedTasks, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.uuid_tarea || item.uuid_evento,
          class: "col-md-6 col-xxl-6 col-xxxl-4 mb-4"
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("h5", _hoisted_17, _toDisplayString(item.titulo || item.nombre_evento), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", {
                      class: "badge",
                      style: _normalizeStyle({ backgroundColor: item.color})
                    }, _toDisplayString(item.prioridad), 5)
                  ])
                ]),
                _createElementVNode("ul", _hoisted_19, [
                  (item.descripcion)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_20, [
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-muted fs-12 fs-md-13 text-uppercase fw-medium" }, " Descripción: ", -1)),
                        _createTextVNode(" " + _toDisplayString(item.descripcion), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", _hoisted_21, [
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-muted fs-12 fs-md-13 text-uppercase fw-medium" }, " Fecha de inicio: ", -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.formatDate(item.fecha_inicio)), 1)
                  ]),
                  _createElementVNode("li", _hoisted_22, [
                    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-muted fs-12 fs-md-13 text-uppercase fw-medium" }, " Fecha final: ", -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.formatDate(item.fecha_fin)), 1)
                  ])
                ]),
                (item.tags && item.tags.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.tags, (tag) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: tag.uuid,
                          class: "badge bg-primary me-1"
                        }, _toDisplayString(tag.tag), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_24, [
                (item.comentarios && item.comentarios.length > 0)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-link p-0",
                      onClick: ($event: any) => (_ctx.openCommentsDialog(item.comentarios))
                    }, " Ver comentarios ", 8, _hoisted_25))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_26, [
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "text-muted fs-12 fs-md-13 text-uppercase fw-medium" }, "Creado por:", -1)),
                  _createTextVNode(" " + _toDisplayString(item.nombre_creador), 1)
                ])
              ])
            ])
          ])
        ]))
      }), 128))
    ]),
    (_ctx.filteredTasks.length > _ctx.rowsPerPage)
      ? (_openBlock(), _createBlock(_component_PrimePaginator, {
          key: 0,
          rows: _ctx.rowsPerPage,
          totalRecords: _ctx.filteredTasks.length,
          page: _ctx.currentPage,
          onPage: _cache[5] || (_cache[5] = ($event: any) => (_ctx.currentPage = $event.page))
        }, null, 8, ["rows", "totalRecords", "page"]))
      : _createCommentVNode("", true)
  ]))
}