import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white campaigns-box" }
const _hoisted_2 = { class: "card-body letter-spacing" }
const _hoisted_3 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"mb-5 d-sm-flex align-items-center justify-content-between\"><h5 class=\"card-title fw-bold mb-0\">Campaigns</h5><div class=\"card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><span class=\"fw-medium text-muted me-8\">Last</span><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option value=\"1\" class=\"fw-medium\" selected>1 Week</option><option value=\"2\" class=\"fw-medium\">2 Week</option><option value=\"3\" class=\"fw-medium\">3 Week</option></select></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_apexchart, {
          type: "radialBar",
          height: "325",
          id: "campaignsChart",
          options: _ctx.campaignsChart,
          series: _ctx.campaigns
        }, null, 8, ["options", "series"])
      ]),
      _cache[1] || (_cache[1] = _createStaticVNode("<ul class=\"list ps-0 mb-0 list-unstyled text-center\"><li class=\"d-inline-block position-relative text-primary text-start\"><h5 class=\"fw-black mb-1 text-primary\">5218</h5><span class=\"d-block text-black-emphasis fw-medium\">Total Sent</span></li><li class=\"d-inline-block position-relative text-info text-start\"><h5 class=\"fw-black mb-1 text-info\">5218</h5><span class=\"d-block text-black-emphasis fw-medium\">Reached</span></li><li class=\"d-inline-block position-relative text-success text-start\"><h5 class=\"fw-black mb-1 text-success\">5218</h5><span class=\"d-block text-black-emphasis fw-medium\">Opened</span></li></ul>", 1))
    ])
  ]))
}