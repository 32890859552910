<template>
    <div class="row">
        <div class="col-md-12">
            <Actualizar/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Actualizar from "@/components/crud/Actualizar.vue";

export default defineComponent({
    name: "ActualizarClientPage",
    components: {
        Actualizar,
    },
});
</script>