<template>
  <div class="row">
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    <div class="col-xxl-7 col-xxxl-6">
      <WhatHappening :dataArray="dataArray" :dataWarehouse="dataWarehouse" />

      <div class="row">
        <div class="col-lg-6 col-xxxl-6 col-md-6">
          <ExpectedEarnings :dataArray="dataArray" />
        </div>
        <div class="col-lg-6 col-xxxl-6 col-md-6">
          <AverageDailySales :dataArray="dataArray" />
        </div>
      </div>
    </div>
    <div class="col-xxl-5 col-xxxl-6">
      <StatsBoxes :dataWarehouse="dataWarehouse" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import createSocketService from "@/services/socketService";

import WhatHappening from "../../components/Dashboard/Total/WhatHappening.vue";

import ExpectedEarnings from "../../components/Dashboard/Total/ExpectedEarnings.vue";

import AverageDailySales from "../../components/Dashboard/Total/AverageDailySales.vue";


import StatsBoxes from "../../components/Dashboard/Total/StatsBoxes.vue";



export default defineComponent({
  name: "GeneralPage",
  components: {
    WhatHappening,
    ExpectedEarnings,
    AverageDailySales,
    StatsBoxes,
  },
  setup() {
    let socketService: any = null;
    const dataArray = ref<any[]>([]);
    const dataWarehouse = ref<any[]>([]);
    const dataAgent = ref<any[]>([]);
    const isLoading = ref(false);
    const currentDate = ref(new Date().toISOString().split('T')[0]);

    // const currentDate = ref("2024-10-31");


    const getData = async () => {
      try {
        const fechadata = {
          "fecha": currentDate.value
        };

        socketService.emit("general-indicators-day", fechadata);
        socketService.on("general-indicators-day", (rspta: any) => {
          try {
            if (!rspta.error) {
              const respuesta = rspta.response[0].resultado;
              dataArray.value = respuesta;
            }
          } catch (error) {
            console.log(error);
          }
        });

      } catch (error) {
        console.log(error);
      }
    }


    const getDataWarehouse = async () => {
      try {

        const fechadata = {
          "fecha": currentDate.value
        };

        socketService.emit("general-indicators-day-warehouse", fechadata);
        socketService.on("general-indicators-day-warehouse", (rspta: any) => {
          try {
            if (!rspta.error) {
              const respuesta = rspta.response;
              dataWarehouse.value = respuesta;
            }
          } catch (error) {
            console.log(error);
          }
        });

      } catch (error) {
        console.log(error);
      }
    }


    const getDataAgent = async () => {
      try {

        const fechadata = {
          "fecha": currentDate.value
        };

        socketService.emit("general-indicators-day-agent", fechadata);
        socketService.on("general-indicators-day-agent", (rspta: any) => {
          try {
            if (!rspta.error) {
              const respuesta = rspta.response;
              dataAgent.value = respuesta;
            }
          } catch (error) {
            console.log(error);
          }
        });

      } catch (error) {
        console.log(error);
      }
    }

    onMounted(async () => {
      try {
        isLoading.value = true;
        socketService = await createSocketService();
        socketService.connect();
        await getData();
        await getDataWarehouse();
        await getDataAgent();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }

    })

    return {
      dataArray,
      dataWarehouse,
      dataAgent,
      isLoading
    }
  }

});
</script>