import Control from "./apiService";
import Login from "./loginService";

const control = new Control();
const login = new Login();

export default class LocalStorageValue {
  async getDataFromLocal(key) {
    await login.isTokenExpired();
    const data = localStorage.getItem("data");

    if (data) {
      const responseJson = await control.decryptBody(data);
      const response = JSON.parse(JSON.parse(responseJson));
      if (response) {
        return response[key];
      }
    }
  }

  async getDataLocal() {
    return this.getDataFromLocal("verify");
  }

  async getDataRol() {
    return this.getDataFromLocal("assignment");
  }

  async getName() {
    return this.getDataFromLocal("name");
  }

  async getpermissions() {
    return this.getDataFromLocal("permissions");
  }

  async getUserData() {
    return this.getDataFromLocal("user");
  }

  async getToken() {
    return this.getDataFromLocal("token");
  }
}
