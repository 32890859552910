import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '../../assets/plus-small_4338829.png'
import _imports_1 from '../../assets/minus-small_4338830.png'
import _imports_2 from '../../assets/map_1196804.png'
import _imports_3 from '../../assets/embotellamiento.png'
import _imports_4 from '../../assets/geocerca.png'
import _imports_5 from '../../assets/pantalla-completa.png'


const _hoisted_1 = {
  key: 0,
  class: "sidebar"
}
const _hoisted_2 = { class: "row align-items-center" }
const _hoisted_3 = { class: "col-xl-4 col-lg-5 col-md-6" }
const _hoisted_4 = { class: "header-left-side justify-content-center justify-content-md-start d-flex align-items-center" }
const _hoisted_5 = { class: "search-bar" }
const _hoisted_6 = { class: "tabs" }
const _hoisted_7 = { class: "vehicle-list" }
const _hoisted_8 = { class: "input-group-button w-100" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "map-container" }
const _hoisted_11 = {
  id: "map",
  ref: "mapRef"
}
const _hoisted_12 = { class: "map-buttons" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-md-12" }
const _hoisted_15 = { class: "col-md-12 pt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!
  const _component_VueLoading = _resolveComponent("VueLoading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dashboard-container", { 'sidebar-hidden': !_ctx.isSidebarVisible }])
  }, [
    (_ctx.isSidebarVisible)
      ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0",
                  id: "header-burger-menu",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stateStoreInstance.onChange && _ctx.stateStoreInstance.onChange(...args)))
                }, _cache[17] || (_cache[17] = [
                  _createElementVNode("i", { class: "flaticon-menu-3" }, null, -1)
                ]))
              ])
            ])
          ]),
          _cache[36] || (_cache[36] = _createElementVNode("h4", null, "Filtros", -1)),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
              placeholder: "Buscar vehículo..."
            }, null, 512), [
              [_vModelText, _ctx.searchQuery]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: _normalizeClass(["tab-button", { active: _ctx.selectedTab === 'all' }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setTab('all')))
            }, " Todos ", 2),
            _createElementVNode("button", {
              class: _normalizeClass(["tab-button", { active: _ctx.selectedTab === 'NAENS' }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setTab('NAENS')))
            }, " NAENS ", 2),
            _createElementVNode("button", {
              class: _normalizeClass(["tab-button", { active: _ctx.selectedTab === 'SILM' }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setTab('SILM')))
            }, " SILM ", 2),
            _createElementVNode("button", {
              class: _normalizeClass(["tab-button", { active: _ctx.selectedTab === 'TEG' }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setTab('TEG')))
            }, " TEG ", 2)
          ]),
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredVehicles, (vehicle) => {
              return (_openBlock(), _createElementBlock("li", {
                key: vehicle.imei,
                class: _normalizeClass(["vehicle-item", { 'focused': _ctx.focusedVehicle?.imei === vehicle.imei }])
              }, [
                _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Fecha:", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.fechaposicion) + " ", 1),
                _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Unidad:", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.unidad) + " ", 1),
                _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                _cache[24] || (_cache[24] = _createElementVNode("strong", null, "Ubicación:", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.ubicacion) + " ", 1),
                _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                _cache[26] || (_cache[26] = _createElementVNode("strong", null, "Velocidad: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.velocidad) + " km/h ", 1),
                _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1)),
                _cache[28] || (_cache[28] = _createElementVNode("strong", null, "Angulo: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.Angulo) + " ", 1),
                _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                _cache[30] || (_cache[30] = _createElementVNode("strong", null, "Placas: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.placas) + " ", 1),
                _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
                _cache[32] || (_cache[32] = _createElementVNode("strong", null, "Ignición: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.ignicion === '1' ? 'Encendido' : 'Apagado') + " ", 1),
                _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1)),
                _cache[34] || (_cache[34] = _createElementVNode("strong", null, "odometro: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.odometro) + " ", 1),
                _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    class: "button-li",
                    onClick: ($event: any) => (_ctx.focusOnVehicle(vehicle))
                  }, _cache[18] || (_cache[18] = [
                    _createElementVNode("i", { class: "flaticon-tap-1" }, null, -1),
                    _createTextVNode(" Seguir vehículo")
                  ]), 8, _hoisted_9),
                  (_ctx.focusedVehicle?.imei === vehicle.imei)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "button-li",
                        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.stopFollowingVehicle && _ctx.stopFollowingVehicle(...args)))
                      }, _cache[19] || (_cache[19] = [
                        _createElementVNode("i", { class: "flaticon-close-1" }, null, -1),
                        _createTextVNode(" Dejar de seguir ")
                      ])))
                    : _createCommentVNode("", true)
                ])
              ], 2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, null, 512),
      _cache[46] || (_cache[46] = _createElementVNode("div", {
        id: "street-view",
        class: "street-view-container"
      }, null, -1)),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("button", {
          type: "button",
          class: "icon-btn",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.zoomIn && _ctx.zoomIn(...args)))
        }, _cache[37] || (_cache[37] = [
          _createElementVNode("img", {
            src: _imports_0,
            title: "Zoom",
            alt: "Zoom In"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          type: "button",
          class: "icon-btn",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.zoomOut && _ctx.zoomOut(...args)))
        }, _cache[38] || (_cache[38] = [
          _createElementVNode("img", {
            src: _imports_1,
            title: "Zoom",
            alt: "Zoom Out"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          type: "button",
          class: "icon-btn",
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.toggleMapType && _ctx.toggleMapType(...args)))
        }, _cache[39] || (_cache[39] = [
          _createElementVNode("img", {
            src: _imports_2,
            title: "Cambiar vista",
            alt: "Cambiar Vista"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          type: "button",
          class: "icon-btn",
          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.toggleTraffic && _ctx.toggleTraffic(...args)))
        }, _cache[40] || (_cache[40] = [
          _createElementVNode("img", {
            src: _imports_3,
            title: "Mostrar tráfico",
            alt: "Mostrar/Ocultar Tráfico"
          }, null, -1)
        ])),
        (!_ctx.isGeofenceActive)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "icon-btn",
              onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.toggleGeofence && _ctx.toggleGeofence(...args)))
            }, _cache[41] || (_cache[41] = [
              _createElementVNode("img", {
                src: _imports_4,
                title: "Geocercas",
                alt: "Geocercas"
              }, null, -1)
            ])))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: "icon-btn",
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.deactivateGeofence && _ctx.deactivateGeofence(...args)))
            }, _cache[42] || (_cache[42] = [
              _createElementVNode("img", {
                src: _imports_4,
                title: "Desactivar Geocercas",
                alt: "Desactivar Geocercas"
              }, null, -1)
            ]))),
        _createElementVNode("button", {
          type: "button",
          class: "icon-btn",
          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.toggleFullscreen && _ctx.toggleFullscreen(...args)))
        }, _cache[43] || (_cache[43] = [
          _createElementVNode("img", {
            src: _imports_5,
            title: "Pantalla completa",
            alt: "Pantalla Completa"
          }, null, -1)
        ]))
      ]),
      _createVNode(_component_DialogComponent, {
        visible: _ctx.showModal,
        "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.showModal) = $event)),
        style: { width: '35rem' }
      }, {
        header: _withCtx(() => _cache[44] || (_cache[44] = [
          _createElementVNode("div", { class: "col-md-6 pt-20" }, [
            _createElementVNode("h5", { class: "card-subtitle fw-bold" }, [
              _createElementVNode("i", { class: "flaticon-settings-1" }),
              _createTextVNode(" Configuración")
            ])
          ], -1)
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[45] || (_cache[45] = _createElementVNode("label", {
                for: "basicFormControlInput1",
                class: "form-label fw-medium text-black"
              }, " Agentes: ", -1)),
              _createVNode(_component_MultiSelect, {
                modelValue: _ctx.selectedAgents,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.selectedAgents) = $event)),
                options: _ctx.agents,
                optionLabel: "nombre",
                optionValue: "uuid_usuario",
                placeholder: "Seleccione agentes",
                filter: true,
                multiple: true,
                class: "w-100"
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-sm btn-primary text-center",
                onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.searchPoints && _ctx.searchPoints(...args)))
              }, "Buscar")
            ])
          ])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: 'Cargando...'
      }, null, 8, ["active"])
    ])
  ], 2))
}