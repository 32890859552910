<template>
    <div class="row">
        <div class="col-xxl-9">
            <div class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing">
                <div class="card-body">
                    <ul class="nav nav-tabs bg-white border-0 rounded-0" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100 active"
                                id="shipping-details-tab" data-bs-toggle="tab"
                                data-bs-target="#shipping-details-tab-pane" type="button" role="tab"
                                aria-controls="shipping-details-tab-pane" aria-selected="true">
                                <i class="flaticon-draft"></i>
                                Cotización
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100"
                                id="payment-method-tab" data-bs-toggle="tab" data-bs-target="#payment-method-tab-pane"
                                type="button" role="tab" aria-controls="payment-method-tab-pane" aria-selected="false"
                                @click="handleTabClick">
                                <i class="flaticon-express-delivery"></i>
                                Envío
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100"
                                id="place-order-tab" data-bs-toggle="tab" data-bs-target="#place-order-tab-pane"
                                type="button" role="tab" aria-controls="place-order-tab-pane" aria-selected="false">
                                <i class="flaticon-sterile-box"></i>
                                Souvenir
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content tab-coti p-15 p-sm-20 p-md-25 p-lg-30" id="myTabContent">
                        <div class="tab-pane fade show active" id="shipping-details-tab-pane" role="tabpanel"
                            tabindex="0">
                            <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing manage-reviews-box">
                                <div class="row">
                                    <div class="col col-md-12">
                                        <form class="search-box position-relative" @submit.prevent novalidate>
                                            <InputGroup>
                                                <InputGroupAddon>
                                                    <i class="pi pi-search"></i>
                                                </InputGroupAddon>
                                                <AutoComplete v-model="selectedProducto" fluid
                                                    :suggestions="filteredProductos" @complete="searchProductos"
                                                    class="w-100" placeholder="Buscar producto sku/nombre"
                                                    @update:modelValue="onProductoSelect" field="displayField" />
                                            </InputGroup>
                                        </form>
                                    </div>
                                </div>
                                <div class="card mb-25 border-0 rounded-0 bg-white shopping-cart-box letter-spacing">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <div v-if="almacenProductos.length === 0" class="alert alert-info">
                                                <p>No se ha seleccionado ningún producto. Agrega productos a la
                                                    cotización.</p>
                                            </div>

                                            <table v-else class="table align-middle mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"
                                                            class="text-uppercase fw-medium shadow-none fs-13 text-black"
                                                            style="width: 25%;">
                                                            Producto
                                                        </th>
                                                        <th scope="col"
                                                            class="text-uppercase fw-medium shadow-none text-black fs-13"
                                                            style="width: 14%;">
                                                            Precio
                                                        </th>
                                                        <th scope="col"
                                                            class="text-uppercase fw-medium shadow-none text-black fs-13"
                                                            style="width: 14%;">
                                                            Litros
                                                        </th>
                                                        <th scope="col"
                                                            class="text-uppercase fw-medium shadow-none text-black fs-13"
                                                            style="width: 14%;">
                                                            Cantidad
                                                        </th>
                                                        <th scope="col"
                                                            class="text-uppercase fw-medium shadow-none text-black fs-13"
                                                            style="width: 14%;">
                                                            Descuento
                                                        </th>
                                                        <th scope="col"
                                                            class="text-uppercase fw-medium shadow-none text-black fs-13"
                                                            style="width: 14%;">
                                                            Subtotal
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in almacenProductos" :key="index">
                                                        <th class="shadow-none fw-medium text-black product-title">
                                                            <div class="d-flex align-items-center gap-2">
                                                                <button type="button"
                                                                    class="bg-transparent p-0 border-0 text-danger fs-15 fs-md-16 fs-lg-18 ml-2"
                                                                    @click="deleteProduct(index)">
                                                                    <i class="ph-duotone ph-trash"></i>
                                                                </button>
                                                                <span>{{ item.codigo }} - {{ item.nombre_producto
                                                                    }}</span>
                                                            </div>
                                                        </th>

                                                        <td class="shadow-none lh-1 fw-medium text-black">
                                                            {{ formatCurrency(item.precio_refaccionaria) }}
                                                        </td>
                                                        <td class="shadow-none lh-1 fw-medium text-black">
                                                            {{ getLiters(item).toFixed(2) }} L
                                                        </td>
                                                        <td class="shadow-none lh-1">
                                                            <div class="d-flex flex-column gap-2">
                                                                <!-- Input para cantidad de cajas -->
                                                                <div>
                                                                    <input id="cantidadCajas"
                                                                        class="form-control text-black"
                                                                        :placeholder="item.tipoproducto" type="number"
                                                                        v-model.number="item.cantidadCajas" min="0" />
                                                                </div>

                                                                <!-- Input para cantidad de unidades sueltas -->
                                                                <div v-show="Number(item.presentacion) !== 1">
                                                                    <input id="cantidadUnidadesSueltas"
                                                                        class="form-control text-black"
                                                                        placeholder="Unidades sueltas" type="number"
                                                                        v-model.number="item.cantidadUnidadesSueltas"
                                                                        min="0" :max="item.empaque - 1" />
                                                                </div>
                                                            </div>
                                                        </td>


                                                        <td class="shadow-none lh-1">
                                                            <input class="form-control fs-md-15 shadow-none text-black"
                                                                type="number" v-model.number="item.descuento" min="0"
                                                                max="100" step="0.01" />
                                                        </td>
                                                        <td class="shadow-none lh-1 fw-medium text-black">
                                                            {{ formatCurrency(calculatePriceWithDiscount(item)) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>

                                                    <tr>
                                                        <td colspan="5" class="text-end">Subtotal:</td>
                                                        <td class="fw-bold">{{ formatCurrency(subtotal) }}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="5" class="text-end">IVA:</td>
                                                        <td class="fw-bold">{{ formatCurrency(iva) }}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="5" class="text-end">Total:</td>
                                                        <td class="fw-bold">{{ formatCurrency(totalFinal) }}</td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="payment-method-tab-pane" role="tabpanel" tabindex="0">
                            <div v-if="almacenProductos.length === 0" class="alert alert-info">
                                <p>No se ha seleccionado ningún producto. Agrega productos a la
                                    cotización.</p>
                            </div>
                            <div v-else>
                                <h5 class="mb-15 mb-md-25 fw-bold text-black">
                                    Please Select Your Payment Method
                                </h5>
                                <div class="accordion" id="paymentAccordion">
                                    <div class="accordion-item rounded-0">
                                        <button
                                            class="accordion-button text-black fs-14 fs-md-15 fs-lg-16 fw-semibold shadow-none border-0 rounded-0 bg-white d-flex align-items-center justify-content-between"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#paymentCollapseOne"
                                            aria-expanded="true" aria-controls="paymentCollapseOne">
                                            <span class="dot"></span>
                                            Credit/Debit Card
                                            <div class="payment-types">
                                                <img src="@/assets/images/payment/mastercard.jpg" alt="mastercard" />
                                                <img src="@/assets/images/payment/visa.jpg" alt="visa" />
                                                <img src="@/assets/images/payment/american-express.jpg"
                                                    alt="american-express.jpg" />
                                            </div>
                                        </button>
                                        <div id="paymentCollapseOne" class="accordion-collapse collapse show"
                                            data-bs-parent="#paymentAccordion">
                                            <div class="accordion-body p-0">
                                                <form>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                                <label class="d-block text-black fw-semibold mb-10">Card
                                                                    Number</label>
                                                                <input type="text"
                                                                    class="form-control shadow-none rounded-0 text-black"
                                                                    placeholder="xxxx xxxx xxxx xxxx" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                                <label class="d-block text-black fw-semibold mb-10">Name
                                                                    On
                                                                    Card</label>
                                                                <input type="text"
                                                                    class="form-control shadow-none rounded-0 text-black"
                                                                    placeholder="eg. Adam Smith" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                                <label
                                                                    class="d-block text-black fw-semibold mb-10">Expiration
                                                                    Date</label>
                                                                <input type="date"
                                                                    class="form-control shadow-none rounded-0 text-black" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                                <label class="d-block text-black fw-semibold mb-10">CVV
                                                                    Code</label>
                                                                <input type="text"
                                                                    class="form-control shadow-none rounded-0 text-black"
                                                                    placeholder="xxxx" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-check mb-0 fs-md-15 fs-lg-16 text-muted">
                                                                <input class="form-check-input shadow-none"
                                                                    type="checkbox" id="save-info" />
                                                                <label class="form-check-label fw-normal"
                                                                    for="save-info">
                                                                    Save this payment details for future purchases.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item rounded-0">
                                        <button
                                            class="accordion-button text-black fs-14 fs-md-15 fs-lg-16 fw-semibold shadow-none border-0 rounded-0 bg-white d-flex align-items-center justify-content-between collapsed"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#paymentCollapseTwo"
                                            aria-expanded="false" aria-controls="paymentCollapseTwo">
                                            <span class="dot"></span>
                                            Pay With Paypal
                                            <div class="payment-types">
                                                <img src="@/assets/images/payment/paypal.jpg" alt="paypal" />
                                            </div>
                                        </button>
                                        <div id="paymentCollapseTwo" class="accordion-collapse collapse"
                                            data-bs-parent="#paymentAccordion">
                                            <div class="accordion-body p-0">
                                                <form>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                                <label class="d-block text-black fw-semibold mb-10">Card
                                                                    Number</label>
                                                                <input type="text"
                                                                    class="form-control shadow-none rounded-0 text-black"
                                                                    placeholder="xxxx xxxx xxxx xxxx" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                                <label class="d-block text-black fw-semibold mb-10">Name
                                                                    On
                                                                    Card</label>
                                                                <input type="text"
                                                                    class="form-control shadow-none rounded-0 text-black"
                                                                    placeholder="eg. Adam Smith" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                                <label
                                                                    class="d-block text-black fw-semibold mb-10">Expiration
                                                                    Date</label>
                                                                <input type="date"
                                                                    class="form-control shadow-none rounded-0 text-black" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                                <label class="d-block text-black fw-semibold mb-10">CVV
                                                                    Code</label>
                                                                <input type="text"
                                                                    class="form-control shadow-none rounded-0 text-black"
                                                                    placeholder="xxxx" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-check mb-0 fs-md-15 fs-lg-16 text-muted">
                                                                <input class="form-check-input shadow-none"
                                                                    type="checkbox" id="save-info1" />
                                                                <label class="form-check-label fw-normal"
                                                                    for="save-info1">
                                                                    Save this payment details for future purchases.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item rounded-0">
                                        <button
                                            class="accordion-button text-black fs-14 fs-md-15 fs-lg-16 fw-semibold shadow-none border-0 rounded-0 bg-white d-flex align-items-center justify-content-between collapsed"
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#paymentCollapseThree" aria-expanded="false"
                                            aria-controls="paymentCollapseThree">
                                            <span class="dot"></span>
                                            Cash On Delivery
                                        </button>
                                        <div id="paymentCollapseThree" class="accordion-collapse collapse"
                                            data-bs-parent="#paymentAccordion">
                                            <div class="accordion-body p-0">
                                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base mb-0">
                                                    Cash on Delivery (COD) is a payment gateway that
                                                    required no payment be made online. Orders using Cash on
                                                    Delivery are set to Processing until payment is made
                                                    upon delivery of the order by you or your shipping
                                                    method.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="mt-15 mt-sm-20 mt-md-25 d-sm-flex align-items-center justify-content-between">
                                    <button type="button"
                                        class="d-inline-block fs-14 fs-md-15 fs-lg-16 fw-medium text-decoration-none bg-transparent p-0 border-0 text-primary mb-12 mb-sm-0">
                                        <i class="flaticon-left-arrow lh-1 me-5 position-relative top-2"></i>
                                        Return To Shipping Details
                                    </button>
                                    <button
                                        class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                                        type="submit">
                                        Save & Next
                                        <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="place-order-tab-pane" role="tabpanel" tabindex="0">
                            <h5 class="mb-15 mb-md-25 fw-bold text-black">
                                Please Check Your Order
                            </h5>
                            <div class="box p-15 p-sm-20 p-md-25 mb-15 mb-md-25">
                                <div class="mb-15 mb-sm-20 mb-md-25 d-flex align-items-center justify-content-between">
                                    <h6 class="fw-bold text-black-emphasis mb-0">
                                        Shipping Details
                                    </h6>
                                    <a href="#" class="d-inline-block text-decoration-none lh-1 text-muted fw-medium">
                                        <i
                                            class="ph-duotone ph-pencil text-black position-relative top-1 fs-16 me-1"></i>
                                        Edit
                                    </a>
                                </div>
                                <div class="inner-box p-15 p-sm-20 p-md-25 bg-white">
                                    <ul class="ps-0 mb-0 list-unstyled">
                                        <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
                                            <span>Name:</span>
                                            Jane Ronan
                                        </li>
                                        <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
                                            <span>Phone No:</span>
                                            +1 234 4567 5678
                                        </li>
                                        <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
                                            <span>Email:</span>
                                            jane324506@gmail.com
                                        </li>
                                        <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
                                            <span>Address:</span>
                                            795 Folsom Ave, Suite 600 San Francisco, CA 94107 United
                                            States
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="box p-15 p-sm-20 p-md-25">
                                <div class="mb-15 mb-sm-20 mb-md-25 d-flex align-items-center justify-content-between">
                                    <h6 class="fw-bold text-black-emphasis mb-0">
                                        Payment Method
                                    </h6>
                                    <a href="#" class="d-inline-block text-decoration-none lh-1 text-muted fw-medium">
                                        <i
                                            class="ph-duotone ph-pencil text-black position-relative top-1 fs-16 me-1"></i>
                                        Edit
                                    </a>
                                </div>
                                <div class="d-flex align-items-center payment-method">
                                    <img src="@/assets/images/payment/visa.jpg" alt="visa" />
                                    <div class="title ms-15">
                                        <span class="d-block fw-semibold text-black">
                                            Visacard....9036
                                        </span>
                                        <span class="d-block text-paragraph">Expires 12/32</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-15 mt-sm-20 mt-md-25 d-sm-flex align-items-center justify-content-between">
                                <button type="button"
                                    class="d-inline-block fs-14 fs-md-15 fs-lg-16 fw-medium text-decoration-none bg-transparent p-0 border-0 text-primary mb-8 mb-sm-0">
                                    <i class="flaticon-left-arrow lh-1 me-5 position-relative top-2"></i>
                                    Return To Payment Method
                                </button>
                                <button
                                    class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                                    type="submit">
                                    Place Order
                                    <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card mb-25 border-0 rounded-0 bg-white order-summary-box letter-spacing">
                <div class="card-head bg-white d-flex align-items-center">
                    <i class="flaticon-document text-info"></i>
                    <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">Detalle cotización</h5>
                </div>
                <div class="card-body card-info-item">
                    <ul class="order-summary-list ps-0 mb-0 list-unstyled">
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="d-block text-info text-uppercase fw-medium fs-13">
                                {{ customerName }}
                            </span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="d-block text-muted text-uppercase fw-medium fs-13">
                                DESCRIPCIÓN
                            </span>
                            <span class="d-block text-muted text-uppercase fw-medium fs-13">
                                PRECIO
                            </span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="d-block text-paragraph fw-medium">
                                Subtotal
                            </span>
                            <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium">
                                {{ formatCurrency(subtotal) }}
                            </span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="d-block text-paragraph fw-medium">
                                Envío
                            </span>
                            <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium">
                                + $15.00
                            </span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="d-block text-paragraph fw-medium"> Total </span>
                            <span class="d-block text-primary fs-md-15 fs-lg-16 fw-bold">
                                {{ formatCurrency(totalFinal) }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Dialog v-model:visible="visible" modal header="Seleccionar un cliente" :style="{ width: '45rem' }">
            <div class="row">
                <label for="username" class="font-semibold w-30">Nombre</label>
                <div class="row">
                    <form class="search-box position-relative" @submit.prevent novalidate>
                        <div class="col col-md-12">
                            <InputGroup>
                                <InputGroupAddon>
                                    <i class="pi pi-search"></i>
                                </InputGroupAddon>
                                <AutoComplete v-model="selectedClient" fluid :suggestions="filteredClients"
                                    @complete="searchClients" class="w-100" placeholder="Buscar cliente codigo/nombre"
                                    field="displayField" />
                            </InputGroup>
                        </div>
                        <div class="col col-md-12 pt-40">
                            <button @click="onClientSelect" class="btn btn-info btn-sm" type="button"> Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
        <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
    </div>
</template>

<script lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import Quote from "@/services/quoteService";
import { productsItem, clientsItem, methodsItem } from "@/interfaces/interfaces";
import AutoComplete from "primevue/autocomplete";
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import { useToast } from "vue-toastification";
import Dialog from 'primevue/dialog';

export default {
    name: "QuoteComponent",
    components: {
        AutoComplete,
        InputGroup,
        InputGroupAddon,
        Dialog
    },
    setup() {
        const isLoading = ref(false);
        const dataProducts = ref<productsItem[]>([]);
        const dataClients = ref<clientsItem[]>([]);
        const dataMethods = ref<methodsItem[]>([]);
        const selectedProducto = ref<productsItem[]>([]);
        const filteredProductos = ref<productsItem[]>([]);
        const almacenProductos = ref<productsItem[]>([]);
        const filteredClients = ref<clientsItem[]>([]);
        const selectedClient = ref<clientsItem | null>(null);
        const quantity = ref<number>(1);
        const toast = useToast();
        const visible = ref(false);
        const customerName = ref<string>("");

        const getListClients = async () => {
            try {
                const data = await new Quote().ListClients();
                if (data.response && data.response.length > 0) {
                    dataClients.value = data.response
                    visible.value = true;
                } else {
                    visible.value = false;
                    toast.warning("No se econtró ningun cliente registrado")
                }

            } catch (error) {
                console.log("Ocurrio un error en obtener el listado", error)
            }
        }

        const getListProducts = async () => {
            try {
                isLoading.value = true;

                const data = await new Quote().ListProducts();
                if (data.response) {
                    dataProducts.value = data.response;
                }
            } catch (error) {
                console.log("Ocurrio un error en obtener el listado de productos", error)
            } finally {
                isLoading.value = false;
            }
        }

        const getListPaymentMethods = async () => {
            try {
                const data = await new Quote().ListPaymentMethods();
                if (data.response) {
                    dataMethods.value = data.response
                }
            } catch (error) {
                console.log("Ocurrio un error en obtener el listado de metodos de pagos", error)
            }
        }

        const clientsFormat = computed(() =>
            dataClients.value.map(clients => ({
                ...clients,
                displayField: `${clients.codigo_cliente} - ${clients.nombre}`
            }))
        );

        const productsFormat = computed(() =>
            dataProducts.value.map(producto => ({
                ...producto,
                displayField: `${producto.codigo} - ${producto.nombre_producto}`
            }))
        );



        const validProduct = computed(() => {
            return almacenProductos.value.length > 0;
        });

        const handleTabClick = (event) => {
            if (!validProduct.value) {
                event.preventDefault();
                toast.warning('Debe seleccionar al menos un producto para continuar.');
            }
        };


        const searchProductos = (event) => {
            const query = event.query.toLowerCase();

            if (query.length >= 3) {
                const filtered = productsFormat.value.filter(producto =>
                    producto.displayField.toLowerCase().includes(query)
                );

                if (filtered.length > 30) {
                    filteredProductos.value = filtered.slice(0, 30);
                } else {
                    filteredProductos.value = filtered;
                }
            } else {
                filteredProductos.value = [];
            }
        };


        const searchClients = (event) => {
            const query = event.query.toLowerCase();

            if (query.length >= 3) {
                const filtered = clientsFormat.value.filter(producto =>
                    producto.displayField.toLowerCase().includes(query)
                );

                if (filtered.length > 40) {
                    filteredClients.value = filtered.slice(0, 40);
                } else {
                    filteredClients.value = filtered;
                }
            } else {
                filteredClients.value = [];
            }
        };


        const onProductoSelect = (producto) => {
            if (producto.uuid) {
                almacenProductos.value.push({
                    ...producto,
                });

                selectedProducto.value = [];
            }
        };

        const onClientSelect = () => {
            if (selectedClient.value) {
                customerName.value = selectedClient.value.nombre;

            }
            visible.value = false;
        };

        const formatCurrency = (amount: number): string => {
            return new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
            }).format(amount);
        };

        const getLiters = (item) => {
            const litersPerBox = item.presentacion * item.empaque;
            const litersPerUnit = item.presentacion;
            const cajas = item.cantidadCajas || 0;
            const unidadesSueltas = item.cantidadUnidadesSueltas || 0;
            const totalLitres = cajas * litersPerBox + unidadesSueltas * litersPerUnit;
            return totalLitres;
        };


        const calculatePriceWithDiscount = (item) => {
            const precioPorCaja = parseFloat(item.precio_refaccionaria);
            const precioPorUnidad = precioPorCaja / item.empaque;
            const cantidadCajas = item.cantidadCajas || 0;
            const cantidadUnidadesSueltas = item.cantidadUnidadesSueltas || 0;

            let totalPrecio = cantidadCajas * precioPorCaja + cantidadUnidadesSueltas * precioPorUnidad;

            // Aplica el descuento si está definido y es mayor que 0
            if (item.descuento && !isNaN(item.descuento) && item.descuento > 0) {
                const discountAmount = totalPrecio * (item.descuento / 100);
                totalPrecio -= discountAmount;
            }

            return totalPrecio;
        };


        const deleteProduct = (index) => {
            almacenProductos.value.splice(index, 1);
        };


        const iva = computed(() => {
            const impuesto = 0.16; // 16% de IVA
            return subtotal.value * impuesto;
        });


        const subtotal = computed(() => {
            return almacenProductos.value.reduce((acc, item) => {
                return acc + calculatePriceWithDiscount(item);
            }, 0);
        });


        const totalFinal = computed(() => {
            const impuesto = 0.16;
            return subtotal.value * (1 + impuesto);
        });

        watch(
            almacenProductos,
            (newValue) => {
                newValue.forEach((item) => {
                    watch(
                        () => [item.cantidadCajas, item.cantidadUnidadesSueltas],
                        () => {
                            getLiters(item),
                                calculatePriceWithDiscount(item)
                        },
                        { deep: true }
                    );
                });
            },
            { deep: true }
        );

        watch(
            visible,
            async (newValue) => {
                if (!newValue) {
                    if (!selectedClient.value && !visible.value) {
                        toast.warning("Debes de seleccionar un cliente, para continuar con el proceso");
                    } else {
                        await getListProducts();
                        await getListPaymentMethods();
                    }
                }
            },
        );


        onMounted(async () => {
            try {
                isLoading.value = true;
                await getListClients();
            } catch (error) {
                console.log("Ocurrio un error en las solicitudes", error);
            } finally {
                isLoading.value = false;

            }
        });


        return {
            isLoading,
            dataProducts,
            dataClients,
            dataMethods,
            selectedProducto,
            filteredProductos,
            searchProductos,
            onProductoSelect,
            almacenProductos,
            quantity,
            formatCurrency,
            calculatePriceWithDiscount,
            deleteProduct,
            handleTabClick,
            getLiters,
            totalFinal,
            subtotal,
            iva,
            visible,
            searchClients,
            selectedClient,
            filteredClients,
            onClientSelect,
            customerName
        }
    }
};
</script>
<style scoped>
.tab-coti {
    height: 730px;
    overflow: hidden;
}

.card-info-item {
    height: 600px;
}

.shopping-cart-box .card-body {
    max-height: 600px;
    /* Cambia según lo necesario */
    overflow-y: auto;
}
</style>