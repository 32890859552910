import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_6 = { class: "shadow-none lh-1 fw-bold ps-0" }
const _hoisted_7 = { class: "shadow-none lh-1 fw-semibold text-black-emphasis" }
const _hoisted_8 = { class: "shadow-none lh-1 fw-medium text-body-tertiary" }
const _hoisted_9 = { class: "shadow-none lh-1 fw-medium text-body-tertiary" }
const _hoisted_10 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_11 = { class: "shadow-none lh-1 fw-bold pe-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createStaticVNode("<h6 class=\"card-title fw-bold mb-0\">Stock Report</h6><div class=\"card-select mt-10 mt-sm-0 mb-10 mb-sm-0 d-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><span class=\"fw-medium text-muted me-8\">Status</span><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option selected class=\"fw-medium\">Show All</option><option value=\"1\" class=\"fw-medium\">In Stock</option></select></div>", 2)),
        _createVNode(_component_router_link, {
          to: "/products-list",
          class: "card-btn fw-medium d-inline-block text-decoration-none transition ps-10 pe-10 pt-5 pb-5"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" View Stock ")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("table", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              }, " Item "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " Product ID "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " Price "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " Dated Added "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " Status "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 pe-0"
              }, " Qty ")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stocks, (stock) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: stock.id
              }, [
                _createElementVNode("th", _hoisted_6, [
                  _createVNode(_component_router_link, {
                    to: "/product-details",
                    class: "text-decoration-none text-black-emphasis"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(stock.item), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("td", _hoisted_7, _toDisplayString(stock.productID), 1),
                _createElementVNode("td", _hoisted_8, " $" + _toDisplayString(stock.price), 1),
                _createElementVNode("td", _hoisted_9, _toDisplayString(stock.date), 1),
                _createElementVNode("td", _hoisted_10, [
                  _createElementVNode("span", {
                    class: _normalizeClass(['badge', stock.class])
                  }, _toDisplayString(stock.status), 3)
                ]),
                _createElementVNode("td", _hoisted_11, [
                  _createTextVNode(_toDisplayString(stock.quantity) + " ", 1),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-medium text-body-tertiary" }, "Pcs", -1))
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}