import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white todays-event-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 pt-lg-30 pb-lg-30 ps-lg-25 pe-lg-25 letter-spacing" }
const _hoisted_3 = { class: "row ms-0 me-0 align-items-center" }
const _hoisted_4 = { class: "col-4 ps-0 pe-0" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-8 ps-0 pe-0" }
const _hoisted_7 = { class: "content" }
const _hoisted_8 = { class: "mt-8 text-black fw-medium d-flex align-items-center" }
const _hoisted_9 = { class: "mt-5 text-black fw-medium d-flex align-items-center" }
const _hoisted_10 = { class: "fw-medium text-secondary mt-10 mt-md-25 mb-5 mb-md-8 d-block" }
const _hoisted_11 = { class: "fw-bold text-black" }
const _hoisted_12 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"mb-10 mb-md-25 d-flex justify-content-between\"><div class=\"title\"><h5 class=\"card-title fw-bold mb-7\">Today’s Event</h5><span class=\"d-block text-black fw-medium\">12 Events in all</span></div><div class=\"todays-event-swiper-pagination\"></div></div>", 1)),
      _createVNode(_component_swiper, {
        "slides-per-view": 1,
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
        },
        pagination: {
          clickable: true,
        },
        modules: _ctx.modules,
        class: "todaysEventSwiper"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: course.id
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("img", {
                        src: course.image,
                        alt: "course-image"
                      }, null, 8, _hoisted_5)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_router_link, {
                          to: "/event-details",
                          class: "d-block fw-bold text-decoration-none text-black"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(course.title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("span", _hoisted_8, [
                          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "w-6 h-6 d-block bg-white rounded-circle me-8" }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(course.speakers) + " ", 1),
                          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-black ms-5 fw-normal" }, "Speakers", -1))
                        ]),
                        _createElementVNode("span", _hoisted_9, [
                          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "w-6 h-6 d-block bg-white rounded-circle me-8" }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(course.topics) + " ", 1),
                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-black ms-5 fw-normal" }, "Topics", -1))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_10, [
                    _cache[4] || (_cache[4] = _createTextVNode(" Start ")),
                    _createElementVNode("span", _hoisted_11, " in " + _toDisplayString(course.start) + " mins ", 1)
                  ]),
                  _createElementVNode("div", {
                    class: "progress",
                    role: "progressbar",
                    "aria-valuenow": course.progress,
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }, [
                    _createElementVNode("div", {
                      class: "progress-bar",
                      style: _normalizeStyle({ width: course.progress + '%' })
                    }, null, 4)
                  ], 8, _hoisted_12),
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "d-flex align-items-center justify-content-between mt-15 mt-md-30" }, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "fs-13 fw-medium text-black p-0 bg-transparent border-0"
                    }, " Skip This "),
                    _createElementVNode("button", {
                      type: "button",
                      class: "card-link-btn border-0 bg-transparent p-0 text-decoration-none text-primary fw-medium position-relative d-inline-block"
                    }, " Join Event ")
                  ], -1))
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules"])
    ])
  ]))
}