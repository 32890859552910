import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_6 = { class: "shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0" }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "d-block" }
const _hoisted_10 = { class: "shadow-none lh-1" }
const _hoisted_11 = { class: "users-list d-flex align-items-center" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "shadow-none lh-1 fw-bold text-body-tertiary" }
const _hoisted_14 = { class: "shadow-none lh-1 fw-bold text-body-tertiary" }
const _hoisted_15 = { class: "shadow-none lh-1 fw-medium text-body-tertiary" }
const _hoisted_16 = ["aria-valuenow"]
const _hoisted_17 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_18 = { class: "shadow-none lh-1 fw-medium text-body-tertiary" }
const _hoisted_19 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Project Stats", -1)),
        _createVNode(_component_router_link, {
          to: "/projects-grid",
          class: "card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" View Details ")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("table", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              }, " PROJECT "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " TEAM MEMBERS "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " BUDGET "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " DUE DATE "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " PROGRESS "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " STATUS "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " CHART "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: stat.id
              }, [
                _createElementVNode("th", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: require(`../../../../assets/images/${stat.image}`),
                      width: "37",
                      height: "37",
                      class: "rounded-1 me-10",
                      alt: "project-image"
                    }, null, 8, _hoisted_8),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(stat.title), 1)
                  ])
                ]),
                _createElementVNode("td", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stat.teamMembers, (team) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "rounded-circle text-center",
                        key: team.id
                      }, [
                        _createElementVNode("img", {
                          src: require(`../../../../assets/images/${team.image}`),
                          class: "rounded-circle",
                          alt: "user"
                        }, null, 8, _hoisted_12)
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("td", _hoisted_13, " $" + _toDisplayString(stat.budget), 1),
                _createElementVNode("td", _hoisted_14, _toDisplayString(stat.dueDate), 1),
                _createElementVNode("td", _hoisted_15, [
                  _createElementVNode("div", {
                    class: "progress",
                    role: "progressbar",
                    "aria-valuenow": stat.progress,
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }, [
                    _createElementVNode("div", {
                      class: "progress-bar",
                      style: _normalizeStyle({ width: stat.progress + '%' })
                    }, null, 4)
                  ], 8, _hoisted_16)
                ]),
                _createElementVNode("td", _hoisted_17, [
                  _createElementVNode("span", {
                    class: _normalizeClass(['badge fs-13', stat.class])
                  }, _toDisplayString(stat.status), 3)
                ]),
                _createElementVNode("td", _hoisted_18, [
                  _createElementVNode("img", {
                    src: require(`../../../../assets/images/${stat.chartImage}`),
                    alt: "chart"
                  }, null, 8, _hoisted_19)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                  _createElementVNode("div", { class: "dropdown" }, [
                    _createElementVNode("button", {
                      class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                      type: "button",
                      "data-bs-toggle": "dropdown",
                      "aria-expanded": "false"
                    }, [
                      _createElementVNode("i", { class: "flaticon-dots" })
                    ]),
                    _createElementVNode("ul", { class: "dropdown-menu" }, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" View ")
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" Edit ")
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" Delete ")
                        ])
                      ])
                    ])
                  ])
                ], -1))
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}