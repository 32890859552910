import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/user.png'


const _hoisted_1 = { class: "row align-items-center" }
const _hoisted_2 = { class: "col-xl-4 col-lg-5 col-md-6" }
const _hoisted_3 = { class: "header-left-side justify-content-center justify-content-md-start d-flex align-items-center" }
const _hoisted_4 = { class: "col-xl-8 col-lg-7 col-md-6" }
const _hoisted_5 = { class: "header-right-side d-flex align-items-center justify-content-center justify-content-md-end" }
const _hoisted_6 = { class: "dropdown profile-dropdown" }
const _hoisted_7 = {
  class: "dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_8 = { class: "title d-none d-lg-block ms-10 ms-lg-15" }
const _hoisted_9 = { class: "d-block fw-bold mb-5 mb-md-8" }
const _hoisted_10 = { class: "dropdown-menu rounded-0 bg-white border-0 start-auto end-0" }
const _hoisted_11 = { class: "ps-0 mb-0 list-unstyled dropdown-body" }
const _hoisted_12 = { class: "text-body-secondary fw-semibold transition position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LightDarkSwtichBtn = _resolveComponent("LightDarkSwtichBtn")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass([
    'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed',
    { sticky: _ctx.isSticky },
  ]),
    id: "header"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0",
            id: "header-burger-menu",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stateStoreInstance.onChange && _ctx.stateStoreInstance.onChange(...args)))
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "flaticon-menu-3" }, null, -1)
          ])),
          _cache[2] || (_cache[2] = _createElementVNode("form", { class: "search-box" }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_LightDarkSwtichBtn),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", _hoisted_7, [
              _cache[4] || (_cache[4] = _createElementVNode("img", {
                src: _imports_0,
                class: "rounded",
                width: "44",
                height: "44",
                alt: "admin"
              }, null, -1)),
              _createElementVNode("span", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.name), 1),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-body-emphasis fw-semibold fs-13" }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("ul", _hoisted_11, [
                _createElementVNode("li", _hoisted_12, [
                  _cache[5] || (_cache[5] = _createElementVNode("i", { class: "flaticon-logout" }, null, -1)),
                  _cache[6] || (_cache[6] = _createTextVNode(" Salir ")),
                  _createVNode(_component_router_link, {
                    to: "/logout",
                    class: "d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  })
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 2))
}