import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white crm-stats-box" }
const _hoisted_2 = { class: "card-body letter-spacing" }
const _hoisted_3 = { class: "row justify-content-between justify-content-end" }
const _hoisted_4 = { class: "col-xxxl-6 col-lg-6 col-md-6" }
const _hoisted_5 = { class: "chart style-two" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"col-xxxl-6 col-lg-6 col-md-6\"><div class=\"icon mb-8 d-flex align-items-center text-uppercase text-dark-emphasis fw-medium\"><i class=\"flaticon-sterile-box text-primary me-6\"></i> NEW LEADS </div><h3 class=\"fw-black mb-15 lh-1\">1058</h3><span class=\"fw-bold text-success text-badge d-inline-block\"><i class=\"flaticon-up-arrow fs-11 lh-1 position-relative top-1\"></i> 3.3% </span><span class=\"d-block text-muted mt-8\">Than last week</span></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_apexchart, {
              type: "donut",
              height: "150",
              id: "newLeadsChart",
              options: _ctx.newLeadsChart,
              series: _ctx.leads
            }, null, 8, ["options", "series"])
          ])
        ])
      ])
    ])
  ]))
}