import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../assets/images/welcome/welcome2.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white welcome-support-desk-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 pt-xl-50 pb-xl-50 ps-xl-40 ps-xl-40 letter-spacing" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-lg-8 col-md-8" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = {
  class: "d-block fw-bold text-primary mb-5",
  id: "current-date"
}
const _hoisted_7 = { class: "row list justify-content-center" }
const _hoisted_8 = { class: "d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formattedDate), 1),
            _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "fw-semibold mb-8" }, [
              _createTextVNode(" Welcome To Adlash, "),
              _createElementVNode("span", { class: "fw-bold" }, "Support Desk!")
            ], -1)),
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-black-emphasis fs-md-15 fs-lg-16" }, " Here’s what happening in your desk today. Stay updated. ", -1)),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.updates, (update) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-lg-4 col-sm-4",
                  key: update.id
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(['p-15', update.bgClass])
                  }, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(update.title), 1),
                    _createElementVNode("h4", {
                      class: _normalizeClass(['mb-0 fw-black', update.class])
                    }, _toDisplayString(update.number) + "+ ", 3)
                  ], 2)
                ]))
              }), 128))
            ])
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-lg-4 col-md-4 text-center mt-15 mt-md-0" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "welcome-image"
          })
        ], -1))
      ])
    ])
  ]))
}