import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white top-selling-products-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-15 mb-md-20 mb-lg-25 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "list ps-0 mb-0 list-unstyled" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "d-block fw-bold text-black-emphasis me-15" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "title ms-10 ms-md-15" }
const _hoisted_9 = { class: "d-block fw-medium text-muted" }
const _hoisted_10 = { class: "d-block fw-medium text-black-emphasis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h6", { class: "card-title fw-bold mb-0" }, "Top Selling Products", -1)),
        _createVNode(_component_router_link, {
          to: "/products-list",
          class: "card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Export "),
            _createElementVNode("i", { class: "flaticon-logout lh-1 position-relative ms-5 top-2" }, null, -1)
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-flex mb-12 justify-content-between align-items-center" }, [
        _createElementVNode("span", { class: "fs-13 d-block fw-medium text-dark-emphasis" }, "PRODUCTS"),
        _createElementVNode("span", { class: "fs-13 d-block fw-medium text-dark-emphasis" }, "STOCK")
      ], -1)),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "d-flex align-items-center justify-content-between",
            key: product.id
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(product.id) + ". ", 1),
              _createElementVNode("img", {
                src: require(`../../../../assets/images/${product.image}`),
                width: "44",
                height: "44",
                class: "rounded-circle",
                alt: "product-image"
              }, null, 8, _hoisted_7),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_link, {
                  to: "/product-details",
                  class: "fs-md-15 fs-lg-16 text-black-emphasis text-decoration-none fw-semibold"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(product.title), 1)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("span", _hoisted_9, _toDisplayString(product.itemSold) + " Item Sold ", 1)
              ])
            ]),
            _createElementVNode("span", _hoisted_10, _toDisplayString(product.stock), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}