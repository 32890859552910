<template>
  <DialogComponent v-model:visible="isVisible" :style="{ width: '15rem' }">
    <template #header>
      <h6>Agregar nuevo tag</h6>
    </template>
    <div>
      <input
        type="text"
        v-model="newTagName"
        placeholder="Nombre del tag"
        class="form-control mb-3"
      />
      <button class="btn btn-primary px-2 py-1 w-100" @click="addTag">Agregar</button>
    </div>
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
  </DialogComponent>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from "vue";
import { useToast } from "vue-toastification";
import createSocketService from "@/services/socketService";
import VueLoading from "vue-loading-overlay";

export default defineComponent({
  name: "TagModal",
  components: { VueLoading },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const toast = useToast();
    const newTagName = ref("");
    const isVisible = ref(props.visible);
    const isLoading = ref(false);
    let socketService = null;

    const addTag = async () => {
      isLoading.value = true;
      try {
        socketService.emit("add-tag-user", { nombre: newTagName.value });
        
        socketService.on("add-tag-user", (response) => {
          if (!response.error) {
            toast.success("Tag agregado con éxito");
            emit("close");
          } else {
            toast.error("Error al agregar el tag");
          }
          isLoading.value = false; 
        });
      } catch (error) {
        toast.error("Error al agregar el tag");
        isLoading.value = false;
      }
    };

    watch(
      () => props.visible,
      (newVal) => {
        isVisible.value = newVal;
      }
    );

    watch(isVisible, (newVal) => {
      if (!newVal) {
        emit("close");
      }
    });

    onMounted(async () => {
      socketService = await createSocketService();
    });

    return {
      newTagName,
      isVisible,
      isLoading,
      addTag,
    };
  },
});
</script>
