<template>
  <div class="row">
    <div class="col-sm-6 col-lg-4 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white seller-card">
        <div class="card-body letter-spacing">
          <div
            class="mb-15 mb-md-20 mb-lg-25 d-flex align-items-center justify-content-between"
          >
            <div class="icon position-relative text-center rounded-circle">
              <img
                src="../../../assets/images/sellers/seller1.png"
                class="position-absolute start-0 end-0 ms-auto me-auto"
                alt="seller"
              />
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <h5 class="fs-18 fw-semibold text-black mb-5 mb-md-8">Brand Smith</h5>
          <span class="text-paragraph d-block"
            >Store: <span class="text-primary fw-semibold">Oxitech</span></span
          >
          <ul
            class="list mt-15 mt-lg-25 ps-0 mb-0 list-unstyled d-flex justify-content-between"
          >
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >BALANCE</span
              >
              <span class="d-block text-dark fw-medium">$ 4100.00</span>
            </li>
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >REVENUE</span
              >
              <span class="d-block text-dark fw-medium">$ 3570.05</span>
            </li>
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >PRODUCTS</span
              >
              <span class="d-block text-dark fw-medium">141</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-4 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white seller-card">
        <div class="card-body letter-spacing">
          <div
            class="mb-15 mb-md-20 mb-lg-25 d-flex align-items-center justify-content-between"
          >
            <div class="icon position-relative text-center rounded-circle">
              <img
                src="../../../assets/images/sellers/seller2.png"
                class="position-absolute start-0 end-0 ms-auto me-auto"
                alt="seller"
              />
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <h5 class="fs-18 fw-semibold text-black mb-5 mb-md-8">Johny Kuber</h5>
          <span class="text-paragraph d-block"
            >Store:
            <span class="text-primary fw-semibold">Arrowtech</span></span
          >
          <ul
            class="list mt-15 mt-lg-25 ps-0 mb-0 list-unstyled d-flex justify-content-between"
          >
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >BALANCE</span
              >
              <span class="d-block text-dark fw-medium">$ 3421.00</span>
            </li>
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >REVENUE</span
              >
              <span class="d-block text-dark fw-medium">$ 4312.05</span>
            </li>
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >PRODUCTS</span
              >
              <span class="d-block text-dark fw-medium">100</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-4 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white seller-card">
        <div class="card-body letter-spacing">
          <div
            class="mb-15 mb-md-20 mb-lg-25 d-flex align-items-center justify-content-between"
          >
            <div class="icon position-relative text-center rounded-circle">
              <img
                src="../../../assets/images/sellers/seller3.png"
                class="position-absolute start-0 end-0 ms-auto me-auto"
                alt="seller"
              />
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <h5 class="fs-18 fw-semibold text-black mb-5 mb-md-8">
            Bradley Loren
          </h5>
          <span class="text-paragraph d-block"
            >Store: <span class="text-primary fw-semibold">Meditech</span></span
          >
          <ul
            class="list mt-15 mt-lg-25 ps-0 mb-0 list-unstyled d-flex justify-content-between"
          >
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >BALANCE</span
              >
              <span class="d-block text-dark fw-medium">$ 4321.00</span>
            </li>
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >REVENUE</span
              >
              <span class="d-block text-dark fw-medium">$ 4321.05</span>
            </li>
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >PRODUCTS</span
              >
              <span class="d-block text-dark fw-medium">321</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-4 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white seller-card">
        <div class="card-body letter-spacing">
          <div
            class="mb-15 mb-md-20 mb-lg-25 d-flex align-items-center justify-content-between"
          >
            <div class="icon position-relative text-center rounded-circle">
              <img
                src="../../../assets/images/sellers/seller4.png"
                class="position-absolute start-0 end-0 ms-auto me-auto"
                alt="seller"
              />
            </div>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <h5 class="fs-18 fw-semibold text-black mb-5 mb-md-8">
            Jennyfer Watson
          </h5>
          <span class="text-paragraph d-block"
            >Store: <span class="text-primary fw-semibold">Brengal</span></span
          >
          <ul
            class="list mt-15 mt-lg-25 ps-0 mb-0 list-unstyled d-flex justify-content-between"
          >
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >BALANCE</span
              >
              <span class="d-block text-dark fw-medium">$ 5432.00</span>
            </li>
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >REVENUE</span
              >
              <span class="d-block text-dark fw-medium">$ 5432.05</span>
            </li>
            <li>
              <span
                class="d-block text-dark-emphasis text-uppercase fw-medium fs-12 mb-5 mb-md-8"
                >PRODUCTS</span
              >
              <span class="d-block text-dark fw-medium">99</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreContent",
};
</script>