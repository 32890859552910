import control from "./apiService";

export default class Map {
    async requestPoints() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos"
        );
    }

    async agentList() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-usuario-agente"
        );
    }

    async listAgentPoints(uuids) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos-usuario-agente",
            {
                uuidUser: uuids,
            }
        );
    }

    async stateList() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/traer-estados"
        );
    }

    async listStatesPoints(uuids) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos-estado",
            {
                estados: uuids,
            }
        );
    }

    async regionList() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/traer-regiones"
        );
    }

    async listRegionPoints(uuids) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos-region",
            {
                regiones: uuids,
            }
        );
    }

    async serchIntelligent(text) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/buscador-inteligente",
            {
                text: text,
            }
        );
    }
    async registerClient(data) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/registrar-cliente",
            data
        );
    }

    async ListClasifiClient() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-clasificacion-cliente"
        );
    }
    async ListLevelClient() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-nivel-cliente"
        );
    }

    async updateClient(data) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/actualizar-cliente",
            data
        );
    }

    async frecuenciaCompra() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-frecuencia-compra"
        );
    }

    async formaPagos() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-forma-pago"
        );
    }

    async infoClientes(uuid) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/info-cliente",
            { uuid }
        );
    }

    async ListAlmacenes() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-almacenes",
        );
    }

    async listAgents() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-agentes-v2",
        );
    }
    async ListRoles() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-roles",
        );
    }
    async ListPermisos() {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/listar-permisos",
        );
    }


    async listGeneralAgentPoints(uuids) {
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos-usuario-agente",
            {
                uuidUser: uuids,
            }
        );
    }

    async saveNewRol(newRol){
        return await new control().fetchData(
            "/cmc24/api/cmc_siystem24/yetox/v1/agregar-rol",
            {
                rol: newRol
            }
        );
    }
}
