<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-20 p-md-25 p-lg-30">
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/google.png"
            alt="google"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Google
            </span>
            <span class="d-block text-muted fw-medium">
              Calendar and Contacts
            </span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/slack.png"
            alt="slack"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Slack
            </span>
            <span class="d-block text-muted fw-medium">Communications</span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/github.png"
            alt="github"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Github
            </span>
            <span class="d-block text-muted fw-medium">
              Manage your Git repositories
            </span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/mailchimp.png"
            alt="mailchimp"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Mailchimp
            </span>
            <span class="d-block text-muted fw-medium">
              Email marketing service
            </span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/asana.png"
            alt="asana"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Asana
            </span>
            <span class="d-block text-muted fw-medium">Communication</span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-muted"
        >
          Not Connected
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/facebook.png"
            alt="facebook"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Facebook
            </span>
            <span class="d-block text-muted fw-medium">
              Calendar and Contacts
            </span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/twitter.png"
            alt="twitter"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Twitter
            </span>
            <span class="d-block text-muted fw-medium">Communications</span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/instagram.png"
            alt="instagram"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Instagram
            </span>
            <span class="d-block text-muted fw-medium">
              Manage your Git repositories
            </span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-15 mb-sm-20 mb-md-25"
      >
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/dribbble.png"
            alt="dribbble"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Dribbble
            </span>
            <span class="d-block text-muted fw-medium">
              Email marketing service
            </span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <img
            src="../../../assets/images/connected-accounts/behance.png"
            alt="behance"
          />
          <div class="ms-15">
            <span class="d-block text-black fs-md-15 fs-lg-16 fw-bold mb-1">
              Behance
            </span>
            <span class="d-block text-muted fw-medium">Communication</span>
          </div>
        </div>
        <button
          type="button"
          class="bg-transparent fs-md-15 fs-lg-16 fw-medium p-0 border-0 text-primary"
        >
          Connected
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConnectedAccounts",
};
</script>