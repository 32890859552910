<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-20">Colors</h5>
      <p class="text-primary fw-medium">.text-primary</p>
      <p class="text-primary-emphasis fw-medium">.text-primary-emphasis</p>
      <p class="text-secondary fw-medium">.text-secondary</p>
      <p class="text-secondary-emphasis fw-medium">.text-secondary-emphasis</p>
      <p class="text-success fw-medium">.text-success</p>
      <p class="text-success-emphasis fw-medium">.text-success-emphasis</p>
      <p class="text-danger fw-medium">.text-danger</p>
      <p class="text-danger-emphasis fw-medium">.text-danger-emphasis</p>
      <p class="text-warning bg-dark fw-medium">.text-warning</p>
      <p class="text-warning-emphasis fw-medium">.text-warning-emphasis</p>
      <p class="text-info bg-dark fw-medium">.text-info</p>
      <p class="text-info-emphasis fw-medium">.text-info-emphasis</p>
      <p class="text-light bg-dark fw-medium">.text-light</p>
      <p class="text-light-emphasis fw-medium">.text-light-emphasis</p>
      <p class="text-dark bg-white fw-medium">.text-dark</p>
      <p class="text-dark-emphasis fw-medium">.text-dark-emphasis</p>
      <p class="text-body fw-medium">.text-body</p>
      <p class="text-body-emphasis fw-medium">.text-body-emphasis</p>
      <p class="text-body-secondary fw-medium">.text-body-secondary</p>
      <p class="text-body-tertiary fw-medium">.text-body-tertiary</p>
      <p class="text-black bg-white fw-medium">.text-black</p>
      <p class="text-white bg-dark fw-medium">.text-white</p>
      <p class="text-black-50 bg-white fw-medium">.text-black-50</p>
      <p class="text-white-50 bg-dark fw-medium">.text-white-50</p>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#colorsCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="colorsCode">
&lt;p class="text-primary"&gt;.text-primary&lt;/p&gt;
&lt;p class="text-primary-emphasis"&gt;.text-primary-emphasis&lt;/p&gt;
&lt;p class="text-secondary"&gt;.text-secondary&lt;/p&gt;
&lt;p class="text-secondary-emphasis"&gt;.text-secondary-emphasis&lt;/p&gt;
&lt;p class="text-success"&gt;.text-success&lt;/p&gt;
&lt;p class="text-success-emphasis"&gt;.text-success-emphasis&lt;/p&gt;
&lt;p class="text-danger"&gt;.text-danger&lt;/p&gt;
&lt;p class="text-danger-emphasis"&gt;.text-danger-emphasis&lt;/p&gt;
&lt;p class="text-warning bg-dark"&gt;.text-warning&lt;/p&gt;
&lt;p class="text-warning-emphasis"&gt;.text-warning-emphasis&lt;/p&gt;
&lt;p class="text-info bg-dark"&gt;.text-info&lt;/p&gt;
&lt;p class="text-info-emphasis"&gt;.text-info-emphasis&lt;/p&gt;
&lt;p class="text-light bg-dark"&gt;.text-light&lt;/p&gt;
&lt;p class="text-light-emphasis"&gt;.text-light-emphasis&lt;/p&gt;
&lt;p class="text-dark bg-white"&gt;.text-dark&lt;/p&gt;
&lt;p class="text-dark-emphasis"&gt;.text-dark-emphasis&lt;/p&gt;

&lt;p class="text-body"&gt;.text-body&lt;/p&gt;
&lt;p class="text-body-emphasis"&gt;.text-body-emphasis&lt;/p&gt;
&lt;p class="text-body-secondary"&gt;.text-body-secondary&lt;/p&gt;
&lt;p class="text-body-tertiary"&gt;.text-body-tertiary&lt;/p&gt;

&lt;p class="text-black bg-white"&gt;.text-black&lt;/p&gt;
&lt;p class="text-white bg-dark"&gt;.text-white&lt;/p&gt;
&lt;p class="text-black-50 bg-white"&gt;.text-black-50&lt;/p&gt;
&lt;p class="text-white-50 bg-dark"&gt;.text-white-50&lt;/p&gt;
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-5 mb-md-15">Opcity Colors</h5>
      <div class="text-primary mt-10 fw-medium">
        This is default primary text
      </div>
      <div class="text-primary text-opacity-75 mt-10 fw-medium">
        This is 75% opacity primary text
      </div>
      <div class="text-primary text-opacity-50 mt-10 fw-medium">
        This is 50% opacity primary text
      </div>
      <div class="text-primary text-opacity-25 mt-10 fw-medium">
        This is 25% opacity primary text
      </div>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#opcityColorsCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="opcityColorsCode">
&lt;div class="text-primary"&gt;This is default primary text&lt;/div&gt;
&lt;div class="text-primary text-opacity-75"&gt;This is 75% opacity primary text&lt;/div&gt;
&lt;div class="text-primary text-opacity-50"&gt;This is 50% opacity primary text&lt;/div&gt;
&lt;div class="text-primary text-opacity-25"&gt;This is 25% opacity primary text&lt;/div&gt;
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-20">Colors</h5>
      <p class="text-primary fw-medium">.text-primary</p>
      <p class="text-primary-emphasis fw-medium">.text-primary-emphasis</p>
      <p class="text-secondary fw-medium">.text-secondary</p>
      <p class="text-secondary-emphasis fw-medium">.text-secondary-emphasis</p>
      <p class="text-success fw-medium">.text-success</p>
      <p class="text-success-emphasis fw-medium">.text-success-emphasis</p>
      <p class="text-danger fw-medium">.text-danger</p>
      <p class="text-danger-emphasis fw-medium">.text-danger-emphasis</p>
      <p class="text-warning bg-dark fw-medium">.text-warning</p>
      <p class="text-warning-emphasis fw-medium">.text-warning-emphasis</p>
      <p class="text-info bg-dark fw-medium">.text-info</p>
      <p class="text-info-emphasis fw-medium">.text-info-emphasis</p>
      <p class="text-light bg-dark fw-medium">.text-light</p>
      <p class="text-light-emphasis fw-medium">.text-light-emphasis</p>
      <p class="text-dark bg-white fw-medium">.text-dark</p>
      <p class="text-dark-emphasis fw-medium">.text-dark-emphasis</p>
      <p class="text-body fw-medium">.text-body</p>
      <p class="text-body-emphasis fw-medium">.text-body-emphasis</p>
      <p class="text-body-secondary fw-medium">.text-body-secondary</p>
      <p class="text-body-tertiary fw-medium">.text-body-tertiary</p>
      <p class="text-black bg-white fw-medium">.text-black</p>
      <p class="text-white bg-dark fw-medium">.text-white</p>
      <p class="text-black-50 bg-white fw-medium">.text-black-50</p>
      <p class="text-white-50 bg-dark fw-medium">.text-white-50</p>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#colorsCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="colorsCode">
&lt;p class="text-primary"&gt;.text-primary&lt;/p&gt;
&lt;p class="text-primary-emphasis"&gt;.text-primary-emphasis&lt;/p&gt;
&lt;p class="text-secondary"&gt;.text-secondary&lt;/p&gt;
&lt;p class="text-secondary-emphasis"&gt;.text-secondary-emphasis&lt;/p&gt;
&lt;p class="text-success"&gt;.text-success&lt;/p&gt;
&lt;p class="text-success-emphasis"&gt;.text-success-emphasis&lt;/p&gt;
&lt;p class="text-danger"&gt;.text-danger&lt;/p&gt;
&lt;p class="text-danger-emphasis"&gt;.text-danger-emphasis&lt;/p&gt;
&lt;p class="text-warning bg-dark"&gt;.text-warning&lt;/p&gt;
&lt;p class="text-warning-emphasis"&gt;.text-warning-emphasis&lt;/p&gt;
&lt;p class="text-info bg-dark"&gt;.text-info&lt;/p&gt;
&lt;p class="text-info-emphasis"&gt;.text-info-emphasis&lt;/p&gt;
&lt;p class="text-light bg-dark"&gt;.text-light&lt;/p&gt;
&lt;p class="text-light-emphasis"&gt;.text-light-emphasis&lt;/p&gt;
&lt;p class="text-dark bg-white"&gt;.text-dark&lt;/p&gt;
&lt;p class="text-dark-emphasis"&gt;.text-dark-emphasis&lt;/p&gt;

&lt;p class="text-body"&gt;.text-body&lt;/p&gt;
&lt;p class="text-body-emphasis"&gt;.text-body-emphasis&lt;/p&gt;
&lt;p class="text-body-secondary"&gt;.text-body-secondary&lt;/p&gt;
&lt;p class="text-body-tertiary"&gt;.text-body-tertiary&lt;/p&gt;

&lt;p class="text-black bg-white"&gt;.text-black&lt;/p&gt;
&lt;p class="text-white bg-dark"&gt;.text-white&lt;/p&gt;
&lt;p class="text-black-50 bg-white"&gt;.text-black-50&lt;/p&gt;
&lt;p class="text-white-50 bg-dark"&gt;.text-white-50&lt;/p&gt;
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-5 mb-md-15">Opcity Colors</h5>
      <div class="text-primary mt-10 fw-medium">
        This is default primary text
      </div>
      <div class="text-primary text-opacity-75 mt-10 fw-medium">
        This is 75% opacity primary text
      </div>
      <div class="text-primary text-opacity-50 mt-10 fw-medium">
        This is 50% opacity primary text
      </div>
      <div class="text-primary text-opacity-25 mt-10 fw-medium">
        This is 25% opacity primary text
      </div>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#opcityColorsCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="opcityColorsCode">
&lt;div class="text-primary"&gt;This is default primary text&lt;/div&gt;
&lt;div class="text-primary text-opacity-75"&gt;This is 75% opacity primary text&lt;/div&gt;
&lt;div class="text-primary text-opacity-50"&gt;This is 50% opacity primary text&lt;/div&gt;
&lt;div class="text-primary text-opacity-25"&gt;This is 25% opacity primary text&lt;/div&gt;
</code>
</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorsContent",
};
</script>