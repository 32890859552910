<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15">Swiper</h5>
      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base mb-12">
        The Most Modern Mobile Touch Slider
      </p>
      <ul class="mb-15">
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Library Agnostic
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Mutation Observer
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">Flexbox Layout</li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Full True RTL Support
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Multi Row Slides Layout
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">3D Effects</li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Two-way Control
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Full Navigation Control
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">Rich API</li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Most Flexible Slides Layout Grid
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Parallax Transitions
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">
          Images Lazy Loading
        </li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">Virtual Slides</li>
        <li class="fs-md-15 fs-lg-16 lh-base text-paragraph">And many more</li>
      </ul>
      <span class="fw-medium text-black fs-md-15 fs-lg-16 d-block">
        Start using it now!
      </span>
      <a
        href="https://swiperjs.com/demos"
        target="_blank"
        class="default-btn text-decoration-none transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success d-inline-block mt-15 mt-md-20"
      >
        Click to See Swiper Demos
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwiperSlider",
};
</script>