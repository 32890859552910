import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white working-schedule-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "card-buttons" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-info"
}
const _hoisted_6 = {
  key: 1,
  class: "info-list ps-0 mb-0 list-unstyled"
}
const _hoisted_7 = { class: "d-inline-block me-4" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "fw-bold" }
const _hoisted_10 = { class: "d-inline-block me-4" }
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = { class: "fw-bold" }
const _hoisted_13 = {
  key: 2,
  class: "schedule-list mt-15 mt-md-20",
  style: {"max-height":"500px","overflow-y":"auto"}
}
const _hoisted_14 = { class: "content" }
const _hoisted_15 = { class: "d-flex align-items-center mb-5" }
const _hoisted_16 = { class: "fw-medium mb-0" }
const _hoisted_17 = { class: "text-muted fs-12" }
const _hoisted_18 = { class: "d-block text-black fw-semibold fs-md-15 mb-1" }
const _hoisted_19 = {
  key: 0,
  class: "d-block text-muted fs-13 mb-1"
}
const _hoisted_20 = { class: "d-block text-muted fs-12 fw-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_ModalContent = _resolveComponent("ModalContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VueLoading, {
      active: $setup.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: "Cargando..."
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "Próximos eventos", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "button",
              class: "default-btn transition text-white card-btn mt-20 mt-sm-0 d-inline-block fw-medium transition text-sm",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.cargarDatosYMostrarModal && $setup.cargarDatosYMostrarModal(...args)))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "flaticon-plus" }, null, -1),
              _createTextVNode(" Crear nuevo evento ")
            ]))
          ])
        ]),
        ($setup.counts.tasks === 0 && $setup.counts.events === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, " No tienes tareas pendientes por hoy. "))
          : (_openBlock(), _createElementBlock("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "d-block w-10 h-10 bg-black rounded-circle me-8" }, null, -1)),
                  _createElementVNode("span", _hoisted_9, [
                    _createTextVNode(_toDisplayString($setup.counts.tasks) + " ", 1),
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-muted fw-semibold" }, "Tareas", -1))
                  ])
                ])
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "d-block w-10 h-10 bg-black rounded-circle me-8" }, null, -1)),
                  _createElementVNode("span", _hoisted_12, [
                    _createTextVNode(_toDisplayString($setup.counts.events) + " ", 1),
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-muted fw-semibold" }, "Eventos", -1))
                  ])
                ])
              ])
            ])),
        ($setup.counts.tasks > 0 || $setup.counts.events > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedItems, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: $setup.getItemId(item),
                  class: "list-item pt-15 pb-15 pt-md-20 pb-md-20 ps-20 pe-15 position-relative",
                  style: _normalizeStyle({ backgroundColor: item.color_prioridad + '20' })
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("h6", _hoisted_16, [
                        _createTextVNode(_toDisplayString($setup.formatTime(item.fecha_inicio)) + " - " + _toDisplayString($setup.formatTime(item.fecha_fin)) + " ", 1),
                        _createElementVNode("span", _hoisted_17, _toDisplayString($setup.getTimePeriod(item.fecha_inicio)), 1)
                      ]),
                      _createElementVNode("div", {
                        class: "ms-10 icon rounded-circle bg-white text-center position-relative",
                        style: _normalizeStyle({ color: item.color_prioridad })
                      }, _cache[8] || (_cache[8] = [
                        _createElementVNode("i", { class: "flaticon-bell-2" }, null, -1)
                      ]), 4)
                    ]),
                    _createElementVNode("span", _hoisted_18, _toDisplayString($setup.isTask(item) ? item.titulo : item.nombre_evento), 1),
                    ($setup.isTask(item))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(item.descripcion), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_20, [
                      _cache[9] || (_cache[9] = _createTextVNode("creado por: ")),
                      _createElementVNode("span", {
                        style: _normalizeStyle({ color: item.color_prioridad })
                      }, _toDisplayString(item.nombre_creador), 5)
                    ])
                  ])
                ], 4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_ModalContent, {
      visible: $setup.modalVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (($setup.modalVisible) = $event)),
      partners: $setup.partners,
      tags: $setup.tags,
      priorities: $setup.priorities,
      onClose: $setup.handleCloseModal,
      onGuardarTarea: $setup.guardarTarea
    }, null, 8, ["visible", "partners", "tags", "priorities", "onClose", "onGuardarTarea"])
  ], 64))
}