<template>
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div
            class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
            <h5 class="text-left col-md-3">Lista de pagos</h5>
            <div class="col-md-6 d-flex justify-content-center">
                <div class="input-group">
                    <PrimeCalendar v-model="dates" selectionMode="range" placeholder="Inserta un rango de fechas"
                        class="w-100 shadow-none text-black rounded-0 border-0" :showIcon="true" appendTo="body"
                        dateFormat="dd/mm/yy" />

                </div>
            </div>
        </div>

        <div class="card-body p-15 p-sm-20 p-md-25">
            <div class="search mb-3">
                <InputText v-model="searchTerm" placeholder="Buscar por folio, cliente o monto" class="w-100" />
            </div>

            <div class="table-responsive">
                <DataTable :value="filteredInventory" showGridlines tableStyle="min-width: 50rem" paginator :rows="10"
                    :rowsPerPageOptions="[5, 10, 20, 50]">
                    <Column class="column-table" field="fecha_pago" header="Fecha" style="width: 10%"></Column>
                    <Column class="column-table" field="tipo" header="Tipo" style="width: 10%"></Column>
                    <Column class="column-table" sortable field="factura" header="Factura" style="width: 10%">
                    </Column>
                    <Column class="column-table" sortable field="cliente" header="Cliente" style="width: 20%">
                    </Column>
                    <Column class="column-table" sortable field="agente" header="Agente" style="width: 20%">
                    </Column>
                    <Column class="column-table" field="observacion" header="Observación" style="width: 20%">
                    </Column>
                    <Column class="column-table" header="Opciones" style="width: 10%">
                        <template #body="slotProps">
                            <Button icon="pi pi pi-check" label="Confirmar"
                                @click="showConfirmDialog(slotProps.data.uuid, slotProps.data.factura)" severity="info"
                                size="small"></Button>
                        </template>
                    </Column>
                    <template #footer>
                        Total {{ inventoryData ? inventoryData.length : 0 }}
                    </template>
                </DataTable>
            </div>
        </div>
        <ConfirmDialog></ConfirmDialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { useToast } from "vue-toastification";
import { listPayment } from "@/interfaces/interfaces";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import createSocketService from "@/services/socketService";
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';


export default defineComponent({
    name: "PaymentList",
    components: {
        DataTable,
        Column,
        Button,
        InputText,
        ConfirmDialog
    },
    setup() {
        const isLoading = ref(true);
        const toast = useToast();
        const inventoryData = ref<listPayment[]>([]);
        const displayDetailsDialog = ref(false);
        const selectedProductDetails = ref<listPayment[]>([]);
        const searchTerm = ref("");
        const updateDate = ref("");
        let socketService: any = null;
        const confirm = useConfirm();
        const dates = ref<(Date | null)[]>([null, null]);

        const getCurrentMonthRange = () => {
            const now = new Date();
            const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
            const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            return [firstDay, lastDay];
        };

        // Función para obtener datos filtrados
        const getListData = async (startDate: string, endDate: string) => {
            try {
                const fechadata = {
                    "fecha_inicio": startDate,
                    "fecha_fin": endDate
                };

                socketService.emit("list-payment-verify", fechadata);
                socketService.on("list-payment-verify", (rspta: any) => {
                    try {
                        if (!rspta.error) {
                            inventoryData.value = rspta.response;
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error("Error en obtener las facturas");
                    }
                });
            } catch (error) {
                toast.error("Ocurrió un error al obtener el inventario");
            }
        };

        // Función para mostrar el diálogo de confirmación
        const showConfirmDialog = (uuid, num_factura) => {
            confirm.require({
                message: `¿Estás seguro de confirmar el pago para la factura con folio:  ${num_factura}?`,
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sí',
                rejectLabel: 'No',
                accept: () => {
                    const data = {
                        "uuidPago": uuid
                    };
                    socketService.emit("save-payment-verify", data);

                    socketService.on("save-payment-verify", (rspta: any) => {
                        try {
                            if (!rspta.error) {
                                toast.success("Datos guardados exitosamente");
                            }
                        } catch (error) {
                            console.log(error);
                            toast.error("Error al guardar los datos");
                        }
                    });
                },
                reject: () => {
                    console.log('Rechazado');
                }
            });
        };

        // Computed para filtrar el inventario
        const filteredInventory = computed(() => {
            if (!searchTerm.value) {
                return inventoryData.value;
            }

            // Filtramos por SKU o nombre del producto
            return inventoryData.value.filter(item =>
                item.factura.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                item.monto.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                item.cliente.toLowerCase().includes(searchTerm.value.toLowerCase())
            );
        });

        watch(dates, (newDates) => {
            if (Array.isArray(newDates) && newDates.length === 2) {
                const [startDate, endDate] = newDates;

                if (startDate && endDate) {
                    getListData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
                } else {
                    toast.error("Una o ambas fechas son nulas.");
                }
            } else {
                toast.error("No se seleccionó un rango válido o es null.");
            }
        });



        onMounted(async () => {
            try {
                isLoading.value = true;
                socketService = await createSocketService();
                socketService.connect();
                dates.value = getCurrentMonthRange();
                if (dates.value[0] && dates.value[1]) {
                    getListData(dates.value[0].toISOString().split('T')[0], dates.value[1].toISOString().split('T')[0]);
                }
            } catch (error) {
                toast.error("Ocurrió un error en la conexión");
            } finally {
                isLoading.value = false;
            }
        });
        return {
            inventoryData,
            isLoading,
            showConfirmDialog,
            displayDetailsDialog,
            selectedProductDetails,
            searchTerm,
            filteredInventory,
            updateDate,
            dates
        };
    },
});
</script>
