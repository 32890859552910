import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_5 = { class: "shadow-none fw-medium ps-0" }
const _hoisted_6 = { class: "mb-10 d-block" }
const _hoisted_7 = { class: "d-flex align-items-center text-muted" }
const _hoisted_8 = { class: "fw-bold ms-5 me-5" }
const _hoisted_9 = { class: "shadow-none lh-1 fw-bold text-black-emphasis" }
const _hoisted_10 = { class: "shadow-none lh-1 fw-bold text-black-emphasis" }
const _hoisted_11 = { class: "shadow-none lh-1 fw-bold text-black-emphasis" }
const _hoisted_12 = { class: "shadow-none lh-1 fw-bold text-black-emphasis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between\"><h5 class=\"card-title fw-bold mb-0\">Courses</h5><div class=\"card-buttons\"><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 active\"> All Courses </a><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 ms-5\"> Paid </a><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 ms-5\"> Free </a><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 ms-5\"> Top Rated </a><a href=\"javascript:void(0);\" class=\"card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5 ms-5\"> Most Popular </a></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              }, " COURSE TITLE "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " TRAINER "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " PUBLISHED ON "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " ENROLLED "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " PRICE "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: course.id
              }, [
                _createElementVNode("th", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: "/course-details",
                      class: "text-decoration-none fs-md-15 fs-lg-16 fw-bold text-black-emphasis"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(course.title), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("span", _hoisted_7, [
                    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "flaticon-book lh-1 text-primary position-relative top-1 fs-md-15 fs-lg-16" }, null, -1)),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(course.classes), 1),
                    _cache[1] || (_cache[1] = _createTextVNode(" Classes "))
                  ])
                ]),
                _createElementVNode("td", _hoisted_9, _toDisplayString(course.trainer), 1),
                _createElementVNode("td", _hoisted_10, _toDisplayString(course.date), 1),
                _createElementVNode("td", _hoisted_11, _toDisplayString(course.enrolled), 1),
                _createElementVNode("td", _hoisted_12, " $" + _toDisplayString(course.price), 1),
                _cache[2] || (_cache[2] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                  _createElementVNode("div", { class: "dropdown" }, [
                    _createElementVNode("button", {
                      class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                      type: "button",
                      "data-bs-toggle": "dropdown",
                      "aria-expanded": "false"
                    }, [
                      _createElementVNode("i", { class: "flaticon-dots" })
                    ]),
                    _createElementVNode("ul", { class: "dropdown-menu" }, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" View ")
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" Edit ")
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" Delete ")
                        ])
                      ])
                    ])
                  ])
                ], -1))
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}