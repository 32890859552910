import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = { class: "card mb-25 border-0 rounded-0" }
const _hoisted_3 = { class: "card-body p-0 letter-spacing" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_6 = { class: "text-black fw-bold mb-10" }
const _hoisted_7 = { class: "mb-15 fs-md-15 fs-lg-16 text-paragraph lh-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-sm-6 col-xl-4 col-xxxl-3",
        key: card.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: require(`../../../../assets/images/${card.image}`),
              alt: "card-image"
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h5", _hoisted_6, _toDisplayString(card.title), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(card.shortDesc), 1),
              _createVNode(_component_router_link, {
                to: "/cards",
                class: "default-btn d-inline-block text-primary text-decoration-none fw-medium pt-10 pb-10 pt-md-12 pb-md-12 ps-25 pe-25 rounded-1 transition bg-f2f1f9"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" View More ")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}