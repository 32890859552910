<template>
  <div>
    <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />

    <CommentsDialog :visible="isDialogVisible" :comments="selectedComments"
      @update:visible="isDialogVisible = $event" />

    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-head box-shadow bg-white p-20 p-md-25 p-lg-30">
        <div class="row align-items-center">
          <div class="col-md-3">
            <div class="input-group">
              <PrimeCalendar v-model="dates" selectionMode="range" placeholder="Inserta un rango de fechas"
                class="w-100 shadow-none text-black rounded-0 border-0" :showIcon="true" appendTo="body" dateFormat="dd/mm/yy" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <PrimeDropdown v-model="selectedPriority" :options="priority" optionLabel="name"
                placeholder="Selecciona una prioridad" checkmark :highlightOnSelect="false" :showClear="true"
                class="w-100" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <PrimeDropdown v-model="selectedType" :options="typeOptions" optionLabel="name"
                placeholder="Tarea o Evento" checkmark :highlightOnSelect="false" :showClear="true" class="w-100" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <span class="input-group-text">
                <i class="pi pi-search"></i>
              </span>
              <input type="text" class="form-control" v-model="searchTerm" placeholder="Buscar por tarea o evento" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="item in filteredPaginatedTasks" :key="item.uuid_tarea || item.uuid_evento"
        class="col-md-6 col-xxl-6 col-xxxl-4 mb-4">
        <div class="card mb-25 border-0 rounded-0 letter-spacing project-card h-100">
          <div class="card-body p-20 p-md-25 p-lg-30 d-flex flex-column justify-content-between">
            <div>
              <div class="row">
                <div class="col-md-8">
                  <h5 class="mb-0 fs-14 fs-md-16 fs-lg-20 fw-bold mt-8 mt-md-10">
                    {{ item.titulo || item.nombre_evento }}
                  </h5>
                </div>
                <div class="col-md-4 text-end">
                  <span class="badge" :style="{ backgroundColor: item.color}">
                    {{ item.prioridad }}
                  </span>
                </div>
              </div>

              <ul class="info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled">
                <li v-if="item.descripcion" class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                  <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">
                    Descripción:
                  </span>
                  {{ item.descripcion }}
                </li>
                <li class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                  <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">
                    Fecha de inicio:
                  </span>
                  {{ formatDate(item.fecha_inicio) }}
                </li>
                <li class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                  <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">
                    Fecha final:
                  </span>
                  {{ formatDate(item.fecha_fin) }}
                </li>
              </ul>

              <div v-if="item.tags && item.tags.length > 0" class="mb-3">
                <span v-for="tag in item.tags" :key="tag.uuid" class="badge bg-primary me-1">
                  {{ tag.tag }}
                </span>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-auto">
              <button v-if="item.comentarios && item.comentarios.length > 0" class="btn btn-link p-0"
                @click="openCommentsDialog(item.comentarios)">
                Ver comentarios
              </button>
              <span class="text-secondary fs-md-15 fs-md-16 fw-semibold">
                <span class="text-muted fs-12 fs-md-13 text-uppercase fw-medium">Creado por:</span>
                {{ item.nombre_creador }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PrimePaginator v-if="filteredTasks.length > rowsPerPage" :rows="rowsPerPage" :totalRecords="filteredTasks.length"
      :page="currentPage" @page="currentPage = $event.page" />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import createSocketService from "@/services/socketService";
import { TaskOrEvent } from "@/interfaces/interfaces";
import PrimePaginator from "primevue/paginator";
import PrimeDropdown from "primevue/dropdown";
import CommentsDialog from "@/components/Projects/ProjectsGrid/CommentsDialog.vue";
import { useToast } from "vue-toastification";

let socketService: any = null;

export default defineComponent({
  name: "ProjectsGrid",
  components: { PrimePaginator, PrimeDropdown, CommentsDialog },
  setup() {
    const tasks = ref<TaskOrEvent[]>([]);
    const searchTerm = ref("");
    const rowsPerPage = ref(6);
    const currentPage = ref(0);
    const toast = useToast();

    const selectedPriority = ref<{ name: string; code: string } | null>(null);
    const selectedType = ref<{ name: string; code: string } | null>(null);

    const isLoading = ref(false);
    const isDialogVisible = ref(false);
    const selectedComments = ref([]);

    const priority = ref([
      { name: "Todos", code: "ALL" },
      { name: "Urgente", code: "URG" },
      { name: "Alta", code: "ALTA" },
      { name: "Media", code: "MEDIA" },
      { name: "Baja", code: "BAJA" },
    ]);

    const typeOptions = ref([
      { name: "Todos", code: "ALL" },
      { name: "Tarea", code: "TASK" },
      { name: "Evento", code: "EVENT" },
    ]);

    const getCurrentMonthRange = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return [firstDay, lastDay];
    };

    const dates = ref(getCurrentMonthRange());

    const filteredTasks = computed(() => {
      return tasks.value.filter((task) => {
        const matchesTitle = task.titulo
          ? task.titulo.toLowerCase().includes(searchTerm.value.toLowerCase())
          : task.nombre_evento?.toLowerCase().includes(searchTerm.value.toLowerCase());

        const matchesPriority =
          !selectedPriority.value ||
          selectedPriority.value.code === "ALL" ||
          (task.prioridad && task.prioridad.toLowerCase() === selectedPriority.value.name.toLowerCase());

        const matchesType =
          !selectedType.value ||
          selectedType.value.code === "ALL" ||
          (selectedType.value.code === "TASK" && !!task.titulo) ||
          (selectedType.value.code === "EVENT" && !!task.nombre_evento);

        return matchesTitle && matchesPriority && matchesType;
      });
    });

    const filteredPaginatedTasks = computed(() => {
      const start = currentPage.value * rowsPerPage.value;
      const end = start + rowsPerPage.value;
      return filteredTasks.value.slice(start, end);
    });

    const openCommentsDialog = (comments) => {
      selectedComments.value = comments;
      isDialogVisible.value = true;
    };

    const fetchTasks = () => {
      isLoading.value = true;
      const [startDate, endDate] = dates.value;
      if (!startDate || !endDate) {
        console.error("Fechas no válidas.");
        isLoading.value = false;
        return;
      }

      const fechadata = {
        fecha_inicio: formatDateWithTime(startDate, "00:00:00.000000"),
        fecha_fin: formatDateWithTime(endDate, "23:59:59.000000"),
      };

      if (!fechadata.fecha_inicio || !fechadata.fecha_fin) {
        console.error("Error formateando las fechas.");
        isLoading.value = false;
        return;
      }

      socketService.emit("list-task-user", fechadata);
      socketService.once("list-task-user", (resp: any) => {
        if (!resp.error) {
          if (resp.response.length === 0) {
            toast.warning("No se encontró ningún dato");
          } else {
            tasks.value = resp.response;
          }
          currentPage.value = 0;
        } else {
          console.error(resp.message);
        }
        isLoading.value = false;
      });
    };

    const formatDateWithTime = (date: Date | null, time: string) => {
      if (!date) {
        return null;
      }
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day} ${time}`;
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleString();
    };

    watch(dates, fetchTasks);

    return {
      tasks,
      dates,
      rowsPerPage,
      currentPage,
      filteredPaginatedTasks,
      priority,
      selectedPriority,
      selectedType,
      typeOptions,
      filteredTasks,
      searchTerm,
      fetchTasks,
      formatDate,
      isLoading,
      isDialogVisible,
      selectedComments,
      openCommentsDialog,
    };
  },
  async mounted() {
    socketService = await createSocketService();
    socketService.connect();

    this.fetchTasks();
  },
});
</script>

