<template>
    <div class="row">
        <div class="col-md-12">
            <CaracteristicasAnaologo/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CaracteristicasAnaologo from "@/components/buscador/CaracteristicasAnaologo.vue";

export default defineComponent({
    name: "CaracteristicasA",
    components: {
        CaracteristicasAnaologo,
    },
});
</script>