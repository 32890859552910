<template>
  <div class="content_app" v-if="isAuthenticated">
    <MainHeader  v-if="!isHeaderVisible" />
    <MainSidebar />
    <div :class="['main-content', { 'no-header': isHeaderVisible, 'has-header': !isHeaderVisible }]" class="d-flex flex-column transition overflow-hidden">
      
      <router-view />
      <MainFooter v-if="!isMapRoute" />
    </div>
  </div>
  <div class="main-content login" v-else>
    <div class="container d-flex flex-column overflow-hidden transition">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watchEffect } from "vue";
import { useRoute } from "vue-router";
import stateStore from "./utils/store";
import localStorageValue from "@/services/localStorageService";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  data() {
    return {
      isAuthenticated: false, 
    };
  },
  async created() {
    this.isAuthenticated = (await new localStorageValue().getDataLocal()) === 1;
    document.body.classList.add("bg-body-secondary");
  },
  setup() {
    const stateStoreInstance = stateStore;
    const route = useRoute(); 

    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    });

    const isMapRoute = computed(() => {
      return route.meta.hideFooter === true; 
    });

    const isHeaderVisible = computed(()=>{
      return route.meta.hideHeader === true;
    })

    return {
      isMapRoute,
      isHeaderVisible
    };
  },
});
</script>

<style scoped>

.main-content.no-header {
  padding-top: 10px;
  padding-right:10px;
}

.main-content.has-header{
  padding-top: 125px;
}
</style>
