import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white time-duration-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "card-body letter-spacing" }, [
      _createElementVNode("div", { class: "icon position-relative lh-1 text-info-light" }, [
        _createElementVNode("i", { class: "flaticon-clock" })
      ]),
      _createElementVNode("div", { class: "content" }, [
        _createElementVNode("span", { class: "d-block fw-bold text-dark-emphasis mb-1" }, " Time To Resolve Complaints "),
        _createElementVNode("h2", { class: "mb-0 fw-black" }, "8 min 30s")
      ]),
      _createElementVNode("span", { class: "fw-medium text-secondary d-block mb-5 mb-md-8" }, [
        _createTextVNode(" Goal "),
        _createElementVNode("span", { class: "fw-bold text-black" }, "7 min 10 sec")
      ]),
      _createElementVNode("div", {
        class: "progress",
        role: "progressbar",
        "aria-valuenow": "42",
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }, [
        _createElementVNode("div", {
          class: "progress-bar bg-info-light",
          style: {"width":"75%"}
        })
      ])
    ], -1)
  ])))
}