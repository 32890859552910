import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing p-4" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-15 p-lg-30" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6 pt-10 rounded-md" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "col-md-6 pt-10 rounded-md" }
const _hoisted_7 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-md-25 text-center" }, "Asignar Razon Social", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("label", {
              for: "taskPriority",
              class: "form-label fw-medium"
            }, "Clientes:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedClient,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedClient) = $event)),
              filter: true,
              options: _ctx.clientes,
              optionLabel: "razon_social",
              optionValue: "uuid",
              placeholder: "Seleccione un cliente",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[3] || (_cache[3] = _createElementVNode("label", {
              for: "taskPriority",
              class: "form-label fw-medium"
            }, "Razon Social:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedClient,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedClient) = $event)),
              filter: true,
              multiple: true,
              options: _ctx.clientes,
              optionLabel: "razon_social",
              optionValue: "uuid",
              placeholder: "Seleccione un cliente",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-center" }, [
          _createElementVNode("button", { class: "btn btn-primary" }, " Enviar ")
        ], -1))
      ])
    ])
  ]))
}