<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing lead-info-box">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
            <div class="profile-info d-sm-flex align-items-center">
                <img src="@/assets/images/user.png" class="rounded" alt="user" />
                <div class="title mt-12 mt-sm-0">
                    <h5 class="text-black fw-medium mb-8">{{ nombre }}</h5>
                    <span class="d-block fw-bold text-primary"> {{ rolNombre }}</span>
                </div>
            </div>
            <div class="border-top mt-15 mb-15 mt-sm-20 mb-sm-20 mt-md-25 mb-md-25 mt-lg-30 mb-lg-30"></div>
            <div class="d-flex align-items-center justify-content-between">
                <h5 class="fw-medium text-black-emphasis mb-0">Información</h5>
            </div>
            <ul class="info mt-25 ps-0 mb-0 list-unstyled">
                <li class="position-relative">
                    <div class="icon text-success rounded-circle text-center">
                        <i class="flaticon-mail-inbox-app"></i>
                    </div>
                    <span class="d-block text-black mb-5 fw-semibold">Email</span>
                    <a href="mailto:johnathon23@gmail.com"
                        class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none">
                        {{ email }}
                    </a>
                </li>
                <li class="position-relative">
                    <div class="icon text-info rounded-circle text-center">
                        <i class="flaticon-telephone-call"></i>
                    </div>
                    <span class="d-block text-black mb-5 fw-semibold">Telefono</span>
                    <a href="tel:+1-321-456-8756"
                        class="d-inline-block fs-md-15 fs-lg-16 text-muted text-decoration-none">
                        {{ telefono }}
                    </a>
                </li>
                <li class="position-relative">
                    <div class="icon text-warning rounded-circle text-center">
                        <i class="flaticon-industry"></i>
                    </div>
                    <span class="d-block text-black mb-5 fw-semibold">RFC</span>
                    <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
                        {{ rfc }}
                    </span>
                </li>
                <li class="position-relative">
                    <div class="icon text-info rounded-circle text-center">
                        <i class="flaticon-employee"></i>
                    </div>
                    <span class="d-block text-black mb-5 fw-semibold">
                        Dirección
                    </span>
                    <span class="d-inline-block fs-md-15 fs-lg-16 text-muted"> {{ direccion }} </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, watch } from "vue";
import User from "@/services/userService";
import mapService from "@/services/mapService";
export default {
    name: "LeadsInformation",
    props: {
        uuid: {
            type: String,
            required: false,
        }
    },
    setup(props) {
        interface Role {
            uuid: string;
            rol: string;
        }

        const nombre = ref("");
        const email = ref("");
        const telefono = ref("");
        const roles = ref<Role[]>([]);
        const rolNombre = ref('');
        const rfc = ref("");
        const direccion = ref("");

        const getRoles = async () => {
            try {
                const response = await new mapService().ListRoles();
                roles.value = response.datos;
            } catch (error) {
                console.log(error)
            }
        };

        const getInfoUser = async (uuid) => {
            try {
                const response = await new User().infoUser({ uuid });
                if (!response.error) {
                    nombre.value = response.datos.nombre;
                    email.value = response.datos.email;
                    telefono.value = response.datos.telefono;
                    rfc.value = response.datos.rfc;
                    direccion.value = response.datos.direccion;

                    await getRoles()

                    const userRoleUuid = response.datos.roles[0];
                    const rolInfo = roles.value.find(r => r.uuid === userRoleUuid);
                    if (rolInfo) {
                        rolNombre.value = rolInfo.rol
                    }
                }
            } catch (error) {
                console.error("Error en getInfoUser:", error);
            }
        }

        watch(
            () => props.uuid,
            async (newData) => {
                await getInfoUser(newData)
            },
            { immediate: true }
        );

        return {
            nombre,
            email,
            telefono,
            rolNombre,
            rfc,
            direccion
        }
    }
};
</script>