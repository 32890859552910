import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing p-4" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-15 p-lg-30" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6 pt-10" }
const _hoisted_5 = { class: "input-group w-100" }
const _hoisted_6 = { class: "col-md-6 pt-10" }
const _hoisted_7 = { class: "input-group w-100" }
const _hoisted_8 = { class: "d-flex justify-content-center mt-3" }
const _hoisted_9 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_10 = { class: "table table-bordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("h5", { class: "mb-4 text-center" }, "Buscador Análogo", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("label", {
              for: "basicFormControlInput1",
              class: "form-label fw-medium text-black"
            }, " Clasificación ", -1)),
            _createVNode(_component_multiselect, {
              modelValue: _ctx.selectedTipo,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTipo) = $event)),
              options: _ctx.clasificaciones,
              searchable: true,
              "close-on-select": true,
              placeholder: "Seleccione una clasificación",
              "track-by": "uuid",
              label: "nombre",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createElementVNode("label", {
              for: "basicFormControlInput1",
              class: "form-label fw-medium text-black"
            }, " Buscar Producto ", -1)),
            _createVNode(_component_AutoComplete, {
              modelValue: _ctx.selectedProducto,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedProducto) = $event)),
              suggestions: _ctx.filteredProductos,
              field: "nombre_producto",
              onComplete: _ctx.searchProductos,
              placeholder: "Buscar por nombre de producto",
              class: "w-100"
            }, null, 8, ["modelValue", "suggestions", "onComplete"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onProductoSelect && _ctx.onProductoSelect(...args))),
          class: "btn btn-primary"
        }, " Buscar ")
      ]),
      (_ctx.equivalenteProductos.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-center" }, "Productos Equivalentes", -1)),
            _createElementVNode("table", _hoisted_10, [
              _cache[5] || (_cache[5] = _createElementVNode("thead", { class: "thead-dark" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Nombre del Producto"),
                  _createElementVNode("th", null, "Marca"),
                  _createElementVNode("th", null, "Clasificación"),
                  _createElementVNode("th", null, "Características")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equivalenteProductos, (producto) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: producto.uuid_producto
                  }, [
                    _createElementVNode("td", null, _toDisplayString(producto.nombre_producto), 1),
                    _createElementVNode("td", null, _toDisplayString(producto.nombre_marca), 1),
                    _createElementVNode("td", null, _toDisplayString(producto.nombre_clasificacion), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(producto.caracteristicas, (caracteristica) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: caracteristica.uuid_caracteristica
                          }, _toDisplayString(caracteristica.nombre_caracteristica) + ": " + _toDisplayString(caracteristica.valor), 1))
                        }), 128))
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}