import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.localVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localVisible) = $event)),
    header: 'Comentarios',
    modal: true,
    closable: true,
    width: "500px"
  }, {
    default: _withCtx(() => [
      (_ctx.comments && _ctx.comments.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: comment.uuid,
                  class: "mb-2"
                }, [
                  _createElementVNode("strong", null, _toDisplayString(comment.nombre) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(comment.comentario), 1)
                ]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, "No hay comentarios para esta tarea."))
    ]),
    _: 1
  }, 8, ["visible"]))
}