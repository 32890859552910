import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/courses/course1.jpg'
import _imports_1 from '../../../assets/images/user/user3.jpg'
import _imports_2 from '../../../assets/images/courses/course2.jpg'
import _imports_3 from '../../../assets/images/user/user12.jpg'
import _imports_4 from '../../../assets/images/courses/course3.jpg'
import _imports_5 from '../../../assets/images/user/user13.jpg'
import _imports_6 from '../../../assets/images/courses/course4.jpg'
import _imports_7 from '../../../assets/images/user/user8.jpg'
import _imports_8 from '../../../assets/images/courses/course5.jpg'
import _imports_9 from '../../../assets/images/user/user1.jpg'
import _imports_10 from '../../../assets/images/courses/course6.jpg'
import _imports_11 from '../../../assets/images/user/user5.jpg'
import _imports_12 from '../../../assets/images/courses/course7.jpg'
import _imports_13 from '../../../assets/images/user/user14.jpg'
import _imports_14 from '../../../assets/images/courses/course8.jpg'
import _imports_15 from '../../../assets/images/user/user10.jpg'
import _imports_16 from '../../../assets/images/courses/course9.jpg'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-4 col-xxxl-3" }
const _hoisted_3 = { class: "col-lg-8 col-xxxl-9" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_6 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_7 = { class: "card-body p-0 letter-spacing" }
const _hoisted_8 = { class: "image position-relative" }
const _hoisted_9 = { class: "content p-20" }
const _hoisted_10 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }
const _hoisted_11 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_12 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_13 = { class: "card-body p-0 letter-spacing" }
const _hoisted_14 = { class: "image position-relative" }
const _hoisted_15 = { class: "content p-20" }
const _hoisted_16 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }
const _hoisted_17 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_18 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_19 = { class: "card-body p-0 letter-spacing" }
const _hoisted_20 = { class: "image position-relative" }
const _hoisted_21 = { class: "content p-20" }
const _hoisted_22 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }
const _hoisted_23 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_24 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_25 = { class: "card-body p-0 letter-spacing" }
const _hoisted_26 = { class: "image position-relative" }
const _hoisted_27 = { class: "content p-20" }
const _hoisted_28 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }
const _hoisted_29 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_30 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_31 = { class: "card-body p-0 letter-spacing" }
const _hoisted_32 = { class: "image position-relative" }
const _hoisted_33 = { class: "content p-20" }
const _hoisted_34 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }
const _hoisted_35 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_36 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_37 = { class: "card-body p-0 letter-spacing" }
const _hoisted_38 = { class: "image position-relative" }
const _hoisted_39 = { class: "content p-20" }
const _hoisted_40 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }
const _hoisted_41 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_42 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_43 = { class: "card-body p-0 letter-spacing" }
const _hoisted_44 = { class: "image position-relative" }
const _hoisted_45 = { class: "content p-20" }
const _hoisted_46 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }
const _hoisted_47 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_48 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_49 = { class: "card-body p-0 letter-spacing" }
const _hoisted_50 = { class: "image position-relative" }
const _hoisted_51 = { class: "content p-20" }
const _hoisted_52 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }
const _hoisted_53 = { class: "col-sm-6 col-lg-6 col-xxxl-4" }
const _hoisted_54 = { class: "card mb-25 border-0 rounded-0 bg-white single-course-card" }
const _hoisted_55 = { class: "card-body p-0 letter-spacing" }
const _hoisted_56 = { class: "image position-relative" }
const _hoisted_57 = { class: "content p-20" }
const _hoisted_58 = { class: "mb-10 fw-semibold fs-16 fs-lg-18" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SideBar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[1] || (_cache[1] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("h4", _hoisted_10, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Artificial Intelligence A-Z 2023: Build an AI with ChatGPT4 ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.0</span><span class=\"text-muted fw-medium fs-13\">(21 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 15 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 71 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 37 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$250</span><span class=\"text-muted fs-13 fw-medium ms-5 me-8 text-decoration-line-through\">$350</span><span class=\"text-success fw-medium\"><span class=\"fw-black\">60%</span> Off</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_1 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">J. Ronan</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("img", {
                      src: _imports_2,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[5] || (_cache[5] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("h4", _hoisted_16, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" TensorFlow Developer Certificate in 2023: Zero to Mastery ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">5.0</span><span class=\"text-muted fw-medium fs-13\">(32 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 10 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 34 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 21 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$99</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_3 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">J. Pinkman</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createElementVNode("img", {
                      src: _imports_4,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[9] || (_cache[9] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("h4", _hoisted_22, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Complete Machine Learning & Data Science Bootcamp 2023 ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-2\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.5</span><span class=\"text-muted fw-medium fs-13\">(12 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 20 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 99 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 43 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$140</span><span class=\"text-muted fs-13 fw-medium ms-5 me-8 text-decoration-line-through\">$280</span><span class=\"text-success fw-medium\"><span class=\"fw-black\">50%</span> Off</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_5 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">A. Carter</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createElementVNode("img", {
                      src: _imports_6,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[13] || (_cache[13] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("h4", _hoisted_28, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" Photoshop Master Course: From Beginner to Photoshop Pro ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.0</span><span class=\"text-muted fw-medium fs-13\">(21 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 15 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 71 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 37 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$132</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_7 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">V. Loper</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createElementVNode("img", {
                      src: _imports_8,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[17] || (_cache[17] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("h4", _hoisted_34, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createTextVNode(" User Experience (UX): The Ultimate Guide to Usability and UX ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.0</span><span class=\"text-muted fw-medium fs-13\">(21 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 15 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 71 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 37 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$45</span><span class=\"text-muted fs-13 fw-medium ms-5 me-8 text-decoration-line-through\">$100</span><span class=\"text-success fw-medium\"><span class=\"fw-black\">45%</span> Off</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_9 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">W. White</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createElementVNode("img", {
                      src: _imports_10,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[21] || (_cache[21] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("h4", _hoisted_40, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode(" Adobe Audition CC Tutorial - Audition Made Easy ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[23] || (_cache[23] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.0</span><span class=\"text-muted fw-medium fs-13\">(21 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 15 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 71 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 37 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$199</span><span class=\"text-muted fs-13 fw-medium ms-5 me-8 text-decoration-line-through\">$300</span><span class=\"text-success fw-medium\"><span class=\"fw-black\">60%</span> Off</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_11 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">T. Skyler</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createElementVNode("img", {
                      src: _imports_12,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[25] || (_cache[25] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("h4", _hoisted_46, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode(" Web Development Masterclass & Certifications ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[27] || (_cache[27] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.0</span><span class=\"text-muted fw-medium fs-13\">(21 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 15 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 71 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 37 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$45</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_13 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">K. Hong</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_47, [
          _createElementVNode("div", _hoisted_48, [
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("div", _hoisted_50, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createElementVNode("img", {
                      src: _imports_14,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[29] || (_cache[29] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("h4", _hoisted_52, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[30] || (_cache[30] = [
                      _createTextVNode(" Create a Complete 2D Survivors Style Game in Godot 4 ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[31] || (_cache[31] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.0</span><span class=\"text-muted fw-medium fs-13\">(21 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 15 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 71 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 37 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$54</span><span class=\"text-muted fs-13 fw-medium ms-5 me-8 text-decoration-line-through\">$100</span><span class=\"text-success fw-medium\"><span class=\"fw-black\">54%</span> Off</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_15 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">B. Traversy</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_53, [
          _createElementVNode("div", _hoisted_54, [
            _createElementVNode("div", _hoisted_55, [
              _createElementVNode("div", _hoisted_56, [
                _createVNode(_component_router_link, {
                  to: "/course-details",
                  class: "d-block"
                }, {
                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                    _createElementVNode("img", {
                      src: _imports_16,
                      alt: "course-image"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[33] || (_cache[33] = _createElementVNode("button", {
                  class: "fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block",
                  type: "button"
                }, [
                  _createElementVNode("i", { class: "flaticon-heart" })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_57, [
                _createElementVNode("h4", _hoisted_58, [
                  _createVNode(_component_router_link, {
                    to: "/course-details",
                    class: "text-decoration-none text-black"
                  }, {
                    default: _withCtx(() => _cache[34] || (_cache[34] = [
                      _createTextVNode(" The Complete Python Bootcamp From Zero to Hero in Python ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[35] || (_cache[35] = _createStaticVNode("<div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.0</span><span class=\"text-muted fw-medium fs-13\">(21 Reviews)</span></div><ul class=\"mb-0 ps-0 list-unstyled info\"><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-book\"></i> 15 <span class=\"text-muted\">Classes</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-user-3\"></i> 71 <span class=\"text-muted\">Enrolled</span></li><li class=\"d-inline-block text-black position-relative\"><i class=\"flaticon-clock\"></i> 37 <span class=\"text-muted\">Hrs</span></li></ul><div class=\"mt-12 price d-flex align-items-end\"><span class=\"text-primary lh-1 fw-bold fs-16 fs-md-20 fs-lg-25\">$29</span></div><div class=\"mt-15 d-flex align-items-center\"><img src=\"" + _imports_1 + "\" width=\"24\" height=\"24\" class=\"rounded-circle\" alt=\"user\"><span class=\"d-block text-black ms-8 fs-md-15 fs-lg-16 fw-medium\">J. Ronan</span></div><button type=\"button\" class=\"add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative\"> Add To Cart <i class=\"flaticon-shopping-cart-2 transition\"></i></button>", 5))
              ])
            ])
          ])
        ])
      ]),
      _cache[36] || (_cache[36] = _createStaticVNode("<div class=\"pagination-area d-md-flex mb-25 justify-content-between align-items-center\"><p class=\"mb-0 text-paragraph\"> Showing <span class=\"fw-bold\">09</span> out of <span class=\"fw-bold\">134</span> results </p><nav class=\"mt-15 mt-md-0\"><ul class=\"pagination mb-0\"><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Previous\"><i class=\"flaticon-chevron-1\"></i></a></li><li class=\"page-item\"><a class=\"page-link active\" href=\"#\">1</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">2</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">3</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Next\"><i class=\"flaticon-chevron\"></i></a></li></ul></nav></div>", 1))
    ])
  ]))
}