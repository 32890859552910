import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "commentsContainer",
  style: {"max-height":"300px","overflow-y":"auto"}
}
const _hoisted_2 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createBlock(_component_DialogComponent, {
    visible: _ctx.localVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localVisible) = $event)),
    style: { width: '30rem' }
  }, {
    header: _withCtx(() => _cache[3] || (_cache[3] = [
      _createElementVNode("h5", { class: "card-subtitle fw-bold" }, "Comentarios", -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localTask.responseComentarios, (comment) => {
          return (_openBlock(), _createElementBlock("div", {
            key: comment.uuid,
            class: "comment-box mb-3"
          }, [
            _createElementVNode("strong", null, _toDisplayString(comment.nombre) + ":", 1),
            _createElementVNode("p", null, _toDisplayString(comment.comentario), 1)
          ]))
        }), 128))
      ], 512),
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("label", {
          for: "newComment",
          class: "form-label fw-medium"
        }, "Nuevo Comentario:", -1)),
        _withDirectives(_createElementVNode("textarea", {
          id: "newComment",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newComment) = $event)),
          rows: "3",
          placeholder: "Escribe tu comentario aquí..."
        }, null, 512), [
          [_vModelText, _ctx.newComment]
        ])
      ]),
      _createElementVNode("button", {
        class: "btn btn-primary btn-sm",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addComment && _ctx.addComment(...args)))
      }, " Agregar Comentario "),
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: "Cargando..."
      }, null, 8, ["active"])
    ]),
    _: 1
  }, 8, ["visible"]))
}