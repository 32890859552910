<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-lg-20 mb-xl-25">
            Basic Spinners
          </h5>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="position-relative mt-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#basicSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="basicSpinnersCode">
<div>&lt;div class="spinner-border" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-lg-20 mb-xl-25">
            Growing Colors Spinners
          </h5>
          <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-danger" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="position-relative mt-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#growingSpinners2Code"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="growingSpinners2Code">
<div>&lt;div class="spinner-grow text-primary" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow text-secondary" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow text-success" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow text-danger" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow text-warning" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow text-info" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow text-light" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow text-dark" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-lg-20">Flex</h5>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="position-relative mt-25 mb-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#flexCenterSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="flexCenterSpinnersCode">
<div>&lt;div class="d-flex justify-content-center"&gt;</div>
    <div>&lt;div class="spinner-border" role="status"&gt;</div>
        <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
    <div>&lt;/div&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
          <div class="d-flex align-items-center">
            <strong>Loading...</strong>
            <div
              class="spinner-border ms-auto"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
          <div class="position-relative mt-25 mb-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#flexEndSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="flexEndSpinnersCode">
<div>&lt;div class="d-flex align-items-center"&gt;</div>
    <div>&lt;strong&gt;Loading...&lt;/strong&gt;</div>
    <div>&lt;div class="spinner-border ms-auto" role="status" aria-hidden="true"&gt;&lt;/div&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
          <h5 class="fw-bold mb-15">Text align</h5>
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="position-relative mt-25 mb-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#textAlignSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="textAlignSpinnersCode">
&lt;div class="text-center"&gt;
    &lt;div class="spinner-border" role="status"&gt;
        &lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code>
</pre>
          </div>
          <h5 class="fw-bold mb-15">Floats</h5>
          <div class="clearfix">
            <div class="spinner-border float-end" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="position-relative mt-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#floatsSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="floatsSpinnersCode">
<div>&lt;div class="clearfix"&gt;</div>
    <div>&lt;div class="spinner-border float-end" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
    <div>&lt;/div&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-lg-20 mb-xl-25">
            Colors Spinners
          </h5>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-border text-danger" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-border text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="position-relative mt-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#colorsSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="colorsSpinnersCode">
<div>&lt;div class="spinner-border text-primary" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-border text-secondary" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-border text-success" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-border text-danger" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-border text-warning" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-border text-info" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-border text-light" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-border text-dark" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-15 mb-lg-20 mb-xl-25">
            Growing Spinners
          </h5>
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="position-relative mt-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#growingSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="growingSpinnersCode">
<div>&lt;div class="spinner-grow" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-10">Size</h5>
          <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
            Add <code>.spinner-border-sm</code> and
            <code>.spinner-grow-sm</code> to make a smaller spinner that can
            quickly be used within other components.
          </p>
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="position-relative mt-25 mb-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#smSizeSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="smSizeSpinnersCode">
<div>&lt;div class="spinner-border spinner-border-sm" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow spinner-grow-sm" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
          <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
            Or, use custom CSS or inline styles to change the dimensions as
            needed.
          </p>
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <div
            class="spinner-grow"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="position-relative mt-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#lgSizeSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="lgSizeSpinnersCode">
<div>&lt;div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>

<div>&lt;div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/div&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="card-title fw-bold mb-10">Buttons</h5>
          <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
            Use spinners within buttons to indicate an action is currently
            processing or taking place. You may also swap the text out of the
            spinner element and utilize button text as needed.
          </p>
          <button class="btn btn-primary me-10" type="button" disabled>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Loading...</span>
          </button>
          <button class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
          <div class="position-relative mt-25 mb-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#buttonsSpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="buttonsSpinnersCode">
<div>&lt;button class="btn btn-primary" type="button" disabled&gt;</div>
    <div>&lt;span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"&gt;&lt;/span&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/button&gt;</div>

<div>&lt;button class="btn btn-primary" type="button" disabled&gt;</div>
    <div>&lt;span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"&gt;&lt;/span&gt;</div>
    <div>Loading...</div>
<div>&lt;/button&gt;</div>
</code>
</pre>
          </div>
          <button class="btn btn-primary me-10" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Loading...</span>
          </button>
          <button class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
          <div class="position-relative mt-25">
            <button
              class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
              data-clipboard-target="#buttons2SpinnersCode"
            >
              Copy
            </button>
            <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="buttons2SpinnersCode">
<div>&lt;button class="btn btn-primary" type="button" disabled&gt;</div>
    <div>&lt;span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"&gt;&lt;/span&gt;</div>
    <div>&lt;span class="visually-hidden"&gt;Loading...&lt;/span&gt;</div>
<div>&lt;/button&gt;</div>

<div>&lt;button class="btn btn-primary" type="button" disabled&gt;</div>
    <div>&lt;span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"&gt;&lt;/span&gt;</div>
    <div>Loading...</div>
<div>&lt;/button&gt;</div>
</code>
</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpinnersContent",
};
</script>