<template>
  <div class="row">
    <div class="col-md-12">
      <ActualizarUsuario :uuid="uuid" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ActualizarUsuario from "@/components/crud/ActualizarUsuario.vue";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "ActualizarUsuarioComponent",
  components: {
    ActualizarUsuario,
  },
  setup() {
    const route = useRoute();
    const uuid = ref<string | undefined>(undefined);

    if (route.params.uuid) {
      uuid.value = Array.isArray(route.params.uuid) ? route.params.uuid[0] : route.params.uuid;
    }

    return {
      uuid,
    };

  },
});
</script>