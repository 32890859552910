import { AES, enc } from "crypto-js";
import axios from "axios";
import login from "./loginService";
import LocalStorageValue from "./localStorageService";

export default class Control {
  async encryptBody(body: any) {
    try {
      const jsonString = JSON.stringify(body);
      const encrypted = AES.encrypt(
        jsonString,
        process.env.VUE_APP_CMC_API_SECRET
      ).toString();
      return encrypted;
    } catch (error) {
      console.error("Error al encriptar el body:", error);
    }
  }

  async decryptBody(encryptedText: string) {
    const decryptedBytes = AES.decrypt(
      encryptedText,
      process.env.VUE_APP_CMC_API_SECRET
    );
    const decryptedText = decryptedBytes.toString(enc.Utf8);
    return decryptedText;
  }

  /*  esta funcion la utilizo para enviar peticiones */
  async fetchData(url, additionalPayload = {}) {
    await new login().isTokenExpired();

    const useKey = await new LocalStorageValue().getUserData();
    const token = await new LocalStorageValue().getToken();

    if (!useKey || !token) {
      throw new Error("No se pudo obtener información del usuario");
    }

    const payload = {
      key: useKey,
      ...additionalPayload,
    };


    const body = await this.encryptBody(payload);
    const encryptedRequestBody = JSON.stringify({
      data: body,
    });

    const configuration = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.VUE_APP_CMC_URL + url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: encryptedRequestBody,
    };

    const responseencry = await axios.request(configuration);
    if (responseencry.status < 200 || responseencry.status >= 300) {
      throw new Error(
        `La solicitud no fue exitosa. status: ${responseencry.status}`
      );
    }

    const response = await this.decryptBody(responseencry.data);
    return JSON.parse(response);
  }
  catch(error) {
    console.log("error en la solicitud", error);
  }



}  
