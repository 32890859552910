import control from "./apiService";

export default class Product {
  async AddMarca(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/agregar-marca-analogo",
      data
    );
  }

  async AddClasificacion(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/agregar-marca-analogo",
      data
    );
  }

  async ListMarca() {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/Listar-marcas-analogo"
    );
  }
  async ListClasificacion() {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-clasificacion-analogo"
    );
  }

  async ListProductos() {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-productos-originales-analogo"
    );
  }

  async ListCaracteristicas() {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-caracteristicas-analogo"
    );
  }

  async SendForm(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/agregar-producto-analogo",
      data
    );
  }

  async SearchProducts(data) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/buscador-productos-analogo",
      data
    );
  }
  

  async ListEquivalente(dato) {
    return await new control().fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-productos-equivalentes-a-analogo",
      dato
    );
  }


}



