import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '../../assets/images/ENTRA.png'
import _imports_1 from '../../assets/images/carrosbien.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-15 p-lg-30" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_5 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_6 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_7 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_8 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_9 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_10 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_11 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_12 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_13 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_14 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_15 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_16 = { class: "col-12 col-md-3 mb-3" }
const _hoisted_17 = { class: "col-12 col-md-3" }
const _hoisted_18 = { class: "d-flex flex-column text-center mb-3" }
const _hoisted_19 = { class: "d-flex justify-content-center" }
const _hoisted_20 = { class: "form-check me-3" }
const _hoisted_21 = { class: "form-check" }
const _hoisted_22 = { class: "col-12 col-md-3" }
const _hoisted_23 = { class: "d-flex flex-column text-center mb-3" }
const _hoisted_24 = { class: "d-flex justify-content-center" }
const _hoisted_25 = { class: "form-check me-3" }
const _hoisted_26 = { class: "form-check" }
const _hoisted_27 = { class: "col-12 col-md-3" }
const _hoisted_28 = { class: "d-flex flex-column text-center mb-3" }
const _hoisted_29 = { class: "d-flex justify-content-center" }
const _hoisted_30 = { class: "form-check me-3" }
const _hoisted_31 = { class: "form-check" }
const _hoisted_32 = { class: "col-md-6" }
const _hoisted_33 = { class: "table table-bordered text-center" }
const _hoisted_34 = ["onUpdate:modelValue"]
const _hoisted_35 = ["onUpdate:modelValue"]
const _hoisted_36 = { class: "col-md-6" }
const _hoisted_37 = { class: "table table-bordered text-center" }
const _hoisted_38 = ["onUpdate:modelValue"]
const _hoisted_39 = ["onUpdate:modelValue"]
const _hoisted_40 = ["onUpdate:modelValue"]
const _hoisted_41 = { class: "col-md-6" }
const _hoisted_42 = { class: "table table-bordered text-center" }
const _hoisted_43 = ["onUpdate:modelValue"]
const _hoisted_44 = ["onUpdate:modelValue"]
const _hoisted_45 = ["onUpdate:modelValue"]
const _hoisted_46 = { class: "col-md-6" }
const _hoisted_47 = { class: "table table-bordered text-center" }
const _hoisted_48 = ["onUpdate:modelValue"]
const _hoisted_49 = ["onUpdate:modelValue"]
const _hoisted_50 = { class: "col-md-6" }
const _hoisted_51 = { class: "table table-bordered text-center" }
const _hoisted_52 = ["onUpdate:modelValue"]
const _hoisted_53 = ["onUpdate:modelValue"]
const _hoisted_54 = ["onUpdate:modelValue"]
const _hoisted_55 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_56 = { class: "col-md-6" }
const _hoisted_57 = { class: "table table-bordered text-center" }
const _hoisted_58 = ["onUpdate:modelValue"]
const _hoisted_59 = ["onUpdate:modelValue"]
const _hoisted_60 = ["onUpdate:modelValue"]
const _hoisted_61 = { class: "col-md-4 mb-4" }
const _hoisted_62 = { class: "d-flex align-items-center" }
const _hoisted_63 = { class: "form-check me-3" }
const _hoisted_64 = {
  key: 0,
  class: "me-3"
}
const _hoisted_65 = { class: "form-check" }
const _hoisted_66 = { class: "col-md-4 mb-4 position-relative" }
const _hoisted_67 = { class: "canvas-container" }
const _hoisted_68 = { class: "col-12 mb-3" }
const _hoisted_69 = { class: "table table-bordered text-center" }
const _hoisted_70 = { scope: "col" }
const _hoisted_71 = { scope: "col" }
const _hoisted_72 = { class: "col-md-12 position-relative" }
const _hoisted_73 = { class: "canvas-container" }
const _hoisted_74 = { class: "col-12 mb-3" }
const _hoisted_75 = { class: "col-12 mb-3" }
const _hoisted_76 = { class: "col-12 col-md-12 pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: 'Cargando...'
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[80] || (_cache[80] = _createElementVNode("h4", { class: "card-title fw-bold mb-15 mb-md-25 text-center" }, " REVISIÓN E INSPECCIÓN VEHICULAR ", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[34] || (_cache[34] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "FECHA:", -1)),
            _createVNode(_component_Calendar, {
              modelValue: _ctx.date,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
              class: "w-100"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[35] || (_cache[35] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Selecciona una empresa:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedEmpresa,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedEmpresa) = $event)),
              options: _ctx.empresas,
              optionLabel: "empresa",
              optionValue: "uuid",
              placeholder: "Selecciona una empresa",
              class: "w-100"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[36] || (_cache[36] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "KILOMETRAJE INICIAL:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.kilinicial,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kilinicial) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[37] || (_cache[37] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "Kilometraje Final:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.kilfinal,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kilfinal) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[38] || (_cache[38] = _createElementVNode("label", {
              for: "taskVe",
              class: "form-label fw-medium"
            }, "N° ECONÓMICO:", -1)),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.neconomico,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.neconomico) = $event)),
              options: _ctx.vehiculos,
              optionLabel: "numero",
              optionValue: vehicle => ({ uuid: vehicle.uuid, numero: vehicle.numero }),
              placeholder: "Selecciona un vehiculo",
              class: "w-100"
            }, null, 8, ["modelValue", "options", "optionValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[39] || (_cache[39] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "ÁREA:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.area,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.area) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[40] || (_cache[40] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "MODELO:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.modelo,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelo) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[41] || (_cache[41] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "CONDUCTOR:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.conductor,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.conductor) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[42] || (_cache[42] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "PLACAS:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.placas,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.placas) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[43] || (_cache[43] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "N° DE LICENCIA:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.licencia,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.licencia) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[44] || (_cache[44] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "TIPO DE LICENCIA:", -1)),
            _createVNode(_component_InputText, {
              class: "w-100",
              type: "text",
              modelValue: _ctx.tlicencia,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.tlicencia) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[45] || (_cache[45] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "VENCIMIENTO:", -1)),
            _createVNode(_component_Calendar, {
              modelValue: _ctx.vencimiento,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.vencimiento) = $event)),
              class: "w-100"
            }, null, 8, ["modelValue"])
          ]),
          _cache[76] || (_cache[76] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-md-25 text-center" }, " DOCUMENTOS DEL VEHICULO ", -1)),
          _createElementVNode("div", _hoisted_16, [
            _cache[46] || (_cache[46] = _createElementVNode("label", {
              for: "taskTags",
              class: "form-label fw-medium"
            }, "VIGENCIA DE PÓLIZA:", -1)),
            _createVNode(_component_Calendar, {
              modelValue: _ctx.vigenpoliza,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.vigenpoliza) = $event)),
              class: "w-100"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[49] || (_cache[49] = _createElementVNode("label", {
                for: "taskTags",
                class: "fw-medium mb-2"
              }, "TARJETA DE CIRCULACIÓN:", -1)),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.uuidsel) = $event)),
                    class: "form-check-input shadow-none",
                    type: "radio",
                    id: "flexRadioDefault1",
                    value: 1
                  }, null, 512), [
                    [_vModelRadio, _ctx.uuidsel]
                  ]),
                  _cache[47] || (_cache[47] = _createElementVNode("label", {
                    class: "form-check-label fw-medium",
                    for: "flexRadioDefault1"
                  }, " Sí ", -1))
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.uuidsel) = $event)),
                    class: "form-check-input shadow-none",
                    type: "radio",
                    id: "flexRadioDefault2",
                    value: 0
                  }, null, 512), [
                    [_vModelRadio, _ctx.uuidsel]
                  ]),
                  _cache[48] || (_cache[48] = _createElementVNode("label", {
                    class: "form-check-label fw-medium",
                    for: "flexRadioDefault2"
                  }, " No ", -1))
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _cache[52] || (_cache[52] = _createElementVNode("label", {
                for: "taskTags",
                class: "fw-medium mb-2"
              }, "ENGOMADO DE PLACAS:", -1)),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.uuidsel1) = $event)),
                    class: "form-check-input shadow-none",
                    type: "radio",
                    id: "flexRadioDefault1",
                    value: 1
                  }, null, 512), [
                    [_vModelRadio, _ctx.uuidsel1]
                  ]),
                  _cache[50] || (_cache[50] = _createElementVNode("label", {
                    class: "form-check-label fw-medium",
                    for: "flexRadioDefault1"
                  }, " Sí ", -1))
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.uuidsel1) = $event)),
                    class: "form-check-input shadow-none",
                    type: "radio",
                    id: "flexRadioDefault2",
                    value: 0
                  }, null, 512), [
                    [_vModelRadio, _ctx.uuidsel1]
                  ]),
                  _cache[51] || (_cache[51] = _createElementVNode("label", {
                    class: "form-check-label fw-medium",
                    for: "flexRadioDefault2"
                  }, " No ", -1))
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _cache[55] || (_cache[55] = _createElementVNode("label", {
                for: "taskTags",
                class: "fw-medium mb-2"
              }, "BITACORA VEHICULAR:", -1)),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.uuidsel2) = $event)),
                    class: "form-check-input shadow-none",
                    type: "radio",
                    id: "flexRadioDefault1",
                    value: 1
                  }, null, 512), [
                    [_vModelRadio, _ctx.uuidsel2]
                  ]),
                  _cache[53] || (_cache[53] = _createElementVNode("label", {
                    class: "form-check-label fw-medium",
                    for: "flexRadioDefault1"
                  }, " Sí ", -1))
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.uuidsel2) = $event)),
                    class: "form-check-input shadow-none",
                    type: "radio",
                    id: "flexRadioDefault2",
                    value: 0
                  }, null, 512), [
                    [_vModelRadio, _ctx.uuidsel2]
                  ]),
                  _cache[54] || (_cache[54] = _createElementVNode("label", {
                    class: "form-check-label fw-medium",
                    for: "flexRadioDefault2"
                  }, " No ", -1))
                ])
              ])
            ])
          ]),
          _cache[77] || (_cache[77] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-md-25 text-center" }, " ACCESORIOS, HERRAMIENTA Y MOTOR ", -1)),
          _cache[78] || (_cache[78] = _createElementVNode("h6", { class: "card-title fw-bold mb-15 mb-md-25 text-center" }, " marque en cada casilla indicando las condiciones en que se encuentra los descrito a contianuación ", -1)),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("table", _hoisted_33, [
              _cache[56] || (_cache[56] = _createElementVNode("thead", { class: "table-light" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { scope: "col" }, "Descripción de Interiores"),
                  _createElementVNode("th", { scope: "col" }, "Cumple"),
                  _createElementVNode("th", { scope: "col" }, "No Cumple")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inspectionRows, (row, index) => {
                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                    _createElementVNode("td", null, _toDisplayString(row.label), 1),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 1
                      }, null, 8, _hoisted_34), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 0
                      }, null, 8, _hoisted_35), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("table", _hoisted_37, [
              _cache[57] || (_cache[57] = _createElementVNode("thead", { class: "table-light" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { scope: "col" }, "Descripción de Exteriores"),
                  _createElementVNode("th", { scope: "col" }, "Cumple"),
                  _createElementVNode("th", { scope: "col" }, "No Cumple"),
                  _createElementVNode("th", { scope: "col" }, "N/A")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exteriorInspectionRows, (row, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `exterior-${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(row.label), 1),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 1
                      }, null, 8, _hoisted_38), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 0
                      }, null, 8, _hoisted_39), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 2
                      }, null, 8, _hoisted_40), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("table", _hoisted_42, [
              _cache[58] || (_cache[58] = _createElementVNode("thead", { class: "table-light" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { scope: "col" }, "Motor"),
                  _createElementVNode("th", { scope: "col" }, "Bueno"),
                  _createElementVNode("th", { scope: "col" }, "Regular"),
                  _createElementVNode("th", { scope: "col" }, "Malo")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.motorInspectionRows, (row, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `motor-${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(row.label), 1),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 1
                      }, null, 8, _hoisted_43), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 0
                      }, null, 8, _hoisted_44), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 2
                      }, null, 8, _hoisted_45), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_46, [
            _createElementVNode("table", _hoisted_47, [
              _cache[59] || (_cache[59] = _createElementVNode("thead", { class: "table-light" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { scope: "col" }, "Descripción"),
                  _createElementVNode("th", { scope: "col" }, "Cumple"),
                  _createElementVNode("th", { scope: "col" }, "No Cumple")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toolsInspectionRows, (row, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `tools-${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(row.label), 1),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 1
                      }, null, 8, _hoisted_48), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 0
                      }, null, 8, _hoisted_49), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_50, [
            _cache[62] || (_cache[62] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-md-25 text-center" }, " ESTADO FÍSICO DE LAS LLANTAS ", -1)),
            _createElementVNode("table", _hoisted_51, [
              _cache[60] || (_cache[60] = _createElementVNode("thead", { class: "table-light" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { scope: "col" }, "Posición"),
                  _createElementVNode("th", { scope: "col" }, "Bueno"),
                  _createElementVNode("th", { scope: "col" }, "Regular"),
                  _createElementVNode("th", { scope: "col" }, "Malo")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tiresInspectionRows, (row, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `tires-${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(row.label), 1),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 1
                      }, null, 8, _hoisted_52), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 0
                      }, null, 8, _hoisted_53), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 2
                      }, null, 8, _hoisted_54), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_55, [
              _cache[61] || (_cache[61] = _createElementVNode("label", {
                for: "taskTags",
                class: "form-label fw-medium"
              }, "Porcentaje vida util llantas:", -1)),
              _createVNode(_component_InputText, {
                class: "w-100",
                type: "text",
                modelValue: _ctx.porcllantas,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.porcllantas) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_56, [
            _cache[64] || (_cache[64] = _createElementVNode("h5", { class: "card-title fw-bold mb-15 mb-md-25 text-center" }, " Estado Físico de Carrocería y Limpieza Interiores ", -1)),
            _createElementVNode("table", _hoisted_57, [
              _cache[63] || (_cache[63] = _createElementVNode("thead", { class: "table-light" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { scope: "col" }, "Parte"),
                  _createElementVNode("th", { scope: "col" }, "Bueno"),
                  _createElementVNode("th", { scope: "col" }, "Regular"),
                  _createElementVNode("th", { scope: "col" }, "Malo")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyInspectionRows, (row, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `body-${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(row.label), 1),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 1
                      }, null, 8, _hoisted_58), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 0
                      }, null, 8, _hoisted_59), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((row.cumpleValue) = $event),
                        class: "form-check-input shadow-none",
                        type: "radio",
                        value: 2
                      }, null, 8, _hoisted_60), [
                        [_vModelRadio, row.cumpleValue]
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_61, [
            _cache[67] || (_cache[67] = _createElementVNode("label", { class: "form-label fw-bold" }, "Control de acceso", -1)),
            _createElementVNode("div", _hoisted_62, [
              _createElementVNode("div", _hoisted_63, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.controlAcceso) = $event)),
                  class: "form-check-input shadow-none",
                  type: "radio",
                  id: "controlAccesoSi",
                  value: 1
                }, null, 512), [
                  [_vModelRadio, _ctx.controlAcceso]
                ]),
                _cache[65] || (_cache[65] = _createElementVNode("label", {
                  class: "form-check-label fw-medium",
                  for: "controlAccesoSi"
                }, "Sí", -1))
              ]),
              (_ctx.controlAcceso === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.numeroControl,
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.numeroControl) = $event)),
                      class: "form-control",
                      type: "text",
                      placeholder: "No°"
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_65, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.controlAcceso) = $event)),
                  class: "form-check-input shadow-none",
                  type: "radio",
                  id: "controlAccesoNo",
                  value: 0
                }, null, 512), [
                  [_vModelRadio, _ctx.controlAcceso]
                ]),
                _cache[66] || (_cache[66] = _createElementVNode("label", {
                  class: "form-check-label fw-medium",
                  for: "controlAccesoNo"
                }, "No", -1))
              ])
            ])
          ]),
          _cache[79] || (_cache[79] = _createElementVNode("div", { class: "col-md-4 mb-4" }, [
            _createElementVNode("label", { class: "form-label fw-bold" }, "COMBUSTIBLE")
          ], -1)),
          _createElementVNode("div", _hoisted_66, [
            _createElementVNode("div", _hoisted_67, [
              _cache[68] || (_cache[68] = _createElementVNode("div", { class: "image-container" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Combustible",
                  class: "fuel-image"
                })
              ], -1)),
              _createElementVNode("canvas", {
                ref: "fuelCanvas",
                class: "drawing-canvas-fuel",
                onMousedown: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.startDrawingFuel && _ctx.startDrawingFuel(...args))),
                onMousemove: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.drawFuel && _ctx.drawFuel(...args))),
                onMouseup: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.stopDrawingFuel && _ctx.stopDrawingFuel(...args)))
              }, null, 544)
            ])
          ]),
          _createElementVNode("div", _hoisted_68, [
            _createElementVNode("table", _hoisted_69, [
              _createElementVNode("tbody", null, [
                _createElementVNode("tr", null, [
                  _cache[69] || (_cache[69] = _createElementVNode("td", {
                    scope: "col",
                    rowspan: "2",
                    class: "text-center fw-bold align-middle"
                  }, " DAÑOS ", -1)),
                  _cache[70] || (_cache[70] = _createElementVNode("td", {
                    scope: "col",
                    class: "text-center fw-bold align-middle"
                  }, " Ninguno ", -1)),
                  _createElementVNode("td", _hoisted_70, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.danioSeleccionado) = $event)),
                      class: "form-check-input shadow-none",
                      type: "radio",
                      value: 0
                    }, null, 512), [
                      [_vModelRadio, _ctx.danioSeleccionado]
                    ])
                  ]),
                  _cache[71] || (_cache[71] = _createElementVNode("td", {
                    scope: "col",
                    rowspan: "2",
                    class: "text-center fw-bold align-middle"
                  }, " Indique cualquier daño observado en un círculo o en la ubicación correspondiente en el diagrama ", -1))
                ]),
                _createElementVNode("tr", null, [
                  _cache[72] || (_cache[72] = _createElementVNode("td", {
                    scope: "col",
                    class: "text-center fw-bold align-middle"
                  }, " Segun lo indicado ", -1)),
                  _createElementVNode("td", _hoisted_71, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.danioSeleccionado) = $event)),
                      class: "form-check-input shadow-none",
                      type: "radio",
                      value: 1
                    }, null, 512), [
                      [_vModelRadio, _ctx.danioSeleccionado]
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_72, [
            _createElementVNode("div", _hoisted_73, [
              _cache[73] || (_cache[73] = _createElementVNode("img", {
                src: _imports_1,
                alt: "carro lado derecho",
                class: "car-image"
              }, null, -1)),
              _createElementVNode("canvas", {
                ref: "canvas",
                class: "drawing-canvas",
                onMousedown: _cache[28] || (_cache[28] = 
//@ts-ignore
(...args) => (_ctx.startDrawing && _ctx.startDrawing(...args))),
                onMousemove: _cache[29] || (_cache[29] = 
//@ts-ignore
(...args) => (_ctx.draw && _ctx.draw(...args))),
                onMouseup: _cache[30] || (_cache[30] = 
//@ts-ignore
(...args) => (_ctx.stopDrawingAndSave && _ctx.stopDrawingAndSave(...args))),
                style: _normalizeStyle({
                                pointerEvents: _ctx.danioSeleccionado === 1 ? 'auto' : 'none',
                            })
              }, null, 36)
            ])
          ]),
          _createElementVNode("div", _hoisted_74, [
            _cache[74] || (_cache[74] = _createElementVNode("label", { class: "form-label fw-bold" }, "Observaciones, cualquier anomalía o defecto que no se encuentre en listado favor de anotarlo", -1)),
            _createVNode(_component_Textarea, {
              modelValue: _ctx.observacionesGenerales,
              "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.observacionesGenerales) = $event)),
              class: "form-control",
              rows: "4",
              placeholder: "Escribe tus observaciones aquí..."
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_75, [
            _cache[75] || (_cache[75] = _createElementVNode("label", { class: "form-label fw-bold" }, "Observaciones de quien recibe", -1)),
            _createVNode(_component_Textarea, {
              modelValue: _ctx.comentariosAdicionales,
              "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.comentariosAdicionales) = $event)),
              class: "form-control",
              rows: "4",
              placeholder: "Escribe comentarios adicionales aquí..."
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_76, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary me-2",
              onClick: _cache[33] || (_cache[33] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
            }, " Guardar ")
          ])
        ])
      ])
    ])
  ], 64))
}