import control from "./apiService";
import axios from "axios";
import moment from "moment";


export default class login {
  async performLogin(pass, mail) {
    const data = {
      email: mail,
      password: pass,
    };

    const body = await new control().encryptBody(data);

    const encryptedRequestBody = JSON.stringify({
      data: body,
    });

    const configuration = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        process.env.VUE_APP_CMC_URL + "/cmc24/api/cmc_siystem24/yetox/v1/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: encryptedRequestBody,
    };

    try {
      const responseencry = await axios.request(configuration);
      const response = await new control().decryptBody(responseencry.data);
      const valida = await this.saveLoginData(response, mail, pass);
      return valida;
    } catch (error) {
      console.log("error en el login", error);
    }
  }

  async saveLoginData(dataObj, mail, pass) {
    const data = JSON.parse(dataObj);

    const localStorageData = {
      user: data.key,
      name: data.nombre,
      isAuthenticated: true,
      token: data.token,
      expiration: data.expireAt,
      verify: data.verificado,
      // assignment: data.asignacion,
      mail: mail,
      pass: pass,
      permissions: data.permisos,
    };

    return data.error === true
      ? 0
      : (async () => {
        const encryptStorage = await new control().encryptBody(
          JSON.stringify(localStorageData)
        );
        localStorage.removeItem("data");
        localStorage.setItem("data", encryptStorage);
        return 1;
      })();
  }

  async isTokenExpired() {
    try {
      const data = localStorage.getItem("data");
      if (!data) return;

      const decryptedData = await new control().decryptBody(data);
      const resp = JSON.parse(JSON.parse(decryptedData));

      const expirationDate = new Date(resp.expiration);

      expirationDate.setHours(expirationDate.getHours() - 1);
      const currentDate = moment();

      if (expirationDate < currentDate.toDate()) {
        await this.renewToken();
      } else {
        return 1;
      }
    } catch (error) {
      console.error("Error en la verificación o renovación del token:", error);
    }
  }

  async renewToken() {
    try {
      const dataUser = localStorage.getItem("data");
      if (!dataUser) return;

      const decryptedData = await new control().decryptBody(dataUser);
      const resp = JSON.parse(JSON.parse(decryptedData));

      const loginUser = await this.performLogin(resp.pass, resp.mail);
      return loginUser;
    } catch (error) {
      console.error("Error en la renovación de token:", error);
    }
  }
}
