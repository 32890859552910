import control from "./apiService";

export default class Vehicles {
  async ListVehicle() {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/listar-modelo-vehiculo"
    );
  }

  async ListEmpresa() {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/listar-empresa",
    );
  }
  async ListAseguradora() {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/listar-aseguradora",
    );
  }
  async listVehicles() {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/listar-vehiculo",
    );
  }
  async ListResponsable() {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/listar-responsable",
    );
  }
  async SendVehicle(data) {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/registrar-vehiculo",
        data
    );
  }
  async ListAllVehicles() {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/listar-vehiculo",
    );
  }
  async InfoVehicle(data) {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/info-vehiculo",
      data
    );
  }
  async saveAsegu(data) {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/registrar-aseguradora",
      {
        nombre: data
      }
    );
  }
  async saveMarca(data) {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/registrar-marca",
      {
        nombre: data
      }
    );
  }
  async saveModelo(data) {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/registrar-modelo-vehiculo",
      data
    );
  }

  async listMarc() {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/listar-marca",
    );
  }



  
  async Editvehicle(data) {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/editar-vehiculo",
      data
    );
  }
  async sendInforme(data) {
    return await new control().fetchData(
      "/vehiculos/12%59TR_02/cmc24/api/VH_m02/yetox/v1/informe-vehicular",
      data
    );
  }

}




