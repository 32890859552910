<template>
	<div class="dashboard-container" :class="{ 'sidebar-hidden': !isSidebarVisible }">

		<aside v-if="isSidebarVisible" class="sidebar">
			<div class="row align-items-center">
				<div class="col-xl-4 col-lg-5 col-md-6">
					<div
						class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center">
						<button class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
							id="header-burger-menu" @click="stateStoreInstance.onChange">
							<i class="flaticon-menu-3"></i>
						</button>
					</div>
				</div>
			</div>

			<h4>Filtros</h4>
			<div class="search-bar">
				<input type="text" v-model="searchQuery" placeholder="Buscar vehículo..." />
			</div>

			<div class="tabs">
				<button class="tab-button" :class="{ active: selectedTab === 'all' }" @click="setTab('all')">
					Todos
				</button>
				<button class="tab-button" :class="{ active: selectedTab === 'NAENS' }" @click="setTab('NAENS')">
					NAENS
				</button>
				<button class="tab-button" :class="{ active: selectedTab === 'SILM' }" @click="setTab('SILM')">
					SILM
				</button>
				<button class="tab-button" :class="{ active: selectedTab === 'TEG' }" @click="setTab('TEG')">
					TEG
				</button>
			</div>

			<ul class="vehicle-list">
				<li v-for="vehicle in filteredVehicles" :key="vehicle.imei" class="vehicle-item"
					:class="{ 'focused': focusedVehicle?.imei === vehicle.imei }">
					<strong>Fecha:</strong> {{ vehicle.fechaposicion }}
					<br>
					<strong>Unidad:</strong> {{ vehicle.unidad }}
					<br />
					<strong>Ubicación:</strong> {{ vehicle.ubicacion }}
					<br />
					<!-- <strong>Latitud:</strong> {{ vehicle.latitud }}
					<br />
					<strong>Longitud:</strong> {{ vehicle.longitud }}
					<br /> -->
					<strong>Velocidad: </strong> {{ vehicle.velocidad }} km/h
					<br />
					<strong>Angulo: </strong> {{ vehicle.Angulo }}
					<br />
					<strong>Placas: </strong> {{ vehicle.placas }}
					<br />
					<strong>Ignición: </strong> {{ vehicle.ignicion === '1' ? 'Encendido' : 'Apagado' }}
					<br />
					<strong>odometro: </strong> {{ vehicle.odometro }}
					<br />

					<div class="input-group-button w-100">
						<button class="button-li" @click="focusOnVehicle(vehicle)"> <i class="flaticon-tap-1"></i>
							Seguir vehículo</button>
						<button class="button-li" v-if="focusedVehicle?.imei === vehicle.imei"
							@click="stopFollowingVehicle"> <i class="flaticon-close-1"></i>
							Dejar de seguir
						</button>
					</div>

				</li>
			</ul>
		</aside>

		<div class="map-container">
			<div id="map" ref="mapRef"></div>

			<div id="street-view" class="street-view-container"></div>

			<div class="map-buttons">
				<button type="button" class="icon-btn" @click="zoomIn">
					<img src="../../assets/plus-small_4338829.png" title="Zoom" alt="Zoom In" />
				</button>
				<button type="button" class="icon-btn" @click="zoomOut">
					<img src="../../assets/minus-small_4338830.png" title="Zoom" alt="Zoom Out" />
				</button>
				<button type="button" class="icon-btn" @click="toggleMapType">
					<img src="../../assets/map_1196804.png" title="Cambiar vista" alt="Cambiar Vista" />
				</button>
				<button type="button" class="icon-btn" @click="toggleTraffic">
					<img src="../../assets/embotellamiento.png" title="Mostrar tráfico" alt="Mostrar/Ocultar Tráfico" />
				</button>
				<button type="button" class="icon-btn" @click="toggleGeofence" v-if="!isGeofenceActive">
					<img src="../../assets/geocerca.png" title="Geocercas" alt="Geocercas" />
				</button>
				<button type="button" class="icon-btn" @click="deactivateGeofence" v-else>
					<img src="../../assets/geocerca.png" title="Desactivar Geocercas" alt="Desactivar Geocercas" />
				</button>
				<!-- <button type="button" class="icon-btn" @click="toggleClient">
					<img src="../../assets/group_921347.png" title="Mostrar Clientes" alt="Clientes" />
				</button> -->
				<button type="button" class="icon-btn" @click="toggleFullscreen">
					<img src="../../assets/pantalla-completa.png" title="Pantalla completa" alt="Pantalla Completa" />
				</button>
			</div>

			<!-- Modal usando PrimeVue -->
			<DialogComponent v-model:visible="showModal" :style="{ width: '35rem' }">
				<template #header>
					<div class="col-md-6 pt-20">
						<h5 class="card-subtitle fw-bold"> <i class="flaticon-settings-1"></i> Configuración</h5>
					</div>
				</template>
				<div class="row">
					<div class="col-md-12">
						<label for="basicFormControlInput1" class="form-label fw-medium text-black">
							Agentes:
						</label>
						<MultiSelect v-model="selectedAgents" :options="agents" optionLabel="nombre"
							optionValue="uuid_usuario" placeholder="Seleccione agentes" :filter="true" :multiple="true"
							class="w-100" />
					</div>
					<div class="col-md-12 pt-20">
						<button type="button" class="btn btn-sm btn-primary text-center"
							@click="searchPoints">Buscar</button>
					</div>

				</div>
			</DialogComponent>

			<VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />

		</div>

	</div>
</template>

<script lang="ts">
import { ref, onMounted, watch, defineComponent } from "vue";
import { vehiclesData, ItemAlmacenes, agentesItem, customerData } from "@/interfaces/interfaces";
import carIcon from '@/assets/PROMASTER/VISTA_0_GRADOS_PROMASTER.png';
import carIcon2 from '@/assets/PROMASTER/VISTA_45 GRADOS_PROMASTER.png';
import carIcon3 from '@/assets/PROMASTER/VISTA_90_GRADOS_PROMASTER.png';
import carIcon4 from "@/assets/PROMASTER/VISTA_135_GRADOS_PROMASTER.png";
import carIcon5 from "@/assets/PROMASTER/VISTA_180_GRADOS_PROMASTER.png";
import carIcon6 from "@/assets/PROMASTER/VISTA_225_GRADOS_PROMASTER.png";
import carIcon7 from "@/assets/PROMASTER/VISTA_270_GRADOS_PROMASTER.png";
import carIcon8 from "@/assets/PROMASTER/VISTA_315 GRADOS_PROMASTER.png";

// import almacenIcon from "@/assets/almacen.png";
import control from "@/services/apiService";
import Agent from "@/services/agentsService";
import mapService from "@/services/mapService";
import createSocketService from '@/services/socketService';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import stateStore from "@/utils/store";
import { useToast } from "vue-toastification";


export default defineComponent({
	name: "DashboardVehicles",
	setup: () => {
		const map = ref<google.maps.Map | null>(null);
		const mapCenter: google.maps.LatLngLiteral = { lat: 23.6345, lng: -102.5528 };
		const selectedTab = ref<string>("all");
		const isSidebarVisible = ref<boolean>(true);
		const searchQuery = ref<string>("");
		const filteredVehicles = ref<vehiclesData[]>([]);
		const vehicles = ref<vehiclesData[]>([]);
		const trafficLayer = ref<google.maps.TrafficLayer | null>(null);
		const almacenes = ref<ItemAlmacenes[]>([]);
		const almacenesMarkers = ref<google.maps.Marker[]>([]);
		const areAlmacenesVisible = ref<boolean>(false);
		let socketService: any = null;
		const streetViewPanorama = ref<google.maps.StreetViewPanorama | null>(null);
		const geofence = ref<google.maps.Circle | null>(null)
		// const vehicleMarkers: { [key: string]: VehicleMarker } = {};
		const focusedVehicle = ref<vehiclesData | null>(null);
		const followVehicleListener = ref<google.maps.MapsEventListener | null>(null);
		const isFollowing = ref(false);
		const locations = ref<customerData[]>([]);
		const markers = ref<google.maps.Marker[]>([]);
		let markerClusterer: MarkerClusterer | null = null;
		let vehicleMarkers = new Map<string, google.maps.Marker>();
		const isGeofenceActive = ref(false);
		let circles: google.maps.Circle[] = [];
		const stateStoreInstance = stateStore;
		const showModal = ref(false);
		const agents = ref<agentesItem[]>([]);
		const selectedAgents = ref<agentesItem[]>([]);
		const isLoading = ref(false);
		const toast = useToast();

		// Array de almacenes
		almacenes.value = [
			{ lat: 18.02913684292889, lng: -92.92965018957332, nombre: "ALM Villahermosa", radius: 1500 },
			{ lat: 21.053401597302372, lng: -89.6649454134926, nombre: "ALM Mérida ", radius: 1500 },
			{ lat: 18.137466421392716, lng: -94.44066473893147, nombre: "ALM Coatzacoalcos", radius: 1500 },
			{ lat: 19.16983651146419, lng: -96.2336514846568, nombre: "ALM Veracruz", radius: 1500 },
			{ lat: 20.534455489220786, lng: -97.4696056098208, nombre: "ALM Poza Rica", radius: 1500 },
			{ lat: 20.534455489220786, lng: -97.4696056098208, nombre: "ALM Guadalajara", radius: 1500 },
			{ lat: 17.969919139752413, lng: -92.96455522732025, nombre: "Services Inter Lab de México", radius: 1500 },
			{ lat: 18.15438681060617, lng: -93.19710897892085, nombre: "Planta de TEGEOS", radius: 1500 },
		];

		const filterVehicles = () => {
			let filtered = vehicles.value;

			if (selectedTab.value !== "all") {
				filtered = filtered.filter(vehicle => vehicle.unidad.startsWith(selectedTab.value));
			}

			if (searchQuery.value) {
				filtered = filtered.filter(vehicle => vehicle.unidad.toLowerCase().includes(searchQuery.value.toLowerCase()));
			}

			filteredVehicles.value = filtered;
		};


		// const getwWarehouses = async () => {
		// 	almacenesMarkers.value.forEach(marker => {
		// 		marker.setMap(null);
		// 	});
		// 	almacenesMarkers.value = [];

		// 	almacenes.value.forEach(almacen => {
		// 		const almacenMarker = new google.maps.Marker({
		// 			position: { lat: almacen.lat, lng: almacen.lng },
		// 			map: areAlmacenesVisible.value ? map.value : null,
		// 			// map: map.value,
		// 			title: almacen.nombre,
		// 			icon: {
		// 				url: almacenIcon,
		// 				scaledSize: new google.maps.Size(40, 40),
		// 			},
		// 		});

		// 		const infoWindow = new google.maps.InfoWindow({
		// 			content: `<h5>${almacen.nombre}</h5>`,
		// 		});

		// 		almacenMarker.addListener("click", () => {
		// 			infoWindow.open(map.value, almacenMarker);

		// 		});

		// 		almacenesMarkers.value.push(almacenMarker);
		// 	});
		// };

		const loadVehiclesFromLocalStorage = async () => {
			const data = localStorage.getItem("vehiclesData");
			if (data) {
				const decryptedData = await new control().decryptBody(data);
				const storedVehicles = JSON.parse(decryptedData);

				if (storedVehicles) {
					vehicles.value = JSON.parse(storedVehicles);
					vehicles.value.forEach(vehicle => {
						addOrUpdateMarkerForVehicle(vehicle);
					});

					filterVehicles();
				}
			}
		};

		const saveVehiclesToLocalStorage = async () => {
			const vehiclesToStore = vehicles.value.map(vehicle => {
				const { marker, ...rest } = vehicle;
				return rest;
			});

			try {
				const encryptStorage = await new control().encryptBody(
					JSON.stringify(vehiclesToStore)
				);
				localStorage.setItem('vehiclesData', encryptStorage);
			} catch (error) {
				console.error("Error al guardar en localStorage:", error);
			}
		};

		const addStyleToLabel = () => {
			const style = document.createElement('style');
			style.textContent = `
    	.custom-marker-label {
     		background-color: rgba(255, 140, 0, 0.9); 
			color: white;
			padding: 2px 6px;
			border-radius: 4px;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
			white-space: nowrap;
			margin-bottom: 0px;
    	}
  	`;
			document.head.appendChild(style);
		}


		const getMarkerIcon = (color) => {
			return `http://maps.google.com/mapfiles/ms/icons/${color}`;
		};

		const carIcons = [
			carIcon,
			carIcon2,
			carIcon3,
			carIcon4,
			carIcon5,
			carIcon6,
			carIcon7,
			carIcon8,
		];


		const getCardinalDirection = (angle) => {
			const normalizedAngle = angle % 360;
			const index = Math.floor((normalizedAngle + 22.5) / 45) % carIcons.length;

			return carIcons[index];

		};

		const addOrUpdateMarkerForVehicle = (vehicleData: vehiclesData) => {
			const updateMarkerIconSize = (marker, zoomLevel) => {
				const newSize = 30 + (zoomLevel * 2);
				marker.setIcon({
					url: getCardinalDirection(vehicleData.Angulo),
					scaledSize: new google.maps.Size(newSize, newSize),
					labelOrigin: new google.maps.Point(newSize / 2, -10),
				});
			};

			if (vehicleMarkers[vehicleData.imei]) {
				vehicleMarkers[vehicleData.imei].marker.setPosition({
					lat: vehicleData.latitud,
					lng: vehicleData.longitud,
				});
				if (map.value) {
					updateMarkerIconSize(vehicleMarkers[vehicleData.imei].marker, map.value.getZoom());
				}
			} else {
				const label = {
					text: vehicleData.unidad,
					fontSize: "11px",
					fontWeight: "bold",
					className: "custom-marker-label",
				};

				const marker = new google.maps.Marker({
					position: { lat: vehicleData.latitud, lng: vehicleData.longitud },
					label: label,
					map: map.value,
					icon: {
						url: getCardinalDirection(vehicleData.Angulo),
						scaledSize: new google.maps.Size(50, 50),
						labelOrigin: new google.maps.Point(50, -10),
					},
					title: `Vehículo ${vehicleData.unidad}`,

				});
				const infoWindowContent = document.createElement("div");
				infoWindowContent.classList.add("info-window-content");
				infoWindowContent.innerHTML = `
    <h5 class="clase-text">Vehículo: ${vehicleData.unidad}</h5>
    <p>Ubicación: (${vehicleData.ubicacion})</p>
    <p>Velocidad: ${vehicleData.velocidad} km/h</p>
`;

				const infoWindow = new google.maps.InfoWindow({
					content: infoWindowContent
				});

				marker.addListener("click", () => {
					infoWindow.open(map.value, marker);
					focusOnVehicle(vehicleData);
				});

				vehicleMarkers[vehicleData.imei] = {
					marker: marker,
					infoWindow: infoWindow,
				};

				if (map.value) {
					updateMarkerIconSize(marker, map.value.getZoom());
					map.value.addListener("zoom_changed", () => {
						const zoomLevel = map.value?.getZoom();
						updateMarkerIconSize(marker, zoomLevel);
					});
				}
			}

			addStyleToLabel()
		}


		const maintainLastPositions = (existingVehicles: Map<string, vehiclesData>) => {
			const currentTimestamp = Date.now();
			const timeout = 30 * 1000;

			existingVehicles.forEach((vehicle, imei) => {
				if (!vehicle.lastUpdate || currentTimestamp - new Date(vehicle.lastUpdate).getTime() > timeout) {
					addOrUpdateMarkerForVehicle(vehicle);
				}
			});
		};

		const setTab = (tab: string) => {
			selectedTab.value = tab;
			filterVehicles();
		};

		const focusOnVehicle = (vehicle) => {

			map.value?.setCenter({ lat: vehicle.latitud, lng: vehicle.longitud });
			map.value?.setZoom(14);
			focusedVehicle.value = vehicle;
			isFollowing.value = true;

			// Seguir el vehículo automáticamente
			const followVehicle = () => {
				if (isFollowing.value && focusedVehicle.value && vehicleMarkers[focusedVehicle.value.imei]) {
					const newPosition = {
						lat: focusedVehicle.value.latitud,
						lng: focusedVehicle.value.longitud,
					};
					map.value?.setCenter(newPosition);
				}
			};

			if (map.value && !followVehicleListener.value) {
				followVehicleListener.value = google.maps.event.addListener(map.value, 'idle', followVehicle);
			}
		};

		const stopFollowingVehicle = () => {
			isFollowing.value = false;

			if (followVehicleListener.value) {
				google.maps.event.removeListener(followVehicleListener.value);
				followVehicleListener.value = null;
			}

			focusedVehicle.value = null;
		};


		const zoomIn = () => {
			const currentZoom = map.value?.getZoom();
			if (currentZoom !== undefined) {
				map.value?.setZoom(currentZoom + 1);
			}

		};

		const zoomOut = () => {
			const currentZoom = map.value?.getZoom();
			if (currentZoom !== undefined) {
				map.value?.setZoom(currentZoom - 1);
			}

		};

		const toggleMapType = () => {
			const currentType = map.value?.getMapTypeId();
			map.value?.setMapTypeId(currentType === "hybrid" ? "roadmap" : "hybrid");

		};

		const toggleTraffic = () => {
			if (map.value) {
				if (!trafficLayer.value) {
					trafficLayer.value = new google.maps.TrafficLayer();
				}
				const isTrafficVisible = trafficLayer.value.getMap();
				trafficLayer.value.setMap(isTrafficVisible ? null : map.value);
			}
		};

		const toggleClient = async () => {
			isLoading.value = true;
			try {
				const response = await new Agent().listGeneralAgents();
				if (!response.error) {
					agents.value = response.datos;
				}
			} catch (error) {
				console.log(error);
				toast.error("Ocurrio un error al listar los clientes");
			} finally {
				showModal.value = true;
				isLoading.value = false;
			}
		}


		const toggleFullscreen = () => {
			isSidebarVisible.value = !isSidebarVisible.value;
		};

		const toggleAlmacenes = async () => {
			areAlmacenesVisible.value = !areAlmacenesVisible.value;

			if (areAlmacenesVisible.value) {
				almacenesMarkers.value.forEach(marker => {
					marker.setMap(map.value);
				});
			} else {
				almacenesMarkers.value.forEach(marker => {
					marker.setMap(null);
				});
			}
		};

		const toggleGeofence = () => {
			if (!geofence.value) {
				if (map.value) {
					almacenes.value.forEach(almacen => {
						const populationOptionsAgain: google.maps.CircleOptions = {
							strokeColor: "#FF0000",
							strokeOpacity: 0.35,
							strokeWeight: 0,
							fillColor: "#FF0000",
							fillOpacity: 0.35,
							map: map.value,
							center: { lat: almacen.lat, lng: almacen.lng },
							radius: almacen.radius,
						};

						const cityCircle = new google.maps.Circle(populationOptionsAgain);

						// Crear un InfoWindow
						const infowindow = new google.maps.InfoWindow({
							content: `${almacen.nombre}`,
						});

						cityCircle.addListener('mouseover', () => {
							infowindow.setPosition(cityCircle.getCenter());
							infowindow.open(map.value);
						});

						cityCircle.addListener('mouseout', () => {
							infowindow.close();
						});

						cityCircle.addListener('click', () => {
							infowindow.setPosition(cityCircle.getCenter());
							infowindow.open(map.value);
						});

						circles.push(cityCircle);
					});
				} else {
					console.error("El mapa no está inicializado o es nulo.");
				}
			} else {
				console.error("El mapa no está inicializado.");
			}

			isGeofenceActive.value = true;
		};



		const deactivateGeofence = () => {
			circles.forEach(circle => {
				if (circle) {
					circle.setMap(null);
				}
			});
			circles = [];

			isGeofenceActive.value = false;
		};


		const initMap = async () => {
			map.value = new google.maps.Map(document.getElementById("map") as HTMLElement, {
				center: mapCenter,
				zoom: 6,
				mapTypeId: "hybrid",
				mapId: "f8d56006232c1d87",
				disableDefaultUI: true,
				streetViewControl: true,
				gestureHandling: "greedy",
				scaleControl: true,
			});

			streetViewPanorama.value = map.value.getStreetView();
			streetViewPanorama.value.setOptions({
				position: mapCenter,
				pov: { heading: 0, pitch: 0 },
				visible: false,
			});

			map.value.addListener('bounds_changed', () => {
				const bounds = map.value?.getBounds();
				if (bounds) {
					const visibleVehicles = vehicles.value.filter(vehicle => {
						const vehiclePosition = new google.maps.LatLng(vehicle.latitud, vehicle.longitud);
						return bounds.contains(vehiclePosition);
					});

					visibleVehicles.forEach((vehicle) => {
						addOrUpdateMarkerForVehicle(vehicle);
					});
				}
			});


		}

		const initializeSocket = async () => {
			socketService = await createSocketService();
			socketService.connect();

			socketService.on('gps-realtime', (receivedData: vehiclesData[]) => {
				const dataArray = Array.isArray(receivedData) ? receivedData : [receivedData];

				dataArray.forEach((vehicleData) => {
					const existingVehicle = vehicles.value.find(vehicle => vehicle.imei === vehicleData.imei);

					if (!existingVehicle) {
						vehicles.value.push(vehicleData);
					} else {
						existingVehicle.latitud = vehicleData.latitud;
						existingVehicle.longitud = vehicleData.longitud;
						existingVehicle.velocidad = vehicleData.velocidad;
						existingVehicle.ignicion = vehicleData.ignicion;
						existingVehicle.Angulo = vehicleData.Angulo;
						existingVehicle.fechaposicion = vehicleData.fechaposicion;
					}
				});

				const existingVehicles = new Map<string, vehiclesData>(vehicles.value.map(vehicle => [vehicle.imei, vehicle]));
				maintainLastPositions(existingVehicles);

				saveVehiclesToLocalStorage();
			});
		}

		// const updateSelectedAgent = (newAgents) => {
		// 	if (newAgents) {
		// 		selectedAgents.value = newAgents
		// 	} else {
		// 		console.log("estoy vacio");
		// 	}
		// };

		const searchPoints = async () => {
			try {
				isLoading.value = true;
				const agentsArray = Object.values(selectedAgents.value);
				const response = await new mapService().listGeneralAgentPoints(agentsArray);

				if (!response.error) {
					locations.value = response.datos;

					markers.value.forEach(marker => marker.setMap(null));
					markers.value = [];
					locations.value.forEach((location) => {
						const label = {
							text: location.uniclave,
							color: "black",
							fontSize: "12px",
							fontWeight: "bold",
						};

						// Crea el nuevo marcador
						const marker = new google.maps.Marker({
							position: { lat: parseFloat(location.latitud), lng: parseFloat(location.longitud) },
							label: label,
							icon: getMarkerIcon(location.color),
							map: map.value,
						});
						markers.value.push(marker);
					});

					if (map.value) {
						markerClusterer = new MarkerClusterer({ markers: markers.value, map: map.value });
					}
				} else {
					toast.warning("No se encontró datos");
				}
			} catch (error) {
				toast.error("Ocurrio un error en la solicitud");
			} finally {
				showModal.value = false;
				isLoading.value = false;
			}
		}

		const addAgentPointsToMap = async (agent) => {
			const agentPoints = await new mapService().listGeneralAgentPoints([agent]);

			if (!agentPoints.error) {
				const locations = agentPoints.datos;

				// Agregar nuevos marcadores al mapa
				locations.forEach(location => {
					const label = {
						text: location.uniclave,
						color: "black",
						fontSize: "12px",
						fontWeight: "bold",
					};

					// Crea el nuevo marcador
					const marker = new google.maps.Marker({
						position: { lat: parseFloat(location.latitud), lng: parseFloat(location.longitud) },
						label: label,
						icon: getMarkerIcon(location.color),
						map: map.value,
					});
					markers.value.push(marker);
				});

				// Reagrupar marcadores si es necesario
				if (map.value) {
					markerClusterer = new MarkerClusterer({ markers: markers.value, map: map.value });
				}
			} else {
				toast.warning("No se encontró datos para el agente seleccionado");
			}
		};



		const removeAgentPointsFromMap = async (agent) => {
			const agentPoints = await new mapService().listGeneralAgentPoints([agent]);

			if (!agentPoints.error) {
				const locationsToRemove = agentPoints.datos;

				// Iterar sobre los puntos de localización del agente para eliminarlos del mapa
				locationsToRemove.forEach(location => {
					const markerToRemove = markers.value.find(marker => {
						const position = marker.getPosition();
						if (position) {
							return (
								areCoordinatesEqual(position.lat(), parseFloat(location.latitud)) &&
								areCoordinatesEqual(position.lng(), parseFloat(location.longitud))
							);
						}
						return false;
					});

					if (markerToRemove) {
						markerToRemove.setMap(null);
						markers.value = markers.value.filter(marker => marker !== markerToRemove);
					}
				});

				// Actualizar el markerClusterer solo si quedan marcadores
				if (markers.value.length > 0 && markerClusterer) {
					markerClusterer.clearMarkers();
					markerClusterer.addMarkers(markers.value);
				} else if (markerClusterer) {
					markerClusterer.clearMarkers();
					markerClusterer = null;
				}
			}
		};

		// Función para comparar coordenadas con precisión ajustada
		function areCoordinatesEqual(coord1, coord2, tolerance = 0.00001) {
			return Math.abs(coord1 - coord2) < tolerance;
		}


		watch([selectedTab, searchQuery], filterVehicles, { immediate: true });

		watch(
			() => selectedAgents.value,
			async (newValue, oldValue) => {
				if (newValue.length < oldValue.length) {
					const removedAgents = oldValue.filter(agent => !newValue.includes(agent));

					// Eliminar puntos del mapa para los agentes deseleccionados
					await Promise.all(removedAgents.map(agent => removeAgentPointsFromMap(agent)));
				}

				// Identificar nuevos agentes seleccionados
				if (newValue.length > oldValue.length) {
					const addedAgents = newValue.filter(agent => !oldValue.includes(agent));

					// Agregar puntos en el mapa correspondientes a los nuevos agentes seleccionados
					await Promise.all(addedAgents.map(agent => addAgentPointsToMap(agent)));
				}
			},
			{ deep: true }
		);




		onMounted(async () => {
			await initMap();
			await loadVehiclesFromLocalStorage();
			await initializeSocket();

		});


		return {
			map,
			mapCenter,
			selectedTab,
			isSidebarVisible,
			vehicles,
			filteredVehicles,
			searchQuery,
			toggleAlmacenes,
			zoomIn,
			zoomOut,
			toggleMapType,
			toggleTraffic,
			toggleFullscreen,
			setTab,
			focusOnVehicle,
			toggleGeofence,
			focusedVehicle,
			stopFollowingVehicle,
			toggleClient,
			isGeofenceActive,
			deactivateGeofence,
			stateStoreInstance,
			showModal,
			agents,
			selectedAgents,
			isLoading,
			searchPoints
		};
	},
});
</script>

<style scoped>
.dashboard-container {
	display: flex;
	height: 100vh;
	background-color: #f4f4f4;
	flex-direction: row;
}

.dark .dashboard-container {
	background-color: #2c2c2c;
}

/* Estilos de la barra lateral */
.sidebar {
	width: 300px;
	background-color: #fff;
	padding: 20px;
	overflow-y: auto;
}

.dark .sidebar {
	background-color: #34334a;
	color: #fff;
}


.sidebar h4 {
	margin-bottom: 20px;
	color: #007bff;
}

.dark .sidebar h4 {
	color: #6ab0ff;
}


.dark h5 {
	background-color: #34334a;
	color: #fff;
}

.search-bar {
	margin-bottom: 20px;
}

.search-bar input {
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
}

.dark .search-bar input {
	background-color: #34334a;
	color: #fff;
	border: 1px solid #777;
}


/* Tabs para la navegación en la barra lateral */
.tabs {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
}

.tab-button {
	flex: 1;
	padding: 10px;
	background-color: #e9ecef;
	border: none;
	border-radius: 5px;
}

.dark .tab-button {
	background-color: #34334a;
	;
	color: #fff;
}

.tab-button.active {
	background-color: #007bff;
	color: #fff;
}

/* Lista de vehículos */
.vehicle-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.vehicle-item {
	padding: 15px;
	margin-bottom: 10px;
	border-radius: 8px;
	background-color: aliceblue;
}

.dark .vehicle-item {
	background-color: #34334a;
	color: #fff;
}


.map-container {
	flex-grow: 1;
	position: relative;
}

.dark .map-container {
	background-color: #222;
}

#map {
	width: 100%;
	height: 100%;
}


.map-buttons {
	position: absolute;
	bottom: 80px;
	right: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.icon-btn {
	background-color: rgba(255, 255, 255, 0.8);
	border: none;
	padding: 5px;
	cursor: pointer;
	border-radius: 50%;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
}

.dark .icon-btn {
	background-color: rgb(255, 255, 255);
	color: #fff;
}


.icon-btn img {
	width: 24px;
	height: 24px;
}

.icon-btn:hover {
	background-color: rgba(255, 255, 255, 1);
}

.vehicle-item.focused {
	background-color: #ffffd3;
}


.dark .vehicle-item.focused {
	background-color: #ffffd3;
	color: #000000;
}

.rotate-svg {
	transform: rotate(90deg);
}

.dark h5 {
	color: #000000
}

.input-group-button {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.button-li {
	flex: 1;
	margin: 0 5px;
	padding: 8px 12px;
	border: none;
	border-radius: 5px;
	background-color: #007bff;
	color: white;
	cursor: pointer;
	transition: background-color 0.3s;
}

.button-li:hover {
	background-color: #0056b3;
}

/* footer{
	display: none !important;
} */

.header-area {
	display: none !important;
}

.info-window-content {
	background-color: #0056b3;
}

@media (max-width: 768px) {
	.dashboard-container {
		flex-direction: column;
	}

	.sidebar {
		width: 100%;
		padding: 10px;
	}

	.map-buttons {
		bottom: 10px;
		right: 10px;
		flex-direction: row;
		gap: 5px;
	}

	.icon-btn img {
		width: 18px;
		height: 18px;
	}
}

/* Ajustes para pantallas pequeñas (teléfonos de 480px o menos) */
@media (max-width: 480px) {
	.sidebar {
		padding: 5px;
	}

	.map-buttons {
		flex-direction: row;
		bottom: 5px;
		right: 5px;
		gap: 3px;
	}

	.icon-btn {
		padding: 3px;
	}

	.icon-btn img {
		width: 16px;
		height: 16px;
	}
}
</style>
