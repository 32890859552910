<template>
    <QuoteComponent />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import QuoteComponent from "@/components/Quote/QuoteComponent.vue";
  
  export default defineComponent({
    name: "QuotePage",
    components: {
        QuoteComponent,
    },
  });
  </script>