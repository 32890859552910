<template>
    <Dialog v-model:visible="localVisible" :header="'Comentarios'" :modal="true" :closable="true" width="500px">
      <div v-if="comments && comments.length > 0">
        <ul>
          <li v-for="comment in comments" :key="comment.uuid" class="mb-2">
            <strong>{{ comment.nombre }}:</strong> {{ comment.comentario }}
          </li>
        </ul>
      </div>
      <p v-else>No hay comentarios para esta tarea.</p>
    </Dialog>
  </template>
  
  <script lang="ts">
  import { defineComponent, PropType, watch, ref } from "vue";
  import Dialog from "primevue/dialog";
  
  export default defineComponent({
    name: "CommentsDialog",
    components: { Dialog },
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
      comments: {
        type: Array as PropType<Array<{ nombre: string; comentario: string; uuid: string }>>,
        default: () => [],
      },
    },
    emits: ["update:visible"],
    setup(props, { emit }) {
      const localVisible = ref(props.visible);
  
      watch(
        () => props.visible,
        (newVal) => {
          localVisible.value = newVal;
        }
      );
  
      watch(localVisible, (newVal) => {
        emit("update:visible", newVal);
      });
  
      return {
        localVisible,
      };
    },
  });
  </script>
  
  <style scoped>
  </style>
  