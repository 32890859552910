<template>
    <GoogleMapsGeneral />
  
  </template>
  <script lang="ts">
  import { defineComponent } from "vue";
import GoogleMapsGeneral from "../../components/Maps/GoogleMaps/GoogleMapsGeneral.vue"
  
  export default defineComponent({
    name: "GoogleMapsGener",
    components: {
      GoogleMapsGeneral,
    },
  });
  </script>