<template>
    <div class="row">
        <div class="col-md-12">
            <BuscadorAnalogo/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BuscadorAnalogo from "@/components/buscador/buscadorAnalogo.vue";

export default defineComponent({
    name: "BuscadorAnalogoss",
    components: {
        BuscadorAnalogo,
    },
});
</script>