<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-4">
        <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
            <h5 class="card-title fw-bold mb-15 mb-md-25 text-center">Asignar Razon Social</h5>
            <div class="row">
            <div class="col-md-6 pt-10 rounded-md">
                <div class="mb-3">
                    <label for="taskPriority" class="form-label fw-medium">Clientes:</label>
                    <Dropdown v-model="selectedClient" :filter="true" :options="clientes" optionLabel="razon_social"
                        optionValue="uuid" placeholder="Seleccione un cliente" class="w-100" />
                </div>
            </div>
            <div class="col-md-6 pt-10 rounded-md">
                <div class="mb-3">
                    <label for="taskPriority" class="form-label fw-medium">Razon Social:</label>
                    <Dropdown v-model="selectedClient" :filter="true" :multiple="true"  :options="clientes" optionLabel="razon_social"
                        optionValue="uuid" placeholder="Seleccione un cliente" class="w-100" />
                </div>
            </div>
            <div class="text-center ">
                <button class="btn btn-primary">
                    Enviar
                </button>
            </div>
        </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import Actualizar from '@/services/actualizarService';
import Dropdown from "primevue/dropdown";
import { Cliente } from '@/interfaces/interfaces';


export default defineComponent({
    name: "RegistRazonSocial",
    components: { Dropdown },
    setup() {
        const selectedClient = ref<string | null>(null);
        const clientes = ref<Cliente[]>([]);

        const getClient = async () => {
            try {
                const response = await new Actualizar().ListClientData();
                clientes.value = response.response;
                console.log("Imprimir los clientes que trae", clientes.value);

            } catch (error) {
                console.error("Error al obtener los clientes:", error);
            }
        };

        onMounted(() => {
            getClient();
        });

        return {
            selectedClient,
            clientes,
        };
    },
});
</script>