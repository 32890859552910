<template>
  <div>
    <select
      class="form-select shadow-none text-black fs-md-15"
      v-model="selectedState"
      @change="filterLocations"
    >
      <option value="">Selecciona una región</option>
      <option v-for="state in states" :key="state" :value="state">
        {{ state }}
      </option>
    </select>
    <div class="pac-card" id="pac-card">
      <div id="search-container">
        <input 
          id="pac-input" 
          type="text" 
          placeholder="Buscar ubicación" 
          style="width: 100%; padding: 5px"
        />
      </div>
    </div>

    <div id="map" style="width: 100%; height: 500px"></div>
    <VueLoading
      :active="isLoading"
      :is-full-page="true"
      loader="bars"
      :text="'Cargando...'"
    />
  </div>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from "vue";
import { useRoute } from "vue-router";
import control from "@/services/mapService";
import { useToast } from "vue-toastification";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default defineComponent({
  name: "GoogleMaps",
  emits: ["markerUpdated"],
  setup(props, { emit }) {
    const route = useRoute(); 
    const map = ref<google.maps.Map | null>(null);
    const markers = ref<google.maps.Marker[]>([]);
    const mapCenter = ref({ lat: 23.6345, lng: -102.5528 });
    const mapZoom = ref(5);
    const activeInfoWindow = ref<google.maps.InfoWindow | null>(null);
    const locations = ref<any[]>([]);
    const filteredLocations = ref<any[]>([]);
    const selectedState = ref<string>("");
    const isLoading = ref(true);
    const toast = useToast();

    const stateCoordinates = {
      "Norte de México": { center: { lat: 29.5669, lng: -104.4114 }, zoom: 10 },
      "Centro norte de México": { center: { lat: 23.6345, lng: -102.5528 }, zoom: 8 },
      "Centro de México": { center: { lat: 19.4326, lng: -99.1332 }, zoom: 8 },
      "Occidente México": { center: { lat: 20.6597, lng: -103.3496 }, zoom: 9 },
      "Este México": { center: { lat: 19.1738, lng: -96.1342 }, zoom: 9 },
      "Sureste México": { center: { lat: 20.423, lng: -87.622 }, zoom: 8 },
      "Sur de México": { center: { lat: 16.7532, lng: -93.115 }, zoom: 9 },
      "Noreste de México": { center: { lat: 31.3252, lng: -113.2295 }, zoom: 9 },
      "Ver todos": { center: { lat: 23.6345, lng: -102.5528 }, zoom: 5 },
    };

    const states = Object.keys(stateCoordinates);

    async function initMap() {
      const { Map } = (await google.maps.importLibrary(
        "maps"
      )) as google.maps.MapsLibrary;

      map.value = new Map(document.getElementById("map") as HTMLElement, {
        zoom: mapZoom.value,
        center: mapCenter.value,
        mapId: "f8d56006232c1d87",
      });

      const input = document.getElementById("pac-input") as HTMLInputElement;
      const autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.bindTo("bounds", map.value);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          console.log("No hay detalles disponibles para: '" + place.name + "'");
          return;
        }

        if (place.geometry.viewport) {
          map.value?.fitBounds(place.geometry.viewport);
        } else {
          map.value?.setCenter(place.geometry.location);
          map.value?.setZoom(17);
        }
      });

      updateMarkers();
    }

    const toggleInfoWindow = (marker: google.maps.Marker) => {
      if (activeInfoWindow.value) {
        activeInfoWindow.value.close();
      }
      const infoWindow = new google.maps.InfoWindow({
        content: getInfoWindowContent(marker),
      });
      infoWindow.open(map.value, marker);
      activeInfoWindow.value = infoWindow;
    };

    const getInfoWindowContent = (marker: google.maps.Marker) => {
      return `
        <div class="info-window">
          <p><strong>Uniclave:</strong> ${marker.get("uniclave") || "N/A"}</p>
          <p><strong>RFC:</strong> ${marker.get("rfc") || "N/A"}</p>
          <p><strong>Razón social:</strong> ${
            marker.get("razon_social") || "N/A"
          }</p>
          <p><strong>CP:</strong> ${marker.get("cp") || "N/A"}</p>
          <p><strong>Vendedor:</strong> ${marker.get("vendedor") || "N/A"}</p>
        </div>
      `;
    };

    const getMarkerIcon = (color: string) => {
      return `http://maps.google.com/mapfiles/ms/icons/${color}`;
    };

    const filterLocations = () => {
      if (selectedState.value === "") {
        filteredLocations.value = locations.value;
        mapCenter.value = { lat: 23.6345, lng: -102.5528 };
        mapZoom.value = 5;
      } else {
        filteredLocations.value = locations.value.filter(
          (location) => location.estado === selectedState.value
        );
        const { center, zoom } = stateCoordinates[selectedState.value];
        mapCenter.value = center;
        mapZoom.value = zoom;
      }
      updateMap();
    };

    const updateMap = () => {
      if (map.value) {
        map.value.setCenter(mapCenter.value);
        map.value.setZoom(mapZoom.value);
        updateMarkers();
      }
    };

    const updateMarkers = () => {
  if (map.value) {
    markers.value.forEach((marker) => marker.setMap(null));
    markers.value = [];

    filteredLocations.value.forEach((location, i) => {
      if (isNaN(location.lat) || isNaN(location.lng)) {
        console.error("Coordenadas inválidas:", location);
        return;
      }

      const label = {
        text: location.uniclave ? location.uniclave.toString() : "N/A",
        color: "black",
        fontSize: "12px",
        fontWeight: "bold",
      };

      const marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        label: label,
        icon: getMarkerIcon(location.color),
        map: map.value,
        draggable: true, 
      });

      marker.set("rfc", location.rfc);
      marker.set("razon_social", location.razonSocial);
      marker.set("calle", location.calle);
      marker.set("cp", location.cp);
      marker.set("estado", location.estado);
      marker.set("correo_electronico", location.correo_electronico);
      marker.set("telefono", location.telefono);
      marker.set("municipio", location.municipio);
      marker.set("nombre_establecimiento", location.nombre_establecimiento);
      marker.set("contacto_nombre", location.contacto_nombre);
      marker.set("giro_comercial", location.giro_comercial);
      marker.set("uniclave", location.uniclave);
      marker.set("vendedor", location.vendedor);
      marker.set("colonia", location.colonia);
      marker.set("telefonoContacto", location.telefonoContacto);
      marker.set("correo_electronico", location.correo_electronico);
      marker.set("numero_ext", location.numero_ext);
      marker.set("numero_int", location.numero_int);
      marker.set("color", location.color);
      marker.set("uuid_vendedor", location.uuid_vendedor);
      marker.set("uuid_cliente", location.uuid_cliente);
      marker.set("uuid_nivel_cliente", location.uuid_nivel_cliente);
      marker.set("uuid_clasificacion_cliente", location.uuid_clasificacion_cliente);
      marker.set("uuid_frecuencia_compra", location.uuid_frecuencia_compra);
      marker.set("uuid_forma_pago", location.uuid_forma_pago);
      marker.set("calle2", location.calle2);
      marker.set("contacto_telefono", location.contacto_telefono);

      marker.addListener("click", () => {
        toggleInfoWindow(marker);
      });

      if (map.value) {
        map.value.addListener("click", (event) => {
          const clickedLat = event.latLng.lat();
          const clickedLng = event.latLng.lng();

          marker.setPosition({ lat: clickedLat, lng: clickedLng });

          emit("markerUpdated", {
            latitud: clickedLat,
            longitud: clickedLng,
          });
        });
      }

      marker.addListener("dragend", () => {
        const newLat = marker.getPosition()?.lat();
        const newLng = marker.getPosition()?.lng();
        if (newLat && newLng) {
          emit("markerUpdated", {
            latitud: newLat,
            longitud: newLng,
          });
        }
      });

      markers.value.push(marker);

      emit("markerUpdated", {
        uuid_vendedor: location.uuid_vendedor,
        uuid_cliente: location.uuid_cliente,
        vendedor: location.vendedor,
        razon_social: location.razonSocial,
        correo_electronico: location.correo_electronico,
        telefono: location.telefono,
        municipio: location.municipio,
        nombre_establecimiento: location.nombre_establecimiento,
        rfc: location.rfc,
        giro_comercial: location.giro_comercial,
        contacto_nombre: location.contacto_nombre,
        contacto_telefono: location.contacto_telefono,
        longitud: location.lng,
        latitud: location.lat,
        calle1: location.calle,
        calle2: location.calle2,
        numero_ext: location.numero_ext,
        numero_int: location.numero_int,
        colonia: location.colonia,
        cp: location.cp,
        estado: location.estado,
        uniclave: location.uniclave,
        uuid_nivel_cliente: location.uuid_nivel_cliente,
        uuid_clasificacion_cliente: location.uuid_clasificacion_cliente,
        uuid_forma_pago: location.uuid_forma_pago,
        uuid_estado: location.uuid_estado,
        uuid_frecuencia_compra: location.uuid_frecuencia_compra,
      });
    });

    new MarkerClusterer({ markers: markers.value, map: map.value });
  }
};



    const getPoint = async () => {
      try {
        const uuid = route.params.uuid as string;
        if (!uuid) {
          toast.error("UUID no disponible");
          return;
        }

        const response = await new control().infoClientes(uuid); 
        const data = response.datos;

        const location = {
          uniclave: data.uniclave,
          lat: parseFloat(data.latitud),
          lng: parseFloat(data.longitud),
          rfc: data.rfc,
          razonSocial: data.razon_social,
          nombre_establecimiento: data.nombre_establecimiento || 'N/A',
          calle: data.calle1 ? `${data.calle1} ${data.numero_ext || ''}` : 'N/A',
          cp: data.cp || 'N/A',
          calle2: data.calle2 || 'N/A',
          estado: data.estado || 'N/A',
          correo_electronico: data.email || 'N/A',
          vendedor: data.vendedor || 'N/A',
          telefono: data.telefono || 'N/A',
          numero_ext: data.numero_ext || 'N/A',
          numero_int: data.numero_int || 'N/A',
          municipio: data.municipio || 'N/A',
          colonia: data.colonia || 'N/A',
          contacto_telefono: data.telefono || 'N/A',
          giro_comercial: data.giro_comercial || 'N/A',
          contacto_nombre: data.contacto_nombre || 'N/A',
          uuid_vendedor: data.uuid_agente,
          uuid_cliente: data.uuid,
          uuid_nivel_cliente: data.uuid_nivel,
          uuid_clasificacion_cliente: data.uuid_clasificacion_cliente,
          uuid_forma_pago: data.uuid_forma_pago,
          uuid_frecuencia_compra: data.uuid_frecuencia_compra,
          color: data.color || 'red-dot.png'
        };

        locations.value = [location];
        filteredLocations.value = locations.value;
        updateMap();
      } catch (error) {
        console.error("Error al obtener los puntos:", error);
        toast.error("Error al obtener los puntos. Por favor, intente de nuevo.");
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      try {
        isLoading.value = true;
        await initMap();
        await getPoint();
      } catch (error) {
        console.error("Error al inicializar el mapa:", error);
        toast.error("Ocurrió un error al cargar la información, intente de nuevo.");
      } finally {
        isLoading.value = false;
      }
    });

    return {
      mapCenter,
      mapZoom,
      locations,
      filteredLocations,
      activeInfoWindow,
      toggleInfoWindow,
      selectedState,
      states,
      filterLocations,
      getMarkerIcon,
      isLoading,
    };
  },
});
</script>

<style scoped>
.info-window {
  width: 200px;
}

#pac-input:focus {
  border-color: #4d90fe;
}
</style>
