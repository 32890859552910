import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { class: "tab-content" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "progress mb-3" }
const _hoisted_10 = { class: "list-group" }
const _hoisted_11 = { class: "d-flex align-items-start" }
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = ["onUpdate:modelValue"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "d-flex justify-content-between mt-2" }
const _hoisted_16 = { class: "flex-grow-1 me-1" }
const _hoisted_17 = ["onUpdate:modelValue"]
const _hoisted_18 = { class: "flex-grow-1 ms-1" }
const _hoisted_19 = ["onUpdate:modelValue"]
const _hoisted_20 = { class: "d-flex justify-content-between mt-2" }
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { class: "mb-3" }
const _hoisted_23 = { class: "mb-3" }
const _hoisted_24 = { class: "mb-3" }
const _hoisted_25 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_26 = { class: "list-group" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = { class: "col-md-12 pt-20" }
const _hoisted_29 = { class: "tab-content" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "mb-3" }
const _hoisted_32 = { class: "mb-3" }
const _hoisted_33 = { class: "mb-3" }
const _hoisted_34 = { class: "mb-3" }
const _hoisted_35 = { class: "mb-3" }
const _hoisted_36 = { class: "mb-3" }
const _hoisted_37 = { class: "mb-3" }
const _hoisted_38 = { class: "mb-3" }
const _hoisted_39 = { class: "mb-3" }
const _hoisted_40 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_41 = { class: "list-group" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "col-md-12 pt-20" }
const _hoisted_44 = { class: "tab-content" }
const _hoisted_45 = { class: "row" }
const _hoisted_46 = { class: "mb-3" }
const _hoisted_47 = { class: "col-md-12 pt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_TagModal = _resolveComponent("TagModal")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!
  const _component_VueLoading = _resolveComponent("VueLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DialogComponent, {
      visible: _ctx.localVisible,
      "onUpdate:visible": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.localVisible) = $event)),
      style: { width: '37rem' }
    }, {
      header: _withCtx(() => _cache[30] || (_cache[30] = [
        _createElementVNode("div", { class: "col-md-6 pt-20" }, [
          _createElementVNode("h5", { class: "card-subtitle fw-bold" }, "Eventos")
        ], -1)
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("button", {
              class: _normalizeClass(["tab", { active: _ctx.activeTab === 'tareas' }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 'tareas'))
            }, "Tareas ", 2),
            _createElementVNode("button", {
              class: _normalizeClass(["tab", { active: _ctx.activeTab === 'eventos' }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 'eventos'))
            }, "Eventos ", 2),
            _createElementVNode("button", {
              class: _normalizeClass(["tab", { active: _ctx.activeTab === 'archivo' }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeTab = 'archivo'))
            }, "Archivo ", 2)
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[31] || (_cache[31] = _createElementVNode("label", {
                  for: "taskTitle",
                  class: "form-label fw-medium"
                }, "Título de la tarea:", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control shadow-none",
                  id: "taskTitle",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newEvent.title) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.title]
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[32] || (_cache[32] = _createElementVNode("label", {
                  for: "taskDescription",
                  class: "form-label fw-medium"
                }, "Descripción:", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  class: "form-control",
                  id: "taskDescription",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newEvent.description) = $event)),
                  rows: "3"
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.description]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[33] || (_cache[33] = _createElementVNode("label", {
                  for: "taskStartTime",
                  class: "form-label fw-medium"
                }, "Fecha y Hora de inicio:", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "datetime-local",
                  class: "form-control",
                  id: "taskStartTime",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newEvent.startTime) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.startTime]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[34] || (_cache[34] = _createElementVNode("label", {
                  for: "taskEndTime",
                  class: "form-label fw-medium"
                }, "Fecha y Hora de finalización:", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "datetime-local",
                  class: "form-control",
                  id: "taskEndTime",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newEvent.endTime) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.endTime]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[37] || (_cache[37] = _createElementVNode("label", { class: "form-label fw-medium" }, "Progreso de actividades:", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", {
                    class: "progress-bar",
                    role: "progressbar",
                    style: _normalizeStyle({ width: `${_ctx.progress}%` })
                  }, _toDisplayString(_ctx.progress.toFixed(0)) + "% ", 5)
                ]),
                _cache[38] || (_cache[38] = _createElementVNode("label", { class: "form-label fw-medium" }, "Checklist de actividades:", -1)),
                _createElementVNode("ul", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newActivity.checklist, (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "list-group-item"
                    }, [
                      _createElementVNode("div", _hoisted_11, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          "onUpdate:modelValue": ($event: any) => ((item.check) = $event)
                        }, null, 8, _hoisted_12), [
                          [_vModelCheckbox, item.check]
                        ]),
                        _withDirectives(_createElementVNode("textarea", {
                          class: "form-control ms-2 flex-grow-1",
                          "onUpdate:modelValue": ($event: any) => ((item.nombre) = $event),
                          placeholder: "Escribe una tarea...",
                          rows: "2"
                        }, null, 8, _hoisted_13), [
                          [_vModelText, item.nombre]
                        ]),
                        _createElementVNode("button", {
                          class: "btn btn-danger btn-sm ms-2",
                          onClick: ($event: any) => (_ctx.removeChecklistItem(index))
                        }, "Eliminar", 8, _hoisted_14)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _cache[35] || (_cache[35] = _createElementVNode("label", {
                            for: "startDate",
                            class: "form-label"
                          }, "Fecha inicio:", -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "datetime-local",
                            class: "form-control",
                            "onUpdate:modelValue": ($event: any) => ((item.fecha_inicio) = $event)
                          }, null, 8, _hoisted_17), [
                            [_vModelText, item.fecha_inicio]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _cache[36] || (_cache[36] = _createElementVNode("label", {
                            for: "EndDate",
                            class: "form-label"
                          }, "Fecha fin:", -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "datetime-local",
                            class: "form-control",
                            "onUpdate:modelValue": ($event: any) => ((item.fecha_fin) = $event)
                          }, null, 8, _hoisted_19), [
                            [_vModelText, item.fecha_fin]
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-sm",
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.addChecklistItem && _ctx.addChecklistItem(...args)))
                  }, "Agregar")
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _cache[39] || (_cache[39] = _createElementVNode("label", {
                  for: "taskPartners",
                  class: "form-label fw-medium"
                }, "Usuarios:", -1)),
                _createVNode(_component_MultiSelect, {
                  modelValue: _ctx.selectedPartner,
                  "onUpdate:modelValue": [
                    _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedPartner) = $event)),
                    _ctx.updateSelectedPartners
                  ],
                  options: _ctx.partners,
                  optionLabel: "nombre",
                  optionValue: "uuid",
                  placeholder: "Seleccione usuarios",
                  filter: true,
                  multiple: true,
                  class: "w-100"
                }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _cache[40] || (_cache[40] = _createElementVNode("label", {
                  for: "taskTags",
                  class: "form-label fw-medium"
                }, "Tags:", -1)),
                _createElementVNode("button", {
                  class: "btn btn-success btn-sm px-2 py-1 mx-2",
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.openTagModal && _ctx.openTagModal(...args)))
                }, " crear tag "),
                _createVNode(_component_MultiSelect, {
                  modelValue: _ctx.selectedTag,
                  "onUpdate:modelValue": [
                    _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedTag) = $event)),
                    _ctx.updateSelectedTag
                  ],
                  options: _ctx.tags,
                  optionLabel: "tag",
                  optionValue: "uuid",
                  placeholder: "Seleccione tags",
                  filter: true,
                  multiple: true,
                  class: "w-100"
                }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _cache[41] || (_cache[41] = _createElementVNode("label", {
                  for: "taskPriority",
                  class: "form-label fw-medium"
                }, "Prioridad:", -1)),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.selectedPriority,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedPriority) = $event)),
                  options: _ctx.priorities,
                  optionLabel: "prioridad",
                  optionValue: "uuid",
                  placeholder: "Seleccione una prioridad",
                  class: "w-100"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _cache[42] || (_cache[42] = _createElementVNode("label", {
                  for: "taskPriority",
                  class: "form-label fw-medium"
                }, "Adjuntar archivos:", -1)),
                _createElementVNode("input", {
                  type: "file",
                  class: "form-control",
                  id: "eventFiles",
                  multiple: "",
                  onChange: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args)))
                }, null, 32)
              ]),
              (_ctx.uploadedFiles.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _cache[43] || (_cache[43] = _createElementVNode("label", {
                      for: "taskPriority",
                      class: "form-label fw-medium"
                    }, "Archivos cargados:", -1)),
                    _createElementVNode("ul", _hoisted_26, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadedFiles, (file, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: index,
                          class: "list-group-item d-flex justify-content-between align-items-center"
                        }, [
                          _createTextVNode(_toDisplayString(file.contentType) + " ", 1),
                          _createElementVNode("button", {
                            type: "button",
                            class: "btn btn-danger btn-sm",
                            onClick: ($event: any) => (_ctx.removeFile(index))
                          }, " Eliminar ", 8, _hoisted_27)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-sm btn-primary text-center",
                  onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.validarCamposYGuardarTarea && _ctx.validarCamposYGuardarTarea(...args)))
                }, "Guardar Tarea"),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-sm btn-secondary text-center ms-2",
                  onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                }, "Cerrar")
              ])
            ])
          ], 512), [
            [_vShow, _ctx.activeTab === 'tareas']
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _cache[44] || (_cache[44] = _createElementVNode("label", {
                  for: "taskTitle",
                  class: "form-label fw-medium"
                }, "Título del evento:", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control shadow-none",
                  id: "taskTitle",
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.newEvent.title) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.title]
                ])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _cache[45] || (_cache[45] = _createElementVNode("label", {
                  for: "taskDescription",
                  class: "form-label fw-medium"
                }, "Descripción:", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  class: "form-control",
                  id: "taskDescription",
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.newEvent.description) = $event)),
                  rows: "3"
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.description]
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _cache[46] || (_cache[46] = _createElementVNode("label", {
                  for: "taskPartners",
                  class: "form-label fw-medium"
                }, "Usuarios:", -1)),
                _createVNode(_component_MultiSelect, {
                  modelValue: _ctx.selectedPartner,
                  "onUpdate:modelValue": [
                    _cache[17] || (_cache[17] = ($event: any) => ((_ctx.selectedPartner) = $event)),
                    _ctx.updateSelectedPartners
                  ],
                  options: _ctx.partners,
                  optionLabel: "nombre",
                  optionValue: "uuid",
                  placeholder: "Selecciona un usuario",
                  filter: true,
                  multiple: true,
                  class: "w-100"
                }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_34, [
                _cache[47] || (_cache[47] = _createElementVNode("label", {
                  for: "taskStartTime",
                  class: "form-label fw-medium"
                }, "Fecha y Hora de inicio:", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "datetime-local",
                  class: "form-control",
                  id: "taskStartTime",
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.newEvent.startTime) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.startTime]
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _cache[48] || (_cache[48] = _createElementVNode("label", {
                  for: "taskEndTime",
                  class: "form-label fw-medium"
                }, "Fecha y Hora de finalización:", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "datetime-local",
                  class: "form-control",
                  id: "taskEndTime",
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.newEvent.endTime) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.endTime]
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _cache[49] || (_cache[49] = _createElementVNode("label", {
                  for: "taskTitle",
                  class: "form-label fw-medium"
                }, "Ubicación:", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control shadow-none",
                  id: "taskTitle",
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.newEvent.ubicacion) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.ubicacion]
                ])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _cache[50] || (_cache[50] = _createElementVNode("label", {
                  for: "taskTitle",
                  class: "form-label fw-medium"
                }, "Link (Si aplica):", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control shadow-none",
                  id: "taskTitle",
                  placeholder: "Ingrese link de reunion",
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.newEvent.link) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newEvent.link]
                ])
              ]),
              _createElementVNode("div", _hoisted_38, [
                _cache[51] || (_cache[51] = _createElementVNode("label", {
                  for: "taskTags",
                  class: "form-label fw-medium"
                }, "Tags:", -1)),
                _createElementVNode("button", {
                  class: "btn btn-success btn-sm px-2 py-1 mx-2",
                  onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.openTagModal && _ctx.openTagModal(...args)))
                }, " crear tag "),
                _createVNode(_component_MultiSelect, {
                  modelValue: _ctx.selectedTag,
                  "onUpdate:modelValue": [
                    _cache[23] || (_cache[23] = ($event: any) => ((_ctx.selectedTag) = $event)),
                    _ctx.updateSelectedTag
                  ],
                  options: _ctx.tags,
                  optionLabel: "tag",
                  optionValue: "uuid",
                  placeholder: "Seleccione tags",
                  filter: true,
                  multiple: true,
                  class: "w-100"
                }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_39, [
                _cache[52] || (_cache[52] = _createElementVNode("label", {
                  for: "taskPriority",
                  class: "form-label fw-medium"
                }, "Prioridad:", -1)),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.selectedPriority,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.selectedPriority) = $event)),
                  options: _ctx.priorities,
                  optionLabel: "prioridad",
                  optionValue: "uuid",
                  placeholder: "Seleccione una prioridad",
                  class: "w-100"
                }, null, 8, ["modelValue", "options"])
              ]),
              (_ctx.uploadedFiles.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                    _cache[53] || (_cache[53] = _createElementVNode("label", {
                      for: "taskPriority",
                      class: "form-label fw-medium"
                    }, "Archivos cargados:", -1)),
                    _createElementVNode("ul", _hoisted_41, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadedFiles, (file, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: index,
                          class: "list-group-item d-flex justify-content-between align-items-center"
                        }, [
                          _createTextVNode(_toDisplayString(file.contentType) + " ", 1),
                          _createElementVNode("button", {
                            type: "button",
                            class: "btn btn-danger btn-sm",
                            onClick: ($event: any) => (_ctx.removeFile(index))
                          }, " Eliminar ", 8, _hoisted_42)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-sm btn-primary text-center",
                  onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.guardarEvento && _ctx.guardarEvento(...args)))
                }, "Guardar evento"),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-sm btn-secondary text-center ms-2",
                  onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                }, "Cerrar")
              ])
            ])
          ], 512), [
            [_vShow, _ctx.activeTab === 'eventos']
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_44, [
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("div", _hoisted_46, [
                _cache[54] || (_cache[54] = _createElementVNode("label", {
                  for: "taskTitle",
                  class: "form-label fw-medium"
                }, "Ingresar archivo:", -1)),
                _createVNode(_component_FileUpload, {
                  ref: "fileUploadRef",
                  mode: "basic",
                  name: "documento",
                  accept: ".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                  maxFileSize: 1000000,
                  auto: false,
                  chooseLabel: "Explorar",
                  onSelect: _ctx.handleFileSelect,
                  style: {"width":"100%"}
                }, null, 8, ["onSelect"])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-sm btn-primary text-center",
                  onClick: _cache[27] || (_cache[27] = 
//@ts-ignore
(...args) => (_ctx.sendFile && _ctx.sendFile(...args)))
                }, "Guardar"),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-sm btn-secondary text-center ms-2",
                  onClick: _cache[28] || (_cache[28] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                }, "Cerrar")
              ])
            ])
          ], 512), [
            [_vShow, _ctx.activeTab === 'archivo']
          ])
        ]),
        _createVNode(_component_TagModal, {
          visible: _ctx.isTagModalVisible,
          onClose: _ctx.closeTagModal
        }, null, 8, ["visible", "onClose"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_VueLoading, {
      active: _ctx.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: "Cargando..."
    }, null, 8, ["active"])
  ], 64))
}