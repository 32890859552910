import control from "./apiService";

export default class Quote {
    async ListClients() {
        return await new control().fetchData(
            "/cotizador/cmc24/api/cmc_cotizador24/yetox/v1/listar-clientes",
            {
                "rol": "17af6af3-3121-4b9c-99ca-4c9d3131e512",
            }
        );
    }

    async ListProducts() {
        return await new control().fetchData(
            "/cotizador/cmc24/api/cmc_cotizador24/yetox/v1/listar-productos",
        );
    }
    
    async ListPaymentMethods() {
        return await new control().fetchData(
            "/cotizador/cmc24/api/cmc_cotizador24/yetox/v1/listar-metodos-pago",
        );
    }
}




