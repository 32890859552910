import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelDynamic as _vModelDynamic } from "vue"
import _imports_0 from '@/assets/images/icon/LOGO_CMC24.png'


const _hoisted_1 = { class: "vh-100" }
const _hoisted_2 = { class: "container-fluid h-custom" }
const _hoisted_3 = { class: "row d-flex justify-content-center align-items-center h-100" }
const _hoisted_4 = {
  key: 0,
  class: "alert alert-danger alert-dismissible fs-md-15 fs-lg-16",
  role: "alert"
}
const _hoisted_5 = { class: "col-md-8 col-lg-6 col-xl-4 offset-xl-1" }
const _hoisted_6 = { novalidate: "" }
const _hoisted_7 = { class: "form-outline mb-4" }
const _hoisted_8 = { class: "form-outline mb-3" }
const _hoisted_9 = { class: "input-group" }
const _hoisted_10 = ["type"]
const _hoisted_11 = { class: "text-center text-lg-start mt-4 pt-2 transition border-0 btn-init" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-md-9 col-lg-6 col-xl-5" }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "img-fluid",
            alt: "Sample image"
          })
        ], -1)),
        ($setup.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createTextVNode(_toDisplayString($setup.errorMessage) + " ", 1),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close shadow-none",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.error = false)),
                "aria-label": "Close"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", _hoisted_6, [
            _createVNode(_component_VueLoading, {
              active: $setup.isLoading,
              "is-full-page": true,
              loader: "bars",
              text: 'Cargando...'
            }, null, 8, ["active"]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "d-flex flex-row align-items-center justify-content-center justify-content-lg-start" }, [
              _createElementVNode("h2", { class: "mb-15 mb-md-25 fw-bold text-primary btn-init" }, " Ingrese los datos ")
            ], -1)),
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                type: "email",
                id: "form3Example3",
                class: "form-control form-control-lg",
                placeholder: "Ingresa tu correo electronico",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.email) = $event))
              }, null, 512), [
                [_vModelText, $setup.email]
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("label", {
                class: "form-label",
                for: "form3Example3"
              }, "Correo electronico", -1))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  class: "input-group-text rounded-0 fs-14 fw-bold text-primary pointer",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.togglePasswordVisibility && $setup.togglePasswordVisibility(...args)))
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass($setup.showPassword ? 'flaticon-visibility-off' : 'flaticon-eye'
                                        )
                  }, null, 2)
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: $setup.showPassword ? 'text' : 'password',
                  class: "form-control shadow-none rounded-0 text-black",
                  placeholder: "**************",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.password) = $event))
                }, null, 8, _hoisted_10), [
                  [_vModelDynamic, $setup.password]
                ])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("label", {
                class: "form-label",
                for: "form3Example4"
              }, "Password", -1))
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-primary btn-lg",
                style: {"padding-left":"2.5rem","padding-right":"2.5rem"},
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.onLogin && $setup.onLogin(...args)))
              }, " Iniciar sesión ")
            ])
          ])
        ])
      ])
    ])
  ]))
}