<template>
  <DialogComponent v-model:visible="localVisible" :style="{ width: '35rem' }">
    <template #header>
      <div class="col-md-6 pt-20">
        <h5 class="card-subtitle fw-bold">Detalles de la Tarea</h5>
      </div>
    </template>
    <div class="row">
      <div class="mb-3">
        <label for="overviewEmail" class="form-label fw-medium">Título de la tarea:</label>
        <input type="text" class="form-control shadow-none text-black fs-md-15 fs-lg-16" id="overviewEmail"
          v-model="newEvent.title" />
      </div>

      <div class="mb-3">
        <label for="eventDescription" class="form-label fw-medium">Descripción de la tarea:</label>
        <textarea class="form-control" id="eventDescription" v-model="newEvent.description" rows="3"></textarea>
      </div>

      <div class="mb-3">
        <label for="eventStartTime" class="form-label fw-medium">Fecha y Hora de inicio:</label>
        <input type="datetime-local" class="form-control" id="eventStartTime" v-model="newEvent.startTime" required />
      </div>

      <div class="mb-3">
        <label for="eventEndTime" class="form-label fw-medium">Fecha y Hora de finalización:</label>
        <input type="datetime-local" class="form-control" id="eventEndTime" v-model="newEvent.endTime" required />
      </div>

      <div class="mb-3">
        <label class="form-label fw-medium">Progreso de actividades:</label>
        <div class="progress mb-3">
          <div class="progress-bar" role="progressbar" :style="{ width: `${progress}%` }">
            {{ progress.toFixed(0) }}%
          </div>
        </div>

        <label class="form-label fw-medium">Checklist de actividades:</label>
        <ul class="list-group">
          <li v-for="(item, index) in newActivity.checklist" :key="index" class="list-group-item">
            <div class="d-flex align-items-start">
              <input type="checkbox" v-model="item.check" @change="markAsModified(item)" />
              <textarea class="form-control ms-2 flex-grow-1" v-model="item.nombre" @input="markAsModified(item)"
                placeholder="Escribe una tarea..." rows="2"></textarea>
              <button class="btn btn-danger btn-sm ms-2" @click="removeChecklistItem(index)">
                Eliminar
              </button>

              <button v-if="item.isNew" class="btn btn-success btn-sm" @click="emitAddActivity(item)">
                Añadir
              </button>
              <button v-else-if="item.isModified" class="btn btn-success btn-sm" @click="emitEditActivity(item)">
                Actualizar
              </button>
            </div>

            <div class="d-flex justify-content-between mt-2">
              <div class="flex-grow-1 me-1">
                <label for="startDate" class="form-label">Fecha inicio:</label>
                <input type="datetime-local" class="form-control" v-model="item.fecha_inicio"
                  @input="markAsModified(item)" />
              </div>
              <div class="flex-grow-1 ms-1">
                <label for="EndDate" class="form-label">Fecha fin:</label>
                <input type="datetime-local" class="form-control" v-model="item.fecha_fin"
                  @input="markAsModified(item)" />
              </div>
            </div>
          </li>
        </ul>

        <div class="d-flex justify-content-between mt-2">
          <!-- <button class="btn btn-primary btn-sm" @click="addChecklistItem" :disabled="!canAddTask">Agregar</button> -->
          <!-- <button class="btn btn-success btn-sm" @click="saveChecklist" :disabled="!canSaveTask">Guardar</button> -->

          <button class="btn btn-primary btn-sm" @click="addChecklistItem">
            Agregar
          </button>
        </div>
      </div>

      <div class="mb-3">
        <label for="overviewEmail" class="form-label fw-medium">Usuarios:</label>
        <MultiSelect v-model="selectedPartner" :options="partners" optionLabel="nombre" optionValue="uuid"
          placeholder="Selecciona un usuario" :filter="true" :multiple="true"
          @update:modelValue="updateSelectedPartners" class="w-100" />
      </div>

      <div class="mb-3">
        <label for="overviewEmail" class="form-label fw-medium">Tags:</label>
        <button class="btn btn-success btn-sm px-2 py-1 mx-2" @click="openTagModal">
          crear tag
        </button>
        <MultiSelect v-model="selectedTag" :options="tags" optionLabel="tag" optionValue="uuid"
          placeholder="Seleccione tags" :filter="true" :multiple="true" @update:modelValue="updateSelectedTag"
          class="w-100" />
      </div>

      <div class="mb-3">
        <label for="eventFiles" class="form-label fw-medium">Adjuntar archivos:</label>
        <input type="file" class="form-control" id="eventFiles" multiple @change="handleFileUpload" />
      </div>
      <div class="mb-3" v-if="uploadedFiles.length">
        <label class="form-label">Archivos cargados</label>
        <ul class="list-group">
          <li v-for="(file, index) in uploadedFiles" :key="index"
            class="list-group-item d-flex justify-content-between align-items-center">
            {{ file.contentType }}
            <button type="button" class="btn btn-success btn-sm me-2" @click="addFileTask(file)">
              Agregar
            </button>
            <button type="button" class="btn btn-danger btn-sm" @click="removeFile(index)">
              Eliminar
            </button>
          </li>
        </ul>
      </div>
      <div class="mb-3" v-if="task.archivos && task.archivos.length">
        <label class="form-label">Archivos adjuntos existentes</label>
        <ul class="list-group">
          <li v-for="(file, index) in task.archivos" :key="index"
            class="list-group-item d-flex justify-content-between align-items-center">
            <img :src="getFileIcon(file.nombre)" alt="icono" class="me-2" style="width: 20px; cursor: pointer"
              @click="openFile(file.path, file.nombre)" />

            {{ file.nombre }}
          </li>
        </ul>
      </div>
      <div class="mb-3">
        <label class="form-label fw-medium">Comentarios:</label>

        <div v-if="task.responseComentarios && task.responseComentarios.length">
          <input type="text" class="form-control" :value="task.responseComentarios[task.responseComentarios.length - 1]
            .comentario
            " readonly />

          <button v-if="task.responseComentarios.length > 0" class="btn btn-secondary btn-sm"
            @click="openCommentsModal">
            Ver más
          </button>
        </div>

        <div v-else>
          <button class="btn btn-primary mt-2" @click="openCommentsModal">
            Agregar Comentario
          </button>
        </div>
      </div>

      <ModalComment v-if="showCommentsModal" :visible="showCommentsModal" :task="task" @close="closeCommentsModal" />

      <div class="mb-3">
        <label for="overviewEmail" class="form-label fw-medium">Prioridad:</label>
        <Dropdown v-model="selectedPriority" :options="priorities" optionLabel="prioridad" optionValue="uuid"
          :filter="true" placeholder="Seleccione una prioridad" class="w-100" />
      </div>

      <div class="col-md-12 pt-20">
        <button type="button" class="btn btn-sm btn-primary text-center" @click="guardarTask">
          Guardar Tarea
        </button>
        <button type="button" class="btn btn-sm btn-secondary text-center ms-2" @click="closeModal">
          Cerrar
        </button>
      </div>
    </div>
    <TagModal :visible="isTagModalVisible" @close="closeTagModal" />
  </DialogComponent>
  <VueLoading :active="isLoading" :is-full-page="true" loader="bars" text="Cargando..." />
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  watch,
  onMounted,
  computed,
} from "vue";
import moment from "moment";
import ModalComment from "./ModalComment.vue";
import createSocketService from "@/services/socketService";
import { useToast } from "vue-toastification";
import {
  Unidad,
  Tag,
  UploadedFile,
  Prioridad,
  Activity,
} from "@/interfaces/interfaces";
import Dropdown from "primevue/dropdown";
import TagModal from "./TagModal.vue";

export default defineComponent({
  name: "ModalContentEdit",
  components: {
    Dropdown,
    TagModal,
    ModalComment,
  },
  props: {
    task: {
      type: Object as PropType<any>,
      required: true,
      default: () => ({
        responseComentarios: [],
        archivos: [],
        tags: [],
        integrantes: [],
      }),
    },
    visible: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  emits: [
    "close",
    "edit-task",
    "add-member-activity",
    "remove-member-activity",
  ],
  setup(props, { emit }) {
    const localTask = ref({ ...props.task });
    const isLoading = ref(false);

    watch(
      () => props.visible,
      (newVisible) => {
        if (newVisible) {
          resetModalState();
        }
      },
      { immediate: true }
    );

    const showCommentsModal = ref(false);
    const openCommentsModal = () => {
      showCommentsModal.value = true;
    };

    const closeCommentsModal = () => {
      showCommentsModal.value = false;

    };

    const toast = useToast();
    const localVisible = ref(props.visible);
    const getFileIcon = (fileName: string) => {
      const extension = fileName.split(".").pop()?.toLowerCase();
      if (extension === "pdf") return require("@/assets/images/icon/pdf.png");
      if (extension === "jpg" || extension === "jpeg")
        return require("@/assets/images/icon/jpg.png");
      if (extension === "png") return require("@/assets/images/icon/png.png");
      if (extension === "txt") return require("@/assets/images/icon/txt.png");
      if (extension === "xlsx") return require("@/assets/images/icon/xl4.png");
      return require("@/assets/images/icon/jpg.png");
    };

    const openFile = (path: string, nombre: string) => {
      const baseUrl =
        "https://defaultappyetox.blob.core.windows.net/yetoxblob/"; //LA DEJO AQUI O LA TRAIGO DESDE ENV?
      const fullUrl = `${baseUrl}${path}${nombre}`;
      window.open(fullUrl, "_blank");
    };

    let socketService: any = null;

    const isTagModalVisible = ref(false);

    const openTagModal = () => {
      isTagModalVisible.value = true;
    };

    const closeTagModal = () => {
      isTagModalVisible.value = false;
    };
    const newEvent = ref({
      title: "",
      description: "",
      startTime: "",
      endTime: "",
    });
    const newActivity = ref<Activity>({
      title: "",
      description: "",
      startTime: "",
      endTime: "",
      checklist: [],
    });

    const priorities = ref<Prioridad[]>([]);
    const tags = ref<Tag[]>([]);
    const partners = ref<Unidad[]>([]);
    const selectedTag = ref("");
    const uploadedFiles = ref<UploadedFile[]>([]);
    const selectedPriority = ref("");

    const selectedPartner = ref([]);
    const previousSelectedPartner = ref<string[]>([]);

    onMounted(() => {
      previousSelectedPartner.value = [...selectedPartner.value];
    });

    watch(
      selectedPartner,
      (newSelection) => {
        previousSelectedPartner.value = [...newSelection];
      },
      { immediate: true }
    );

    const updateSelectedPartners = (newSelection: string[]) => {
      const addedUsers = newSelection.filter(
        (uuid) => !previousSelectedPartner.value.includes(uuid)
      );
      const removedUsers = previousSelectedPartner.value.filter(
        (uuid) => !newSelection.includes(uuid)
      );

      addedUsers.forEach((uuid) => {
        socketService.emit("add-member-activity", {
          uuid_tarea: props.task.uuid,
          uuid_usuario: uuid,
        });
        socketService.on("add-member-activity", (response) => {
          if (!response.error) {
            toast.success("usuario agregado con exito");
          } else {
            toast.error("Error al agregar usuario");
          }
        });
      });

      removedUsers.forEach((uuid) => {
        console.log(`Usuario deseleccionado: ${uuid}`);
        socketService.emit("remove-member-activity", {
          uuid_tarea: props.task.uuid,
          uuid_usuario: uuid,
        });
        socketService.on("remove-member-activity", (response) => {
          if (!response.error) {
            toast.success("usuario removido con exito");
          } else {
            toast.error("Error al remover ususario");
          }
        });
      });

      previousSelectedPartner.value = [...newSelection];
    };

    watch(
      () => props.visible,
      (newVisible) => {
        localVisible.value = newVisible;
        if (newVisible) resetModalState();
      }
    );

    watch(
      () => props.task,
      (newTask) => {
        if (newTask) {
          newEvent.value.title = newTask.titulo || "";
          newEvent.value.description = newTask.descripcion || "";
          newEvent.value.startTime = moment(newTask.fecha_inicio).format(
            "YYYY-MM-DDTHH:mm"
          );
          newEvent.value.endTime = moment(newTask.fecha_fin).format(
            "YYYY-MM-DDTHH:mm"
          );

          selectedPartner.value = Array.isArray(newTask.integrantes)
            ? newTask.integrantes.map((integrante) => integrante.uuid)
            : [];

          selectedTag.value = Array.isArray(newTask.tags)
            ? newTask.tags.map((tag) => tag.uuid)
            : [];

          const matchingPriority = priorities.value.find(
            (priority) => priority.prioridad === newTask.prioridad
          );
          if (matchingPriority) {
            selectedPriority.value = matchingPriority.uuid;
          }

          newActivity.value.checklist = Array.isArray(newTask.actividades)
            ? newTask.actividades.map((activity) => ({
              nombre: activity.nombre,
              check: activity.check === true,
              fecha_inicio: moment(activity.fecha_inicio).format(
                "YYYY-MM-DDTHH:mm"
              ),
              fecha_fin: moment(activity.fecha_fin).format(
                "YYYY-MM-DDTHH:mm"
              ),
              uuid: activity.uuid,
              isModified: false,
            }))
            : [];
        }
      },
      { immediate: true }
    );

    const resetModalState = () => {
      newEvent.value = {
        title: props.task.titulo || "",
        description: props.task.descripcion || "",
        startTime: props.task.fecha_inicio
          ? moment(props.task.fecha_inicio).format("YYYY-MM-DDTHH:mm")
          : "",
        endTime: props.task.fecha_fin
          ? moment(props.task.fecha_fin).format("YYYY-MM-DDTHH:mm")
          : "",
      };

      selectedPartner.value = Array.isArray(props.task.integrantes)
        ? props.task.integrantes.map((integrante) => integrante.uuid)
        : [];

      selectedTag.value = Array.isArray(props.task.tags)
        ? props.task.tags.map((tag) => tag.uuid)
        : [];

      newActivity.value.checklist = Array.isArray(props.task.actividades)
        ? props.task.actividades.map((activity) => ({
          nombre: activity.nombre,
          check: activity.check === true,
          fecha_inicio: moment(activity.fecha_inicio).format(
            "YYYY-MM-DDTHH:mm"
          ),
          fecha_fin: moment(activity.fecha_fin).format("YYYY-MM-DDTHH:mm"),
          uuid: activity.uuid,
          isModified: false,
        }))
        : [];
    };

    const emitEditActivity = (activity) => {
      const payload = {
        uuid_tarea: props.task.uuid,
        uuid_actividad: activity.uuid,
        nombre: activity.nombre,
        fecha_inicio: activity.fecha_inicio,
        fecha_fin: activity.fecha_fin,
        check: activity.check,
      };

      socketService.emit("edit-activity", payload);
    };

    const emitAddActivity = (activity) => {
      const payload = {
        uuid_tarea: props.task.uuid,
        nombre: activity.nombre,
        fecha_inicio: activity.fecha_inicio,
        fecha_fin: activity.fecha_fin,
        check: activity.check,
      };

      socketService.emit("add-activity-task", payload);
    };

    const markAsModified = (item) => {
      item.isModified = true;
    };

    const closeModal = () => {
      localVisible.value = false;
      showCommentsModal.value = false;
      emit("close");
    };

    watch(localVisible, (newValue) => {
      if (!newValue) {
        showCommentsModal.value = false;
        emit("close");
      }
    });

    const handleFileUpload = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onload = () => {
            const result = reader.result as string | null;

            if (result !== null) {
              uploadedFiles.value.push({
                base64: result,
                contentType: files[i].type,
                name: files[i].name,
              });
            } else {
              console.error("Error: el resultado es nulo.");
            }
          };
        }
      }
    };

    const addFileTask = (file) => {
      const payload = {
        uuid_tarea: props.task.uuid,
        base64: file.base64,
        contentType: file.contentType,
      };
      console.log(payload);
      isLoading.value = true;
      socketService.emit("add-file-task", payload)

    };

    const removeFile = (index: number) => {
      uploadedFiles.value.splice(index, 1);
    };

    const updateSelectedPartherns = (newTag) => {
      selectedPriority.value = newTag;
    };

    const updateSelectedTag = (newPartherns) => {
      selectedTag.value = newPartherns;
    };

    const progress = computed(() => {
      const totalItems = newActivity.value.checklist.length;
      const completedItems = newActivity.value.checklist.filter(
        (item) => item.check
      ).length;

      return totalItems > 0 ? (completedItems / totalItems) * 100 : 0;
    });
   
    const guardarTask = () => {
      isLoading.value = true
      const tarea = {
        uuid_tarea: props.task.uuid,
        titulo: newEvent.value.title,
        mensaje: newEvent.value.description,
        fecha_inicio: newEvent.value.startTime,
        fecha_fin: newEvent.value.endTime,
        uuidPrioridad: selectedPriority.value,
        tags: selectedTag.value,
      };

      socketService.emit("edit-task", tarea);
      console.log("Enviar una tarea", tarea);
    };

    const removeChecklistItem = (index) => {
      const activity = newActivity.value.checklist[index];

      const payload = {
        uuid_tarea: props.task.uuid,
        uuid_actividad: activity.uuid,
      };

      socketService.emit("remove-activity-task", payload);
    };

    const addChecklistItem = () => {
      newActivity.value.checklist.push({
        uuid: "",
        nombre: "",
        check: false,
        fecha_inicio: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
        fecha_fin: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
        isModified: false,
        isNew: true,
      });
    };

    onMounted(async () => {
      try {
        socketService = await createSocketService();
        socketService.connect();

        socketService.emit("list-users");
        socketService.emit("list-priorities");
        socketService.emit("list-tag-user");

        socketService.on("list-tag-user", (TagsData: { response: Tag[] }) => {
          tags.value = TagsData.response;
        });

        socketService.on(
          "list-users",
          (partnertsData: { response: Unidad[] }) => {
            partners.value = partnertsData.response;
          }
        );
        socketService.on("add-file-task", (response) => {
          if (!response.error) {
            toast.success("Archivo agregado correctamente");
            isLoading.value = false
          } else {
            toast.error("Error al agregar el archivo");
          }
        });

        socketService.on(
          "list-priorities",
          (prioritiesData: { unidades: Prioridad[] }) => {
            priorities.value = prioritiesData.unidades;
          }
        );
        socketService.on("edit-task", (response) => {
          if (!response.error) {
            toast.success("Tarea actualizada con exito");
            closeModal();
            isLoading.value = false
          } else {
            toast.error("Error al actualizar al tarea");
          }
        });

        socketService.on("remove-activity-task", (response) => {
          if (!response.error) {
            toast.success("Actividad eliminada correctamente");

            const index = newActivity.value.checklist.findIndex(
              (item) => item.uuid === response.uuid_actividad
            );

            if (index !== -1) {
              newActivity.value.checklist.splice(index, 1);
            }
          } else {
            toast.error("Error al eliminar la actividad");
          }
        });

        socketService.on("add-activity-task", (response) => {
          if (!response.error) {
            toast.success("Actividad añadida correctamente");

            const index = newActivity.value.checklist.findIndex(
              (item) => item.isNew
            );
            if (index !== -1) {
              newActivity.value.checklist[index].uuid = response.uuid_actividad;
              newActivity.value.checklist[index].isNew = false;
              newActivity.value.checklist[index].isModified = false;
            }
          } else {
            toast.error("Error al añadir la actividad");
          }
        });
      } catch (error) {
        console.log(error);
      }
    });

    return {
      localVisible,
      newEvent,
      closeModal,
      guardarTask,
      partners,
      selectedPartner,
      tags,
      selectedTag,
      handleFileUpload,
      removeFile,
      uploadedFiles,
      selectedPriority,
      priorities,
      updateSelectedPartners,
      updateSelectedTag,
      progress,
      newActivity,
      removeChecklistItem,
      addChecklistItem,
      markAsModified,
      emitEditActivity,
      emitAddActivity,
      isTagModalVisible,
      openTagModal,
      closeTagModal,
      getFileIcon,
      openFile,
      addFileTask,
      showCommentsModal,
      openCommentsModal,
      closeCommentsModal,
      localTask,
      resetModalState,
      isLoading
    };
  },
});
</script>
