<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-20 mb-lg-25">
        Background Color
      </h5>
      <div class="p-3 mb-2 bg-primary text-white fw-medium">.bg-primary</div>
      <div class="p-3 mb-2 bg-primary-subtle text-emphasis-primary fw-medium">
        .bg-primary-subtle
      </div>
      <div class="p-3 mb-2 bg-secondary text-white fw-medium">
        .bg-secondary
      </div>
      <div
        class="p-3 mb-2 bg-secondary-subtle text-emphasis-secondary fw-medium"
      >
        .bg-secondary-subtle
      </div>
      <div class="p-3 mb-2 bg-success text-white fw-medium">.bg-success</div>
      <div class="p-3 mb-2 bg-success-subtle text-emphasis-success fw-medium">
        .bg-success-subtle
      </div>
      <div class="p-3 mb-2 bg-danger text-white fw-medium">.bg-danger</div>
      <div class="p-3 mb-2 bg-danger-subtle text-emphasis-danger fw-medium">
        .bg-danger-subtle
      </div>
      <div class="p-3 mb-2 bg-warning text-dark fw-medium">.bg-warning</div>
      <div class="p-3 mb-2 bg-warning-subtle text-emphasis-warning fw-medium">
        .bg-warning-subtle
      </div>
      <div class="p-3 mb-2 bg-info text-dark fw-medium">.bg-info</div>
      <div class="p-3 mb-2 bg-info-subtle text-emphasis-info fw-medium">
        .bg-info-subtle
      </div>
      <div class="p-3 mb-2 bg-light text-dark fw-medium">.bg-light</div>
      <div class="p-3 mb-2 bg-light-subtle text-emphasis-light fw-medium">
        .bg-light-subtle
      </div>
      <div class="p-3 mb-2 bg-dark text-white fw-medium">.bg-dark</div>
      <div class="p-3 mb-2 bg-dark-subtle text-emphasis-dark fw-medium">
        .bg-dark-subtle
      </div>
      <p class="p-3 mb-2 bg-body-secondary fw-medium">.bg-body-secondary</p>
      <p class="p-3 mb-2 bg-body-tertiary fw-medium">.bg-body-tertiary</p>
      <div class="p-3 mb-2 bg-body text-body fw-medium">.bg-body</div>
      <div class="p-3 mb-2 bg-black text-white fw-medium">.bg-black</div>
      <div class="p-3 mb-2 bg-white text-dark fw-medium">.bg-white</div>
      <div class="p-3 mb-2 bg-transparent text-body fw-medium">
        .bg-transparent
      </div>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#backgroundColorCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="backgroundColorCode">
<div>&lt;div class="p-3 mb-2 bg-primary text-white"&gt;.bg-primary&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-primary-subtle text-emphasis-primary"&gt;.bg-primary-subtle&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-secondary text-white"&gt;.bg-secondary&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-secondary-subtle text-emphasis-secondary"&gt;.bg-secondary-subtle&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-success text-white"&gt;.bg-success&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-success-subtle text-emphasis-success"&gt;.bg-success-subtle&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-danger text-white"&gt;.bg-danger&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-danger-subtle text-emphasis-danger"&gt;.bg-danger-subtle&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-warning text-dark"&gt;.bg-warning&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-warning-subtle text-emphasis-warning"&gt;.bg-warning-subtle&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-info text-dark"&gt;.bg-info&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-info-subtle text-emphasis-info"&gt;.bg-info-subtle&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-light text-dark"&gt;.bg-light&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-light-subtle text-emphasis-light"&gt;.bg-light-subtle&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-dark text-white"&gt;.bg-dark&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-dark-subtle text-emphasis-dark"&gt;.bg-dark-subtle&lt;/div&gt;</div>
<div>&lt;p class="p-3 mb-2 bg-body-secondary"&gt;.bg-body-secondary&lt;/p&gt;</div>
<div>&lt;p class="p-3 mb-2 bg-body-tertiary"&gt;.bg-body-tertiary&lt;/p&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-body text-body"&gt;.bg-body&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-black text-white"&gt;.bg-black&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-white text-dark"&gt;.bg-white&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-transparent text-body"&gt;.bg-transparent&lt;/div&gt;</div>
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-20 mb-lg-25">
        Background Gradient Color
      </h5>
      <div class="p-3 mb-2 bg-primary fw-medium bg-gradient text-white">
        .bg-primary.bg-gradient
      </div>
      <div class="p-3 mb-2 bg-secondary fw-medium bg-gradient text-white">
        .bg-secondary.bg-gradient
      </div>
      <div class="p-3 mb-2 bg-success fw-medium bg-gradient text-white">
        .bg-success.bg-gradient
      </div>
      <div class="p-3 mb-2 bg-danger fw-medium bg-gradient text-white">
        .bg-danger.bg-gradient
      </div>
      <div class="p-3 mb-2 bg-warning fw-medium bg-gradient text-dark">
        .bg-warning.bg-gradient
      </div>
      <div class="p-3 mb-2 bg-info fw-medium bg-gradient text-dark">
        .bg-info.bg-gradient
      </div>
      <div class="p-3 mb-2 bg-light fw-medium bg-gradient text-dark">
        .bg-light.bg-gradient
      </div>
      <div class="p-3 mb-2 bg-dark fw-medium bg-gradient text-white">
        .bg-dark.bg-gradient
      </div>
      <div class="p-3 mb-2 bg-black fw-medium bg-gradient text-white">
        .bg-black.bg-gradient
      </div>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#backgroundGradientColorCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="backgroundGradientColorCode">
<div>&lt;div class="p-3 mb-2 bg-primary fw-medium bg-gradient text-white"&gt;.bg-primary.bg-gradient&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-secondary fw-medium bg-gradient text-white"&gt;.bg-secondary.bg-gradient&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-success fw-medium bg-gradient text-white"&gt;.bg-success.bg-gradient&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-danger fw-medium bg-gradient text-white"&gt;.bg-danger.bg-gradient&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-warning fw-medium bg-gradient text-dark"&gt;.bg-warning.bg-gradient&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-info fw-medium bg-gradient text-dark"&gt;.bg-info.bg-gradient&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-light fw-medium bg-gradient text-dark"&gt;.bg-light.bg-gradient&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-dark fw-medium bg-gradient text-white"&gt;.bg-dark.bg-gradient&lt;/div&gt;</div>
<div>&lt;div class="p-3 mb-2 bg-black fw-medium bg-gradient text-white"&gt;.bg-black.bg-gradient&lt;/div&gt;</div>
</code>
</pre>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-20 mb-lg-25">
        Opacity Background Color
      </h5>
      <div class="bg-dark p-2 text-white">This is default dark background</div>
      <div class="bg-dark p-2 text-white bg-opacity-75">
        This is 75% opacity dark background
      </div>
      <div class="bg-dark p-2 text-white bg-opacity-50">
        This is 50% opacity dark background
      </div>
      <div class="bg-dark p-2 text-white bg-opacity-25">
        This is 25% opacity dark background
      </div>
      <div class="bg-dark p-2 text-white bg-opacity-10">
        This is 10% opacity dark background
      </div>
      <div class="position-relative mt-15 mt-md-20">
        <!-- <button
          class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
          data-clipboard-target="#backgroundOpacityColorCode"
        >
          Copy
        </button> -->
        <pre class="line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
<code class="language-markup" id="backgroundOpacityColorCode">
<div>&lt;div class="bg-dark p-2 text-white"&gt;This is default dark background&lt;/div&gt;</div>
<div>&lt;div class="bg-dark p-2 text-white bg-opacity-75"&gt;This is 75% opacity dark background&lt;/div&gt;</div>
<div>&lt;div class="bg-dark p-2 text-white bg-opacity-50"&gt;This is 50% opacity dark background&lt;/div&gt;</div>
<div>&lt;div class="bg-dark p-2 text-white bg-opacity-25"&gt;This is 25% opacity dark background&lt;/div&gt;</div>
<div>&lt;div class="bg-dark p-2 text-white bg-opacity-10"&gt;This is 10% opacity dark background&lt;/div&gt;</div>
</code>
</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackgroundColors",
};
</script>