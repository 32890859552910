<template>
    <div class="card mb-25 border-0 rounded-0 bg-white welcome-support-desk-box">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 pt-xl-50 pb-xl-50 ps-xl-40 ps-xl-40 letter-spacing">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <div class="content">
                        <!-- <span class="d-block fw-bold text-primary mb-5" id="current-date">
                            {{ formattedDate }}
                        </span> -->
                        <h2 class="fw-semibold mb-8">
                            Bienvenido,
                            <span class="fw-bold">{{ name }}</span>
                        </h2>
                        <p class="text-black-emphasis fs-md-15 fs-lg-16">
                            Al sistema de Centro de Monitoreo Continuo (CMC)
                        </p>
                        <!-- <div class="row list justify-content-center">
                            <div class="col-lg-4 col-sm-4" v-for="update in updates" :key="update.id">
                                <div :class="['p-15', update.bgClass]">
                                    <span class="d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase">
                                        {{ update.title }}
                                    </span>
                                    <h4 :class="['mb-0 fw-black', update.class]">
                                        {{ update.number }}+
                                    </h4>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 text-center mt-10 mt-md-0">
                    <img src="@/assets/images/welcome/5057942.png" alt="welcome-image" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import localStorageValue from "@/services/localStorageService";
import { useToast } from "vue-toastification";

export default defineComponent({
    name: "PageWelcome",
    data() {
        return {
            updates: [
                {
                    id: 1,
                    bgClass: "bg-f2f1f9",
                    title: "NAENS",
                    class: "text-primary",
                    number: "523",
                },
                {
                    id: 2,
                    bgClass: "bg-faf7f7",
                    title: "SILM",
                    class: "text-danger",
                    number: "209",
                },
                {
                    id: 3,
                    bgClass: "bg-ecf3f2",
                    title: "TEG",
                    class: "text-success",
                    number: "1836",
                },
            ],
        };
    },
    setup() {
        const formattedDate = ref("");
        const name = ref("");
        const fetchData = async () => {
            const toast = useToast();
            try {
                name.value = await new localStorageValue().getName();
            } catch (error) {
                console.error("Error al obtener o desencriptar los datos:", error);
            } finally {
                if (localStorage.getItem("toast") != "1") {
                    toast.info(`Hola ${name.value} bienvenido al sistema CMC`);
                }
                localStorage.setItem("toast", "1");
            }

        };

        onMounted(() => {
            const months = [
                "Enero",
                "Febreo",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ];

            const currentDate = new Date();
            formattedDate.value = `${months[currentDate.getMonth()]
                } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

            fetchData();
        });

        return {
            formattedDate,
            name,
        };
    },
});
</script>