<template>
    <div class="row">
        <div class="col-md-12">
            <FormCollection/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FormCollection from "@/components/Collection/FormCollection.vue";
export default defineComponent({
    name: "RegistarUsuario",
    components: {
        FormCollection,
    },
});
</script>