import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white stats-item" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 pt-lg-30 letter-spacing" }
const _hoisted_3 = { class: "row justify-content-between" }
const _hoisted_4 = { class: "col-lg-7 col-md-7" }
const _hoisted_5 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-lg-5 col-md-5" }, [
          _createElementVNode("div", { class: "icon position-relative text-info-light rounded-1 text-center" }, [
            _createElementVNode("i", { class: "flaticon-sterile-box" })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_apexchart, {
              type: "area",
              height: "120",
              options: _ctx.onHoldTicketsChart,
              series: _ctx.hold
            }, null, 8, ["options", "series"])
          ])
        ])
      ]),
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"info d-flex align-items-center justify-content-between mt-15\"><span class=\"fs-13 d-block text-uppercase text-dark-emphasis fw-bold\"> ON HOLD TICKETS </span><div class=\"d-flex align-items-center\"><h4 class=\"fw-black mb-0 me-10 lh-1\">69</h4><span class=\"fw-bold text-success text-badge d-inline-block\"><i class=\"flaticon-up-arrow fs-11 lh-1 position-relative top-1\"></i> 5.5% </span></div></div>", 1))
    ])
  ]))
}