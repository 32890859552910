import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  ref: "buscad",
  class: "col-md-3 m-2 pt-15"
}
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "input-group" }
const _hoisted_5 = {
  ref: "vendorselect",
  class: "col-md-2 pt-15"
}
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "flex items-center bg-gray-200 rounded p-1 mr-1" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  ref: "regionSelect",
  class: "col-md-2 pt-15"
}
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "flex items-center bg-gray-200 rounded p-1 mr-1" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  ref: "serbar",
  class: "col-md-2 pt-15 ml-5"
}
const _hoisted_14 = { class: "flex items-center" }
const _hoisted_15 = { class: "flex items-center bg-gray-200 rounded p-1 mr-1" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  class: "modal fade",
  id: "basicModal",
  tabindex: "-1"
}
const _hoisted_18 = { class: "modal-dialog" }
const _hoisted_19 = { class: "modal-content" }
const _hoisted_20 = { class: "modal-header" }
const _hoisted_21 = { class: "modal-body" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_VueLoading = _resolveComponent("VueLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            placeholder: "Busqueda inteligente",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchText) = $event)),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => ($setup.handleSearch && $setup.handleSearch(...args)), ["enter"]))
          }, null, 544), [
            [_vModelText, $setup.searchText]
          ]),
          _createElementVNode("span", {
            class: "input-group-text",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.handleSearch && $setup.handleSearch(...args)))
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("i", { class: "flaticon-search" }, null, -1)
          ])),
          ($setup.searchText)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "input-group-text",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.clearSearch && $setup.clearSearch(...args)))
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("i", { class: "flaticon-close" }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ], 512),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_multiselect, {
        modelValue: $setup.selectedAgentUuids,
        "onUpdate:modelValue": [
          _cache[4] || (_cache[4] = ($event: any) => (($setup.selectedAgentUuids) = $event)),
          $setup.updateSelectedAgents
        ],
        options: $setup.agents,
        multiple: true,
        searchable: true,
        closeOnSelect: false,
        hideSelected: false,
        "track-by": "uuid",
        label: "nombre_agente_cq",
        placeholder: "Selecciona agentes...",
        selectLabel: "",
        deselectLabel: "",
        class: "bg-white border border-gray-300 rounded-md"
      }, {
        singleLabel: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(option.nombre_agente_cq), 1)
          ])
        ]),
        tag: _withCtx(({ option, remove }) => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(option.nombre_agente_cq), 1),
            _createElementVNode("button", {
              type: "button",
              onClick: ($event: any) => (remove(option)),
              class: "ml-1"
            }, " × ", 8, _hoisted_8)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "options", "onUpdate:modelValue"])
    ], 512),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_multiselect, {
        modelValue: $setup.selectedRegion,
        "onUpdate:modelValue": [
          _cache[5] || (_cache[5] = ($event: any) => (($setup.selectedRegion) = $event)),
          $setup.updateSelectedRegions
        ],
        options: $setup.regions,
        searchable: true,
        closeOnSelect: true,
        hideSelected: false,
        "track-by": "uuid",
        label: "nombre",
        selectLabel: "",
        deselectLabel: "",
        placeholder: "Selecciona región..",
        class: "bg-white border border-gray-300 rounded-md"
      }, {
        singleLabel: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", null, _toDisplayString(option.nombre), 1)
          ])
        ]),
        tag: _withCtx(({ option, remove }) => [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", null, _toDisplayString(option.nombre), 1),
            _createElementVNode("button", {
              type: "button",
              onClick: ($event: any) => (remove(option)),
              class: "ml-1"
            }, " × ", 8, _hoisted_12)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "options", "onUpdate:modelValue"])
    ], 512),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_multiselect, {
        modelValue: $setup.selectedState,
        "onUpdate:modelValue": [
          _cache[6] || (_cache[6] = ($event: any) => (($setup.selectedState) = $event)),
          $setup.updateSelectedStates
        ],
        options: $setup.states,
        searchable: true,
        closeOnSelect: true,
        hideSelected: false,
        "track-by": "uuid",
        label: "nombre",
        selectLabel: "",
        deselectLabel: "",
        placeholder: "Selecciona estados..",
        class: "bg-white border border-gray-300 rounded-md"
      }, {
        singleLabel: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", null, _toDisplayString(option.nombre), 1)
          ])
        ]),
        tag: _withCtx(({ option, remove }) => [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", null, _toDisplayString(option.nombre), 1),
            _createElementVNode("button", {
              type: "button",
              onClick: ($event: any) => (remove(option)),
              class: "ml-1"
            }, " × ", 8, _hoisted_16)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "options", "onUpdate:modelValue"])
    ], 512),
    _cache[25] || (_cache[25] = _createElementVNode("div", {
      id: "map",
      style: {"width":"100%","height":"600px"}
    }, null, -1)),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _cache[11] || (_cache[11] = _createElementVNode("h1", { class: "modal-title fs-5" }, "Detalles adicionales", -1)),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => ($setup.closeModal && $setup.closeModal(...args))),
              "aria-label": "Close"
            })
          ]),
          _createElementVNode("div", _hoisted_21, [
            ($setup.activeMarker)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createElementVNode("p", null, [
                    _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Uniclave:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("uniclave")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[13] || (_cache[13] = _createElementVNode("strong", null, "RFC:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("rfc")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Razón Social:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("razon_social")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[15] || (_cache[15] = _createElementVNode("strong", null, "CP:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("cp")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Vendedor:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("vendedor")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Estado:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("estado")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Municipio:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("municipio")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Colonia:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("colonia")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Calle:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("calle")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Número Exterior:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("numero_ext")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Teléfono:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("telefono")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[23] || (_cache[23] = _createElementVNode("strong", null, "Correo Electrónico:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("correo_electronico")), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[24] || (_cache[24] = _createElementVNode("strong", null, "nombre establecimiento:", -1)),
                    _createTextVNode(" " + _toDisplayString($setup.activeMarker.get("nombre_establecimiento")), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => ($setup.closeModal && $setup.closeModal(...args)))
            }, " Cerrar ")
          ])
        ])
      ])
    ]),
    _createVNode(_component_VueLoading, {
      active: $setup.isLoading,
      "is-full-page": true,
      loader: "bars",
      text: 'Cargando...'
    }, null, 8, ["active"])
  ]))
}