import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleMapsUpdate = _resolveComponent("GoogleMapsUpdate")!
  const _component_FormMapsUpdate = _resolveComponent("FormMapsUpdate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GoogleMapsUpdate, { onLocationUpdated: _ctx.handleLocationUpdate }, null, 8, ["onLocationUpdated"]),
    _createVNode(_component_FormMapsUpdate, { locationData: _ctx.locationData }, null, 8, ["locationData"])
  ], 64))
}