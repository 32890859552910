<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="mb-lg-15 d-sm-flex align-items-center justify-content-between">
        <h6 class="card-title fw-bold mb-0">Productos más vendidos</h6>
        <div class="card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5">
          <span class="fw-medium text-muted me-8">Last</span>
          <select v-model="selectedMonths" @change="fetchTopSoldProducts"
            class="form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium">
            <option v-for="month in 9" :key="month" :value="month">{{ month }} Months</option>
          </select>
        </div>
      </div>
      <div id="topSoldProductsChart" class="chart">
        <apexchart type="bar" height="385" :options="chartOptions" :series="seriesData"></apexchart>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import { getTopSoldProducts } from "@/services/apiService";
import { Product } from "@/interfaces/interfaces";

export default defineComponent({
  name: "TopSoldProducts",
  data() {
    return {
      products: [] as Product[],
      seriesData: [] as any,
      chartOptions: {
        chart: {
          type: "bar",
          height: 385,
        },
        xaxis: {
          categories: [] as string[],
        },
        colors: ["#6560F0"],
      },
      selectedMonths: 8,
    };
  },
  mounted() {
    this.fetchTopSoldProducts();
  },
  methods: {
    async fetchTopSoldProducts() {
      try {
        // const products = await getTopSoldProducts(this.selectedMonths);
        // this.products = products;

        // // Actualizar los datos de la gráfica
        // this.seriesData = [{
        //   name: 'Productos',
        //   data: this.products.map((product: Product) => product.totalSales),
        // }];
        // this.chartOptions.xaxis.categories = this.products.map((product: Product) => product._id);
      } catch (error) {
        console.error('Error fetching top sold products:', error);
      }
    }
  }
});
</script>
