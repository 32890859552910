import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white unresolved-tickets-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-9 col-xxxl-10 col-md-10" }
const _hoisted_5 = {
  id: "unresolvedTicketsChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"mb-md-10 d-flex align-items-center justify-content-between\"><h6 class=\"card-title fw-bold mb-0\">Unresolved Tickets By Priority</h6><div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8\"></i> Delete </a></li></ul></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_apexchart, {
              type: "bar",
              height: "337",
              options: _ctx.unresolvedTicketsChart,
              series: _ctx.unresolved
            }, null, 8, ["options", "series"])
          ])
        ]),
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"col-lg-3 col-xxxl-2 col-md-2\"><ul class=\"list ps-0 mb-0 mt-15 mt-md-25 list-unstyled\"><li><div class=\"d-flex align-items-center mb-5\"><span class=\"d-block w-10 h-10 bg-primary rounded-circle\"></span><span class=\"fs-12 fw-medium text-black-emphasis text-uppercase lh-1 ms-8\"> URGENT </span></div><h4 class=\"mb-0 fw-black\">14</h4></li><li><div class=\"d-flex align-items-center mb-5\"><span class=\"d-block w-10 h-10 bg-primary rounded-circle opacity-75\"></span><span class=\"fs-12 fw-medium text-black-emphasis text-uppercase lh-1 ms-8\"> HIGH </span></div><h4 class=\"mb-0 fw-black\">112</h4></li><li><div class=\"d-flex align-items-center mb-5\"><span class=\"d-block w-10 h-10 bg-primary rounded-circle opacity-50\"></span><span class=\"fs-12 fw-medium text-black-emphasis text-uppercase lh-1 ms-8\"> MEDIUM </span></div><h4 class=\"mb-0 fw-black\">24</h4></li><li><div class=\"d-flex align-items-center mb-5\"><span class=\"d-block w-10 h-10 bg-primary rounded-circle opacity-25\"></span><span class=\"fs-12 fw-medium text-black-emphasis text-uppercase lh-1 ms-8\"> LOW </span></div><h4 class=\"mb-0 fw-black\">32</h4></li></ul></div>", 1))
      ])
    ])
  ]))
}