import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "mb-3 col-6" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "mb-3 col-6" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "mb-3" }
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = { class: "col-md-12 pt-20 text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createBlock(_component_DialogComponent, {
    visible: _ctx.localVisible,
    "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localVisible) = $event)),
    style: { width: '35rem' }
  }, {
    header: _withCtx(() => _cache[10] || (_cache[10] = [
      _createElementVNode("div", { class: "pt-20" }, [
        _createElementVNode("h5", { class: "card-subtitle fw-bold" }, "Prospección")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[11] || (_cache[11] = _createElementVNode("label", {
            for: "fechaInicio",
            class: "form-label fw-medium"
          }, "Fecha Inicio:", -1)),
          _createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "fechaInicio",
            value: _ctx.formattedFecha,
            readonly: ""
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[12] || (_cache[12] = _createElementVNode("label", {
            for: "horaInicio",
            class: "form-label fw-medium"
          }, "Hora Inicio:", -1)),
          _createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "horaInicio",
            value: _ctx.formattedHora,
            readonly: ""
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[13] || (_cache[13] = _createElementVNode("label", {
            for: "razonSocial",
            class: "form-label fw-medium"
          }, "Cliente:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "razonSocial",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.prospeccionData.cliente) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.prospeccionData.cliente]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[14] || (_cache[14] = _createElementVNode("label", {
            for: "localidad",
            class: "form-label fw-medium"
          }, "Localidad:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "localidad",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.prospeccionData.localidad) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.prospeccionData.localidad]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[15] || (_cache[15] = _createElementVNode("label", {
            for: "giro",
            class: "form-label fw-medium"
          }, "Giro:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "giro",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.prospeccionData.giro) = $event))
          }, null, 512), [
            [_vModelText, _ctx.prospeccionData.giro]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[16] || (_cache[16] = _createElementVNode("label", {
            for: "cotizado",
            class: "form-label fw-medium"
          }, "Actividad:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "cotizado",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.prospeccionData.actividad) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.prospeccionData.actividad]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[17] || (_cache[17] = _createElementVNode("label", {
            for: "cotizado",
            class: "form-label fw-medium"
          }, "Segmento:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "cotizado",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.prospeccionData.segmento) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.prospeccionData.segmento]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[18] || (_cache[18] = _createElementVNode("label", {
            for: "razonSocial",
            class: "form-label fw-medium"
          }, "N° de seguimiento:", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "razonSocial",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.prospeccionData.seguimiento) = $event)),
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.prospeccionData.seguimiento]
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[19] || (_cache[19] = _createElementVNode("label", {
            for: "observacion",
            class: "form-label fw-medium"
          }, "Observación:", -1)),
          _withDirectives(_createElementVNode("textarea", {
            class: "form-control",
            id: "observacion",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.prospeccionData.observacion) = $event)),
            rows: "3",
            readonly: ""
          }, null, 512), [
            [_vModelText, _ctx.prospeccionData.observacion]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-sm btn-secondary text-center ms-2",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, " Cerrar "),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-sm btn-primary text-center ms-2",
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.sendEdit && _ctx.sendEdit(...args)))
          }, " Guardar Cambios ")
        ])
      ]),
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: "Cargando..."
      }, null, 8, ["active"])
    ]),
    _: 1
  }, 8, ["visible"]))
}