<template>
    <div class="row">
        <div class="col-md-12">
            <Welcome />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Welcome from "@/components/Cmc/Home/Welcome.vue";

export default defineComponent({
    name: "HomePage",
    components: {
        Welcome,
    },
});
</script>