import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/user.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing lead-info-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_3 = { class: "profile-info d-sm-flex align-items-center" }
const _hoisted_4 = { class: "title mt-12 mt-sm-0" }
const _hoisted_5 = { class: "text-black fw-medium mb-8" }
const _hoisted_6 = { class: "d-block fw-bold text-primary" }
const _hoisted_7 = { class: "info mt-25 ps-0 mb-0 list-unstyled" }
const _hoisted_8 = { class: "position-relative" }
const _hoisted_9 = {
  href: "mailto:johnathon23@gmail.com",
  class: "d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none"
}
const _hoisted_10 = { class: "position-relative" }
const _hoisted_11 = {
  href: "tel:+1-321-456-8756",
  class: "d-inline-block fs-md-15 fs-lg-16 text-muted text-decoration-none"
}
const _hoisted_12 = { class: "position-relative" }
const _hoisted_13 = { class: "d-inline-block fs-md-15 fs-lg-16 text-muted" }
const _hoisted_14 = { class: "position-relative" }
const _hoisted_15 = { class: "d-inline-block fs-md-15 fs-lg-16 text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          class: "rounded",
          alt: "user"
        }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString($setup.nombre), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString($setup.rolNombre), 1)
        ])
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "border-top mt-15 mb-15 mt-sm-20 mb-sm-20 mt-md-25 mb-md-25 mt-lg-30 mb-lg-30" }, null, -1)),
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "d-flex align-items-center justify-content-between" }, [
        _createElementVNode("h5", { class: "fw-medium text-black-emphasis mb-0" }, "Información")
      ], -1)),
      _createElementVNode("ul", _hoisted_7, [
        _createElementVNode("li", _hoisted_8, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "icon text-success rounded-circle text-center" }, [
            _createElementVNode("i", { class: "flaticon-mail-inbox-app" })
          ], -1)),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "d-block text-black mb-5 fw-semibold" }, "Email", -1)),
          _createElementVNode("a", _hoisted_9, _toDisplayString($setup.email), 1)
        ]),
        _createElementVNode("li", _hoisted_10, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "icon text-info rounded-circle text-center" }, [
            _createElementVNode("i", { class: "flaticon-telephone-call" })
          ], -1)),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "d-block text-black mb-5 fw-semibold" }, "Telefono", -1)),
          _createElementVNode("a", _hoisted_11, _toDisplayString($setup.telefono), 1)
        ]),
        _createElementVNode("li", _hoisted_12, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "icon text-warning rounded-circle text-center" }, [
            _createElementVNode("i", { class: "flaticon-industry" })
          ], -1)),
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "d-block text-black mb-5 fw-semibold" }, "RFC", -1)),
          _createElementVNode("span", _hoisted_13, _toDisplayString($setup.rfc), 1)
        ]),
        _createElementVNode("li", _hoisted_14, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "icon text-info rounded-circle text-center" }, [
            _createElementVNode("i", { class: "flaticon-employee" })
          ], -1)),
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "d-block text-black mb-5 fw-semibold" }, " Dirección ", -1)),
          _createElementVNode("span", _hoisted_15, _toDisplayString($setup.direccion), 1)
        ])
      ])
    ])
  ]))
}