import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white number-of-tickets-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = {
  id: "numberOfTicketsChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-sm-flex align-items-center justify-content-between\"><h6 class=\"card-title fw-bold mb-0\">Number Of Tickets</h6><div class=\"card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5\"><select class=\"form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium\"><option value=\"1\" class=\"fw-medium\" selected>March</option><option value=\"2\" class=\"fw-medium\">May</option><option value=\"3\" class=\"fw-medium\">June</option><option value=\"4\" class=\"fw-medium\">July</option></select></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_apexchart, {
          type: "bar",
          height: "251",
          options: _ctx.numberOfTicketsChart,
          series: _ctx.number
        }, null, 8, ["options", "series"])
      ]),
      _cache[1] || (_cache[1] = _createStaticVNode("<ul class=\"info ps-0 mb-0 list-unstyled text-center\"><li class=\"d-inline-block\"><div class=\"d-flex align-items-end mb-5\"><h4 class=\"mb-0 text-primary lh-1 fw-black\">62</h4><span class=\"fw-bold text-success d-inline-block position-relative top-1 ms-5\"><i class=\"flaticon-up-arrow fs-11 lh-1\"></i> 5.5% </span></div><span class=\"fw-medium fs-12 text-black-emphasis text-uppercase\"> ON HOLD TICKETS </span></li><li class=\"d-inline-block\"><div class=\"d-flex align-items-end mb-5\"><h4 class=\"mb-0 text-info-light lh-1 fw-black\">126</h4><span class=\"fw-bold text-danger d-inline-block position-relative top-1 ms-5\"><i class=\"flaticon-down-arrow fs-11 lh-1\"></i> 0.5% </span></div><span class=\"fw-medium fs-12 text-black-emphasis text-uppercase\"> OPEN TICKETS </span></li><li class=\"d-inline-block\"><div class=\"d-flex align-items-end mb-5\"><h4 class=\"mb-0 text-warning lh-1 fw-black\">57</h4><span class=\"fw-bold text-success d-inline-block position-relative top-1 ms-5\"><i class=\"flaticon-up-arrow fs-11 lh-1\"></i> 1.5% </span></div><span class=\"fw-medium fs-12 text-black-emphasis text-uppercase\"> DUE TICKETS </span></li><li class=\"d-inline-block\"><div class=\"d-flex align-items-end mb-5\"><h4 class=\"mb-0 text-success lh-1 fw-black\">126</h4><span class=\"fw-bold text-danger d-inline-block position-relative top-1 ms-5\"><i class=\"flaticon-down-arrow fs-11 lh-1\"></i> 0.5% </span></div><span class=\"fw-medium fs-12 text-black-emphasis text-uppercase\"> UNASSIGNED TICKETS </span></li></ul>", 1))
    ])
  ]))
}