import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-lg-15 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5" }
const _hoisted_5 = ["value"]
const _hoisted_6 = {
  id: "topSoldProductsChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "card-title fw-bold mb-0" }, "Productos más vendidos", -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-medium text-muted me-8" }, "Last", -1)),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMonths) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fetchTopSoldProducts && _ctx.fetchTopSoldProducts(...args))),
            class: "form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium"
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (month) => {
              return _createElementVNode("option", {
                key: month,
                value: month
              }, _toDisplayString(month) + " Months", 9, _hoisted_5)
            }), 64))
          ], 544), [
            [_vModelSelect, _ctx.selectedMonths]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_apexchart, {
          type: "bar",
          height: "385",
          options: _ctx.chartOptions,
          series: _ctx.seriesData
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}