import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dark-swtich-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "switch-toggle transition d-inline-block position-relative border-0 rounded-circle",
      id: "switch-toggle",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDarkMode && _ctx.toggleDarkMode(...args)))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("i", { class: "flaticon-moon" }, null, -1)
    ]))
  ]))
}